import { Breakpoint } from '@mui/material';
import { Action, action } from 'easy-peasy';
import React, { ReactNode } from 'react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import {
  defaultCallback,
  defaultMaxLength,
  defaultModalCancel,
  defaultModalConfirm,
  sizeChoices,
} from '../common/defaults';

export interface IConfirmationModal {
  confirmButtonLabel?: string;
  confirmButtonColor?: SemanticCOLORS;
  confirmationSize: Breakpoint | false;
  content: React.ReactNode;
  showModal: boolean;
  title: string;
}

export interface IConfirmActionModal {
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  confirmValue: string;
  freeInput?: boolean;
  confirmValueIsNumber: boolean;
  maxLength?: number;
  showModal: boolean;
  size?: sizeChoices;
  text: string;
  title: ReactNode;
  confirmCallback?(data: number | string): Promise<void>;
  cancelCallback?(): void;
}

export interface IFlexibleModal {
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  showModal: boolean;
  size?: sizeChoices;
  content: ReactNode;
  title: ReactNode;
  requireInput: boolean;
  confirmCallback?(): Promise<void>;
  cancelCallback?(): void;
}

export interface IConfirmStrictModal {
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  confirmValue: string;
  showModal: boolean;
  size?: sizeChoices;
  text: React.ReactNode;
  title: ReactNode;
  confirmCallback?(): Promise<void>;
}

export interface IConfirmSimpleModal {
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  content: React.ReactNode;
  showModal: boolean;
  size?: sizeChoices;
  title: ReactNode;
  confirmCallback?(): void;
}

export interface IModalModel {
  confirmationContent: React.ReactNode;
  confirmationTitle: string;
  confirmationSize: Breakpoint | false;
  confirmationShow: boolean;
  confirmationButtonLabel: string;
  confirmationToggle: Action<IModalModel, IConfirmationModal>;

  confirmSimpleCancelLabel: string;
  confirmSimpleConfirmLabel: string;
  confirmSimpleContent: React.ReactNode;
  confirmSimpleTitle: ReactNode;
  confirmSimpleShow: boolean;
  confirmSimpleToggle: Action<IModalModel, IConfirmSimpleModal>;
  confirmSimpleCallback: () => void;

  confirmStrictCancelLabel: string;
  confirmStrictConfirmLabel: string;
  confirmStrictConfirmValue: string;
  confirmStrictShow: boolean;
  confirmStrictText: React.ReactNode;
  confirmStrictTitle: ReactNode;
  confirmStrictToggle: Action<IModalModel, IConfirmStrictModal>;
  confirmStrictCallback: () => void;

  confirmActionCancelLabel: string;
  confirmActionConfirmLabel: string;
  confirmActionConfirmValue: string;
  confirmActionFreeInput: boolean;
  confirmActionMaxLength: number;
  confirmActionValueIsNumber: boolean;
  confirmActionText: string;
  confirmActionTitle: ReactNode;
  confirmActionShow: boolean;
  confirmActionToggle: Action<IModalModel, IConfirmActionModal>;
  cancelActionCallback: () => void;
  confirmActionCallback: (data: number | string) => void;

  flexibleCancelLabel: string;
  flexibleConfirmLabel: string;
  flexibleContent: ReactNode;
  flexibleTitle: ReactNode;
  flexibleShow: boolean;
  toggleFlexibleModal: Action<IModalModel, IFlexibleModal>;
  flexibleRequireInput: boolean;
  flexibleCancelCallback: () => void;
  flexibleCallback: () => void;
  flexibleConfirmDisabled: boolean;
  setFlexibleConfirmDisabled: Action<IModalModel, boolean>;
}

const modalModel: IModalModel = {
  cancelActionCallback: defaultCallback,
  confirmActionCallback: defaultCallback,
  confirmActionCancelLabel: defaultModalCancel,
  confirmActionConfirmLabel: defaultModalConfirm,
  confirmActionConfirmValue: '',
  confirmActionFreeInput: false,
  confirmActionMaxLength: defaultMaxLength,
  confirmActionShow: false,
  confirmActionText: '',
  confirmActionTitle: '',
  confirmActionValueIsNumber: true,
  confirmActionToggle: action((state, payload) => {
    state.confirmActionConfirmLabel = payload.confirmButtonLabel ? payload.confirmButtonLabel : defaultModalConfirm;
    state.confirmActionCancelLabel = payload.cancelButtonLabel ? payload.cancelButtonLabel : defaultModalCancel;
    state.confirmActionConfirmValue = payload.confirmValue;
    state.confirmActionFreeInput = payload.freeInput ? payload.freeInput : false;
    state.confirmActionMaxLength = payload.maxLength ? payload.maxLength : defaultMaxLength;
    state.confirmActionText = payload.text;
    state.confirmActionTitle = payload.title;
    state.confirmActionShow = payload.showModal;
    state.confirmActionValueIsNumber = payload.confirmValueIsNumber;
    state.confirmActionCallback = payload.confirmCallback ? payload.confirmCallback : defaultCallback;
    state.cancelActionCallback = payload.cancelCallback ? payload.cancelCallback : defaultCallback;
  }),

  flexibleCancelCallback: defaultCallback,
  flexibleCallback: defaultCallback,
  flexibleCancelLabel: defaultModalCancel,
  flexibleConfirmLabel: defaultModalConfirm,
  flexibleShow: false,
  flexibleTitle: '',
  flexibleContent: null,
  flexibleRequireInput: false,
  toggleFlexibleModal: action((state, payload) => {
    state.flexibleConfirmLabel = payload.confirmButtonLabel ? payload.confirmButtonLabel : defaultModalConfirm;
    state.flexibleCancelLabel = payload.cancelButtonLabel ? payload.cancelButtonLabel : defaultModalCancel;
    state.flexibleShow = payload.showModal;
    state.flexibleTitle = payload.title;
    state.flexibleContent = payload.content;
    state.flexibleRequireInput = payload.requireInput;
    state.flexibleCallback = payload.confirmCallback ? payload.confirmCallback : defaultCallback;
    state.flexibleCancelCallback = payload.cancelCallback ? payload.cancelCallback : defaultCallback;
  }),
  flexibleConfirmDisabled: false,
  setFlexibleConfirmDisabled: action((state, payload) => {
    state.flexibleConfirmDisabled = payload;
  }),

  confirmSimpleCallback: defaultCallback,
  confirmSimpleCancelLabel: defaultModalCancel,
  confirmSimpleConfirmLabel: defaultModalConfirm,
  confirmSimpleContent: undefined,
  confirmSimpleShow: false,
  confirmSimpleTitle: '',
  confirmSimpleToggle: action((state, payload) => {
    state.confirmSimpleCancelLabel = payload.cancelButtonLabel ? payload.cancelButtonLabel : defaultModalCancel;
    state.confirmSimpleConfirmLabel = payload.confirmButtonLabel ? payload.confirmButtonLabel : defaultModalConfirm;
    state.confirmSimpleContent = payload.content;
    state.confirmSimpleTitle = payload.title;
    state.confirmSimpleShow = payload.showModal;
    state.confirmSimpleCallback = payload.confirmCallback ? payload.confirmCallback : defaultCallback;
  }),

  confirmStrictCallback: defaultCallback,
  confirmStrictCancelLabel: defaultModalCancel,
  confirmStrictConfirmLabel: defaultModalConfirm,
  confirmStrictConfirmValue: '',
  confirmStrictShow: false,
  confirmStrictText: '',
  confirmStrictTitle: '',
  confirmStrictToggle: action((state, payload) => {
    state.confirmStrictConfirmLabel = payload.confirmButtonLabel ? payload.confirmButtonLabel : defaultModalConfirm;
    state.confirmStrictCancelLabel = payload.cancelButtonLabel ? payload.cancelButtonLabel : defaultModalCancel;
    state.confirmStrictConfirmValue = payload.confirmValue;
    state.confirmStrictText = payload.text;
    state.confirmStrictTitle = payload.title;
    state.confirmStrictShow = payload.showModal;
    state.confirmStrictCallback = payload.confirmCallback ? payload.confirmCallback : defaultCallback;
  }),

  confirmationContent: undefined,
  confirmationButtonLabel: defaultModalConfirm,
  confirmationShow: false,
  confirmationSize: false,
  confirmationTitle: '',
  confirmationToggle: action((state, payload) => {
    state.confirmationContent = payload.content;
    state.confirmationButtonLabel = payload.confirmButtonLabel ? payload.confirmButtonLabel : defaultModalConfirm;
    state.confirmationSize = payload.confirmationSize;
    state.confirmationTitle = payload.title;
    state.confirmationShow = payload.showModal;
  }),
};

export default modalModel;
