import { Grid } from '@mui/material';
import { useStoreActions, useStoreState } from '../../Store';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../modules/common/components';
import FpButton from '../../modules/common/components/FpButton/FpButton';
import './ModalStyles.scss';

const ModalFlexible = (): JSX.Element => {
  const flexibleCancelCallback = useStoreState((state) => state.modalModel.flexibleCancelCallback);
  const flexibleCallback = useStoreState((state) => state.modalModel.flexibleCallback);

  const flexibleCancelLabel = useStoreState((state) => state.modalModel.flexibleCancelLabel);
  const flexibleConfirmLabel = useStoreState((state) => state.modalModel.flexibleConfirmLabel);
  const flexibleShow = useStoreState((state) => state.modalModel.flexibleShow);
  const flexibleTitle = useStoreState((state) => state.modalModel.flexibleTitle);
  const flexibleContent = useStoreState((state) => state.modalModel.flexibleContent);

  const flexibleRequireInput = useStoreState((state) => state.modalModel.flexibleRequireInput);
  const flexibleConfirmDisabled = useStoreState((state) => state.modalModel.flexibleConfirmDisabled);

  const flexibleToggle = useStoreActions((actions) => actions.modalModel.toggleFlexibleModal);

  const closeModal = () => {
    flexibleToggle({
      title: '',
      requireInput: false,
      showModal: false,
      content: null,
    });
    flexibleCancelCallback();
  };

  const confirmAction = () => {
    flexibleToggle({
      title: '',
      requireInput: false,
      showModal: false,
      content: null,
    });
    flexibleCallback();
  };

  const disableConfirm = flexibleRequireInput ? flexibleConfirmDisabled : false;

  return (
    <Dialog open={flexibleShow} onClose={closeModal}>
      <DialogTitle>{flexibleTitle}</DialogTitle>

      <DialogContent>
        <Grid container spacing={{ sm: 1 }} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {flexibleContent}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FpButton color="error" onClick={closeModal}>
          {flexibleCancelLabel}
        </FpButton>
        <FpButton color="secondary" onClick={confirmAction} disabled={disableConfirm}>
          {flexibleConfirmLabel}
        </FpButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalFlexible;
