import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { IResponse } from '@hsjakobsen/utilities';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Fragment, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { Button, DropdownProps, Header } from 'semantic-ui-react';
import FlexRow from '../../../../Components/Containers/FlexRow';
import DropdownListSearchable from '../../../../Components/Input/DropdownListSearchable';
import InputComponentToggle from '../../../../Components/Input/InputComponentToggle';
import HeaderBar from '../../../../Components/Layout/HeaderBar';
import LoaderComponent from '../../../../Components/Loader/LoaderComponent';
import { defaultBaseFilter } from '../../../../Models/graphSettings';
import { useStoreActions, useStoreState } from '../../../../Store';
import { IProcessedDeviceDetails } from '../../../../common/interfacesData';
import { refreshInterval10Seconds, refreshIntervalOneMinute } from '../../../../config';
import FlowplanBarChart from '../../../common/charts/Bar/FlowplanBarChart';
import FpContainer from '../../../common/components/FpContainer/FpContainer';
import InstallationDetailsInsights from '../../../insights/components/InstallationDetailsInsights/InstallationDetailsInsights';
import InstallationHistoryButton from '../../../installation-history/components/InstallationHistoryButton/InstallationHistoryButton';
import InstallationAddNote from '../Actions/AddNote';
import InstallationAddUsage from '../Actions/AddUsage';
import InstallationChangeBattery from '../Actions/ChangeBattery';
import ChangeFlowmeter from '../Actions/ChangeFlowmeter';
import InstallationChangeSerial from '../Actions/ChangeSerial';
import InstallationChangeWaterFilter from '../Actions/ChangeWaterFilter';
import InstallationConfiguration from '../Actions/Configuration';
import InstallationDelete from '../Actions/Delete';
import InstallationLog from '../Actions/Log';
import InstallationMaintenance from '../Actions/Maintenance';
import InstallationResetAverage from '../Actions/ResetAverage';
import InstallationSettings from '../Actions/Settings';
import DataParsingStatusComponent from '../DataParsingStatus/DataParsingStatus';
import DataOverview from './Graphs/DataOverviewList';
import GraphSectionControls from './Graphs/GraphSectionControls';
import GraphSectionInfo from './Graphs/GraphSectionInfoSection';
import styles from './InstallationDetails.module.scss';
import InstallationDetailsBasic from './InstallationDetailsBasic';
import InstallationDetailsBeam from './InstallationDetailsBeam';
import InstallationDetailsFeatures from './InstallationDetailsFeatures';
import InstallationDetailsInstallation from './InstallationDetailsInstallation';
import { Paths } from '../../../navigation/containers/Navigation/navigation-types';

let sensorDetailsRefreshInterval: NodeJS.Timeout;

type InstallationDetailsProps = {
  showCompanyDropdown: boolean;
};

enum ActivityState {
  Active = 'Active',
  Idle = 'Idle',
}

const InstallationDetails = ({ showCompanyDropdown }: InstallationDetailsProps): JSX.Element => {
  const loadingData = useStoreState((state) => state.deviceDataModel.loadingData);
  const deviceDetails: IProcessedDeviceDetails = useStoreState(
    (state) => state.deviceActionsModel.sensorDetailsProcessedDeviceDetails,
  );

  const access = useStoreState((state) => state.authModel.access);
  const graphData = useStoreState((state) => state.deviceDataModel.graphData);
  const barSize = useStoreState((state) => state.graphSettingsModel.barSize);
  const dateSelection = useStoreState((state) => state.graphSettingsModel.graphSettingsTime.dateSelection);
  const graphDomainAndTicks = useStoreState((state) => state.deviceDataModel.graphDomainAndTicks);
  const stockGraphDomainAndTicks = useStoreState((state) => state.deviceDataModel.stockGraphDomainAndTicks);
  const graphTitle = useStoreState((state) => state.graphSettingsModel.graphTitle);
  const selectedInstallation = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const isPremium = useStoreState((state) => state.companyModel.isPremium);
  const stockSettings = useStoreState((state) => state.stockDailyDataModel.stockSettings);
  const sensorDetailsIntervalUpdate = useStoreState((state) => state.deviceActionsModel.sensorDetailsIntervalUpdate);
  const flowplanClientSelected = useStoreState((state) => state.adminDataModel.flowplanClientSelected);

  const getFilterDetailsData = useStoreActions((actions) => actions.deviceActionsModel.getFilterDetailsData);
  const toggleShowDataList = useStoreActions((actions) => actions.deviceDataModel.setShowDataList);
  const showDataList = useStoreState((state) => state.deviceDataModel.showDataList);
  const setSensorDetailsInterval = useStoreActions((actions) => actions.deviceActionsModel.setSensorDetailsInterval);

  const getStockAndSettings = useStoreActions((actions) => actions.stockDailyDataModel.getStockAndSettings);

  const retrievePeriodData = useStoreActions((actions) => actions.deviceDataModel.retrievePeriodData);
  const getInstallationById = useStoreActions((actions) => actions.deviceActionsModel.getInstallationById);
  const companyInstallations = useStoreState((state) => state.companyModel.companyInstallations);

  const setSelectedInstallation = useStoreActions((actions) => actions.graphSettingsModel.setSelectedInstallation);

  const [sensorSelected, setSensorSelected] = useState<number>(-1);
  const [activityState, setActivityState] = useState<string>(ActivityState.Active);

  const navigate = useNavigate();

  useIdleTimer({
    onIdle: () => {
      setActivityState(ActivityState.Idle);
    },
    onActive: () => {
      setActivityState(ActivityState.Active);
    },
    timeout: 600000,
    throttle: 500,
  });

  useEffect(() => {
    if (sensorDetailsRefreshInterval) {
      clearInterval(sensorDetailsRefreshInterval);
    }

    const intervalSpeed = activityState === ActivityState.Active ? refreshInterval10Seconds : refreshIntervalOneMinute;
    sensorDetailsRefreshInterval = setInterval(() => {
      setSensorDetailsInterval(null);
    }, intervalSpeed);

    return () => {
      clearInterval(sensorDetailsRefreshInterval);
      setSensorDetailsInterval(0);
    };
  }, [setSensorDetailsInterval, activityState]);

  useEffect(() => {
    setSensorSelected(selectedInstallation.infoBasic.id);
    if (isPremium && selectedInstallation.infoBasic.id !== 0) {
      getStockAndSettings({
        locationId: selectedInstallation.infoLocationAndClient.locationId,
        sensorId: selectedInstallation.infoBasic.id,
      });
    }
  }, [isPremium, getStockAndSettings, selectedInstallation]);

  const retrieveDetails = useCallback(async () => {
    const detailsResponse: boolean = await getFilterDetailsData();
    if (!detailsResponse) {
      navigate(Paths.installations);
    }
  }, [navigate, getFilterDetailsData]);

  useEffect(() => {
    if (selectedInstallation.infoBasic.id === 0) {
      return;
    }
    retrieveDetails();
  }, [selectedInstallation, retrieveDetails, sensorDetailsIntervalUpdate, flowplanClientSelected]);

  const installationControls = showCompanyDropdown ? (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Divider>Find installation</Divider>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <DropdownListSearchable
          className='do-not-translate'
          items={companyInstallations}
          selectedValue={sensorSelected}
          placeholder="Installations"
          onChangeFunction={async (evt: SyntheticEvent, { value }: DropdownProps) => {
            const sensorId = Number(value);
            setSensorSelected(sensorId);
            if (!sensorId) {
              setSelectedInstallation(defaultBaseFilter);
              return;
            }
            const installationResponse: IResponse = await getInstallationById(sensorId);
            const installation = installationResponse.data as IDeviceView;
            if (installation?.infoBasic.id) {
              setSelectedInstallation(installation);
              retrievePeriodData();
            }
          }}
        />
      </Grid>
    </>
  ) : null;

  if (!sensorSelected || !selectedInstallation.infoBasic.id) {
    return (
      <FpContainer>
        <Header as={'h3'} textAlign="center">
          Select an installation
        </Header>
        {companyInstallations.length === 0 && (
          <Header textAlign="center" as={'h2'}>
            Select company
          </Header>
        )}
        {companyInstallations.length > 0 && (
          <Grid
            container
            spacing={{ xs: 0, sm: 0 }}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2 }}
            className={styles.muiGrid}
          >
            {installationControls}
          </Grid>
        )}
      </FpContainer>
    );
  }

  return (
    <Fragment>
      <FpContainer marginBottom>
        <Grid
          container
          spacing={{ xs: 0, sm: 0 }}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2 }}
          className={styles.muiGrid}
        >
          {installationControls}
          {access.installations.manage && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider>Actions</Divider>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <InstallationChangeWaterFilter featureEnabled={deviceDetails.infoFilter.featureEnabled} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <InstallationMaintenance featureEnabled={deviceDetails.infoService.featureEnabled} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <InstallationSettings />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <InstallationAddUsage
                  featureEnabled={deviceDetails.infoFilter.featureEnabled || deviceDetails.infoService.featureEnabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <InstallationAddNote />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <InstallationHistoryButton />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <InstallationChangeBattery featureEnabled={deviceDetails.infoBeam.showBatteryInformation} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <InstallationChangeSerial />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <ChangeFlowmeter />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <InstallationResetAverage />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <InstallationDelete />
              </Grid>

              {access.flowplanAdmin && (
                <>
                  <Grid item xs={12} sm={6} md={4} lg={6}>
                    <InstallationConfiguration />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={6}>
                    <InstallationLog />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </FpContainer>

      <InputComponentToggle hideComponent={sensorSelected === -1}>
        <FlexRow>
          <InstallationDetailsBasic baseInformation={deviceDetails.infoBasic} tags={deviceDetails.infoTags} />
          <InstallationDetailsBeam beamInfo={deviceDetails.infoBeam} />
          <InstallationDetailsInstallation
            installationInformation={deviceDetails.infoInstallation}
            infoService={selectedInstallation.infoService}
            infoFilter={selectedInstallation.infoFilter}
            statusInformation={deviceDetails.infoStatus}
          />
          <InstallationDetailsFeatures
            filterInformation={deviceDetails.infoFilter}
            serviceInformation={deviceDetails.infoService}
          />
        </FlexRow>

        <DataParsingStatusComponent dataParsingStatus={selectedInstallation.infoDeviceMetrics.dataParsingStatus} />

        <FlexRow>
          <InstallationDetailsInsights />
          <GraphSectionInfo
            initialInstallDate={deviceDetails.infoInstallation.initialInstallDate}
            maintenanceEnabled={deviceDetails.infoService.featureEnabled}
            waterFilterEnabled={deviceDetails.infoFilter.featureEnabled}
          />
        </FlexRow>

        <FpContainer marginTop marginBottom>
          <div className={styles.graphSection}>
            <div className={styles.graphTitleSection}>{graphTitle}</div>
            <FlowplanBarChart
              barSize={barSize}
              chartData={graphData}
              chartHeight={350}
              maxBarSize={60}
              chartWidthPercentage={100}
              yAxisDomain={graphDomainAndTicks.domain}
              yAxisTicks={graphDomainAndTicks.yAxisTicks}
              yAxisTopPadding={0}
              dataKey="value"
              dataUnitXAxis=""
              dataUnitYAxis="L"
              stockDataUnitYAxis="kg"
              dataUnitTooltip={'L'}
              stockYAxisDomain={stockGraphDomainAndTicks.domain}
              stockYAxisTicks={stockGraphDomainAndTicks.yAxisTicks}
              hasBackground={false}
              showGrid={true}
            />
            <GraphSectionControls retrieveStockUsage={stockSettings.length !== 0} />
          </div>

          <div className={styles.graphButtons}>
            <Button
              fluid
              color="blue"
              onClick={() => {
                toggleShowDataList();
                setSensorDetailsInterval(0);
              }}
            >
              Toggle data list
            </Button>
            <LoaderComponent loading={loadingData} size="large" />
          </div>
        </FpContainer>
        {showDataList && <HeaderBar>Installation data from: {dateSelection.format(dateFormatYMD)}</HeaderBar>}
        <DataOverview />
      </InputComponentToggle>
    </Fragment>
  );
};

export default InstallationDetails;
