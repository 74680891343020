import { DeviceStatus, formatLiters, formatNumberNoDecimals } from '@flowplan/flowplan-shared';
import { ConsumptionState } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { IInfoFilter, IInfoService } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import { Icon } from 'semantic-ui-react';
import StatusConnection from '../../../../Components/ListItems/Status/Status.Connection';
import { useStoreState } from '../../../../Store';
import { useTimeSinceLastSeen } from '../../../../hooks/TimeSinceLastSeen';
import { IInstallationInformation } from '../../types/details/installation-details-installation';
import { IStatusInformation } from '../../types/details/installation-details-status';
import DetailsItem from '../Common/DetailsItem';
import DetailsSection from '../Common/DetailsSection';
import estimateStyles from '../Estimate/estimate.module.scss';
import { showEstimatedUsage } from '../Tasks/tasks-utility';
import styles from './InstallationDetailsInstallation.module.scss';
import InstallationDetailsExternalSensor from './InstallationDetailsInstallationExternalSensor';
import FpText from '../../../common/components/FpText/FpText';

interface IInstallationDetailsInstallation {
  installationInformation: IInstallationInformation;
  statusInformation: IStatusInformation;
  infoFilter: IInfoFilter;
  infoService: IInfoService;
}

const InstallationDetailsInstallation = ({
  installationInformation,
  infoFilter,
  infoService,
  statusInformation,
}: IInstallationDetailsInstallation): JSX.Element => {
  const graphDataDetails = useStoreState((state) => state.deviceDataModel.graphDataDetails);

  const {
    averageDailyConsumption,
    averageDailyConsumptionDays,
    deviceStatus,
    hoursNotSeen,
    lastSeen,
    consumptionState,
  } = statusInformation;

  const { flowmeterName, initialInstallDate } = installationInformation;

  const showEstimate = showEstimatedUsage({
    consumptionState,
    infoFilter,
    infoService,
  });

  const averageUsage = averageDailyConsumption ? formatLiters(Number(averageDailyConsumption)) : '0';
  const averageEvents = formatNumberNoDecimals(graphDataDetails.averageDaily.events);

  const timeSinceLastSeen = useTimeSinceLastSeen({ deviceStatus, lastSeen });
  const formatedTime = timeSinceLastSeen.valueTwo ? (
    <>
      <FpText variant='body1' sx={{ fontWeight: 'bold' }} componentType='span'>Time since event: </FpText>
      <FpText variant='body1' sx={{ fontWeight: 'bold' }} componentType='span'>{timeSinceLastSeen.valueTwo}</FpText>
    </>) : ''

  return (
    <DetailsSection sectionTitle="Installation">
      <DetailsItem title="Flowmeter" value={flowmeterName}>
        <Icon name="exchange" size="big" />
      </DetailsItem>

      <DetailsItem title="Install date" value={initialInstallDate}>
        <Icon name="calendar alternate outline" size="big" />
      </DetailsItem>

      <DetailsItem
        title={'Average daily activity ' + averageDailyConsumptionDays}
        value={averageUsage + 'L - ' + averageEvents + ' events '}
      >
        <Icon name="chart line" size="big" />
      </DetailsItem>
      <DetailsItem title="Last seen" value={timeSinceLastSeen.valueOne} secondValue={formatedTime}>
        <StatusConnection status={deviceStatus} hoursNotSeen={hoursNotSeen} showIcon={false} signalStrength={0} />
      </DetailsItem>
      {showEstimate && consumptionState === ConsumptionState.NORMAL && deviceStatus !== DeviceStatus.Online && (
        <DetailsItem
          customStyle={estimateStyles.estimateBackgroundAndBorderRounded}
          title="Usage is being estimated"
          value="Powered by Flowplan® Insights"
        >
          <Icon name="calculator" size="big" />
        </DetailsItem>
      )}
      {consumptionState !== ConsumptionState.NORMAL && (
        <DetailsItem
          customStyle={estimateStyles.estimateBackgroundAndBorderRounded}
          title="Low usage action taken:"
          value={
            consumptionState === ConsumptionState.ESTIMATE
              ? 'Estimate usage until flowevents start again'
              : 'Ignored, waiting until usage starts again'
          }
        >
          {consumptionState === ConsumptionState.ESTIMATE ? (
            <EqualizerIcon className={styles.lowUsageEstimate} />
          ) : (
            <NotificationsPausedIcon className={styles.lowUsageIgnored} />
          )}
        </DetailsItem>
      )}
      <InstallationDetailsExternalSensor {...installationInformation} />
    </DetailsSection>
  );
};

export default InstallationDetailsInstallation;
