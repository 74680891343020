import React, { memo, ReactNode } from 'react';
import './DetailsItem.scss';
import './DetailsItemAligned.scss';

interface IDetailsItemDatasets {
  children: ReactNode;
  customStyle: string;
  icon: ReactNode;
}

const DetailsItemDataSets = memo(({ icon, children, customStyle }: IDetailsItemDatasets) => {
  const childrenContainer = <div className="child">{icon}</div>;

  return (
    <div className={'details-item-aligned ' + customStyle}>
      {childrenContainer}
      <div className="data-sets">{children}</div>
    </div>
  );
});

export default DetailsItemDataSets;
