import { CompanyTag, InstallationTag } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useStoreState } from '../Store';

export type TagStatus = {
    companyTags: CompanyTag[];
    installationTags: InstallationTag[];
}

export function useAvailableInstallationTags() {
    const installationTags = useStoreState((state) => state.installationsModel.installationTags);
    const companyTags = useStoreState((state) => state.companyModel.companyTags);
    const currentUser = useStoreState((state) => state.authModel.currentUser);
    const companyId = useStoreState((state) => state.companyModel.companyId);
    const flowplanClientSelected = useStoreState((state) => state.adminDataModel.flowplanClientSelected);

    const superAdmin = currentUser.role === RoleType.SUPER_ADMIN;
    const isAdminViewingDifferentCompany = flowplanClientSelected !== companyId && superAdmin;

    const [tagStatus, setTagStatus] = useState<TagStatus>({
        companyTags: [],
        installationTags: [],
    })

    useEffect(() => {
        const companyTagIds = companyTags.map((companyTag) => companyTag.id);
        const installationTagIds = installationTags.map((installationTag) => installationTag.tags.id);
        const installationTagsToReturn = installationTags.filter((installationTag) => companyTagIds.indexOf(installationTag.tags.id) !== -1);
        let companyTagsToReturn = companyTags.filter((companyTag) => installationTagIds.indexOf(companyTag.id) === -1);

        if (isAdminViewingDifferentCompany) {
            companyTagsToReturn = companyTagsToReturn.filter((item) => item.flowplanClientId === null);
        }

        setTagStatus({
            companyTags: _.sortBy(companyTagsToReturn, 'name'),
            installationTags: _.sortBy(installationTagsToReturn, item => item.tags.name)
        });

    }, [installationTags, companyTags, isAdminViewingDifferentCompany])
    return tagStatus;
}   