import { PageTitles, Paths } from "../modules/navigation/containers/Navigation/navigation-types"

export function getPageTitle(path: string) {
  if (path.startsWith(Paths.admin)) {
    return PageTitles.admin
  }
  if (path.startsWith(Paths.clients)) {
    return PageTitles.clients
  }
  if (path.startsWith(Paths.support)) {
    return PageTitles.support
  }
  if (path.startsWith(Paths.installations)) {
    return PageTitles.installations
  }
  if (path.startsWith(Paths.installationDetails)) {
    return PageTitles.installationDetails
  }
  if (path.startsWith(Paths.inventoryBeams)) {
    return PageTitles.inventoryBeams
  }
  if (path.startsWith(Paths.inventoryBottles)) {
    return PageTitles.inventoryBottles
  }
  if (path.startsWith(Paths.waterfilterLocations)) {
    return PageTitles.waterfilterLocations
  }
  if (path.startsWith(Paths.login)) {
    return PageTitles.login
  }
  if (path.startsWith(Paths.passwordForgotten)) {
    return PageTitles.passwordForgotten
  }
  if (path.startsWith(Paths.passwordReset)) {
    return PageTitles.passwordReset
  }
  if (path.startsWith(Paths.tasks)) {
    return PageTitles.tasks
  }
  if (path.startsWith(Paths.users)) {
    return PageTitles.users
  }
  if (path.startsWith(Paths.water)) {
    return PageTitles.water
  }
  if (path.startsWith(Paths.bulkInstallations)) {
    return PageTitles.bulkInstallations
  }
  if (path.startsWith(Paths.workOrders)) {
    return PageTitles.workOrders
  }
  if (path.startsWith(Paths.orders)) {
    return PageTitles.orders
  }
  if (path.startsWith(Paths.tapDashboard)) {
    return PageTitles.tapDashboard
  }
  if (path.startsWith(Paths.tapLocations)) {
    return PageTitles.tapLocations
  }
  if (path.startsWith(Paths.tapSettings)) {
    return PageTitles.tapSettings
  }
  if (path.startsWith(Paths.tapStock)) {
    return PageTitles.tapStock
  }
  if (path.startsWith(Paths.tapKeg)) {
    return PageTitles.tapKeg
  }
  if (path.startsWith(Paths.tapTechnician)) {
    return PageTitles.tapTechnician
  }
  if (path.startsWith(Paths.reports)) {
    return PageTitles.reports
  }

  return PageTitles.dashboard
}
