import { IFilter, notAvailable } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { ICalculatedCapacity } from '@flowplan/flowplan-shared/lib/filter/filter';
import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { IMachineType } from '@flowplan/flowplan-shared/lib/machine.types/machine.types';
import { Action, Thunk, action, thunk } from 'easy-peasy';
import { IStoreModel } from '..';
import { ICustomFilterData, ICustomFilterInfo } from '../../common/interfacesFrontend';
import { handlePostRequest } from '../../modules/server-requests/server-requests';

interface IFilterCapacityCalulationRequest {
  carbonateHardness: number;
  filterId: number;
  microSiemensPerCm: number;
  totalHardness: number;
  filterMachineTypeId: number;
}

export interface IProcessedFilterInfo {
  recommendedCapacity: number | string;
  recommendedBypass: number | string;
  capacity: number | string;
  hardness: string;
  filterId: number;
  disableCapacityInput: boolean;
  showCustomFilter: boolean;
  filterMachineTypeId: number;
}

interface ICustomFilterStateVariables {
  infoForFilter: ICustomFilterData;
  sensorLookup: IDeviceView;
}

interface IFilterDataRequest {
  filterProducerId: number;
  filterId: number;
}

export interface IFilterDataModel {
  constructCustomFilterState: Thunk<IFilterDataModel, ICustomFilterStateVariables, void, IStoreModel>;
  getFilterFromProducer: Thunk<IFilterDataModel, IFilterDataRequest, void, IStoreModel>;

  getCalculatedCapacity: Thunk<IFilterDataModel, IFilterCapacityCalulationRequest, void, IStoreModel>;

  availableFilterMachineTypes: IMachineType[];
  setLoadingAvailableFilterMachineType: Action<IFilterDataModel, void>;
  getMachineTypesForFilter: Thunk<IFilterDataModel, number | null, void, IStoreModel>;
  setAvailableFilterMachineTypes: Action<IFilterDataModel, IMachineType[]>;
}

const filterDataModel: IFilterDataModel = {
  getFilterFromProducer: thunk((actions, payload, { getStoreState }) => {
    const filterProducerIdToUse = payload.filterProducerId > 0 ? payload.filterProducerId : 1;
    const filtersFromProducer = getStoreState().staticDataModel.filters[filterProducerIdToUse];
    let filterOnSensor: IFilter | undefined;
    if (payload.filterId > 0) {
      filterOnSensor = filtersFromProducer.find((x) => x.id === payload.filterId);
    }
    if (filterOnSensor === undefined) {
      filterOnSensor = filtersFromProducer[0];
    }

    return filterOnSensor;
  }),

  getCalculatedCapacity: thunk(async (actions, payload): Promise<ICalculatedCapacity> => {
    const requestResponse = await handlePostRequest(payload, Routes.calculateFilterCapacity, true);
    let returnData: ICalculatedCapacity = { capacity: 0, bypass: notAvailable, hardnessInfo: '' };
    if (requestResponse.success) {
      returnData = requestResponse.data as ICalculatedCapacity;
    } else {
      returnData = { bypass: requestResponse.message.split(':')[1], capacity: 0, hardnessInfo: '' };
    }

    return returnData;
  }),

  availableFilterMachineTypes: [],
  setLoadingAvailableFilterMachineType: action((state) => {
    state.availableFilterMachineTypes = [{ id: -1, name: 'Loading machine types' }];
  }),
  setAvailableFilterMachineTypes: action((state, payload) => {
    state.availableFilterMachineTypes = payload;
  }),
  getMachineTypesForFilter: thunk(async (actions, payload): Promise<void> => {
    if (payload === null || payload === -1 || payload === 0) {
      return;
    }

    actions.setLoadingAvailableFilterMachineType();
    const requestResponse = await handlePostRequest({ filterId: payload }, Routes.machineTypes, true);
    if (requestResponse.success && requestResponse.data.length !== 0) {
      const availableFilterMachineTypes = requestResponse.data as IMachineType[];
      actions.setAvailableFilterMachineTypes(availableFilterMachineTypes);
    }
  }),

  constructCustomFilterState: thunk((actions, payload, { getStoreState }) => {
    const infoForFilter = payload.infoForFilter;
    const sensorLookup = payload.sensorLookup;
    let inputCapacity = '';
    if (infoForFilter.id) {
      if (infoForFilter.id === sensorLookup.infoBasic.id) {
        inputCapacity = sensorLookup.infoFilter.capacityTotal < 0 ? '' : '' + sensorLookup.infoFilter.capacityTotal;
      } else {
        console.error('sensor data undefined for:', infoForFilter.id);
      }
    }
    let disableCapacityInput = false;

    const waterFilterLocations = getStoreState().locationsModel.waterFilterLocations;
    const locationLookup = waterFilterLocations.find((x) => x.id === infoForFilter.inputLocationId);
    if (locationLookup === undefined) {
      disableCapacityInput = true;
      inputCapacity = 'Select a location';
    }

    const customFilterInfo: ICustomFilterInfo = {
      disableCapacityInput,
      inputCapacity,
      showCustomFilter: true,
    };

    return customFilterInfo;
  }),
};

export default filterDataModel;
