import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { VariantType, useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../../../Store';
import { getDateObject } from '../../../../Utility/time';
import { Dialog, DialogActions, DialogContent, DialogSubtitle, DialogTitle } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import FpDatePicker from '../../../common/components/FpDatePicker';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpText from '../../../common/components/FpText/FpText';
import styles from './ResetAverage.module.scss';
import { Paths } from '../../../navigation/containers/Navigation/navigation-types';

interface Props {
  alwaysLeftAlign?: boolean;
}
const InstallationResetAverage = (props: Props): JSX.Element => {
  const { alwaysLeftAlign } = props;

  const access = useStoreState((state) => state.authModel.access);
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);

  const resetAverage = useStoreActions((actions) => actions.deviceActionsModel.resetAverage);
  const getFilterDetailsData = useStoreActions((actions) => actions.deviceActionsModel.getFilterDetailsData);
  const retrievePeriodData = useStoreActions((actions) => actions.deviceDataModel.retrievePeriodData);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [averageResetDate, setAverageResetDate] = useState(getDateObject(null));
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };
  return (
    <>
      <FpButtonOutlinedAligned
        alwaysLeftAlign={alwaysLeftAlign}
        disabled={!access.installations.manage}
        className={'greyButton'}
        icon={<CalendarMonthIcon />}
        onClick={() => {
          setShowConfirmationModal(true);
        }}
        label="Reset average"
      />
      <Dialog open={showConfirmationModal} onClose={handleCancel} maxWidth={'sm'}>
        <DialogTitle>
          <FpText componentType='span' variant='h6'>Rest average for: </FpText>
          <FpText componentType='span' variant='h6' className='do-not-translate'>{baseFilter.infoBasic.name}</FpText>
        </DialogTitle>

        <DialogContent>
          <DialogSubtitle>
            <div className={styles.resetAverageSection}>
              <FpText variant="body1">
                Resetting average is useful for when consumption has been abnormal for a period.
              </FpText>
              <FpDivider height="0rem" marginY="0.5rem" />
              <FpText variant="body1">
                For example if a device has been offline or consumption is expected to change in the future due to
                varying reasons, for example an extended emergency or a vacation period.
              </FpText>
              <FpDivider height="0rem" marginY="0.5rem" />
              <FpText variant="body1">Feel free to select a date to restrict average calculations</FpText>
              <FpDivider hidden height="0rem" marginY="0.5rem" />
              <FpDatePicker
                disableFuture
                size="medium"
                label="Reset average date"
                minDate={getDateObject(baseFilter.infoBasic.initialInstallDate)}
                onChange={(value) => setAverageResetDate(value || getDateObject(null))}
                value={averageResetDate}
              />
            </div>
          </DialogSubtitle>
        </DialogContent>
        <DialogActions>
          <FpButton color="error" onClick={handleCancel}>
            Cancel
          </FpButton>
          <FpButton
            color="secondary"
            onClick={async () => {
              const requestResponse = await resetAverage(averageResetDate.format(dateFormatYMD));
              if (requestResponse.success) {
                const detailsResponse = await getFilterDetailsData();
                if (!detailsResponse) {
                  setShowConfirmationModal(false);
                  navigate(Paths.installations);
                  return;
                }
                retrievePeriodData();
              }

              const feedback = requestResponse.success
                ? 'Installation average reset'
                : 'Error: ' + requestResponse.message;
              const variant: VariantType = requestResponse.success ? 'success' : 'error';
              enqueueSnackbar(feedback, { variant });
              setShowConfirmationModal(false);
            }}
          >
            Confirm
          </FpButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InstallationResetAverage;
