import { useState } from 'react';
import { Grid, Input } from 'semantic-ui-react';
import { useStoreActions, useStoreState } from '../../Store';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../modules/common/components';
import FpButton from '../../modules/common/components/FpButton/FpButton';
import './ModalStyles.scss';

const ConfirmModalAction = (): JSX.Element => {
  const cancelActionCallback = useStoreState((state) => state.modalModel.cancelActionCallback);
  const confirmActionCallback = useStoreState((state) => state.modalModel.confirmActionCallback);
  const confirmActionCancelLabel = useStoreState((state) => state.modalModel.confirmActionCancelLabel);
  const confirmActionConfirmLabel = useStoreState((state) => state.modalModel.confirmActionConfirmLabel);
  const confirmActionConfirmValue = useStoreState((state) => state.modalModel.confirmActionConfirmValue);
  const confirmActionFreeInput = useStoreState((state) => state.modalModel.confirmActionFreeInput);

  const confirmActionMaxLength = useStoreState((state) => state.modalModel.confirmActionMaxLength);
  const confirmActionShow = useStoreState((state) => state.modalModel.confirmActionShow);
  const confirmActionText = useStoreState((state) => state.modalModel.confirmActionText);
  const confirmActionTitle = useStoreState((state) => state.modalModel.confirmActionTitle);
  const confirmActionValueIsNumber = useStoreState((state) => state.modalModel.confirmActionValueIsNumber);

  const confirmActionToggle = useStoreActions((actions) => actions.modalModel.confirmActionToggle);

  const [confirmText, setConfirmText] = useState('');
  if (!confirmActionShow && confirmText !== '') {
    setConfirmText('');
  }

  const closeModal = () => {
    confirmActionToggle({
      title: '',
      confirmValueIsNumber: true,
      showModal: false,
      confirmValue: '',
      text: '',
    });
    cancelActionCallback();
  };

  const confirmAction = (data: number | string) => {
    confirmActionToggle({
      title: '',
      confirmValueIsNumber: true,
      showModal: false,
      confirmValue: '',
      text: '',
    });
    confirmActionCallback(data);
  };

  let isDisabled = true;
  if (confirmActionValueIsNumber) {
    isDisabled = isNaN(Number(confirmText)) || confirmText === '';
  } else {
    isDisabled = confirmText !== confirmActionConfirmValue;
  }

  if (confirmActionFreeInput && confirmText !== '') {
    isDisabled = false;
  }

  return (
    <Dialog open={confirmActionShow} onClose={closeModal}>
      <DialogTitle>{confirmActionTitle}</DialogTitle>

      <DialogContent>
        <Grid container spacing={{ sm: 1 }} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <p className="center-text">{confirmActionText}</p>
            <p className="modal-confirmation-text do-not-translate">
              <strong>{confirmActionConfirmValue}</strong>
            </p>
            <p className="center-text">
              Maximum input allowed: {confirmText.length} / {confirmActionMaxLength}
            </p>
            <Input
              value={confirmText}
              size="large"
              maxLength={confirmActionMaxLength}
              className="center-text"
              onChange={(event, data) => {
                setConfirmText(data.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid centered columns={2}>
          <Grid.Column></Grid.Column>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FpButton color="error" onClick={closeModal}>
          {confirmActionCancelLabel}
        </FpButton>
        <FpButton
          color="secondary"
          onClick={() => {
            const confirmValueToUse = confirmActionValueIsNumber ? parseInt(confirmText, 10) : confirmText;
            confirmAction(confirmValueToUse);
          }}
          disabled={isDisabled}
        >
          {confirmActionConfirmLabel}
        </FpButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModalAction;
