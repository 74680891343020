import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { useField } from 'formik';
import { forwardRef } from 'react';

export type FormikTextFieldProps = {
  name: string;
} & Omit<MuiTextFieldProps, 'name'>;

const FormikTextField = forwardRef<HTMLDivElement, FormikTextFieldProps>(({ name, ...props }, ref) => {
  const [field, meta] = useField({
    name,
  });
  const {
    value = field.value,
    onChange = field.onChange,
    onBlur = field.onBlur,
    error = Boolean(meta.error && meta.touched),
    helperText = meta.touched && meta.error,
    children,
    ...rest
  } = props;

  return (
    <MuiTextField
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      {...rest}
      ref={ref}
    >
      {children}
    </MuiTextField>
  );
});

FormikTextField.displayName = 'FormikTextField';

export default FormikTextField;
