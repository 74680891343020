import { memo, useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { SelectedOption } from '../../../../common/constsFrontend';
import LineChartWithControls from '../../../common/charts/Line/LineChartWithControls';

const ExternalSensorHistoryComponent = memo(() => {
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);

  const externalSensorHistory = useStoreState((state) => state.deviceDataModel.externalSensorHistory);
  const externalSensorDomainAndTicks = useStoreState((state) => state.deviceDataModel.externalSensorDomainAndTicks);
  const loadingExternalSensorHistory = useStoreState((state) => state.deviceDataModel.loadingExternalSensorHistory);

  const retrieveExternalSensorHistory = useStoreActions(
    (actions) => actions.deviceDataModel.retrieveExternalSensorHistory,
  );

  const [selectedOption, setSelectionOption] = useState(SelectedOption.SevenDays);

  const retrieveExternalSensorHistoryFunction = useCallback(async () => {
    retrieveExternalSensorHistory(selectedOption);
  }, [retrieveExternalSensorHistory, selectedOption]);

  useEffect(() => {
    retrieveExternalSensorHistoryFunction();
  }, [retrieveExternalSensorHistoryFunction]);

  return (
    <LineChartWithControls
      graphTitle=""
      domain={externalSensorDomainAndTicks.domain}
      yAxisTicks={externalSensorDomainAndTicks.yAxisTicks}
      dataUnit={baseFilter.infoExternalSensorData.measurementUnit}
      chartData={externalSensorHistory}
      interactionFunction={(optionSelected: number) => {
        setSelectionOption(optionSelected);
      }}
      isLoading={loadingExternalSensorHistory}
      placeholderText="No external sensor data found."
      selectedOption={selectedOption}
    />
  );
});

export default ExternalSensorHistoryComponent;
