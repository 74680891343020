import { useMediaQuery, useTheme } from '@mui/material';
import _ from 'lodash';
import { Fragment } from 'react';
import styles from './DataOverviewItem.module.scss';
import { flowEventTypeInformation } from './dataoverview-item-eventype-util';

interface IDataDetailsItemEventType {
  flowEventType: number;
  type: string;
}

const DataDetailsItemEventType = ({ flowEventType, type }: IDataDetailsItemEventType): JSX.Element => {
  const hasValidType = typeof flowEventType === 'number';
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down(420));

  if (!hasValidType) {
    return <Fragment />;
  }

  const flowEventInfo = flowEventTypeInformation(flowEventType, type);

  return (
    <span
      title={flowEventInfo.flowEventName}
      aria-label={flowEventInfo.flowEventName}
      className={
        (isSmallScreen ? styles.machineTypeSmall : styles.machineType) +
        ' ' +
        styles[`machineType${flowEventInfo.flowEventColor}`]
      }
    >
      {isVerySmallScreen ? _.truncate(flowEventInfo.flowEventName, { length: 8 }) : flowEventInfo.flowEventName}
    </span>
  );
};

export default DataDetailsItemEventType;
