import { ConsumptionState } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { VariantType, useSnackbar } from 'notistack';
import { useState } from 'react';
import LoaderComponent from '../../../../Components/Loader/LoaderComponent';
import { useStoreActions, useStoreState } from '../../../../Store';
import { getDateObject } from '../../../../Utility/time';
import { Dialog, DialogActions, DialogContent, DialogSubtitle, DialogTitle } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import { ModalInfo } from '../../../faulty-devices/components/FaultyDevicesList/FaultyDevicesList';
import InstallationDetails from '../../../installations/components/Details/InstallationDetails';
import { IResponse } from '../../../server-requests/server-requests-model';
import {
  GooglePlacesInformation,
  defaultGooglePlacesInformation,
  defaultLowUsageInstallationDetails,
} from '../../services/company-model';
import { LowUsageInstallationDetails } from '../../services/company-model-types';
import LowUsageIntro from './Common/LowUsageIntro';
import LowUsageProgress from './Common/LowUsageProgress';
import LowUsageWizard from './Common/LowUsageWizard';
import { LowUsageAction } from './low-usage-utils';

enum LowUsageInstallationSteps {
  Intro = 1,
  Wizard = 2,
}

type Props = {
  showLowUsageInstallations: boolean;
  handleClose(): void;
};

const LowUsageInstallations = ({ handleClose, showLowUsageInstallations }: Props): JSX.Element => {
  const loadingLowUsageInstallations = useStoreState((state) => state.companyModel.loadingLowUsageInstallations);
  const loadingGooglePlacesInformation = useStoreState((state) => state.companyModel.loadingGooglePlacesInformation);
  const lowUsageInstallations = useStoreState((state) => state.companyModel.lowUsageInstallations);
  const performingLowUsageAction = useStoreState((state) => state.companyModel.performingLowUsageAction);

  const getLowUsageInstallationDetails = useStoreActions(
    (actions) => actions.companyModel.getLowUsageInstallationDetails,
  );
  const getLowUsageInstallations = useStoreActions((actions) => actions.companyModel.getLowUsageInstallations);
  const getGooglePlacesInformation = useStoreActions((actions) => actions.companyModel.getGooglePlacesInformation);
  const getInstallationById = useStoreActions((actions) => actions.deviceActionsModel.getInstallationById);
  const setBaseFilter = useStoreActions((actions) => actions.graphSettingsModel.setSelectedInstallation);
  const lowUsageActionEstimate = useStoreActions((actions) => actions.companyModel.lowUsageActionEstimate);
  const lowUsageActionIgnore = useStoreActions((actions) => actions.companyModel.lowUsageActionIgnore);

  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState(LowUsageInstallationSteps.Intro);
  const [installationNumber, setInstallationNumber] = useState(0);
  const [installationDetails, setInstallationDetails] = useState<LowUsageInstallationDetails>(
    defaultLowUsageInstallationDetails,
  );
  const [googlePlacesInformation, setGooglePlacesInformation] =
    useState<GooglePlacesInformation>(defaultGooglePlacesInformation);

  const onClose = () => {
    handleClose();
    setState(LowUsageInstallationSteps.Intro);
    setInstallationNumber(0);
    setInstallationDetails(defaultLowUsageInstallationDetails);

    getLowUsageInstallations();
    // enqueueSnackbar('Low usage, we will remind you next time you login', { variant: 'success' });
  };

  const goToNextInstallation = async () => {
    if (installationNumber >= lowUsageInstallations.length) {
      onClose();
      enqueueSnackbar('Low usage, No more installations to view', { variant: 'success' });
      return;
    }

    const installation = lowUsageInstallations[installationNumber];

    setInstallationNumber(installationNumber + 1);
    const installationDetailsResponse = (await getLowUsageInstallationDetails(installation.id)) as IResponse;

    if (!installationDetailsResponse.success) {
      setInstallationDetails(defaultLowUsageInstallationDetails);
      setGooglePlacesInformation(defaultGooglePlacesInformation);
      enqueueSnackbar('Unable to find installation details for: ' + installation.name, { variant: 'success' });
      return;
    }

    const instaLowUsageInstallationDetailsData = installationDetailsResponse.data as LowUsageInstallationDetails;
    setInstallationDetails(instaLowUsageInstallationDetailsData);
    const { installationName, location } = instaLowUsageInstallationDetailsData;
    const { name, street, latitude, longitude } = location;
    const googlePlacesInformationResponse: GooglePlacesInformation = await getGooglePlacesInformation({
      address: street,
      locationName: name,
      installationName,
      latitude,
      longitude,
    });

    setGooglePlacesInformation(googlePlacesInformationResponse);
  };

  const installationAction = async (action: LowUsageAction, installationId: number) => {
    if (action === LowUsageAction.ViewInstallation) {
      const requestResponse = await getInstallationById(installationId);
      if (!requestResponse.data) {
        return;
      }
      setBaseFilter(requestResponse.data);
      setModalInfo({
        show: true,
        title: 'Installation: ' + installationDetails.installationName,
      });
      return;
    }

    if (action === LowUsageAction.DoNothing) {
      // just proceed to next
    }

    if (action === LowUsageAction.Ignore) {
      const requestResponse = await lowUsageActionIgnore({
        installationId,
        newConsumptionState: ConsumptionState.IGNORED,
      });
      const feedback = requestResponse.success
        ? installationDetails.installationName + ' has been ignored.'
        : 'Failed ignoring: ' + installationDetails.installationName + ' - ' + requestResponse.message;
      const variant: VariantType = requestResponse.success ? 'success' : 'error';
      enqueueSnackbar(feedback, { variant });
    }

    if (action === LowUsageAction.ActivateEstimate) {
      const { usageInformation, maintenance, waterFilter } = installationDetails;
      const { averageDailyConsumptionHistorically, days, lastFlowEventDate } = usageInformation;
      const { filterChangeDays } = waterFilter;
      const {
        cycleBasedMaintenance,
        cyclesAverageHistorically,
        cyclesCurrent,
        cyclesInterval,
        maintenanceAtLiters,
        maintenanceUsed,
        featureEnabled: maintenanceFeatureEnabled,
      } = maintenance;

      const daysSinceLastFlowEvent = Math.round(
        getDateObject(null).diff(getDateObject(lastFlowEventDate), 'days', true),
      );

      const daysToCalculateFilterUsageWith =
        filterChangeDays < daysSinceLastFlowEvent ? filterChangeDays : daysSinceLastFlowEvent;
      const estimatedWaterFilterUsage = averageDailyConsumptionHistorically * daysToCalculateFilterUsageWith;

      let estimatedMaintenanceUsage = 0;
      if (maintenanceFeatureEnabled) {
        const averageToUse = cycleBasedMaintenance ? cyclesAverageHistorically : averageDailyConsumptionHistorically;
        const estimatedMaintenanceUsageCalculation = averageToUse * daysSinceLastFlowEvent;
        const maintenanceState = cycleBasedMaintenance
          ? cyclesInterval - cyclesCurrent
          : maintenanceAtLiters - maintenanceUsed;
        estimatedMaintenanceUsage = Math.round(maintenanceState - estimatedMaintenanceUsageCalculation);
      }

      const requestResponse = await lowUsageActionEstimate({
        estimatedMaintenanceUsage,
        estimatedWaterFilterUsage,
        installationId,
        lastFlowEventDate,
        newConsumptionState: ConsumptionState.ESTIMATE,
        newDailyConsumptionDays: days,
        newDailyEstimate: averageDailyConsumptionHistorically,
      });
      const feedback = requestResponse.success
        ? installationDetails.installationName + ' will now estimate usage.'
        : 'Failed to activate estimation for: ' +
          installationDetails.installationName +
          ' - ' +
          requestResponse.message;
      const variant: VariantType = requestResponse.success ? 'success' : 'error';
      enqueueSnackbar(feedback, { variant });
    }
    goToNextInstallation();
  };

  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    show: false,
    title: '',
  });

  const closeModal = () => {
    setModalInfo({
      show: false,
      title: '',
    });
  };

  return (
    <Dialog
      open={showLowUsageInstallations}
      disableEscapeKeyDown={state !== LowUsageInstallationSteps.Intro}
      showCloseButton={state === LowUsageInstallationSteps.Intro}
      onClose={() => {
        if (state === LowUsageInstallationSteps.Intro) {
          onClose();
        }
      }}
      maxWidth="lg"
    >
      <DialogTitle color="error">Low usage installations</DialogTitle>

      <DialogContent>
        {state === LowUsageInstallationSteps.Intro && <LowUsageIntro numInstallations={lowUsageInstallations.length} />}
        {state === LowUsageInstallationSteps.Wizard && (
          <>
            <LowUsageWizard
              googlePlacesInformation={googlePlacesInformation}
              installationAction={installationAction}
              installationDetails={installationDetails}
              loadingGooglePlacesInformation={loadingGooglePlacesInformation}
              performingLowUsageAction={performingLowUsageAction}
            />
            <LowUsageProgress installationNumber={installationNumber} numInstallations={lowUsageInstallations.length} />
          </>
        )}

        <Dialog open={modalInfo.show} onClose={closeModal} maxWidth={'xl'}>
          <DialogTitle>{modalInfo.title}</DialogTitle>

          <DialogContent>
            <DialogSubtitle>
              <InstallationDetails showCompanyDropdown={false} />
            </DialogSubtitle>
          </DialogContent>
          <DialogActions>
            <FpButton color="secondary" onClick={closeModal}>
              Return
            </FpButton>
          </DialogActions>
        </Dialog>

        <LoaderComponent loading={loadingLowUsageInstallations} size="large" text="" />
      </DialogContent>

      <DialogActions>
        <FpButton color="error" onClick={onClose}>
          later
        </FpButton>
        {state === LowUsageInstallationSteps.Intro && (
          <FpButton
            color="secondary"
            onClick={async () => {
              setState(LowUsageInstallationSteps.Wizard);
              goToNextInstallation();
            }}
          >
            Start now
          </FpButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default LowUsageInstallations;
