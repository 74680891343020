import { minimumStringLength } from '@flowplan/flowplan-shared';
import { HardnessUnits } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { ILocationRequestPackage } from '@flowplan/flowplan-shared/lib/locations/locations';
import { trimStringAndCheckLength } from '@hsjakobsen/utilities';
import { ILocationInput } from '../../common/interfacesInput';
import { noSelectionMade } from '../../Models/admin/admin.model';

type ValidateInputLocation = {
  inputData: ILocationInput;
  hardnessUnit: HardnessUnits;
}

export const validateInputLocation = ({
  hardnessUnit,
  inputData
}: ValidateInputLocation): ILocationRequestPackage => {
  const locationInfo: ILocationRequestPackage = {
    success: false,
    latitude: inputData.inputLatitude,
    longitude: inputData.inputLongitude,
    phoneNumber: inputData.inputPhone,
    carbonateHardness: 0,
    microSiemensPerCm: 0,
    totalHardness: 0,
  };

  const clientIdToUse = inputData.inputClientId;
  if (clientIdToUse <= 0) {
    locationInfo.message = 'Client not selected';
    return locationInfo;
  }

  const nameResponse = trimStringAndCheckLength(inputData.inputName, 'Name', minimumStringLength, false);
  if (!nameResponse.success) {
    locationInfo.message = nameResponse.feedback;
    return locationInfo;
  }

  if (inputData.inputLatitude === noSelectionMade && inputData.inputLongitude === noSelectionMade) {
    locationInfo.message = 'Address not valid, please select location / address from search results.';
    return locationInfo;
  }

  const carbonateHardnessToUse = inputData.inputCarbonateHardness;
  const totalHardnessToUse = inputData.inputTotalHardness;
  const microSiemensToUse = inputData.inputMicroSiemens;

  if (isNaN(carbonateHardnessToUse) || isNaN(totalHardnessToUse)) {
    locationInfo.message = 'Hardness needs to be a number';
    return locationInfo;
  }

  if (hardnessUnit === HardnessUnits.German) {
    if (carbonateHardnessToUse > 35 || totalHardnessToUse > 40) {
      locationInfo.message = 'Hardness above supported limit';
      return locationInfo;
    }

    if (carbonateHardnessToUse < 4 && carbonateHardnessToUse !== 0) {
      locationInfo.message = 'Carbonate hardness is below supported limit, 0 is allowed';
      return locationInfo;
    }

    if (totalHardnessToUse < 4 && totalHardnessToUse !== 0) {
      locationInfo.message = 'Total hardness is below supported limit, 0 is allowed';
      return locationInfo;
    }
  } else {
    if (carbonateHardnessToUse > 380 || totalHardnessToUse > 380) {
      locationInfo.message = 'Hardness above supported limit';
      return locationInfo;
    }

    if (carbonateHardnessToUse < 0 && carbonateHardnessToUse !== 0) {
      locationInfo.message = 'Carbonate hardness is below supported limit, 0 is allowed';
      return locationInfo;
    }

    if (totalHardnessToUse < 0 && totalHardnessToUse !== 0) {
      locationInfo.message = 'Total hardness is below supported limit, 0 is allowed';
      return locationInfo;
    }
  }


  if (microSiemensToUse > 10000) {
    locationInfo.message = 'MicroSiemens is above supported limit';
    return locationInfo;
  }

  if (microSiemensToUse < 0 && microSiemensToUse !== 0) {
    locationInfo.message = 'Carbonate hardness is below supported limit, 0 is allowed';
    return locationInfo;
  }

  if (inputData.isUpdate) {
    locationInfo.id = inputData.id;
  }
  locationInfo.success = true;
  locationInfo.carbonateHardness = carbonateHardnessToUse;
  locationInfo.city = inputData.inputCity;
  locationInfo.clientId = clientIdToUse;
  locationInfo.name = nameResponse.result;
  locationInfo.street = inputData.inputStreet;
  locationInfo.hardnessUnit = hardnessUnit;
  locationInfo.microSiemensPerCm = microSiemensToUse;
  locationInfo.totalHardness = totalHardnessToUse;
  locationInfo.zipCode = inputData.inputZipCode;
  locationInfo.phoneNumber = inputData.inputPhone;
  return locationInfo;
};
