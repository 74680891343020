import { NotificationTypes } from '@flowplan/flowplan-shared';
import { IFormattedInstallationHistory } from '../../installation-history-interfaces';

interface IGetInstallationHistoryItemStyle {
  processedDataSet: IFormattedInstallationHistory;
  notificationTypeId: NotificationTypes;
}

export const getInstallationHistoryItemStyle = ({
  notificationTypeId,
  processedDataSet,
}: IGetInstallationHistoryItemStyle): string => {
  let itemStyling = '';
  if (processedDataSet.note !== '') {
    itemStyling = ' style-note';
  }

  if (notificationTypeId === NotificationTypes.WaterFilterChanged) {
    itemStyling = ' style-filter-change';
  }
  if (notificationTypeId === NotificationTypes.InstallationUsageAdded) {
    itemStyling = ' style-usage-added';
  }
  if (notificationTypeId === NotificationTypes.ServicePerformed) {
    itemStyling = ' style-maintenance-performed';
  }

  return itemStyling;
};
