import { formatResponse } from '@hsjakobsen/utilities';
import { IResponse, formatError } from '@hsjakobsen/utilities/lib/responses';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { LocalStoreBasic, getBasicSetting } from '../../Utility/LocalStorageFunctions';
import { getBaseRequestResponse } from '../../common/constsFrontend';
import { serverUrl } from '../../config';

const baseRequest = (route: string): string => {
  return serverUrl + route;
};

const getCredentials = (withCredentials: boolean, params?: unknown): AxiosRequestConfig | undefined => {
  const requestWithCredentials: AxiosRequestConfig = {
    params,
    withCredentials: true,
    headers: { Authorization: getBasicSetting(LocalStoreBasic.AccessToken) },
  };
  const credentials = withCredentials ? requestWithCredentials : undefined;
  return credentials;
};

const parseServerErrorResponse = (error: AxiosError): IResponse => {
  const errorMessage: string = error.request.response;
  if (errorMessage === '') {
    return formatError('No response from server');
  }

  let response: IResponse;
  try {
    response = JSON.parse(errorMessage);
  } catch (parseError) {
    response = formatError(errorMessage);
  }

  return response;
};

export const handlePostRequest = async (data: unknown, route: string, withCredentials: boolean): Promise<IResponse> => {
  let requestResponse: IResponse = getBaseRequestResponse();
  await axios
    .post(baseRequest(route), data, getCredentials(withCredentials))
    .then((response) => {
      requestResponse = formatResponse(
        response.data.message,
        response.data.success,
        response.data.data,
        response.data.extraData,
      );
    })
    .catch((error: AxiosError) => {
      requestResponse = parseServerErrorResponse(error);
    });

  return requestResponse;
};

export const handlePutRequest = async (data: unknown, route: string, withCredentials: boolean): Promise<IResponse> => {
  let requestResponse: IResponse = getBaseRequestResponse();
  await axios
    .put(baseRequest(route), data, getCredentials(withCredentials))
    .then((response) => {
      requestResponse = formatResponse(
        response.data.message,
        response.data.success,
        response.data.data,
        response.data.extraData,
      );
    })
    .catch((error: AxiosError) => {
      requestResponse = parseServerErrorResponse(error);
    });

  return requestResponse;
};

type DeleteRequestParams = {
  id?: number | string;
  flowplanClientId?: number;
  flowplanClientsGroupId?: number;
};
type DeleteRequestBody = object;

export const handleDeleteRequest = async (params: DeleteRequestParams, route: string): Promise<IResponse> => {
  let requestResponse: IResponse = getBaseRequestResponse();
  const deleteRoute = baseRequest(route);
  const requestConfig = getCredentials(true);
  if (!requestConfig) {
    return requestResponse;
  }

  requestConfig.params = params;
  await axios
    .delete(deleteRoute, requestConfig)
    .then((response) => {
      requestResponse = formatResponse(
        response.data.message,
        response.data.success,
        response.data.data,
        response.data.extraData,
      );
    })
    .catch((error: AxiosError) => {
      requestResponse = parseServerErrorResponse(error);
    });

  return requestResponse;
};

export const handleDeleteRequestWithBody = async (data: DeleteRequestBody, route: string): Promise<IResponse> => {
  let requestResponse: IResponse = getBaseRequestResponse();
  const deleteRoute = baseRequest(route);
  const requestConfig = getCredentials(true);
  if (!requestConfig) {
    return requestResponse;
  }

  requestConfig.data = data;
  await axios
    .delete(deleteRoute, requestConfig)
    .then((response) => {
      requestResponse = formatResponse(
        response.data.message,
        response.data.success,
        response.data.data,
        response.data.extraData,
      );
    })
    .catch((error: AxiosError) => {
      requestResponse = parseServerErrorResponse(error);
    });

  return requestResponse;
};

export const handleGetRequest = async (route: string, withCredentials: boolean): Promise<any> => {
  let requestResponse;

  const requestWithCredentials: AxiosRequestConfig = {
    responseType: 'arraybuffer',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getBasicSetting(LocalStoreBasic.AccessToken),
    },
  };

  await axios
    .get(baseRequest(route), requestWithCredentials)
    .then((response) => {
      requestResponse = response;
    })
    .catch((error: AxiosError) => {
      requestResponse = parseServerErrorResponse(error);
    });

  return requestResponse;
};
