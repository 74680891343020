import { useMediaQuery, useTheme } from '@mui/material';
import { Fragment, memo } from 'react';
import { useStoreState } from '../../../../Store';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpModalConfirmSimple from '../../../common/components/FpModalConfirmSimple/FpModalConfirmSimple';
import FpText from '../../../common/components/FpText/FpText';
import { isUserGuest } from '../../../users/types/users-configs';
import { NEWS_IMAGE_BORDER } from '../../news-and-updates-consts';
import airBeam from './air-beam.webp';
import batteryInfo from './battery-info.webp';
import changeWaterFilter from './change-water-filter.webp';
import configOptions from './config-options.webp';

type Props = {
  closeModal(): void;
  showModal: boolean;
};

const ReleaseInfoConfigAndBatteryType = memo(({ closeModal, showModal }: Props): JSX.Element => {
  const showMenu = useStoreState((state) => state.authModel.showMenu);
  const currentUser = useStoreState((state) => state.authModel.currentUser);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (!showMenu || isUserGuest(currentUser.role)) {
    return <Fragment />;
  }

  return (
    <FpModalConfirmSimple
      closeModal={closeModal}
      largerContentMargins={false}
      fullScreen={fullScreen}
      maxWidth="md"
      label="Close"
      modalTitle="2024 - October. Beam configuration, battery type information and change water filter in Field Office"
      showModal={showModal}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center' }}>
        <FpText variant="body1" sx={{ textAlign: 'left' }}>
          During October we have been hard at work adding several new features. Read on to find out more:
        </FpText>
        <FpDivider height="0.5rem" marginY="0.5rem" />
        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Beam configuration status
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We now have a simpler way to communicate the status of the Beams and what you should do with them. We are
          introducing "Beam configuration status" that takes several factors into account: The hardware, firmware,
          battery, connectivity setup and remote updates<strong>*</strong> capability.
        </FpText>

        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We recommend swapping the Beam if the status is <strong>"Update recommended"</strong>.
        </FpText>
        <FpDivider hidden height="1rem" />
        <img
          srcSet={configOptions}
          src={configOptions}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Installations with different configuration states'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          There are 4 different states that a Beam can have.
        </FpText>
        <FpDivider hidden height="1rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          *Beams marked as <i>Air</i>
        </FpText>

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We call Beams with remote updates functionality <i>Air</i> and it means that we in many cases can update the
          Beam remotely instead of swapping it.
        </FpText>
        <FpDivider hidden height="2rem" />

        <img
          srcSet={airBeam}
          src={airBeam}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Beams that have remote update capability will be marked with "Air"'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          Beams that have remote update capability will be marked with <i>Air</i>.
        </FpText>

        <FpDivider hidden height="2rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Field Office: Changing water filter type when changing water filter
        </FpText>

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We want to make sure that when you're on the road, you have all the functionality you need in the Field Office
          app. Today we add the option for technicians to easily change water filter types when they are out performing
          water filter changes.
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', fontStyle: 'italic', mx: 2 }}>
          2024.10.1 App version required.
        </FpText>
        <FpDivider hidden height="0.25rem" />
        <img
          srcSet={changeWaterFilter}
          src={changeWaterFilter}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '80%',
            border: NEWS_IMAGE_BORDER,
          }}
          alt={'Field Office - You can now change water filter type when changing water filters'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ fontStyle: 'italic', fontSize: '12px' }}>
          Field Office - You can now change water filter type when changing water filters.
        </FpText>

        <FpDivider hidden height="2rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Required battery for Beams
        </FpText>

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We now show which batteries are compatible with the specific beam.
        </FpText>
        <FpDivider hidden height="0.25rem" />
        <img
          srcSet={batteryInfo}
          src={batteryInfo}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '80%',
            border: NEWS_IMAGE_BORDER,
          }}
          alt={'This Beam is compatible with a FANSO battery.'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ fontStyle: 'italic', fontSize: '12px' }}>
          This Beam is compatible with a FANSO battery.
        </FpText>

        <FpDivider hidden height="2rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Other updates
        </FpText>

        <ul style={{ marginLeft: 2 }}>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              Locations can now store conductivity (μS/cm) measurements.
            </FpText>
          </li>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              New water filters added: Brita XtraSafe C1100, Brita Purity Steam and BWT AK 1023
            </FpText>
          </li>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              News and updates now show until you have read all of the news items.
            </FpText>
          </li>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              Dashboard lists with upcoming installations and locations have been modernized.
            </FpText>
          </li>
          <li>
            <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
              The list with detailed data for an installation has been modernized.
            </FpText>
          </li>
        </ul>
      </div>
    </FpModalConfirmSimple>
  );
});

export default ReleaseInfoConfigAndBatteryType;
