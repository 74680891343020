import { IInventoryBottlesToUpdate, IInventoryBottlesView } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { formatError } from '@hsjakobsen/utilities';
import { Action, Thunk, action, thunk } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { handlePostRequest, handlePutRequest } from '../../server-requests/server-requests';

export interface IBottleSerialsRequest {
  serials: string[];
  flowplanClientId: number;
  setBatchId: boolean;
}

export interface IInventoryBaseRequest {
  flowplanClientIdOverride: number;
}

export interface IInventoryBottlesModel {
  inventoryData: IInventoryBottlesView[];
  inventoryAvailable: IInventoryBottlesView[];
  retrieveInventoryData: Thunk<IInventoryBottlesModel, IInventoryBaseRequest, void, IStoreModel>;
  setInventoryData: Action<IInventoryBottlesModel, IInventoryBottlesView[]>;
  loadingInventoryData: boolean;
  setLoadingInventoryData: Action<IInventoryBottlesModel, boolean>;
  inventoryShowInSystem: boolean;
  toggleInventoryShowInSystem: Action<IInventoryBottlesModel>;

  addSerials: Thunk<IInventoryBottlesModel, IBottleSerialsRequest>;
  assignSerialToCompany: Thunk<IInventoryBottlesModel, IBottleSerialsRequest>;
}

const inventoryBottlesModel: IInventoryBottlesModel = {
  loadingInventoryData: false,
  inventoryData: [],
  inventoryAvailable: [],
  inventoryShowInSystem: true,

  setInventoryData: action((state, payload) => {
    state.inventoryData = payload;
    const inventoryAvailable: IInventoryBottlesView[] = payload.filter((item) => {
      return !item.hasSample;
    });

    inventoryAvailable.unshift({
      batchId: '',
      bottleSerial: '',
      companyName: '',
      hasSample: false,
      sampleType: '',
    });
    state.inventoryAvailable = inventoryAvailable;
  }),
  setLoadingInventoryData: action((state, payload) => {
    state.loadingInventoryData = payload;
  }),

  toggleInventoryShowInSystem: action((state) => {
    const newValue = !state.inventoryShowInSystem;
    state.inventoryShowInSystem = newValue;
  }),

  retrieveInventoryData: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingInventoryData(true);

    const route = Routes.inventoryBottles + payload.flowplanClientIdOverride;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route });
    if (requestResponse.success) {
      actions.setInventoryData(requestResponse.data);
    }
    actions.setLoadingInventoryData(false);
  }),

  addSerials: thunk(async (actions, payload) => {
    const route = Routes.inventoryBottles + payload.flowplanClientId;
    const data: IInventoryBottlesToUpdate = {
      serials: [],
      setBatchId: false,
    };
    payload.serials.forEach((serial) => {
      const serialToAdd = serial.trim();
      if (serialToAdd.length === 10) {
        data.serials.push({ flowplanClientId: payload.flowplanClientId, serial: serialToAdd });
      }
    });

    if (data.serials.length === 0) {
      return formatError('No serials to add, if you had typed something in, it was probably an invalid format');
    }
    const requestResponse = await handlePostRequest(data, route, true);
    if (requestResponse.success) {
      // actions.setConnectionData(requestResponse.data);
    }
    return requestResponse;
  }),
  assignSerialToCompany: thunk(async (actions, payload) => {
    const route = Routes.inventoryBottles + payload.flowplanClientId;
    const data: IInventoryBottlesToUpdate = {
      serials: [],
      setBatchId: payload.setBatchId,
    };
    payload.serials.forEach((serial) => {
      data.serials.push({ flowplanClientId: payload.flowplanClientId, serial });
    });
    const requestResponse = await handlePutRequest(data, route, true);
    if (requestResponse.success) {
      // actions.setDeviceConfiguration(requestResponse.data);
    }
    return requestResponse.success;
  }),
};

export default inventoryBottlesModel;
