import { Button, Popup } from 'semantic-ui-react';
import { useStoreActions, useStoreState } from '../../../../Store';
import InternalTemperatureHistoryComponent from './InternalTemperatureHistoryComponent';

const SensorInternalTemperatureHistoryButton = (): JSX.Element => {
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const confirmationToggle = useStoreActions((actions) => actions.modalModel.confirmationToggle);

  const showInternalTemperatureReadings = () => {
    const content = <InternalTemperatureHistoryComponent />;
    const title = 'Internal temperature history for: ' + baseFilter.infoBasic.name;
    confirmationToggle({
      content,
      showModal: true,
      title,
      confirmationSize: 'lg',
    });
  };

  return (
    <Popup
      content="View temperature readings historically"
      trigger={<Button icon="line graph" basic onClick={() => showInternalTemperatureReadings()} />}
    />
  );
};

export default SensorInternalTemperatureHistoryButton;
