import { IInfoService, TaskTypes } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { numberWithCommas } from '@hsjakobsen/utilities';
import { ITaskInformation } from './tasks-utility';

interface ITaskInformationMaintenance {
  infoService: IInfoService;
  showEstimate: boolean;
}

export const taskInformationMaintenance = ({
  showEstimate,
  infoService,
}: ITaskInformationMaintenance): ITaskInformation => {
  const {
    daysLeft,
    cycleBasedMaintenance,
    estimatedMaintenanceUsage,
    serviceAtLiters,
    serviceUsage,
    taskType,
  } = infoService;

  const usage = serviceUsage + (showEstimate ? estimatedMaintenanceUsage : 0);

  const maintenanceAmountLeft = numberWithCommas((serviceAtLiters - usage).toFixed(0));
  const infoHeader = 'Maintenance task';

  const unitShort = cycleBasedMaintenance ? 'Cups' : 'L';
  const unitLong = cycleBasedMaintenance ? 'Cups' : 'Litres';

  const taskInfoTime = (
    <>
      <span className='do-not-translate'>{daysLeft} </span>
      <span>days</span>
    </>
  )

  const taskInfoUsage = (
    <>
      <span className='do-not-translate'>{maintenanceAmountLeft} </span>
      <span>{unitShort}</span>
    </>
  )
  const infoList = taskType === TaskTypes.Time ? taskInfoTime : taskInfoUsage;

  const infoTooltip =
    taskType === TaskTypes.Time
      ? 'The installation requires maintenance, as it has been set up to be maintained periodically.'
      : 'The installation requires maintenance, as it has:';
  const serviceTaskHighlightUsage = (
    <>
      <span className='do-not-translate'>{daysLeft} </span>
      <span>days left / </span>
      <span className='do-not-translate'>{maintenanceAmountLeft} </span>
      <span>{unitLong} left.</span>
    </>
  )
  const serviceTaskHighlightTime = (
    <>
      <span className='do-not-translate'>{daysLeft} </span>
      <span>days left</span>
    </>
  )

  const infoHighlight = taskType === TaskTypes.Time ? serviceTaskHighlightTime : serviceTaskHighlightUsage;

  return {
    infoHeader,
    infoHighlight,
    infoList,
    infoTooltip,
  };
};
