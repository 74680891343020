import { memo, useCallback, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../Store';
import { SelectedOption } from '../../../../common/constsFrontend';
import LineChartWithControls from '../../../common/charts/Line/LineChartWithControls';

const PowerConsumptionHistoryPopup = memo(() => {
  const powerConsumptionHistory = useStoreState((state) => state.deviceDataModel.powerConsumptionHistory);
  const loadingPowerConsumptionHistory = useStoreState((state) => state.deviceDataModel.loadingPowerConsumptionHistory);

  const retrievePowerConsumptionHistory = useStoreActions(
    (actions) => actions.deviceDataModel.retrievePowerConsumptionHistory,
  );

  const [selectedOption, setSelectionOption] = useState(SelectedOption.SevenDays);

  const retrievePowerConsumptionHistoryFunction = useCallback(async () => {
    retrievePowerConsumptionHistory(selectedOption);
  }, [retrievePowerConsumptionHistory, selectedOption]);

  useEffect(() => {
    retrievePowerConsumptionHistoryFunction();
  }, [retrievePowerConsumptionHistoryFunction]);

  return (
    <>
      <p>The graph is % battery usage on the vertical axis and time on the horizontal axis</p>
      <LineChartWithControls
        graphTitle=""
        dataUnit="%"
        domain={[0, 100]}
        chartData={powerConsumptionHistory}
        interactionFunction={(optionSelected: number) => {
          setSelectionOption(optionSelected);
        }}
        isLoading={loadingPowerConsumptionHistory}
        placeholderText="No power consumption data found."
        selectedOption={selectedOption}
      />
    </>
  );
});

export default PowerConsumptionHistoryPopup;
