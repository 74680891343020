import { notAvailable } from '@flowplan/flowplan-shared';
import { Fragment } from 'react';
import FpText from '../../../../common/components/FpText/FpText';
import { WaterFilter } from '../../../services/company-model-types';
import styles from '../Common/LowUsage.module.scss';
import LowUsageSpacer from '../Common/LowUsageSpacer';
import LowUsageWizardContent from '../Common/LowUsageWizardContent';
import { getLowUsageDays } from '../low-usage-utils';
import LowUsageFilterStatus from './LowUsageFilterStatus';

type Props = {
  avgDailyConsumption: number;
  daysSinceLastFlowEvent: number;
  noConsumptionState: boolean;
  waterFilter: WaterFilter;
};

const LowUsageWaterFilter = ({
  avgDailyConsumption,
  daysSinceLastFlowEvent,
  noConsumptionState,
  waterFilter,
}: Props): JSX.Element => {
  const { capacity, capacityUsed, featureEnabled, filterName, filterChangeDate, filterChangeDays } = waterFilter;

  const lowUsageDays = getLowUsageDays({
    daysSinceLastFlowEvent,
    actionDate: filterChangeDate,
    actionDays: filterChangeDays,
  });
  const estimatedUsage = avgDailyConsumption * lowUsageDays;

  const capacityRemaining = capacity - capacityUsed;
  const percentageRemaining = Math.round((capacityRemaining / capacity) * 100);
  const estimatedCapacityRemaining = capacity - capacityUsed - estimatedUsage;
  const estimatedPercentageRemaining = Math.round((estimatedCapacityRemaining / capacity) * 100);

  return (
    <LowUsageWizardContent
      translateTitle={!featureEnabled}
      title={featureEnabled ? filterName : 'Water filter'}
      icon={
        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <LowUsageFilterStatus percentageStatus={percentageRemaining} />
          {featureEnabled && !noConsumptionState && (
            <LowUsageFilterStatus percentageStatus={estimatedPercentageRemaining} />
          )}
        </div>
      }
    >
      {featureEnabled && (
        <Fragment>
          <FpText variant="body1">Current:</FpText>
          <div className={percentageRemaining < 0 ? styles.warning : styles.healthy}>
            <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
              {Math.round(capacityRemaining) + 'L / ' + capacity + 'L'}
            </FpText>
          </div>
          <FpText variant="body1">{percentageRemaining}% remaining</FpText>
          <LowUsageSpacer height={10} />
          <FpText variant="body1">Estimate:</FpText>
          {!noConsumptionState ? (
            <Fragment>
              {filterChangeDate !== notAvailable && daysSinceLastFlowEvent > filterChangeDays ? (
                <>
                  <FpText variant="body1">
                    <strong>Note:</strong> Last water filter change was <strong>{filterChangeDays} days</strong> ago.
                  </FpText>
                  <FpText variant="body1">Estimated usage is calculated on that amount of days</FpText>
                </>
              ) : null}

              <div className={estimatedPercentageRemaining < 0 ? styles.warning : styles.healthy}>
                <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
                  {Math.round(estimatedCapacityRemaining) + 'L / ' + capacity + 'L'}
                </FpText>
              </div>
              <FpText variant="body1">
                {estimatedPercentageRemaining}% {estimatedCapacityRemaining < 0 ? 'overdue' : 'remaining'}
              </FpText>
            </Fragment>
          ) : (
            <FpText variant="body1">No consumption data</FpText>
          )}
        </Fragment>
      )}
      {!featureEnabled && (
        <FpText variant="body1" sx={{ fontWeight: 'bold' }}>
          Feature not enabled
        </FpText>
      )}
    </LowUsageWizardContent>
  );
};

export default LowUsageWaterFilter;
