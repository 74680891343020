import { Fragment, FunctionComponent } from 'react';
import ConfirmationModal from '../../Components/Modals/ConfirmationModal';
import { useStoreState } from '../../Store';
import TermsAndConditionsDialog from '../../modules/companies/components/TermsAndConditions/TermsAndConditionsDialog';
import NewClientsGroupModal from '../../modules/flowplan-client-groups/containers/NewClientsGroupModal/NewClientsGroupModal';
import NewInstallationModal from '../../modules/installations/components/NewInstallation/Modal';
import NewLocationModal from '../../modules/locations/components/NewLocation/NewLocation';
import AddNewUser from '../../modules/users/containers/AddNewUser/AddNewUser';
import ConfirmModalAction from './ConfirmModalAction';
import ConfirmModalStrict from './ConfirmModalStrict';
import ConfirmSimpleModal from './ConfirmSimpleModal';
import ModalFlexible from './Modal.Flexible';

const ModalContainer: FunctionComponent = () => {
  const isAuthenticated = useStoreState((state) => state.authModel.isAuthenticated);

  if (!isAuthenticated) {
    return <Fragment />;
  }

  return (
    <div>
      <ConfirmationModal />
      <ConfirmModalStrict />
      <ConfirmModalAction />
      <ModalFlexible />
      <ConfirmSimpleModal />

      <NewClientsGroupModal />
      <NewLocationModal />
      <NewInstallationModal />
      <AddNewUser />
      <TermsAndConditionsDialog />
    </div>
  );
};

export default ModalContainer;
