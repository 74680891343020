export enum FlowEventTypes {
  Leak = 1,
  GroupedEvents = 2,
}

interface IFlowEventTypeInfo {
  flowEventName: string;
  flowEventColor: string;
}

export const flowEventTypeInformation = (flowEventTypeId: number, type: string): IFlowEventTypeInfo => {
  const flowEventInfo: IFlowEventTypeInfo = {
    flowEventName: '',
    flowEventColor: '',
  };

  if (flowEventTypeId > FlowEventTypes.GroupedEvents) {
    flowEventInfo.flowEventName = type;

    if (type) {
      if (type.includes('Coffee') || type.includes('Espresso')) {
        flowEventInfo.flowEventColor = 'brown';
      } else {
        flowEventInfo.flowEventColor = 'blue';
      }
    } else {
      flowEventInfo.flowEventColor = 'blue';
    }

    return flowEventInfo;
  }

  switch (flowEventTypeId) {
    case FlowEventTypes.Leak:
      flowEventInfo.flowEventName = 'Possible leak';
      flowEventInfo.flowEventColor = 'red';
      break;
    case FlowEventTypes.GroupedEvents:
      flowEventInfo.flowEventName = 'Grouped event';
      flowEventInfo.flowEventColor = 'grey';
      break;
  }

  return flowEventInfo;
};

export const getReadingTypeFromMeasurementUnit = (measurementUnit: string): string => {
  if (measurementUnit === '°C') {
    return 'Temperature';
  } else if (measurementUnit === 'μS/cm') {
    return 'Conductivity';
  } else if (measurementUnit === 'Bar') {
    return 'Pressure';
  }

  return 'External readings';
};