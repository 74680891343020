import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TypographyProps } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpTextField from '../../../common/components/FpTextField/FpTextField';
import { ALERT_STYLES, TagActionType } from './tags-util';

type ModalConfig = {
  title: string;
  message: ReactNode;
  icon?: ReactNode;
  showInput: boolean;
  confirmLabel: string;
  showCancel: boolean;
  isConfirmDisabled: boolean;
  alertStyle?: React.CSSProperties;
};

interface TagActionModalProps {
  showModal: boolean;
  tagName: string;
  tagId: number | null;
  actionType: TagActionType;
  installationCount?: number;
  onClose: () => void;
  onConfirm: () => void;
}

const TagActionModal = ({
  showModal,
  tagName,
  actionType,
  installationCount = 0,
  onClose,
  onConfirm,
}: TagActionModalProps): JSX.Element => {
  const [confirmValue, setConfirmValue] = useState('');

  // Reset input when modal opens
  useEffect(() => {
    if (showModal) setConfirmValue('');
  }, [showModal]);

  // Get modal configuration
  const getModalConfig = (): ModalConfig => {
    switch (actionType) {
      case TagActionType.DELETE_REGULAR:
        return {
          title: 'Delete Tag',
          message: (
            <>
              Confirm your choice by typing:
              <Box
                component="div"
                sx={{
                  fontWeight: 'bold',
                  my: 2,
                  p: 2,
                  border: '1px solid #e0e0e0',
                  borderRadius: '4px',
                  background: '#f5f5f5',
                  textAlign: 'center',
                  fontSize: '1.5rem',
                  letterSpacing: '0.5px',
                }}
              >
                {tagName}
              </Box>
            </>
          ),
          showInput: true,
          confirmLabel: 'Confirm deletion',
          showCancel: true,
          isConfirmDisabled: confirmValue !== tagName,
        };

      case TagActionType.DELETE_BLOCKED_GLOBAL:
        return {
          title: 'Global Tag Cannot Be Deleted',
          message: 'Only FlowPlan admins can delete this tag.',
          icon: <WarningIcon color="warning" />,
          showInput: false,
          confirmLabel: 'OK',
          showCancel: false,
          isConfirmDisabled: false,
          alertStyle: { ...ALERT_STYLES.base, ...ALERT_STYLES.warning },
        };

      case TagActionType.DELETE_BLOCKED_MULTIPLE_USAGE:
        return {
          title: 'Cannot Delete Tag',
          message: (
            <>
              This tag is used by{' '}
              <Box component="span" sx={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                {installationCount}
              </Box>{' '}
              installations. To delete this tag, you must first remove it from all installations where it's used.
            </>
          ),
          icon: <ErrorIcon color="error" />,
          showInput: false,
          confirmLabel: 'OK',
          showCancel: false,
          isConfirmDisabled: false,
          alertStyle: { ...ALERT_STYLES.base, ...ALERT_STYLES.error },
        };

      case TagActionType.LOADING:
        return {
          title: 'Checking Tag Status',
          message: 'Checking tag usage...',
          icon: <InfoIcon color="info" />,
          showInput: false,
          confirmLabel: 'OK',
          showCancel: false,
          isConfirmDisabled: true,
          alertStyle: { ...ALERT_STYLES.base, ...ALERT_STYLES.info },
        };

      default:
        return {
          title: 'Delete Tag',
          message: '',
          showInput: true,
          confirmLabel: 'Confirm',
          showCancel: true,
          isConfirmDisabled: true,
        };
    }
  };

  const config = getModalConfig();
  const messageProps: TypographyProps = {
    variant: 'body2',
    paragraph: true,
    sx: { textAlign: config.icon ? 'left' : 'center' },
  };

  const renderMessage = () => {
    if (config.icon) {
      return (
        <Box sx={config.alertStyle} marginBottom={2}>
          {config.icon}
          <Typography {...messageProps}>{config.message}</Typography>
        </Box>
      );
    }

    return <Typography {...messageProps}>{config.message}</Typography>;
  };

  return (
    <Dialog open={showModal} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{config.title}</DialogTitle>
      <DialogContent>
        {renderMessage()}

        {config.showInput && (
          <FpTextField
            inputValue={confirmValue}
            maxLength={tagName.length}
            helperText={`${confirmValue.length}/${tagName.length}`}
            inputKey="input-confirm-delete"
            inputText=""
            sx={{ marginTop: '1rem', width: '100%' }}
            variant="outlined"
            handleInput={(event) => setConfirmValue(event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        {config.showCancel && (
          <FpButton color="error" onClick={onClose}>
            Cancel
          </FpButton>
        )}
        <FpButton color="secondary" onClick={onConfirm} disabled={config.isConfirmDisabled}>
          {config.confirmLabel}
        </FpButton>
      </DialogActions>
    </Dialog>
  );
};

export default TagActionModal;
