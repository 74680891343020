import { Action, action } from 'easy-peasy';

export interface IExternalServices {
  googleAPILoaded: boolean;
  googleAPISearching: boolean;

  setGoogleAPILoaded: Action<IExternalServices, boolean>;
  setGoogleAPISearching: Action<IExternalServices, boolean>;
}

const externalServices: IExternalServices = {
  googleAPILoaded: false,
  googleAPISearching: false,

  setGoogleAPILoaded: action((state, payload) => {
    state.googleAPILoaded = payload;
  }),

  setGoogleAPISearching: action((state, payload) => {
    state.googleAPISearching = payload;
  }),
};

export default externalServices;
