import { ListItem, ListItemText, SxProps, Theme } from '@mui/material';
import React from 'react';

type Props = {
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  sx?: SxProps<Theme>;
  rightSideActions?: React.ReactNode;
  leftSideActions?: React.ReactNode;
  onClick(): void;
  selected?: boolean;
};

const FpListItem = ({ onClick, primary, secondary, leftSideActions, rightSideActions, sx, selected }: Props) => {
  return (
    <ListItem
      button
      secondaryAction={rightSideActions}
      sx={{
        ...sx,
        bgcolor: selected ? 'action.selected' : 'background.paper',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      }}
      onClick={onClick}
    >
      {leftSideActions}
      <ListItemText
        primaryTypographyProps={{ fontWeight: 'bold' }}
        primary={primary}
        secondaryTypographyProps={{
          fontSize: '14px',
        }}
        secondary={secondary || null}
      />
    </ListItem>
  );
};

FpListItem.displayName = 'FpListItem';

export default FpListItem;
