import { ISensorCategory, ISensorType } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { useStoreActions, useStoreState } from '../../../../../../Store';
import { DeviceCategories } from '../../../../../../common/constsCategoryAndTypes';
import FpButton from '../../../../../common/components/FpButton/FpButton';
import ButtonDeviceTagging from '../../../DeviceType/ButtonDeviceTagging';
import { getCategoryFromType, getFirstFromCategory } from '../../../DeviceType/utility-category-and-type';
import styles from './DeviceCategoryAndType.module.scss';

const inputKeyDeviceCategory = 'inputCategory';
const inputKeyDeviceType = 'inputType';

const FlowplanDeviceCategoryAndTypeSelection = (): JSX.Element => {
  const inputSelectedType = useStoreState((state) => state.newInstallationModel.inputSelectedType);
  const selectedCategory = useStoreState((state) => state.newInstallationModel.selectedCategory);
  const sensorCategories = useStoreState((state) => state.staticDataModel.sensorCategories);
  const sensorTypes = useStoreState((state) => state.staticDataModel.sensorTypes);

  const evaluateName = useStoreActions((actions) => actions.newInstallationModel.evaluateName);
  const setInputSelectedType = useStoreActions((actions) => actions.newInstallationModel.setInputSelectedType);
  const setSelectedCategory = useStoreActions((actions) => actions.newInstallationModel.setSelectedCategory);
  const handleDeviceTypeSelection = (selectedDeviceType: number) => {
    evaluateName();
    setInputSelectedType(selectedDeviceType);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (selectedCategory !== DeviceCategories.Coffee) {
      return; // prevents having to double click, since this resets selection, should only apply for initial checks
    }

    setSelectedCategory(getCategoryFromType(inputSelectedType));
  }, [inputSelectedType, selectedCategory, setSelectedCategory]);

  const handleSelectionChanges = (inputKey: string, inputValue: number) => {
    switch (inputKey) {
      case inputKeyDeviceCategory:
        if (selectedCategory === inputValue) {
          return;
        }

        handleDeviceTypeSelection(getFirstFromCategory(inputValue));
        setSelectedCategory(inputValue);
        break;
      case inputKeyDeviceType:
        handleDeviceTypeSelection(inputValue);
        break;
    }
  };

  const categoriesToShow = sensorCategories.map((category: ISensorCategory, index) => {
    return (
      <FpButton
        key={'category' + index}
        value={category.id}
        style={{ minWidth: '150px', marginBottom: '0.5rem', marginTop: '0.5rem' }}
        aria-label={category.name}
        color={selectedCategory === category.id ? 'primary' : 'inherit'}
        onClick={() => {
          handleSelectionChanges(inputKeyDeviceCategory, category.id);
        }}
      >
        {category.name}
      </FpButton>
    );
  });

  let numElementsToShow = 0;
  const typesToShow = sensorTypes.map((type: ISensorType, index) => {
    let itemToReturn = null;
    if (selectedCategory === type.categoryId) {
      numElementsToShow++;
      itemToReturn = (
        <ButtonDeviceTagging
          key={'type' + index}
          handleSelection={handleSelectionChanges}
          inputKey={inputKeyDeviceType}
          inputText={type.name}
          inputValue={type.id}
          selectionValue={inputSelectedType}
          showImage={true}
        />
      );
    }
    return itemToReturn;
  });

  return (
    <div className={`${styles.categoryAndTypeSection} ${styles.largeCategoryAndTypeSection}`}>
      <label className={styles.inputSelectLabel}>Machine type*</label>
      <Grid columns="equal" divided={isSmallScreen ? 'vertically' : undefined} centered stretched={!isSmallScreen}>
        <Grid.Row>{categoriesToShow}</Grid.Row>
        {numElementsToShow === 0 && (
          <Grid.Row className={styles.typeRow}>
            <p>
              <strong>Select a machine type</strong>
            </p>
          </Grid.Row>
        )}
        {numElementsToShow > 0 && <Grid.Row className={styles.typeRow}>{typesToShow}</Grid.Row>}
      </Grid>
    </div>
  );
};

export default FlowplanDeviceCategoryAndTypeSelection;
