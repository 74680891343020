import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IRequestChangeBattery } from '@flowplan/flowplan-shared/lib/installations/battery';
import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import {
  IDeviceAddDataRequest,
  IDeviceNote,
  IDeviceRequestPackage,
} from '@flowplan/flowplan-shared/lib/interfaces/installationView';
import { dateFormatYMD, timeFormatHM } from '@hsjakobsen/utilities/lib/date';
import { IResponse } from '@hsjakobsen/utilities/lib/responses';
import { action, thunk, thunkOn } from 'easy-peasy';
import _ from 'lodash';
import { getDateObject } from '../../../Utility/time';
import { IProcessedDeviceDetails } from '../../../common/interfacesData';
import {
  SignalColor,
  SignalText,
} from '../../../modules/installations/components/SignalStrength/signal-strength-parsing';
import { handleDeleteRequest, handlePostRequest, handlePutRequest } from '../../server-requests/server-requests';
import { ITapSensorCreate } from '../../taps/services/tap.model';
import { showEstimatedUsage } from '../components/Tasks/tasks-utility';
import { IBaseInformation, collectBasicInformation } from '../types/details/installation-details-basic';
import { IBeamInformation, collectBeamInformation } from '../types/details/installation-details-beam';
import { IFilterInformation, collectFilterInformation } from '../types/details/installation-details-filter';
import {
  IInstallationInformation,
  collectInstallationInformation,
} from '../types/details/installation-details-installation';
import {
  IMaintenanceInformation,
  collectMaintenanceInformation,
} from '../types/details/installation-details-maintenance';
import { IStatusInformation, collectStatusInformation } from '../types/details/installation-details-status';
import IDeviceActionsModel from '../types/installations-action-model';

const defaultDeviceDetails: IProcessedDeviceDetails = {
  infoBasic: {} as IBaseInformation,
  infoBeam: { signalInfo: { signalColor: SignalColor.Bad, signalText: SignalText.Bad } } as IBeamInformation,
  infoFilter: {} as IFilterInformation,
  infoInstallation: {} as IInstallationInformation,
  infoService: {} as IMaintenanceInformation,
  infoStatus: {} as IStatusInformation,
  infoTags: [],
};

const deviceActionsModel: IDeviceActionsModel = {
  usagePerWeekday: {},
  setUsagePerWeekday: action((state, payload) => {
    state.usagePerWeekday = payload
  }),

  dateSelection: getDateObject(null),
  setDateSelection: action((state, payload) => {
    state.dateSelection = payload;
  }),
  addSensor: thunk(async (actions, payload) => {
    const sensor = { ...payload };
    delete sensor.maintenanceCycles;
    const requestResponse = await handlePostRequest(sensor, Routes.sensors, true);
    if (requestResponse.success) {
      if (payload.maintenanceCycles) {
        const dataoverview = {
          maintenanceCycles: payload.maintenanceCycles,
          sensorId: requestResponse.data.id,
        };
        // get sensorid from response
        // send put request to dataoverview
        await handlePutRequest(dataoverview, '/data-overview/', true);
      }
    }
    return requestResponse;
  }),

  addTapSensor: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const requestResponse = await handlePostRequest(payload, `${Routes.sensors}taps/`, true);
    return requestResponse;
  }),

  updateSensor: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;
    const route = Routes.sensors + flowplanClientIdOverride;
    const sensor = { ...payload };
    delete sensor.maintenanceCycles;
    const requestResponse = await handlePutRequest(sensor, route, true);
    if (requestResponse.success) {
      if (payload.maintenanceCycles) {
        const dataoverview = {
          maintenanceCycles: payload.maintenanceCycles,
          sensorId: payload.id,
        };
        // get sensorid from response
        // send put request to dataoverview
        await handlePutRequest(dataoverview, '/data-overview/', true);
      }
    }
    return requestResponse;
  }),

  updateTapSensor: thunk(async (actions, payload) => {
    return await actions.updateSensor(payload)
  }),

  removeSensor: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
    const installationId = baseFilter.infoBasic.id;
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;
    const params = { id: installationId, flowplanClientId: flowplanClientIdOverride };

    actions.sensorDetailsHandleLoading(true);
    const requestResponse = await handleDeleteRequest(params, Routes.sensors);
    actions.sensorDetailsHandleLoading(false);
    return requestResponse;
  }),

  removeTapSensor: thunk(async (actions) => {
    return await actions.removeSensor()
  }),

  changeSerial: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;
    const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
    const sensorId = baseFilter.infoBasic.id;
    const route = Routes.changeSerial + sensorId + '/' + flowplanClientIdOverride + '/' + payload;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: route });
    return requestResponse;
  }),

  batteryChangeDate: getDateObject(null),
  setBatteryChangeDate: action((state, payload) => {
    state.batteryChangeDate = payload;
  }),
  changeBattery: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;
    const batteryChangeDate = getStoreState().deviceActionsModel.batteryChangeDate;
    const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
    const sensorId = baseFilter.infoBasic.id;
    const route = Routes.changeBattery + flowplanClientIdOverride;

    // time is added to make the notifications better
    const currentTime: string = getDateObject(null).format(timeFormatHM);

    const data: IRequestChangeBattery = {
      date: batteryChangeDate.format(dateFormatYMD) + ' ' + currentTime,
      sensorId,
    };
    const requestResponse = await handlePostRequest(data, route, true);
    return requestResponse;
  }),
  changeWaterFilter: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;
    const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
    const sensorId = baseFilter.infoBasic.id;
    const route = Routes.changeWaterFilter + sensorId + '/' + flowplanClientIdOverride;
    const requestResponse = await handlePutRequest({ date: payload.format(dateFormatYMD) }, route, true);
    if (requestResponse.success) {
      getStoreActions().deviceActionsModel.setDateSelection(getDateObject(null));
    }
    return requestResponse;
  }),
  changeToNewFilter: thunk(async (actions, payload, { getStoreState }) => {
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected
    const route = `/change-water-filter-settings/${flowplanClientIdOverride}`;
    const requestResponse = await handlePutRequest(payload, route, true);
    return requestResponse;
  }),

  changeFlowmeter: thunk(async (actions, payload, { getStoreState }) => {
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;
    const route = '/change-flowmeter/' + flowplanClientIdOverride;
    const requestResponse = await handlePutRequest(payload, route, true);
    return requestResponse;
  }),
  performMaintenance: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;
    const maintenanceDescription = getStoreState().deviceActionsModel.maintenanceDescription;
    const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
    const sensorId = baseFilter.infoBasic.id;
    const route = Routes.servicePerformed + sensorId + '/' + flowplanClientIdOverride;
    const note: IDeviceNote = { note: maintenanceDescription };
    const requestResponse = await handlePostRequest(note, route, true);
    if (requestResponse.success) {
      requestResponse.extraData = note;
    }
    getStoreActions().deviceActionsModel.setMaintenanceDescription('');
    return requestResponse;
  }),
  resetAverage: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;
    const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
    const sensorId = baseFilter.infoBasic.id;
    const route = Routes.resetAverage + sensorId + '/' + flowplanClientIdOverride + '/' + payload;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: route });
    return requestResponse;
  }),

  addNote: thunk(async (actions, payload, { getStoreState }) => {
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;

    const route = Routes.notifications + payload.installationId + '/' + flowplanClientIdOverride;
    const requestResponse = await handlePostRequest(payload, route, true);
    return requestResponse;
  }),

  addUsage: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;
    const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
    const sensorId = baseFilter.infoBasic.id;

    const route = Routes.addUsageToFilter + flowplanClientIdOverride;
    const data: IDeviceAddDataRequest = { id: sensorId, litersAdded: payload, success: true };

    const requestResponse = await handlePostRequest(data, route, true);
    return requestResponse;
  }),

  getUsagePerWeekday: thunk(async (actions, payload, { getStoreActions }) => {
    const { sensorId, daysTilNow } = payload

    const route = Routes.sensors + `taps/${sensorId}/dailyUsage/${daysTilNow}`;

    const response = await getStoreActions().serverRequestsModel.get({ route: route });

    actions.setUsagePerWeekday(response.data)

    return response.data;
  }),

  getInstallationById: thunk(async (actions, payload, { getStoreActions, getStoreState }): Promise<IResponse> => {
    const installationId = payload;
    const flowplanClientSelected = getStoreState().adminDataModel.flowplanClientSelected;
    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: Routes.sensorById + installationId + '/' + flowplanClientSelected,
    });
    return requestResponse;
  }),

  getInstallationBySerial: thunk(async (actions, payload, { getStoreActions }): Promise<IResponse> => {
    const flowplanSerial = payload.substring(6);
    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: Routes.sensorByFlowplanDeviceId + flowplanSerial,
    });
    return requestResponse;
  }),

  getFilterDetailsData: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const selectedInstallation = getStoreState().graphSettingsModel.selectedInstallation;
    const companyHardnessUnit = getStoreState().companyModel.companyHardnessUnit;
    const flowplanSerial = selectedInstallation.infoBasic.flowplanDeviceId.substring(6);
    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: Routes.sensorByFlowplanDeviceId + flowplanSerial,
    });
    if (!requestResponse.success) {
      return false;
    }

    const sensor = requestResponse.data as IDeviceView;
    if (!sensor) {
      return false;
    }

    const { infoUsage, infoFilter, infoService } = sensor;

    const showEstimate = showEstimatedUsage({
      consumptionState: infoUsage.consumptionState,
      infoFilter,
      infoService,
    });

    const returnedProcessedDeviceDetails: IProcessedDeviceDetails = {
      infoBasic: collectBasicInformation({ hardnessUnit: companyHardnessUnit, installation: sensor }),
      infoBeam: collectBeamInformation(sensor),
      infoFilter: collectFilterInformation({ infoFilter: sensor.infoFilter, showEstimate }),
      infoInstallation: collectInstallationInformation(sensor),
      infoService: collectMaintenanceInformation({ infoService: sensor.infoService, showEstimate }),
      infoStatus: collectStatusInformation(sensor),
      infoTags: sensor.infoTags,
    };
    actions.sensorDetailsSetData(returnedProcessedDeviceDetails);
    return true;
  }),
  sensorDetailsHandleLoading: action((state, payload) => {
    state.loadingSensorDetails = payload;
  }),
  sensorDetailsIntervalUpdate: -1,
  loadingSensorDetails: false,
  sensorDetailsProcessedDeviceDetails: defaultDeviceDetails,
  sensorDetailsSetData: action((state, payload) => {
    state.sensorDetailsProcessedDeviceDetails = payload !== undefined ? payload : defaultDeviceDetails;
  }),
  setSensorDetailsInterval: action((state, payload) => {
    if (payload !== null) {
      state.sensorDetailsIntervalUpdate = payload;
      return;
    }
    state.sensorDetailsIntervalUpdate++;
  }),

  maintenanceDescription: '',
  setMaintenanceDescription: action((state, payload) => {
    state.maintenanceDescription = payload;
  }),

  onActionPerformed: thunkOn(
    (actions, storeActions) => [
      actions.changeWaterFilter,
      actions.changeToNewFilter,
      actions.changeBattery,
      actions.performMaintenance,
      actions.changeSerial,
      actions.resetAverage,
      actions.addUsage,
      actions.changeFlowmeter,
    ],
    async (actions, target, { getStoreState, getStoreActions }) => {
      if (target.result !== undefined && !target.result.success) {
        // error performing action
        return;
      }

      const baseFilterSelected = getStoreState().graphSettingsModel.selectedInstallation;
      const { id } = baseFilterSelected.infoBasic;
      const updatedInstallationResponse = await actions.getInstallationById(id);
      const payload = updatedInstallationResponse.data as IDeviceView;

      const installationsList = getStoreState().installationsModel.sensors;
      const itemUpdateIndex = installationsList.findIndex((x) => x.infoBasic.id === payload.infoBasic.id);
      const installationsListNew = _.cloneDeep(installationsList);
      try {
        installationsListNew[itemUpdateIndex] = payload;
      } catch (error) {
        // error updating list
      }

      getStoreActions().installationsModel.setSensors(installationsListNew);
      getStoreActions().companyModel.getCompanyInstallations();

      if (baseFilterSelected.infoBasic.id === payload.infoBasic.id) {
        getStoreActions().graphSettingsModel.setSelectedInstallation(payload);
      }
    },
  ),

  installationImages: [],
  getInstallationImages: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingInstallationImages(true);
    const route = `${Routes.S3}installation-images`;
    const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
    const sensorId = baseFilter.infoBasic.id;
    const params = { sensorId };
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: route, params });
    if (requestResponse.success) {
      const images: string[] = requestResponse.data.urls;
      actions.setInstallationImages(images);
    }
    actions.setLoadingInstallationImages(false);
    return requestResponse;
  }),
  setInstallationImages: action((state, payload) => {
    state.installationImages = payload;
  }),
  uploadInstallationImages: thunk(async (actions, files, { getStoreState }) => {
    actions.setLoadingInstallationImages(true);
    const route = `${Routes.S3}installation-images`;
    const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
    const sensorId = baseFilter.infoBasic.id;
    const formData = new FormData();
    formData.append('sensorId', sensorId.toString());
    files.forEach((file) => {
      formData.append('files', file);
    });
    const requestResponse = await handlePostRequest(formData, route, true);
    if (requestResponse.success) {
      actions.getInstallationImages();
    }
    actions.setLoadingInstallationImages(false);
    return requestResponse;
  }),
  deleteInstallationImages: thunk(async (actions, payload) => {
    actions.setLoadingInstallationImages(true);
    const route = `${Routes.S3}installation-images`;
    const requestResponse = await handleDeleteRequest({ id: payload }, route);
    if (requestResponse.success) {
      actions.getInstallationImages();
    }
    actions.setLoadingInstallationImages(false);
    return requestResponse;
  }),
  loadingInstallationImages: false,
  setLoadingInstallationImages: action((state, payload) => {
    state.loadingInstallationImages = payload;
  }),

  onActionsManagingSensorForSublocation: thunkOn(
    (actions) => [
      actions.addTapSensor,
      actions.updateTapSensor,
      actions.removeTapSensor
    ],
    async (actions, target, { getStoreActions, getStoreState }) => {
      const [addTapSensor, updateTapSensor, removeTapSensor] = target.resolvedTargets

      switch (target.type) {
        case removeTapSensor: {
          const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
          const locationInfo = baseFilter.infoLocationAndClient;
          locationInfo.sublocationId && await getStoreActions().sublocationModel.getSublocationWithSensors(locationInfo.sublocationId)
          await getStoreActions().tapStockModel.getTapStocksByLocationId(locationInfo.locationId)
          return
        }
        case addTapSensor: {
          const addTapSensorPayload = target.payload as ITapSensorCreate
          if (addTapSensorPayload) {
            await getStoreActions().sublocationModel.getSublocationWithSensors(addTapSensorPayload.sublocationIndex)
            addTapSensorPayload.locationId && await getStoreActions().tapStockModel.getTapStocksByLocationId(addTapSensorPayload.locationId)
          }
          return
        }
        case updateTapSensor: {
          const updateTapSensorPayload = target.payload as IDeviceRequestPackage
          if (updateTapSensorPayload) {
            const promiseList = []
            const { locationId, sublocationId, id } = updateTapSensorPayload
            if (locationId) {
              promiseList.push(getStoreActions().sublocationModel.getSublocationsByLocationId(locationId))
              promiseList.push(getStoreActions().tapStockCounterModel.getTapStockCountersByTaplocationId(locationId))
            }
            sublocationId && promiseList.push(getStoreActions().sublocationModel.getSublocationWithSensors(sublocationId))
            await Promise.all(promiseList)
            if (id) {
              const installationResponse = await actions.getInstallationById(Number(id));
              getStoreActions().graphSettingsModel.setSelectedInstallation(installationResponse.data)
            }
          }
        }
      }
    }
  )
};

export default deviceActionsModel;
