import { IResponse } from '@hsjakobsen/utilities';
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { handleDeleteRequest, handlePostRequest, handlePutRequest } from '../../server-requests/server-requests';

export type TapStockType = {
  id: number;
  name: string;
  multiplier: number;
};

export type CreateTapStockType = {
  name: string;
  // variants: number[];
  multiplier: number;
};

export type UpdateTapStockType = {
  id: number;
  name: string;
  multiplier: number;
};

export interface IFlowplanClientStockTypeModel {
  getStockTypesForCompany: Thunk<IFlowplanClientStockTypeModel, void, void, IStoreModel>;
  stockTypes: TapStockType[];
  setStockTypes: Action<IFlowplanClientStockTypeModel, TapStockType[]>;
  loadingStockTypes: boolean;
  setLoadingStockTypes: Action<IFlowplanClientStockTypeModel, boolean>;
  createStockType: Thunk<IFlowplanClientStockTypeModel, CreateTapStockType, void, IStoreModel>;
  removeStockType: Thunk<IFlowplanClientStockTypeModel, number, void, IStoreModel>;
  updateStockType: Thunk<IFlowplanClientStockTypeModel, UpdateTapStockType, void, IStoreModel>;
  onActionsThatUpdateStockTypes: ThunkOn<IFlowplanClientStockTypeModel, void, IStoreModel>;
}

const flowplanClientStockTypeModel: IFlowplanClientStockTypeModel = {
  stockTypes: [],
  getStockTypesForCompany: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingStockTypes(true);
    const requestResponse: IResponse = await getStoreActions().serverRequestsModel.get({
      route: '/flowplan-client-stock-types/',
    });
    actions.setLoadingStockTypes(false);
    if (requestResponse.success) {
      actions.setStockTypes(requestResponse.data as TapStockType[]);
    }

    return requestResponse;
  }),

  loadingStockTypes: false,
  setLoadingStockTypes: action((state, payload) => {
    state.loadingStockTypes = payload;
  }),
  setStockTypes: action((state, payload) => {
    state.stockTypes = payload;
  }),

  createStockType: thunk(async (actions, payload) => {
    const requestResponse = await handlePostRequest(payload, '/flowplan-client-stock-types/', true);
    return requestResponse;
  }),
  removeStockType: thunk(async (actions, payload) => {
    return await handleDeleteRequest({ id: payload }, '/flowplan-client-stock-types/');
  }),
  updateStockType: thunk(async (actions, payload) => {
    const requestResponse = await handlePutRequest(
      payload,
      '/flowplan-client-stock-types/',
      true, // Set to true if credentials are required
    );
    return requestResponse;
  }),

  onActionsThatUpdateStockTypes: thunkOn(
    (actions, storeActions) => [actions.createStockType, actions.removeStockType, actions.updateStockType],
    async (actions, payload) => {
      actions.getStockTypesForCompany();
    },
  ),
};

export default flowplanClientStockTypeModel;
