import { FlowplanProductTypes, SubscriptionOptions } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import collapsedLogo from '../../../../assetstore/logo/flowplan_logo_collapsed.png';
import { useStoreState } from '../../../../Store';
import { storeLastVisitedLocation } from '../../../../Utility/LocalStorageFunctions';
import './NavigationLogo.scss';
import { Paths } from './navigation-types';

const getDashboardPath = (productType: FlowplanProductTypes, paths: typeof Paths): string => {
  switch (productType) {
    case FlowplanProductTypes.WaterFilter:
      return paths.dashboard;
    case FlowplanProductTypes.Coffee:
      return paths.dashboard;
    case FlowplanProductTypes.Taps:
      return paths.tapDashboard;
    case FlowplanProductTypes.Everything:
      return paths.dashboard;
    default:
      return paths.dashboard;
  }
};

interface NavigationLogoProps {
  isCollapsed?: boolean;
  isSmallScreen?: boolean;
}

const NavigationLogo = ({ isCollapsed = false, isSmallScreen = false }: NavigationLogoProps): JSX.Element => {
  const navigate = useNavigate();
  const subscription = useStoreState((state) => state.companyModel.subscription);
  const flowplanProductTypeId = useStoreState((state) => state.adminDataModel.flowplanProductTypeId);
  const isPremium = useStoreState((state) => state.companyModel.isPremium);
  const logoUrl = useStoreState((state) => state.companyModel.logoUrl);

  const subscriptionToShow = subscription === SubscriptionOptions.ESSENTIALS ? 'ESSENTIAL' : subscription;
  const subscriptionStyle = isPremium ? 'premium' : 'essential';

  const handleNavigation = () => {
    const path = getDashboardPath(flowplanProductTypeId as FlowplanProductTypes, Paths);
    navigate(path);
    storeLastVisitedLocation(path);
  };

  // Show full logo on mobile or when not collapsed
  if (isSmallScreen || !isCollapsed) {
    return (
      <Box
        sx={{
          py: 1,
          minHeight: '100px', // Add minimum height to maintain spacing
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          className={'subscription ' + subscriptionStyle}
          sx={{
            display: 'block',
            textAlign: 'center',
            fontSize: '1.2rem',
            fontWeight: 'bold',
          }}
        >
          {subscriptionToShow}
        </Typography>
        {logoUrl ? (
          <Box sx={{ padding: '1rem' }}>
            <img
              src={logoUrl + '?random'}
              className="app-logo-custom"
              alt="logo"
              onClick={handleNavigation}
              style={{ aspectRatio: 'auto', width: '200px' }}
            />
            <p style={{ fontSize: '12px', color: '#919191' }}>Powered by Flowplan</p>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
            <div
              className="app-logo"
              role="img"
              aria-label="Flowplan - Intelligent filter management"
              title="Flowplan - Intelligent filter management"
              onClick={handleNavigation}
              style={{ height: '55px' }}
            />
          </Box>
        )}
      </Box>
    );
  }

  // Return space-maintaining box in collapsed state if using custom logo
  if (logoUrl) {
    return (
      <Box
        sx={{
          minHeight: '47px', // Match the height of collapsed logo
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  }

  // Show collapsed Flowplan logo only when using default logo
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        minHeight: '47px', // Ensure consistent height
      }}
    >
      <img
        src={collapsedLogo}
        alt="Flowplan"
        style={{
          width: '32px',
          height: 'auto',
          cursor: 'pointer',
        }}
        onClick={handleNavigation}
      />
    </Box>
  );
};

export default NavigationLogo;
