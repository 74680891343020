import { getSerialNumberToShow } from '@flowplan/flowplan-shared';
import fansoBattery from '../../../../assetstore/icons/batteries/FANSO.png';
import saftBattery from '../../../../assetstore/icons/batteries/SAFT.png';
import { HardwareGenerations } from '../../../../hooks/BeamHealthStatus';
import { useStoreActions, useStoreState } from '../../../../Store';
import { getDateObject } from '../../../../Utility/time';
import FpDatePicker from '../../../common/components/FpDatePicker';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpText from '../../../common/components/FpText/FpText';
import styles from './InstallationInformationChangeBattery.module.scss';

const InstallationInformationChangeBattery = (): JSX.Element => {
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const batteryChangeDate = useStoreState((state) => state.deviceActionsModel.batteryChangeDate);
  const setBatteryChangeDate = useStoreActions((state) => state.deviceActionsModel.setBatteryChangeDate);

  const { flowplanDeviceId, name } = baseFilter.infoBasic;
  const { batteryInstallationDate } = baseFilter.infoDeviceMetrics;

  const confirmValue = name + ' (' + getSerialNumberToShow(flowplanDeviceId) + ')';

  const hardwareGeneration = baseFilter.infoDeviceMetrics.hardware;
  const serial = baseFilter.infoBasic.flowplanDeviceId;

  const serialAsNumber = Number(serial.substring(8));
  const onlySupportsSaft = hardwareGeneration === HardwareGenerations.GenFour && serialAsNumber < 3000;

  return (
    <div className={styles.changeBatterySection}>
      <FpText variant="body1" sx={{ textAlign: 'center' }}>
        You're about to change the battery for:
      </FpText>
      <FpText variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }} className='do-not-translate'>
        {confirmValue}
      </FpText>
      <FpDivider hidden height="1rem" />

      <FpText variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Select the date, when you changed the battery:
      </FpText>

      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
        <FpDatePicker
          disableFuture
          size="medium"
          fullWidth={false}
          minDate={getDateObject(batteryInstallationDate)}
          label="When was the battery changed?"
          onChange={(value) => {
            setBatteryChangeDate(value || getDateObject(null));
          }}
          value={batteryChangeDate}
        />
      </div>

      <FpDivider />

      <div style={{ width: '100%', textAlign: 'center' }}>
        <FpText variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          Use the below battery in this Beam.
        </FpText>
        <img
          srcSet={onlySupportsSaft ? saftBattery : fansoBattery}
          src={onlySupportsSaft ? saftBattery : fansoBattery}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '1rem',
            maxWidth: '200px',
          }}
          alt={'Battery to install'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center' }}>
          Contact Flowplan if you need batteries.
        </FpText>
      </div>
    </div>
  );
};

export default InstallationInformationChangeBattery;
