import { Box } from '@mui/material';
import { DeviceTypes } from '../../../../common/constsCategoryAndTypes';
import { getDeviceTypeImage } from './device-type-image';

type Props = {
  explanation: string;
  typeId: DeviceTypes;
};

const ImageDeviceType = ({ typeId, explanation }: Props): JSX.Element => {
  const typeImageToShow = getDeviceTypeImage(typeId);

  return (
    <Box
      component="img"
      sx={{
        height: 40,
        width: 40,
      }}
      aria-label={explanation}
      title={explanation}
      src={typeImageToShow}
    />
  );
};

export default ImageDeviceType;
