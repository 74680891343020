import InfoIcon from '@mui/icons-material/Info';
import { HealthStateColors } from '../../../../hooks/BeamHealthStatus';

type Props = {
  color: HealthStateColors;
  text: string;
  large?: boolean;
};

const HealthStateSimple = ({ color, large, text }: Props): JSX.Element => {
  return (
    <>
      <InfoIcon sx={{ width: large ? '4.5em' : '0.6em', marginRight: large ? '0em' : '0.5rem' }} color={color} />
      <span>{text}</span>
    </>
  );
};

export default HealthStateSimple;
