import { SxProps } from '@mui/system';
import { CSSProperties } from 'react';

interface BeerKegProps {
  fill?: string;
  secondaryFill?: string;
  width?: number;
  height?: number;
  sx?: SxProps;
  stroke?: 'black' | 'white';
  strokeWidth?: number;
  fillpercentage?: number;
  showPercentage?: boolean;
  liquidfill?: 'beer' | 'dark';
  fontSize?: 'small' | 'medium' | 'large';
}

// Base component with all shared logic
const BaseBeerKeg = (props: BeerKegProps): JSX.Element => {
  const {
    fill = 'none',
    width,
    height,
    stroke = 'black',
    strokeWidth,
    sx = {},
    fillpercentage = 0,
    showPercentage = true,
    liquidfill = 'beer', // Default to beer color
    ...rest
  } = props;

  const cssStyle: CSSProperties = {
    ...(sx as CSSProperties),
  };

  // Determine fill color based on liquidFill prop
  const fillColor = liquidfill === 'beer' ? '#F8C24D' : '#00000050';

  // Normalize fill percentage
  const normalizedFill = Math.min(Math.max(fillpercentage, 0), 100);

  // Constants for positioning
  const xValue = 10;
  const kegWidth = 100;
  const kegHeight = 150;
  const maxFillHeight = kegHeight;
  const startY = 10;
  const fillHeight = (normalizedFill / 100) * maxFillHeight;
  const fillY = startY + (maxFillHeight - fillHeight);

  return (
    <svg
      className="icon"
      width={width}
      height={height}
      viewBox="0 0 120 170"
      xmlns="http://www.w3.org/2000/svg"
      style={cssStyle}
      {...rest}
    >
      <defs>
        <clipPath id="containerClip">
          <rect x={xValue} y="10" width={kegWidth} height={kegHeight} rx="35" />
        </clipPath>
      </defs>

      {/* Fill */}
      <rect x={xValue} y={fillY} width={kegWidth} height={fillHeight} fill={fillColor} clipPath="url(#containerClip)" />

      {/* Container outline */}
      <rect
        x={xValue}
        y="10"
        width={kegWidth}
        height={kegHeight}
        rx="35"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
      />

      {/* Lids */}
      <rect x={xValue} y="0" width={kegWidth} height="20" rx="12" stroke={stroke} fill={'black'} />
      <rect x={xValue} y="150" width={kegWidth} height="20" rx="12" stroke={stroke} fill={'black'} />

      {/* Fill lines */}
      <line x1={xValue} y1="52" x2="110" y2="52" stroke={stroke} strokeWidth="1" />
      <line x1={xValue} y1="104" x2="110" y2="104" stroke={stroke} strokeWidth="1" />

      {/* Percentage text - only shown if showPercentage is true */}
      {showPercentage && (
        <text x="60" y="84" textAnchor="middle" dominantBaseline="middle" fontSize="35" fontWeight="bold">
          {Math.round(normalizedFill)}%
        </text>
      )}
    </svg>
  );
};

const BeerKegIcon = ({ stroke = 'black', fontSize = 'medium', ...props }: BeerKegProps): JSX.Element => {
  const size = fontSize === 'small' ? 20 : fontSize === 'large' ? 35 : 24;

  return (
    <svg
      className="icon"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      style={{
        display: 'inline-block',
        fontSize: 'inherit',
        userSelect: 'none',
        flexShrink: 0,
      }}
      {...props}
    >
      <BaseBeerKeg width={20} height={25} strokeWidth={1.5} showPercentage={false} {...props} />
    </svg>
  );
};

// Meter variant - optimized for cards and displaying usage metrics
export const BeerKegMeter = (props: BeerKegProps): JSX.Element => {
  return <BaseBeerKeg width={120} height={140} strokeWidth={2} showPercentage={true} {...props} />;
};

// Default export is the icon variant
export default BeerKegIcon;
