import { ILogOverview } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { Action, action, Thunk, thunk } from 'easy-peasy';
import { IStoreModel } from '../../../Models';

export interface LogsOverviewExtended extends ILogOverview {
  id: string;
}

export interface ILogsModel {
  loadingLogsData: boolean;
  loadingLogDetails: boolean;
  logOverview: LogsOverviewExtended[];
  logDetails: string[];

  retrieveLogs: Thunk<ILogsModel, void, void, IStoreModel>;
  retrieveLogDetails: Thunk<ILogsModel, string, void, IStoreModel>;

  setLogDetails: Action<ILogsModel, string[]>;
  setLoadingLogsData: Action<ILogsModel, boolean>;
  setLoadingLogDetails: Action<ILogsModel, boolean>;
  setLogOverview: Action<ILogsModel, ILogOverview[]>;
}

const logsModel: ILogsModel = {
  loadingLogDetails: false,
  loadingLogsData: false,
  logDetails: [],
  logOverview: [],

  retrieveLogDetails: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingLogDetails(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.log + payload });
    if (requestResponse.success) {
      actions.setLogDetails(requestResponse.data);
    }
    actions.setLoadingLogDetails(false);
  }),
  retrieveLogs: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingLogsData(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.logs });
    if (requestResponse.success) {
      actions.setLogOverview(requestResponse.data);
    }
    actions.setLoadingLogsData(false);
  }),
  setLoadingLogDetails: action((state, payload) => {
    state.loadingLogDetails = payload;
  }),
  setLoadingLogsData: action((state, payload) => {
    state.loadingLogsData = payload;
  }),
  setLogDetails: action((state, payload) => {
    state.logDetails = payload;
  }),
  setLogOverview: action((state, payload) => {
    const extendedLogOverview = payload.map((item, index) => {
      return {
        ...item,
        id: index.toString()
      }
    })

    state.logOverview = extendedLogOverview;
  }),
};

export default logsModel;
