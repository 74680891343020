import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../modules/common/components';
import FpButton from '../../modules/common/components/FpButton/FpButton';
import './ModalStyles.scss';

interface IAddItemModal {
  children: React.ReactNode;
  interactionDisabled: boolean;
  confirmInteractionDisabled: boolean;
  isModalOpen: boolean;
  modalCancelLabel: string;
  modalConfirmLabel: string;
  modalTitle: string;
  confirmAction: () => void;
  closeModal: () => void;
}

const AddItemModal = ({
  children,
  closeModal,
  confirmAction,
  interactionDisabled,
  confirmInteractionDisabled,
  isModalOpen,
  modalCancelLabel,
  modalConfirmLabel,
  modalTitle,
}: IAddItemModal): JSX.Element => {
  return (
    <Dialog onClose={closeModal} open={isModalOpen} maxWidth="md">
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent sx={{ overflowY: 'unset' }}>{children}</DialogContent>
      <DialogActions>
        <FpButton onClick={closeModal} disabled={interactionDisabled} color="error">
          {modalCancelLabel}
        </FpButton>
        <FpButton
          onClick={confirmAction}
          disabled={interactionDisabled || confirmInteractionDisabled}
          color="secondary"
        >
          {modalConfirmLabel}
        </FpButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemModal;
