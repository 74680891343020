import { Dialog, DialogActions, DialogContent, DialogSubtitle, DialogTitle } from '../../modules/common/components';
import FpButton from '../../modules/common/components/FpButton/FpButton';
import { useStoreActions, useStoreState } from '../../Store';

const ConfirmationModal = (): JSX.Element => {
  const confirmationContent = useStoreState((state) => state.modalModel.confirmationContent);
  const confirmationShow = useStoreState((state) => state.modalModel.confirmationShow);
  const confirmationSize = useStoreState((state) => state.modalModel.confirmationSize);
  const confirmationTitle = useStoreState((state) => state.modalModel.confirmationTitle);
  const confirmationButtonLabel = useStoreState((state) => state.modalModel.confirmationButtonLabel);

  const confirmationToggle = useStoreActions((actions) => actions.modalModel.confirmationToggle);

  const closeModal = () => {
    confirmationToggle({ content: undefined, showModal: false, title: '', confirmationSize: false });
  };

  return (
    <Dialog open={confirmationShow} onClose={closeModal} maxWidth={confirmationSize}>
      <DialogTitle>{confirmationTitle}</DialogTitle>

      <DialogContent>
        <DialogSubtitle>{confirmationContent}</DialogSubtitle>
      </DialogContent>
      <DialogActions>
        <FpButton color="secondary" onClick={closeModal}>
          {confirmationButtonLabel}
        </FpButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
