import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import AddIcon from '@mui/icons-material/Add';
import { memo, useState } from 'react';
import { useStoreActions } from '../../../../Store';
import FpButtonIcon from '../../../common/components/FpButtonIcon';
import { isUserGuest } from '../../../users/types/users-configs';
import InstallationTagsOverview from './InstallationTagsOverview';

export type TagOptions = {
  label: string;
  display: 'none' | 'inline-block';
  color?: string;
};

type Props = {
  installationId: number;
  userRole: RoleType;
  size?: 'small' | 'medium' | 'large';
};

const AddInstallationTagButton = memo(({ installationId, userRole, size = 'small' }: Props) => {
  const getInstallationTags = useStoreActions((actions) => actions.installationsModel.getInstallationTags);
  const getTagsForCompany = useStoreActions((actions) => actions.companyModel.getTagsForCompany);

  const [showAddTagModal, setShowAddTagModal] = useState(false);

  if (isUserGuest(userRole)) return null;

  return (
    <>
      <FpButtonIcon
        size={size}
        sx={{
          marginLeft: '0.5rem',
          backgroundColor: 'rgba(56, 118, 29, 0.7)',
          color: '#fff',
          '&:hover': {
            backgroundColor: 'rgba(56, 118, 29, 1)',
            color: '#fff',
          },
        }}
        onClick={async (event) => {
          event.stopPropagation();
          await getInstallationTags(installationId);
          await getTagsForCompany();
          setShowAddTagModal(true);
        }}
      >
        <AddIcon sx={{ color: 'white !important', fontSize: '12px !important' }} />
      </FpButtonIcon>
      <InstallationTagsOverview
        closeTagModal={() => {
          setShowAddTagModal(false);
        }}
        installationId={installationId}
        showTagModal={showAddTagModal}
        userRole={userRole}
      />
    </>
  );
});

export default AddInstallationTagButton;
