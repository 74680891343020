import { INotificationNoteRequest } from '@flowplan/flowplan-shared';
import { IDeviceRequestPackage } from '@flowplan/flowplan-shared/lib/interfaces/installationView';
import { Dayjs } from 'dayjs';
import { Action, Thunk, ThunkOn } from 'easy-peasy';
import { FileWithPreview } from '../../../Components/common/Dropzone/Dropzone';
import { IStoreModel } from '../../../Models';
import { IProcessedDeviceDetails } from '../../../common/interfacesData';
import { ITapSensorCreate } from '../../taps/services/tap.model';
import { FilterMachineTypes } from '@flowplan/flowplan-shared/lib/machine.types/machine.types';

export type SelectedWaterFilter = {
  filterChangeOnTime: number;
  filterProducerId: number;
  filterId: number;
  filterMachineTypeId: number;
  filterProducerHasData: boolean;
  filterBypassInfo: string;
  filterCapacity: number | string;
  filterProperCapacity: number;
  filterName: string;
  filterProducerName: string;
  hardness: number | string;
};

export const defaultWaterFilterSelection: SelectedWaterFilter = {
  filterCapacity: '',
  filterProperCapacity: 0,
  filterId: -1,
  filterMachineTypeId: FilterMachineTypes.Normal,
  filterProducerId: -1,
  filterChangeOnTime: -1,
  filterName: '',
  filterProducerName: '',
  filterProducerHasData: true,
  filterBypassInfo: 'N/A',
  hardness: '',
};


interface AddNoteRequest extends INotificationNoteRequest {
  installationId: number;
}

type ChangeInstallationFlowmeter = {
  flowmeterId: number;
  installationId: number;
  dateOfChange: string;
};

export type ChangeWaterFilterSettings = {
  installationId: number;
  waterFilterChangeDate: string;
  waterFilterProducerId: number;
  waterFilterId: number;
  waterFilterChangeByLatestId: number;
  waterFilterMachineTypeId: number;
  waterFilterCapacity: number;
};
type DailyUsagePerWeekdayRequest = {
  sensorId: number;
  daysTilNow: number
}

export type UsagePerWeekday = {
  [index: string]: {
    averageUsage: number;
    usage: number
    date: string
  }
}

export interface IDeviceActionsModel {
  // all actions:
  usagePerWeekday: UsagePerWeekday;
  setUsagePerWeekday: Action<IDeviceActionsModel, UsagePerWeekday>

  batteryChangeDate: Dayjs;
  setBatteryChangeDate: Action<IDeviceActionsModel, Dayjs>;
  // export data

  addSensor: Thunk<IDeviceActionsModel, IDeviceRequestPackage, void, IStoreModel>;
  addTapSensor: Thunk<IDeviceActionsModel, ITapSensorCreate, void, IStoreModel>;
  updateSensor: Thunk<IDeviceActionsModel, IDeviceRequestPackage, void, IStoreModel>;
  updateTapSensor: Thunk<IDeviceActionsModel, IDeviceRequestPackage, void, IStoreModel>;
  removeSensor: Thunk<IDeviceActionsModel, void, void, IStoreModel>;
  removeTapSensor: Thunk<IDeviceActionsModel, void, void, IStoreModel>;
  changeBattery: Thunk<IDeviceActionsModel, void, void, IStoreModel>;
  changeWaterFilter: Thunk<IDeviceActionsModel, Dayjs, void, IStoreModel>;
  changeToNewFilter: Thunk<IDeviceActionsModel, ChangeWaterFilterSettings, void, IStoreModel>;
  changeFlowmeter: Thunk<IDeviceActionsModel, ChangeInstallationFlowmeter, void, IStoreModel>;
  changeSerial: Thunk<IDeviceActionsModel, string, void, IStoreModel>; // flowplanSerial (string)
  performMaintenance: Thunk<IDeviceActionsModel, void, void, IStoreModel>;
  resetAverage: Thunk<IDeviceActionsModel, string, void, IStoreModel>; // date format YMD
  addNote: Thunk<IDeviceActionsModel, AddNoteRequest, void, IStoreModel>;
  addUsage: Thunk<IDeviceActionsModel, number, void, IStoreModel>; // liters (number)
  getUsagePerWeekday: Thunk<IDeviceActionsModel, DailyUsagePerWeekdayRequest, void, IStoreModel>;

  dateSelection: Dayjs;
  setDateSelection: Action<IDeviceActionsModel, Dayjs>;

  maintenanceDescription: string;
  setMaintenanceDescription: Action<IDeviceActionsModel, string>;

  getInstallationById: Thunk<IDeviceActionsModel, number, void, IStoreModel>; // id (number)
  getInstallationBySerial: Thunk<IDeviceActionsModel, string, void, IStoreModel>; // flowplanSerial (string)

  sensorDetailsIntervalUpdate: number;
  setSensorDetailsInterval: Action<IDeviceActionsModel, number | null>; // interval
  loadingSensorDetails: boolean;

  getFilterDetailsData: Thunk<IDeviceActionsModel, void, void, IStoreModel>;
  sensorDetailsProcessedDeviceDetails: IProcessedDeviceDetails;
  sensorDetailsSetData: Action<IDeviceActionsModel, IProcessedDeviceDetails>;
  sensorDetailsHandleLoading: Action<IDeviceActionsModel, boolean>;

  onActionPerformed: ThunkOn<IDeviceActionsModel, void, IStoreModel>;

  getInstallationImages: Thunk<IDeviceActionsModel, void, void, IStoreModel>;
  uploadInstallationImages: Thunk<IDeviceActionsModel, FileWithPreview[], void, IStoreModel>;
  deleteInstallationImages: Thunk<IDeviceActionsModel, string, void, IStoreModel>;
  setInstallationImages: Action<IDeviceActionsModel, string[]>;
  loadingInstallationImages: boolean;
  setLoadingInstallationImages: Action<IDeviceActionsModel, boolean>;
  installationImages: string[];

  onActionsManagingSensorForSublocation: ThunkOn<IDeviceActionsModel, void, IStoreModel>;
}
export default IDeviceActionsModel;
