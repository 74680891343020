import { IFilter, sessionExpired } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { ICalculatedCapacity } from '@flowplan/flowplan-shared/lib/filter/filter';
import { IDevice } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { FilterMachineTypes } from '@flowplan/flowplan-shared/lib/machine.types/machine.types';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import { IResponse } from '@hsjakobsen/utilities/lib/responses';
import { action, thunk, thunkOn } from 'easy-peasy';
import { noSelectionMade } from '../../../Models/admin/admin.model';
import {
  defaultFilterRelatedInformationResult,
  locationNotFoundState,
} from '../../../Models/filterData/filterdata.consts';
import { IProcessedFilterInfo } from '../../../Models/filterData/filterdata.model';
import { defaultBaseFilter } from '../../../Models/graphSettings';
import { getDateObject } from '../../../Utility/time';
import { DeviceCategories, DeviceTypes } from '../../../common/constsCategoryAndTypes';
import { HardnessEnum } from '../../../common/constsFrontend';
import { ICustomFilterData, ICustomFilterInfo } from '../../../common/interfacesFrontend';
import {
  IInputExternalSensor,
  IInputFeatureFilter,
  IInputRequired,
  IInputService,
} from '../../../common/interfacesInput';
import { getCategoryFromType, getNameSuggestionForMachine } from '../components/DeviceType/utility-category-and-type';
import INewInstallationModel from '../types/newInstallation-model';

export enum NewInstallationSteps {
  Clients = 1,
  ClientsAddNew = 2,
  Locations = 3,
  LocationsAddNew = 4,
  Machine = 5,
  NameAndDescription = 6,
  Serial = 7,
  Flowmeters = 8,
  ExternalSensor = 9,
  WaterFilterFeature = 10,
  WaterFilterDetails = 11,
  MaintenanceFeature = 12,
  MaintenanceDetails = 13,
  UpdateView = 14,
}

const newInstallationModel: INewInstallationModel = {
  newInstallationStep: NewInstallationSteps.Clients,
  setNewInstallationStep: action((state, payload) => {
    state.newInstallationStep = payload;
  }),

  inputFlowmeterId: 0,
  inputName: '',
  inputSelectedType: DeviceTypes.NoSelection,
  inputSerial: '',
  inputShortDesc: '',
  setInputFlowmeterId: action((state, payload) => {
    state.inputFlowmeterId = payload;
  }),
  setInputName: action((state, payload) => {
    state.inputName = payload;
    state.overwriteName = false;
  }),
  setInputSelectedType: action((state, payload) => {
    state.inputSelectedType = payload;
  }),
  setInputSerial: action((state, payload) => {
    state.inputSerial = payload;
  }),
  setInputShortDesc: action((state, payload) => {
    state.inputShortDesc = payload;
  }),
  inputLocationId: noSelectionMade,
  setInputLocationId: action((state, payload) => {
    state.inputLocationId = payload;
  }),
  inputExternalSensorId: noSelectionMade,
  setInputExternalSensorId: action((state, payload) => {
    state.inputExternalSensorId = payload;
  }),

  deviceId: undefined,
  setDeviceId: action((state, payload) => {
    state.deviceId = payload;
  }),

  selectedCategory: DeviceCategories.NoSelection,
  setSelectedCategory: action((state, payload) => {
    state.selectedCategory = payload;
  }),

  // Service
  featureService: false,
  setFeatureService: action((state, payload) => {
    state.featureService = payload;
  }),
  inputServiceAtLiters: '',
  setInputServiceAtLiters: action((state, payload) => {
    state.inputServiceAtLiters = payload;
    state.inputMaintenanceAtCycles = '';
  }),
  inputMaintenanceAtCycles: '',
  setInputMaintenanceAtCycles: action((state, payload) => {
    state.inputMaintenanceAtCycles = payload;
    state.inputServiceAtLiters = '';
  }),
  inputServiceChangeByLatestId: noSelectionMade,
  setInputServiceChangeByLatestId: action((state, payload) => {
    state.inputServiceChangeByLatestId = payload;
  }),

  inputServiceStartDate: getDateObject(null),
  setInputServiceStartDate: action((state, payload) => {
    state.inputServiceStartDate = payload;
  }),
  serviceMinDate: null,

  modalNewInstallationData: undefined,
  manualChange: false,
  setManualChange: action((state, payload) => {
    state.manualChange = payload;
  }),
  isUpdate: false,
  shouldRetrieveDetailsData: false,
  overwriteName: true,
  setOverwriteName: action((state, payload) => {
    state.overwriteName = payload;
  }),
  evaluateName: thunk((actions, payload, { getStoreState }) => {
    const inputLocationId = getStoreState().newInstallationModel.inputLocationId;
    const selectedType = getStoreState().newInstallationModel.inputSelectedType;
    const waterFilterLocationsDropdown = getStoreState().locationsModel.waterFilterLocationsDropdown;
    const sensorCategories = getStoreState().staticDataModel.sensorCategories;
    const currentName = getNameSuggestionForMachine({
      inputLocationId,
      locations: waterFilterLocationsDropdown,
      selectedType,
      sensorCategories,
    });
    const currentInput = getStoreState().newInstallationModel.inputName;
    actions.setOverwriteName(currentInput === '' || currentInput === currentName);
  }),
  generateName: thunk((actions, payload, { getStoreState }) => {
    const overwriteNameValue = getStoreState().newInstallationModel.overwriteName;
    if (!overwriteNameValue) {
      return;
    }
    const inputLocationId = getStoreState().newInstallationModel.inputLocationId;
    const selectedType = getStoreState().newInstallationModel.inputSelectedType;
    const waterFilterLocationsDropdown = getStoreState().locationsModel.waterFilterLocationsDropdown;
    const sensorCategories = getStoreState().staticDataModel.sensorCategories;
    const newName = getNameSuggestionForMachine({
      inputLocationId,
      locations: waterFilterLocationsDropdown,
      selectedType,
      sensorCategories,
    });
    actions.setInputName(newName);
  }),

  parseModalNewInstallationData: thunk((actions, payload, { getStoreState }) => {
    if (payload.installationData !== undefined) {
      // Fix for setting up the proper list of machine service options when editing an installation
      actions.setInputSelectedType(payload.installationData.deviceType);
    }

    actions.setModalNewInstallationData(payload);
    const filterProducerId = getStoreState().newInstallationModel.inputFilterProducerId;
    const filters = getStoreState().staticDataModel.filters;
    const filterIdToSet = payload.installationData ? payload.installationData.filterId : 1;
    if (filterIdToSet !== -1 && filterProducerId !== 0) {
      const filterList = filters[filterProducerId];
      if (filterList !== undefined) {
        actions.setFiltersList(filterList);
      }
    }

    actions.setInputFilterId(filterIdToSet ? filterIdToSet : -1);
    const machineTypeId = payload.installationData?.machineTypeId || FilterMachineTypes.Normal;
    actions.setInputFilterMachineTypeId(payload.installationData ? machineTypeId : FilterMachineTypes.Normal);
    actions.setIsMounted(true);
  }),

  setModalNewInstallationData: action((state, payload) => {
    const isUpdate = payload.installationData !== undefined;
    state.isUpdate = isUpdate;
    state.newInstallationStep = isUpdate ? NewInstallationSteps.UpdateView : NewInstallationSteps.Clients;
    state.overwriteName = !isUpdate;
    state.manualChange = false;

    if (payload.installationData === undefined) {
      return;
    }

    const installationData = payload.installationData;

    // Filter
    state.deviceId = installationData.id;
    const featureFilter = installationData.featureFilter === 1;
    state.featureFilter = featureFilter;
    const filterProducerIdToSet = featureFilter ? installationData.filterProducerId : 1;
    state.inputFilterProducerId = filterProducerIdToSet;
    const changeByLatestIdToSet = installationData.changeByLatestId
      ? installationData.changeByLatestId
      : noSelectionMade;
    state.inputFilterChangeByLatestId = changeByLatestIdToSet;
    state.inputFilterMachineTypeId = installationData.machineTypeId
      ? installationData.machineTypeId
      : FilterMachineTypes.Normal;

    // External sensor
    const externalSensorSelected = installationData.externalSensorId
      ? installationData.externalSensorId
      : noSelectionMade;
    state.inputExternalSensorId = externalSensorSelected;

    // Required information
    const deviceType = installationData.deviceTypeId ? installationData.deviceTypeId : 0;
    state.inputSelectedType = deviceType;

    state.selectedCategory = getCategoryFromType(deviceType);
    state.inputName = installationData.name;
    const serialToSet = installationData.flowplanDeviceId ? installationData.flowplanDeviceId : '';
    state.inputSerial = serialToSet;
    const shortDescToSet = installationData.shortDesc ? installationData.shortDesc : '';
    state.inputShortDesc = shortDescToSet;
    const locationIdToSet = installationData.locationId ? installationData.locationId : noSelectionMade;
    state.inputLocationId = locationIdToSet;
    const flowmeterIdToSet = installationData.flowmeterId ? installationData.flowmeterId : 0;
    state.inputFlowmeterId = flowmeterIdToSet;

    // Service
    state.featureService = installationData.featureService === 1;

    const serviceAtLiters = installationData.serviceAtLiters ? installationData.serviceAtLiters : '';
    state.inputServiceAtLiters = serviceAtLiters;
    state.inputMaintenanceAtCycles = installationData.dataOverView.maintenanceCycles
      ? installationData.dataOverView.maintenanceCycles
      : '';

    const serviceChangeByLatestIdSelected = installationData.serviceChangeByLatestId
      ? installationData.serviceChangeByLatestId
      : noSelectionMade;
    state.inputServiceChangeByLatestId = serviceChangeByLatestIdSelected;

    state.inputServiceStartDate = getDateObject(installationData.serviceDate || null);
    state.serviceMinDate = installationData.installmentDate ? getDateObject(installationData.installmentDate) : null;
  }),

  openInstallationSettings: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const installationId = getStoreState().graphSettingsModel.selectedInstallation.infoBasic.id;
    const flowplanClientIdOverride = getStoreState().adminDataModel.flowplanClientSelected;

    const routeToUse = Routes.databaseDevice + installationId + '/' + flowplanClientIdOverride;
    const installationDataResponse = await getStoreActions().serverRequestsModel.get({ route: routeToUse });

    actions.toggleNewSensorModal({
      flowplanDeviceId: undefined,
      showModal: true,
      shouldRetrieveDetailsData: payload.shouldRetrieveDetailsData,
    });
    const installationData: IDevice = installationDataResponse.data;
    actions.parseModalNewInstallationData({ installationData: installationData });
    if (installationData === undefined) {
      return;
    }
    const flowmeters = getStoreState().staticDataModel.flowmeters;
    const flowmeter = flowmeters.find((x) => x.id === installationData.flowmeterId);
    const flowmeterNameToUse = flowmeter ? flowmeter.name : '';
    actions.setFlowmeterName(flowmeterNameToUse);
    if (!getStoreState().companyModel.isPremium) {
      return;
    }
    getStoreActions().stockDailyDataModel.getStockAndSettings({
      locationId: installationData.locationId,
      sensorId: installationData.id,
    });
  }),
  showModalNewSensor: false,
  modalInteractionDisabled: false,
  toggleModalInteractionDisabled: action((state, payload) => {
    state.modalInteractionDisabled = payload;
  }),
  toggleNewSensorModal: action((state, payload) => {
    const serialToSet = payload.flowplanDeviceId ? payload.flowplanDeviceId : '';
    state.inputSerial = serialToSet;
    state.showModalNewSensor = payload.showModal;
    state.shouldRetrieveDetailsData = payload.shouldRetrieveDetailsData;
  }),

  flowmeterName: '',
  setFlowmeterName: action((state, payload) => {
    state.flowmeterName = payload;
  }),

  resetInput: thunk((actions, payload, { getStoreState }) => {
    const filters = getStoreState().staticDataModel.filters;
    actions.setFiltersList(filters[1]);
    actions.resetState();
    actions.setFlowplanCapacityRecommendationInfo({ capacity: '', hardness: '' });
  }),

  resetState: action((state) => {
    state.mounted = false;
    state.overwriteName = true;
    state.featureFilter = false;
    state.deviceId = undefined;
    state.inputName = '';
    state.inputSerial = '';
    state.inputShortDesc = '';
    state.inputSelectedType = DeviceTypes.NoSelection;
    state.inputLocationId = noSelectionMade;
    state.manualChange = false;

    // External sensor
    state.inputExternalSensorId = noSelectionMade;

    // Water filter
    state.inputFlowmeterId = 0;
    state.inputFilterId = 1;
    state.inputFilterProducerId = noSelectionMade;
    state.inputFilterChangeByLatestId = noSelectionMade;
    state.inputFilterMachineTypeId = FilterMachineTypes.Normal;
    state.inputCapacity = 0;
    state.showCustomFilter = false;

    state.selectedCategory = DeviceCategories.NoSelection;

    // Service
    state.featureService = false;
    state.inputServiceChangeByLatestId = noSelectionMade;
    state.inputServiceAtLiters = '';
    state.inputMaintenanceAtCycles = '';
    state.inputServiceStartDate = getDateObject(null);
    state.serviceMinDate = null;
  }),

  addNewInstallation: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const sensorInfo = payload.requestPackage;

    let response: IResponse = await getStoreActions().deviceActionsModel.addSensor(sensorInfo);
    if (response.success) {
      response = await getStoreActions().stockSettingsModel.manageStockSettingCreation({
        selectedStockSettings: payload.selectedStockSettings,
        sensorId: response.data.id,
      });
    }

    actions.handleNewSensorFeedback(response);
    const flowplanClientSelected = getStoreState().adminDataModel.flowplanClientSelected;
    getStoreActions().inventoryBottlesModel.retrieveInventoryData({ flowplanClientIdOverride: flowplanClientSelected });

    return response;
  }),
  addNewInstallationDraft: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const sensorInfo = payload.requestPackage;

    let response: IResponse = await getStoreActions().deviceActionsModel.addSensor(sensorInfo);
    if (response.success) {
      response = await getStoreActions().stockSettingsModel.manageStockSettingCreation({
        selectedStockSettings: payload.selectedStockSettings,
        sensorId: response.data.id,
      });
    }

    actions.handleNewSensorFeedback(response);
    const flowplanClientSelected = getStoreState().adminDataModel.flowplanClientSelected;
    getStoreActions().inventoryBottlesModel.retrieveInventoryData({ flowplanClientIdOverride: flowplanClientSelected });

    return response;
  }),
  updateInstallation: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const sensorInfo = payload.requestPackage;

    let response: IResponse = await getStoreActions().deviceActionsModel.updateSensor(sensorInfo);
    if (response.success) {
      response = await getStoreActions().stockSettingsModel.manageStockSettingUpdates({
        ...payload,
        sensorId: sensorInfo.id ?? null,
      });
    }

    actions.handleNewSensorFeedback(response);
    const flowplanClientSelected = getStoreState().adminDataModel.flowplanClientSelected;
    getStoreActions().inventoryBottlesModel.retrieveInventoryData({ flowplanClientIdOverride: flowplanClientSelected });

    return response;
  }),
  handleNewSensorFeedback: action((state, payload) => {
    state.modalInteractionDisabled = false;
    if (!payload.success) {
      if (payload.data === sessionExpired) {
        window.location.reload();
        return;
      }

      if (!state.showModalNewSensor) {
        alert('Error occured: ' + payload.message);
      }

      return;
    }
    state.showModalNewSensor = false;
  }),

  getInstallationInput: thunk((actions, payload, { getState }) => {
    const inputExternalSensor: IInputExternalSensor = {
      inputExternalSensorId: getState().inputExternalSensorId,
    };
    const inputFilterOptions: IInputFeatureFilter = {
      inputCapacity: getState().inputCapacity,
      inputChangeByLatestId: getState().inputFilterChangeByLatestId,
      inputFeatureFilter: getState().featureFilter,
      inputFilterId: getState().inputFilterId,
      inputFilterProducerId: getState().inputFilterProducerId,
      inputUsingMachineTypeId: getState().inputFilterMachineTypeId,
    };
    const inputRequired: IInputRequired = {
      inputDeviceType: getState().inputSelectedType,
      inputFlowmeterId: getState().inputFlowmeterId,
      inputLocationId: getState().inputLocationId,
      inputName: getState().inputName,
      inputSerial: String(getState().inputSerial),
      inputShortDesc: getState().inputShortDesc,
    };
    const inputService: IInputService = {
      inputFeatureService: getState().featureService,
      inputServiceAtLiters: Number(getState().inputServiceAtLiters),
      serviceChangeByLatestId: getState().inputServiceChangeByLatestId,
      serviceStartDate: getState().inputServiceStartDate.format(dateFormatYMD),
      inputMaintenanceAtCycles: Number(getState().inputMaintenanceAtCycles),
    };

    const isUpdate = getState().isUpdate;
    const id = getState().deviceId;

    return {
      inputExternalSensor,
      inputFilterOptions,
      inputRequired,
      inputService,
      isUpdate,
      id,
    };
  }),

  filtersList: [],
  setFiltersList: action((state, payload) => {
    state.filtersList = payload;
  }),

  flowplanCapacityRecommendationInfo: { capacity: '', hardness: '' },
  setFlowplanCapacityRecommendationInfo: action((state, payload) => {
    state.flowplanCapacityRecommendationInfo = payload;
  }),

  bypassSetting: '',
  setBypassSetting: action((state, payload) => {
    state.bypassSetting = payload;
  }),

  disableCapacityInput: false,
  setDisableCapacityInput: action((state, payload) => {
    state.disableCapacityInput = payload;
  }),
  featureFilter: false,
  setFeatureFilter: action((state, payload) => {
    state.featureFilter = payload;
  }),

  showCustomFilter: false,
  setShowCustomFilter: action((state, payload) => {
    state.showCustomFilter = payload;
  }),
  inputCapacity: '',
  setInputCapacity: action((state, payload) => {
    state.inputCapacity = payload;
  }),

  inputFilterId: 1,
  setInputFilterId: action((state, payload) => {
    state.inputFilterId = payload;
  }),
  inputFilterProducerId: noSelectionMade,
  setInputFilterProducerId: action((state, payload) => {
    state.inputFilterProducerId = payload;
  }),

  inputFilterChangeByLatestId: noSelectionMade,
  setInputFilterChangeByLatestId: action((state, payload) => {
    state.inputFilterChangeByLatestId = payload;
  }),

  inputFilterMachineTypeId: FilterMachineTypes.Normal,
  setInputFilterMachineTypeId: action((state, payload) => {
    state.inputFilterMachineTypeId = payload;
  }),

  mounted: false,
  setIsMounted: action((state, payload) => {
    state.mounted = payload;
  }),

  // Side effects
  onDeviceTypeSelected: thunkOn(
    (actions, storeActions) => storeActions.newInstallationModel.setInputSelectedType,
    (actions, payload) => {
      actions.generateName();
      actions.setInputMaintenanceAtCycles(0);
      actions.setInputServiceAtLiters(0);
    },
  ),

  onLocationSelected: thunkOn(
    (actions, storeActions) => storeActions.newInstallationModel.setInputLocationId,
    async (actions, target, { getState, getStoreState, getStoreActions }) => {
      actions.generateName();
      actions.filterInfoCalculation();

      const locationId = target.payload;
      const sensorId = getState().deviceId;

      const isPremium = getStoreState().companyModel.isPremium;
      if (!isPremium) {
        return;
      }

      getStoreActions().stockDailyDataModel.getStockAndSettings({ locationId, sensorId });
    },
  ),

  onFiltersListRetrieved: thunkOn(
    (actions, storeActions) => storeActions.staticDataModel.setFilters,
    async (actions, payload, { getStoreState }) => {
      const filters = getStoreState().staticDataModel.filters;
      actions.setFiltersList(filters[1]);
      const filterIdToSet: IFilter[] = filters[1] as IFilter[];
      actions.setInputFilterId(filterIdToSet[0].id);
    },
  ),

  onFilterFeatureChanges: thunkOn(
    (actions, storeActions) => storeActions.newInstallationModel.setFeatureFilter,
    async (actions, target, { getStoreState }) => {
      actions.setInputFilterId(target.payload ? 1 : -1);
      // actions.filterInfoCalculation();
    },
  ),
  onFilterIdChanges: thunkOn(
    (actions, storeActions) => storeActions.newInstallationModel.setInputFilterId,
    async (actions, payload, { getStoreActions, getState }) => {
      const filterId = payload.payload;
      actions.filterSelectionChanges({ filterId });
      actions.filterInfoCalculation();
      await getStoreActions().filterDataModel.getMachineTypesForFilter(filterId);
    },
  ),

  onFilterProducerIdChanges: thunkOn(
    (actions, storeActions) => storeActions.newInstallationModel.setInputFilterProducerId,
    async (actions, payload, { getStoreState, getStoreActions }) => {
      const filters = getStoreState().staticDataModel.filters;
      const newFiltersList: IFilter[] = filters[payload.payload] as IFilter[];
      if (newFiltersList) {
        const filterId = newFiltersList[0].id;
        actions.setFiltersList(newFiltersList);
        actions.filterSelectionChanges({
          filterId,
        });
        await getStoreActions().filterDataModel.getMachineTypesForFilter(filterId);
      } else {
        actions.setInputFilterId(0);
      }
      actions.filterInfoCalculation();
    },
  ),

  onFilterMachineTypeIdSettingChanges: thunkOn(
    (actions, storeActions) => storeActions.newInstallationModel.setInputFilterMachineTypeId,
    async (actions, payload, { getStoreState }) => {
      actions.filterInfoCalculation();
    },
  ),

  onMountedChanges: thunkOn(
    (actions, storeActions) => storeActions.newInstallationModel.setIsMounted,
    async (actions, payload, { getStoreState }) => {
      actions.filterInfoCalculation();
    },
  ),

  filterInfoCalculation: thunk(async (actions, payload, { getStoreActions, getStoreState, getState }) => {
    const mounted = getStoreState().newInstallationModel.mounted;
    if (!mounted) {
      return;
    }

    const baseFilter = getStoreState().graphSettingsModel.selectedInstallation;
    const deviceId = getState().deviceId;
    const inputLocationId = getState().inputLocationId;
    const inputFilterProducerId = getState().inputFilterProducerId;
    const deviceIdToUse = deviceId ? deviceId : -1;
    let sensorLookup = getStoreState().installationsModel.sensors.find((x) => x.infoBasic.id === deviceIdToUse);
    if (sensorLookup === undefined) {
      sensorLookup = defaultBaseFilter;
    }

    const filterProducers = getStoreState().staticDataModel.filterProducers;

    const filterProducerLookup = filterProducers.find((x) => x.id === inputFilterProducerId);
    if (filterProducerLookup !== undefined && filterProducerLookup.data === 0 && filterProducerLookup.monitor === 0) {
      const constructCustomFilterState = getStoreActions().filterDataModel.constructCustomFilterState;
      const filterData: ICustomFilterData = {
        id: deviceId,
        inputLocationId,
      };

      if (baseFilter.infoBasic.id !== 0) {
        sensorLookup = baseFilter;
      }
      const customFilterInfo: ICustomFilterInfo = constructCustomFilterState({
        infoForFilter: filterData,
        sensorLookup,
      });

      actions.setFlowplanCapacityRecommendationInfo({ capacity: '', hardness: '' });
      actions.setInputCapacity(customFilterInfo.inputCapacity);
      actions.setShowCustomFilter(customFilterInfo.showCustomFilter);
      actions.setDisableCapacityInput(customFilterInfo.disableCapacityInput);
      return;
    }

    const inputFilterId = getState().inputFilterId;
    const inputFilterMachineTypeId = getState().inputFilterMachineTypeId;
    const manualChange = getState().manualChange;
    const featureFilter = getState().featureFilter;

    const waterFilterLocations = getStoreState().locationsModel.waterFilterLocations;
    const locationLookup = waterFilterLocations.find((x) => x.id === inputLocationId);

    let info: IProcessedFilterInfo = defaultFilterRelatedInformationResult;

    if (!locationLookup) {
      info = locationNotFoundState;
    }

    if (featureFilter && locationLookup) {
      const { hardnessData } = locationLookup;

      const carbonateHardness = hardnessData.find((data) => data.hardnessType.name === HardnessEnum.CARBONATE_HARDNESS);
      const totalHardness = hardnessData.find((data) => data.hardnessType.name === HardnessEnum.TOTAL_HARDNESS);
      const microSiemensPerCm = hardnessData.find((data) => data.hardnessType.name === HardnessEnum.MICRO_SIEMENS_PER_CM);

      const capacityAndBypass: ICalculatedCapacity = await getStoreActions().filterDataModel.getCalculatedCapacity(
        {
          filterId: inputFilterId,
          carbonateHardness: carbonateHardness ? carbonateHardness.value : 0,
          totalHardness: totalHardness ? totalHardness.value : 0,
          microSiemensPerCm: microSiemensPerCm ? microSiemensPerCm.value : 0,
          filterMachineTypeId: inputFilterMachineTypeId,
        },
      );

      const { bypass, capacity, hardnessInfo } = capacityAndBypass;

      info = {
        capacity,
        filterId: inputFilterId,
        hardness: hardnessInfo,
        recommendedBypass: bypass,
        recommendedCapacity: capacity,
        disableCapacityInput: false,
        showCustomFilter: false,
        filterMachineTypeId: inputFilterMachineTypeId,
      };

      let capacityToShow = info.capacity;
      if (baseFilter.infoBasic.id !== 0 && sensorLookup === undefined) {
        sensorLookup = baseFilter;
      }
      const installationId = sensorLookup.infoBasic.id;
      if (installationId) {
        const installationCurrentCapacity = sensorLookup.infoFilter.capacityTotal;
        if (!manualChange && installationCurrentCapacity !== -1) {
          capacityToShow = installationCurrentCapacity;
        }
      }
      info.capacity = capacityToShow;
    }

    actions.setFlowplanCapacityRecommendationInfo({
      capacity: info.recommendedCapacity,
      hardness: info.hardness,
    });

    actions.filterInfoChanges(info);
  }),

  filterInfoChanges: action((state, payload) => {
    state.inputCapacity = payload.capacity;
    state.bypassSetting = payload.recommendedBypass;
    state.disableCapacityInput = payload.disableCapacityInput;
    state.showCustomFilter = payload.showCustomFilter;
    state.inputFilterMachineTypeId = payload.filterMachineTypeId;
  }),

  filterSelectionChanges: action((state, payload) => {
    state.inputFilterMachineTypeId = FilterMachineTypes.Normal;
    state.inputFilterId = payload.filterId;
    state.showCustomFilter = false;
  }),
};

export default newInstallationModel;
