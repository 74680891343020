import { Color } from '@flowplan/flowplan-shared';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import InputComponentCanDisable from '../../../../Components/Input/InputComponentCanDisable';
import { useStoreActions, useStoreState } from '../../../../Store';
import { Dialog, DialogActions, DialogContent, DialogSubtitle, DialogTitle } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpText from '../../../common/components/FpText/FpText';
import FpImageButton from '../FpImageButton/FpImageButton';
import { loadImage } from '../../../../Utility/dynamicImport';

const FlowplanFlowmeterSelection = (): JSX.Element => {
  const setInputFlowmeterId = useStoreActions((actions) => actions.newInstallationModel.setInputFlowmeterId);
  const setFlowmeterName = useStoreActions((actions) => actions.newInstallationModel.setFlowmeterName);
  const inputFlowmeterId = useStoreState((state) => state.newInstallationModel.inputFlowmeterId);

  const flowmeterName = useStoreState((state) => state.newInstallationModel.flowmeterName);
  const flowmeters = useStoreState((state) => state.staticDataModel.flowmeters);
  const isUpdate = useStoreState((state) => state.newInstallationModel.isUpdate);

  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [flowmeterImageSrcs, setFlowmeterImageSrcs] = useState<string[]>([])
  const closeModal = () => {
    setDialogVisible(false);
  };
  const inputFlowmeterIndex = flowmeters.findIndex((flowmeter) => flowmeter.id === inputFlowmeterId)

  useEffect(() => {
    const async_effect = async () => {
      const dynamicImports = flowmeters.map(async (flowmeter) => {
        return await loadImage(flowmeter.id)
      })
      const srcArray = await Promise.all(dynamicImports)
      setFlowmeterImageSrcs(srcArray)
    }
    async_effect()
  }, [flowmeters, setFlowmeterImageSrcs])

  return (
    <InputComponentCanDisable isDisabled={isUpdate} inputValue={flowmeterName} inputText="Flowmeter*">
      <>
        <FpText variant="body1" sx={{ pt: 2, color: Color.FlowplanBlue }}>
          Flowmeter*
        </FpText>

        <FpButton
          value={1}
          style={{
            minWidth: '170px',
            width: '100%',
            marginBottom: '0.5rem',
            marginTop: '0.5rem',
            border: inputFlowmeterId ? `2px solid ${Color.FlowplanBlue}` : 'none',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
          aria-label='3/8" flowmeters'
          color="inherit"
          onClick={() => {
            setDialogVisible(true);
          }}
        >
          {inputFlowmeterId ? (
            <>
              <img
                src={flowmeterImageSrcs.at(inputFlowmeterIndex)}
                style={{
                  width: '200px',
                  height: 'inherit',
                  objectFit: 'contain',
                  scale: '0.85',
                }}
                alt={flowmeterName}
                loading="lazy"
              />
              <FpText
                variant="body1"
                sx={{
                  pt: 2,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: Color.FlowplanBlue,
                  textTransform: 'capitalize',
                }}
              >
                {flowmeterName}
              </FpText>
            </>
          ) : (
            'Select a flowmeter'
          )}
        </FpButton>
        <Dialog open={dialogVisible} onClose={closeModal} maxWidth="sm">
          <DialogTitle>3/4'' and 3/8'' flowmeters</DialogTitle>
          <DialogContent>
            <DialogSubtitle>Select the flowmeter you want to use for this installation.</DialogSubtitle>

            <Box
              sx={{
                gap: '1rem',
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
              }}
            >
              {dialogVisible &&
                flowmeters.map((flowmeter) => {
                  const isSelected = (inputFlowmeterId && flowmeter.id === inputFlowmeterId) || false;
                  const index = flowmeters.findIndex((device) => flowmeter.id === device.id)
                  return (
                    <FpImageButton
                      key={flowmeter.name}
                      altText={flowmeter.name}
                      imageSrc={flowmeterImageSrcs.at(index) ?? ''}
                      isSelected={isSelected}
                      onClick={() => {
                        closeModal();
                        setInputFlowmeterId(flowmeter.id);
                        setFlowmeterName(flowmeter.name);
                      }}
                    >
                      <FpText
                        variant={'body1'}
                        sx={{
                          color: isSelected ? Color.FlowplanBlue : '',
                          fontWeight: isSelected ? '600' : '',
                        }}
                      >
                        {flowmeter.name}
                      </FpText>
                    </FpImageButton>
                  );
                })}
            </Box>
          </DialogContent>
          <DialogActions>
            <FpButton color="error" onClick={closeModal}>
              Close
            </FpButton>
          </DialogActions>
        </Dialog>
      </>
    </InputComponentCanDisable>
  );
};

export default FlowplanFlowmeterSelection;
