import _ from 'lodash';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import HeaderBar from '../Layout/HeaderBar';
import HeaderSectionTitle from '../Layout/HeaderSectionTitle';
import './ListWithHeaderAndTitles.scss';

interface IListWithHeaderAndTitles {
  data: JSX.Element | JSX.Element[];
  children?: ReactNode;
  listTitle: string;
  isTitleSticky: boolean;
  extraHeaderContent?: JSX.Element;
  hasTopPadding?: boolean;
  isLastItem?: boolean;

  subTitleOne: string;
  titleExtraInfoOne: string;
  titleStyleOne?: string;

  subTitleTwo: string;
  titleExtraInfoTwo: string;
  titleStyleTwo?: string;

  subTitleThree?: string;
  titleExtraInfoThree?: string;
  titleStyleThree?: string;

  subTitleFour?: string;
  titleExtraInfoFour?: string;
  titleStyleFour?: string;

  subTitleFive?: string;
  titleExtraInfoFive?: string;
  titleStyleFive?: string;
}

const ListWithHeaderAndTitles = ({
  children,
  data,
  listTitle,
  isLastItem,
  extraHeaderContent,
  hasTopPadding,
  isTitleSticky,
  subTitleOne,
  titleExtraInfoOne,
  titleStyleOne,
  subTitleTwo,
  titleExtraInfoTwo,
  titleStyleTwo,
  subTitleThree,
  titleExtraInfoThree,
  titleStyleThree,
  subTitleFour,
  titleExtraInfoFour,
  titleStyleFour,
  subTitleFive,
  titleExtraInfoFive,
  titleStyleFive,
}: IListWithHeaderAndTitles): JSX.Element => {
  let listContainerStyle =
    hasTopPadding === undefined ? 'dashboard-list-container' : 'dashboard-list-container-no-top-margin';

  const [isSticky, setSticky] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const headerBarContainerStyle = isSticky ? 'sticky-header-bar-container' : '';
  listContainerStyle += isLastItem ? ' last-item' : '';

  const titleOneStyleApplied = titleStyleOne === undefined ? 'left-align flex-2' : titleStyleOne;
  const titleTwoStyleApplied = titleStyleTwo === undefined ? 'center-align flex-1' : titleStyleTwo;
  const titleThreeStyleApplied = titleStyleThree === undefined ? 'center-align flex-1' : titleStyleThree;
  const titleFourStyleApplied = titleStyleFour === undefined ? 'center-align flex-1' : titleStyleFour;
  const titleFiveStyleApplied = titleStyleFive === undefined ? 'center-align flex-1' : titleStyleFive;

  const handleScroll = useCallback(() => {
    if (!isTitleSticky) {
      return;
    }
    if (ref === null || ref.current === null) {
      return;
    }

    const topValue = ref.current.getBoundingClientRect().top;
    const newStickValue = topValue < -20;

    setSticky(newStickValue);
  }, [isTitleSticky]);

  useEffect(() => {
    const listener = _.debounce(handleScroll, 20);
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [handleScroll]);

  const headerSectionThreeComponent = subTitleThree ? (
    <HeaderSectionTitle styling={titleThreeStyleApplied} sectionTitle={subTitleThree} extraInfo={titleExtraInfoThree} />
  ) : null;

  const headerSectionFourComponent = subTitleFour ? (
    <HeaderSectionTitle styling={titleFourStyleApplied} sectionTitle={subTitleFour} extraInfo={titleExtraInfoFour} />
  ) : null;

  const headerSectionFiveComponent = subTitleFive ? (
    <HeaderSectionTitle styling={titleFiveStyleApplied} sectionTitle={subTitleFive} extraInfo={titleExtraInfoFive} />
  ) : null;

  return (
    <div className={listContainerStyle} ref={ref}>
      <div className={headerBarContainerStyle}>
        <HeaderBar>
          <HeaderSectionTitle styling={titleOneStyleApplied} sectionTitle={listTitle} />
        </HeaderBar>
        {extraHeaderContent}
        <HeaderBar type="subtitle" sx={{ minHeight: '40px' }} className="header-grey">
          <HeaderSectionTitle styling={titleOneStyleApplied} sectionTitle={subTitleOne} extraInfo={titleExtraInfoOne} />
          <HeaderSectionTitle styling={titleTwoStyleApplied} sectionTitle={subTitleTwo} extraInfo={titleExtraInfoTwo} />
          {headerSectionThreeComponent}
          {headerSectionFourComponent}
          {headerSectionFiveComponent}
        </HeaderBar>
      </div>
      {children}
      <ul className="dashboard-ul">{data}</ul>
    </div>
  );
};

export default ListWithHeaderAndTitles;
