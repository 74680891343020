import { PeriodSetting } from '@flowplan/flowplan-shared';
import { dateFormatYMD } from '@hsjakobsen/utilities/lib/date';
import Slider from '@mui/material/Slider';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { defaultGraphSettings } from '../../../../../Models/graphSettings';
import { useStoreActions, useStoreState } from '../../../../../Store';
import { getDateObject } from '../../../../../Utility/time';
import FpButton from '../../../../common/components/FpButton/FpButton';
import FpDatePicker from '../../../../common/components/FpDatePicker';
import FpGrid from '../../../../common/components/FpGrid/FpGrid';
import FpGridItem from '../../../../common/components/FpGrid/FpGridItem';
import FpText from '../../../../common/components/FpText/FpText';

interface GraphSectionControlsProps {
  retrieveStockUsage: boolean;
}

const GraphSectionControls = ({ retrieveStockUsage }: GraphSectionControlsProps): JSX.Element => {
  const setGraphSettingsTime = useStoreActions((actions) => actions.graphSettingsModel.setGraphSettingsTime);

  const selectedInstallation = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const graphSettingsTime = useStoreState((state) => state.graphSettingsModel.graphSettingsTime);

  const today = getDateObject(null);

  const [slideCompleted, setSlideCompleted] = useState(true);
  const [sliderValue, setSliderValue] = useState(PeriodSetting.Three);
  const [dateSelection, setDateSelection] = useState<Dayjs>(today);

  const itemLayout = { xs: 12, sm: 12, md: 8, lg: 8, xl: 8 };
  const titleLayout = { xs: 12, sm: 12, md: 4, lg: 4, xl: 4 };

  useEffect(() => {
    // this makes sure settings are reset
    return function cleanup() {
      setGraphSettingsTime(defaultGraphSettings);
    };
  }, [setGraphSettingsTime]);

  useEffect(() => {
    // This updates the graph settings
    if (!slideCompleted) {
      return;
    }
    setGraphSettingsTime({
      dateSelection,
      timePeriodselection: sliderValue,
    });
  }, [dateSelection, setGraphSettingsTime, sliderValue, slideCompleted]);

  const handleDateSelection = (date: Dayjs) => {
    setDateSelection(date);
    setSlideCompleted(true);
  };

  const goBackToToday = () => {
    setDateSelection(today);
  };

  const selectionIsToday = today.format(dateFormatYMD) === dateSelection.format(dateFormatYMD);

  const marks = [
    {
      value: PeriodSetting.Three,
      label: '1 week',
    },
    {
      value: PeriodSetting.Four,
      label: '1 month',
    },
    {
      value: PeriodSetting.Five,
      label: '1 year',
    },
  ];

  marks.unshift({
    value: PeriodSetting.Two,
    label: '24 hours',
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <FpGrid spacing="1rem" maxWidth={{ xs: '90%', sm: '90%', md: '75%', lg: '65%', xl: '40%' }}>
        <FpGridItem {...titleLayout} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <FpText variant="h5" sx={{ textAlign: { xs: 'center' } }}>
            Period
          </FpText>
        </FpGridItem>
        <FpGridItem {...itemLayout} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Slider
            min={PeriodSetting.Two}
            max={PeriodSetting.Five}
            step={null}
            value={sliderValue}
            marks={marks}
            onChange={(event, value) => {
              setSlideCompleted(false);
              if (typeof value === 'number') {
                setSliderValue(value);
              }
            }}
            onChangeCommitted={(event: React.SyntheticEvent | Event, value: number | Array<number>) => {
              if (graphSettingsTime.timePeriodselection !== value) {
                setSlideCompleted(true);
              }
            }}
          />
        </FpGridItem>
        <FpGridItem {...titleLayout} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <FpText variant="h5" sx={{ textAlign: { xs: 'center' } }}>
            Date to view from
          </FpText>
        </FpGridItem>
        <FpGridItem {...itemLayout} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} gap={1}>
          <FpDatePicker
            disableFuture
            size="small"
            label=""
            fullWidth={false}
            minDate={getDateObject(selectedInstallation.infoBasic.initialInstallDate)}
            onChange={(value) => handleDateSelection(value || getDateObject(null))}
            value={dateSelection}
          />

          <FpButton disabled={selectionIsToday} color="secondary" onClick={() => goBackToToday()}>
            Today
          </FpButton>
        </FpGridItem>
      </FpGrid>
    </div>
  );
};

export default GraphSectionControls;
