import { Header, Icon, Segment } from 'semantic-ui-react';
import DetailsItem from '../Common/DetailsItem';
import DetailsItemDataSets from '../Common/DetailsItemAligned';
import '../Common/DetailsItemAligned.scss';
import EstimatePopup from '../Estimate/EstimatePopup';
import estimateStyles from '../Estimate/estimate.module.scss';
import styles from './InstallationDetailsFeaturesComponent.module.scss';

export interface IFeaturee {
  children?: React.ReactNode;
  daysRemaining: number;
  estimatedUsage: number;
  usage: number;
  featureEnabled: boolean;
  featureName: string;
  lastDate: string;
  nextDate: string;
  percentageLeft: number;
  remaining: string;
  showEstimate: boolean;
  taskIcon: React.ReactNode;
  total: string;
  unit: string;
  unitHeadersOnly: boolean;
}

const InstallationDetailsFeaturesComponent = (props: IFeaturee): JSX.Element => {
  const {
    children,
    daysRemaining,
    featureEnabled,
    estimatedUsage,
    usage,
    featureName,
    lastDate,
    nextDate,
    percentageLeft,
    showEstimate,
    taskIcon,
    total,
    unit,
    unitHeadersOnly,
    remaining,
  } = props;

  const style = styles.featureComponent + ' ' + (showEstimate ? estimateStyles.estimateBackgroundAndBorder : '');
  const infoStyle = 'value ' + (showEstimate ? estimateStyles.estimateColor : '');

  if (!featureEnabled) {
    return (
      <Segment className={styles.featureComponent}>
        <DetailsItem title={featureName} value="N/A">
          <Icon name="broken chain" size="big" />
        </DetailsItem>
      </Segment>
    );
  }

  return (
    <Segment className={style}>
      <Header size="medium">
        {featureName}
        <EstimatePopup showEstimate={showEstimate} estimatedUsage={estimatedUsage} usage={usage} unit={unit} />
      </Header>
      {children}

      <DetailsItemDataSets icon={<Icon name="calendar alternate outline" size="big" />} customStyle="less-margin">
        <div className="dataset three">
          <p className="header">Last</p>
          <p className="value">{lastDate}</p>
        </div>
        <div className="dataset three">
          <p className="header">Next</p>
          <p className={infoStyle}>{nextDate}</p>
        </div>
        <div className="dataset three">
          <p className="header">Days left</p>
          <p className={infoStyle}>{daysRemaining}</p>
        </div>
      </DetailsItemDataSets>
      <DetailsItemDataSets icon={taskIcon} customStyle="less-margin">
        <div className="dataset three">
          <p className="header">Total</p>
          <p className="value">{total + (!unitHeadersOnly ? unit : '')}</p>
        </div>
        <div className="dataset three">
          <p className="header">{unit} left</p>
          <p className={infoStyle}>{remaining + (!unitHeadersOnly ? unit : '')}</p>
        </div>
        <div className="dataset three">
          <p className="header">% left</p>
          <p className={infoStyle}>{percentageLeft + '%'}</p>
        </div>
      </DetailsItemDataSets>
    </Segment>
  );
};

export default InstallationDetailsFeaturesComponent;
