import { Button, ButtonProps, StyledEngineProvider } from '@mui/material';
import { forwardRef } from 'react';
import styles from './FpButtonOutlined.module.scss';

const FpButtonOutlined = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, className, ...rest } = props;
  //only use className prop to add styling to OutlinedButton by using its scss module file
  return (
    <StyledEngineProvider injectFirst>
      <Button
        variant="contained"
        disableElevation
        sx={{ my: '0.5rem' }}
        {...rest}
        ref={ref}
        className={className ? styles[className] : ''}
      >
        {children}
      </Button>
    </StyledEngineProvider>
  );
});

FpButtonOutlined.displayName = 'Button';

export default FpButtonOutlined;
