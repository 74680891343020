import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FeedIcon from '@mui/icons-material/Feed';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import WarningIcon from '@mui/icons-material/Warning';
import { Badge, Divider, IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Fragment, memo, useState } from 'react';
import { useNewNotifications } from '../../hooks/NewNotifications';
import { useStoreActions, useStoreState } from '../../Store';
import FpDivider from '../common/components/FpDivider/FpDivider';
import FpList from '../common/components/FpList/FpList';
import FpModalConfirmSimple from '../common/components/FpModalConfirmSimple/FpModalConfirmSimple';
import FpText from '../common/components/FpText/FpText';
import { isUserGuest } from '../users/types/users-configs';
import ReleaseInformationTags from './2024/08.august/ReleaseInformationTags';
import ReleaseInfoChangeFlowmeter from './2024/09.september/ReleaseInformationChangeFlowmeter';
import ReleaseInfoConfigAndBatteryType from './2024/10.october/ReleaseInformationConfigAndBatteryType';
import ReleaseInfoOrders from './2024/11.november/ReleaseInformationOrders';
import ReleaseInfoPerformanceReports from './2024/11.november/ReleaseInformationPerformanceReports';
import ReleaseInfoNotInSystemNavigationTaps from './2025/02.february/ReleaseInformationNotInSystemNavigationTaps';
import ReleaseInformationCleaningAndImprovements from './2025/03. march/ReleaseInformationCleaningAndImprovements';
import { NewsTypes } from './services/news-and-updates-model';

enum NewsItems {
  Default = 0,
  TagsRelease = 1,
  ChangeFlowmeter = 2,
  ConfigurationAndBatteryType = 3,
  PerformanceReports = 4,
  Orders = 5,
  NotInSystemNavigationTaps = 6,
  CleaningAndImprovements = 7,
}

const NewsAndNotifications = memo((): JSX.Element => {
  const showMenu = useStoreState((state) => state.authModel.showMenu);
  const currentUser = useStoreState((state) => state.authModel.currentUser);
  const showNews = useStoreState((state) => state.newsAndUpdatesModel.showNews);

  const markNewsAndUpdateAsRead = useStoreActions((actions) => actions.newsAndUpdatesModel.markNewsAndUpdateAsRead);

  const newsItems = useNewNotifications();
  const [showNewsAndNotifications, setShowNewsAndNotifications] = useState(showNews);
  const [showNewsItem, setShowNewsItem] = useState<NewsItems>(NewsItems.Default);

  if (!showMenu || isUserGuest(currentUser.role)) {
    return <Fragment />;
  }

  return (
    <>
      <IconButton
        aria-label="notifications"
        // sx={{ marginRight: '1rem' }}
        onClick={() => {
          setShowNewsAndNotifications(true);
        }}
      >
        <Badge badgeContent={newsItems.filter((item) => item.isUnread).length} color="primary">
          <FeedIcon />
        </Badge>
      </IconButton>
      <FpDivider orientation="vertical" sx={{ borderColor: 'rgba(56, 167, 223, 0.5)', borderWidth: '1.5px', mx: 1 }} />

      <FpModalConfirmSimple
        closeModal={() => {
          setShowNewsAndNotifications(false);
        }}
        largerContentMargins={false}
        fullScreen={false}
        maxWidth="sm"
        label="Close"
        modalTitle="News from Flowplan"
        showModal={showNewsAndNotifications}
      >
        <FpText variant="body1">Below you'll find our latest updates and news.</FpText>
        <FpList>
          <Divider />
          {newsItems.map((newsOrUpdate, index) => {
            const { description, id, isUnread, name, newsType } = newsOrUpdate;
            return (
              <Fragment key={index}>
                <ListItemButton
                  onClick={() => {
                    setShowNewsItem(id);
                    if (isUnread) {
                      markNewsAndUpdateAsRead(id);
                    }
                  }}
                >
                  <ListItemIcon>
                    {newsType === NewsTypes.INCIDENT && <WarningIcon color={isUnread ? 'warning' : 'primary'} />}
                    {newsType === NewsTypes.RELEASE && isUnread ? (
                      <CheckBoxOutlineBlankIcon
                        sx={{
                          color: 'grey',
                        }}
                      />
                    ) : (
                      <CheckBoxIcon color="success" />
                    )}
                    {newsType === NewsTypes.INFO && <TipsAndUpdatesIcon color={isUnread ? 'success' : 'primary'} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    secondary={description}
                    primaryTypographyProps={{ fontWeight: 'bold' }}
                  />
                </ListItemButton>
                <Divider />
              </Fragment>
            );
          })}
        </FpList>
      </FpModalConfirmSimple>
      <ReleaseInformationTags
        closeModal={() => {
          setShowNewsItem(NewsItems.Default);
        }}
        showModal={showNewsItem === NewsItems.TagsRelease}
      />
      <ReleaseInfoChangeFlowmeter
        closeModal={() => {
          setShowNewsItem(NewsItems.Default);
        }}
        showModal={showNewsItem === NewsItems.ChangeFlowmeter}
      />
      <ReleaseInfoConfigAndBatteryType
        closeModal={() => {
          setShowNewsItem(NewsItems.Default);
        }}
        showModal={showNewsItem === NewsItems.ConfigurationAndBatteryType}
      />
      <ReleaseInfoPerformanceReports
        closeModal={(closeNews) => {
          if (closeNews) {
            setShowNewsAndNotifications(false);
          }
          setShowNewsItem(NewsItems.Default);
        }}
        showModal={showNewsItem === NewsItems.PerformanceReports}
      />
      <ReleaseInfoOrders
        closeModal={(closeNews) => {
          if (closeNews) {
            setShowNewsAndNotifications(false);
          }
          setShowNewsItem(NewsItems.Default);
        }}
        showModal={showNewsItem === NewsItems.Orders}
      />
      <ReleaseInfoNotInSystemNavigationTaps
        closeModal={(closeNews) => {
          if (closeNews) {
            setShowNewsAndNotifications(false);
          }
          setShowNewsItem(NewsItems.Default);
        }}
        showModal={showNewsItem === NewsItems.NotInSystemNavigationTaps}
      />
      <ReleaseInformationCleaningAndImprovements
        closeModal={(closeNews) => {
          if (closeNews) {
            setShowNewsAndNotifications(false);
          }
          setShowNewsItem(NewsItems.Default);
        }}
        showModal={showNewsItem === NewsItems.CleaningAndImprovements}
      />
    </>
  );
});

export default NewsAndNotifications;
