export const lockscreen = (body: HTMLElement | null): void => {
  if (body !== null) {
    body.className += ' lock-screen';
    body.addEventListener('touchmove', handleTouchMove, false);
  }
};

export const unlockScreen = (body: HTMLElement | null): void => {
  if (body !== null) {
    body.classList.remove('lock-screen');
    body.removeEventListener('touchmove', handleTouchMove);
  }
};

const handleTouchMove = (e: TouchEvent) => {
  e.preventDefault();
};

export const getBody = (): HTMLElement | null => {
  return document.getElementById('body');
};
