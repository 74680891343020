import React, { Fragment } from 'react';
import { IBaseInputProps } from '../../common/interfacesFrontend';
import DisabledInput from './DisabledInput';

interface IInputComponentCanDisable extends IBaseInputProps {
  children: React.ReactNode;
  isDisabled: boolean;
  isRequired?: boolean;
}

const InputComponentCanDisable = ({
  children,
  isDisabled,
  isFirst,
  inputText,
  inputValue,
  isFirstInRow,
}: IInputComponentCanDisable): JSX.Element => {
  if (isDisabled) {
    return (
      <DisabledInput isFirstInRow={isFirstInRow} inputValue={inputValue} isFirst={isFirst} inputText={inputText} />
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default InputComponentCanDisable;
