import './HeaderSectionTitle.scss';

interface IHeaderSectionTitle {
  extraInfo?: string;
  sectionTitle: string;
  styling?: string;
}

const HeaderSectionTitle = ({ extraInfo, sectionTitle, styling = '' }: IHeaderSectionTitle): JSX.Element => {
  const sectionStyle = 'dashboard-section-title ' + styling;

  let headerTitle;
  if (extraInfo === undefined) {
    headerTitle = <span className={sectionStyle}>{sectionTitle}</span>;
  } else {
    headerTitle = (
      <div className={sectionStyle}>
        <p>
          <strong>{sectionTitle} </strong>
          <span>{extraInfo}</span>
        </p>
      </div>
    );
  }

  return headerTitle;
};

export default HeaderSectionTitle;
