import { Color, notAvailable } from '@flowplan/flowplan-shared';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import PinDropIcon from '@mui/icons-material/PinDrop';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import _ from 'lodash';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import FpText from '../../../../common/components/FpText/FpText';
import { GoogleLocationStatus, GoogleOpenStatus, GooglePlacesInformation } from '../../../services/company-model';
import LowUsageSpacer from '../Common/LowUsageSpacer';
import LowUsageWizardContent from '../Common/LowUsageWizardContent';

type Props = {
  googlePlacesInformation: GooglePlacesInformation;
  backofficeLocationName: string;
  loadingGooglePlacesInformation: boolean;
};

const LowUsagePlacesInformation = ({
  backofficeLocationName,
  googlePlacesInformation,
  loadingGooglePlacesInformation,
}: Props): JSX.Element => {
  if (!googlePlacesInformation) {
    return (
      <LowUsageWizardContent
        title="Location info missing"
        icon={<LocationOffIcon sx={{ height: 60, width: 60, color: Color.Week }} />}
      >
        <FpText variant="body1">Try again later</FpText>
      </LowUsageWizardContent>
    );
  }

  const { locationAddress, locationFound, locationName, locationStatus, openingHours, openingHoursFound } =
    googlePlacesInformation;

  const color = locationStatus && locationStatus === GoogleLocationStatus.Operational ? 'green' : 'red';
  const locationStatusText = locationStatus ? _.capitalize(locationStatus.replace(/_/g, ' ')) : notAvailable;

  const trimmedLocationName = locationName.length > 40 ? locationName.slice(0, 40) + '...' : locationName;
  const title = locationFound ? trimmedLocationName : 'Location details';

  return (
    <Segment basic>
      <LowUsageWizardContent
        title={title}
        icon={
          locationFound ? (
            <PinDropIcon sx={{ height: 60, width: 60, color: Color.Month }} />
          ) : (
            <WrongLocationIcon sx={{ height: 60, width: 60, color: Color.Week }} />
          )
        }
      >
        {!locationFound && <FpText variant="body1">Location not found</FpText>}
        {locationFound && (
          <>
            <FpText variant="body1" className='do-not-translate'>{locationAddress}</FpText>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <FpText variant="body1">Current status:</FpText>
              <FpText variant="body1" sx={{ color, fontWeight: 'bold' }}>
                {locationStatusText}
              </FpText>
            </div>
            {openingHoursFound ? (
              <>
                {openingHours.map((openingHour) => {
                  return (
                    <div key={openingHour.dayName} style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                      <FpText variant="body1" sx={{ width: '50%' }}>
                        {openingHour.dayName}
                      </FpText>
                      <FpText variant="body1" sx={{ fontWeight: 'bold', width: '50%' }}>
                        {openingHour.status === GoogleOpenStatus.Open
                          ? openingHour.openingHourInterval
                          : _.capitalize(GoogleOpenStatus.Closed)}
                      </FpText>
                    </div>
                  );
                })}
              </>
            ) : (
              <FpText variant="body1">No opening hours were found.</FpText>
            )}
            <LowUsageSpacer height={20} />
            <FpText variant="body1" sx={{ fontStyle: 'italic' }}>
              <FpText variant='body1' componentType='span'>Data from</FpText>
              <FpText variant='body1' componentType='span' className='do-not-translate'> Google<sup>®</sup></FpText>
            </FpText>
            <div
              onClick={() => {
                window.open(
                  'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(backofficeLocationName),
                  '_blank',
                );
              }}
            >
              <FpText
                variant="body1"
                sx={{ fontStyle: 'italic', textDecoration: 'underline', cursor: 'pointer', color: Color.FlowplanBlue }}
              >
                <FpText variant='body1' componentType='span'>Open in</FpText>
                <FpText variant='body1' componentType='span' className='do-not-translate'> Google<sup>®</sup> Maps</FpText>
              </FpText>
            </div>
          </>
        )}
        <Dimmer active={loadingGooglePlacesInformation} inline="centered">
          <Loader size="large">Searching for location details</Loader>
        </Dimmer>
      </LowUsageWizardContent>
    </Segment>
  );

  // return (
  //   <Grid container spacing={{ sm: 1 }} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }}>
  //     <Grid item {...placesGrid}>
  //       <FpText variant="h5">Location details</FpText>{' '}
  //     </Grid>
  //     <Grid item {...detailsGridTitle}>
  //       Location name:
  //     </Grid>
  //     <Grid item {...detailsGridValue}>
  //       <strong>{locationName}</strong>
  //     </Grid>
  //     <Grid item {...detailsGridTitle}>
  //       Address:
  //     </Grid>
  //     <Grid item {...detailsGridValue}>
  //       <strong>{locationAddress}</strong>
  //     </Grid>
  //     <Grid item {...detailsGridTitle}>
  //       Location status:
  //     </Grid>
  //     <Grid item {...detailsGridValue}>
  //       <FpText variant="body1" sx={{ color, fontWeight: 'bold' }}>
  //         {locationStatusText}
  //       </FpText>
  //     </Grid>
  //     <Grid item {...placesGrid}>
  //       <FpText variant="h6">Opening hours information</FpText>
  //     </Grid>
  //     {openingHoursFound ? (
  //       <>
  //         <Grid item {...openingHoursCurrentStatusTitle}>
  //           Current status:
  //         </Grid>
  //         <Grid item {...openingHoursCurrentStatusValue}>
  //           <FpText variant="body1" sx={{ color: openNow ? 'green' : 'red', fontWeight: 'bold' }}>
  //             {openNow ? 'Open' : 'Closed'}
  //           </FpText>
  //         </Grid>

  //         <Grid item {...placesGrid}>
  //           <Grid container spacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
  //             {openingHours.map((openingHour) => {
  //               return (
  //                 <Grid container key={openingHour.dayName} style={{ width: '50%' }}>
  //                   <Grid item {...placesOpeningHoursGridTitle}>
  //                     {openingHour.dayName}
  //                   </Grid>
  //                   <Grid item {...placesOpeningHoursGridValue}>
  //                     <strong>
  //                       {openingHour.status === GoogleOpenStatus.Open
  //                         ? openingHour.openingHourInterval
  //                         : _.capitalize(GoogleOpenStatus.Closed)}
  //                     </strong>
  //                   </Grid>
  //                 </Grid>
  //               );
  //             })}
  //           </Grid>
  //         </Grid>
  //       </>
  //     ) : (
  //       <Grid item {...placesGrid}>
  //         <FpText variant="body1">No opening hours were found.</FpText>
  //       </Grid>
  //     )}

  //     {/* {}
  //     <Grid item {...detailsGridTitle}>
  //       Address:
  //     </Grid>
  //     <Grid item {...detailsGridValue}>
  //       <strong>{locationInfo}</strong>
  //     </Grid> */}
  //   </Grid>
  // );
};

export default LowUsagePlacesInformation;
