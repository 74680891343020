import { DeviceStatus, getSerialNumberToShow, notAvailable } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IBeamSerialNotInSystem } from '@flowplan/flowplan-shared/lib/beam.serial/beam.serial';
import {
  CountryCodes,
  Currencies,
  FlowplanProductTypes,
  HardnessUnits,
  IFlowplanClient,
  SubscriptionOptions,
} from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { ConsumptionState } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { CompanyTag } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { BillingTypes } from '@flowplan/flowplan-shared/lib/interfaces/networkEUIToFlowplanSerialDb';
import { sortByKey } from '@hsjakobsen/utilities';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { Action, Computed, Thunk, ThunkOn, action, computed, thunk, thunkOn } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { noSelectionMade } from '../../../Models/admin/admin.model';
import { IDropdownData } from '../../../common/interfacesFrontend';
import { handleDeleteRequest, handlePostRequest, handlePutRequest } from '../../server-requests/server-requests';
import { IResponse } from '../../server-requests/server-requests-model';
import { isUserAdmin } from '../../users/types/users-configs';
import { LowUsageInstallationDetails } from './company-model-types';

interface IDeviceSimple {
  flowplanClientId: number;
  flowplanDeviceId: string;
  id: number;
  name: string;
}

type IntegrationInfo = {
  externalGenerateTasks: number;
  externalPushNotifications: number;
  integration: {
    name: string;
  };
};

export enum ModalFlowStep {
  TermsAndConditions = 1,
  Completed = 4,
}

export enum ModalFlowActions {
  Next = 'Next',
  GoTo = 'GoTo',
}

type ModalFlow = {
  step: ModalFlowStep;
  action: ModalFlowActions;
};

type LowUsageInstallationParams = {
  installationId: number;
};

type LowUsageInstallationEstimateRequest = {
  installationId: number;
  newDailyEstimate: number;
  newDailyConsumptionDays: number;
  estimatedMaintenanceUsage: number;
  estimatedWaterFilterUsage: number;
  newConsumptionState: ConsumptionState;
  lastFlowEventDate: string; // YYYY-MM-DD
};

type LowUsageInstallationIgnoreRequest = {
  installationId: number;
  newConsumptionState: ConsumptionState;
};

type GooglePlacesRequest = {
  installationName: string;
  locationName: string;
  address: string;
  latitude: number;
  longitude: number;
};

export interface IFrontendBeamSerialNotInSystem extends IBeamSerialNotInSystem {
  id: string;
  usage: number;
  notInSystemLocationEstimate?: { latitude: number; longitude: number };
}

export type LowUsageInstallation = {
  id: number;
  consumptionState: string;
  consumptionRatio: number;
  flowplanDeviceId: string;
  flowplanClientId: number;
  hasLowUsage: number;
  hoursNotSeen: number;
  name: string;
};

export type GooglePlacesInformation = {
  locationFound: boolean;
  locationAddress: string;
  locationName: string;
  locationStatus: GoogleLocationStatus;

  openingHoursFound: boolean;
  openNow: boolean;
  openingHours: GoogleOpeningHours[];
};

export type GoogleOpeningHours = {
  id: number;
  dayName: string;
  status: GoogleOpenStatus;
  openingHourInterval: string; // e.g. '09:00-12:00'
};

export enum GoogleOpenStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

export enum GoogleLocationStatus {
  Operational = 'OPERATIONAL',
  ClosedTemporarily = 'CLOSED_TEMPORARILY',
  ClosedPermanently = 'CLOSED_PERMANENTLY',
}

export const defaultGooglePlacesInformation: GooglePlacesInformation = {
  locationFound: false,
  locationAddress: '',
  locationName: '',
  locationStatus: GoogleLocationStatus.ClosedPermanently,
  openingHoursFound: false,
  openNow: false,
  openingHours: [],
};

export const defaultLowUsageInstallationDetails: LowUsageInstallationDetails = {
  usageInformation: {
    averageDailyConsumptionHistorically: 0,
    dateEnd: notAvailable,
    dateStart: notAvailable,
    days: 0,
    lastFlowEventDate: notAvailable,
  },
  beamInformation: {
    firmware: notAvailable,
    serial: '0x 00 00 xx xx',
    status: DeviceStatus.Pending,
    averageSignalStrength: 0,
    hoursNotSeen: 0,
    lastSeen: notAvailable,
    hardwareGeneration: '1.0',
  },
  id: -1,
  installationDescription: notAvailable,
  installationName: notAvailable,
  installationType: noSelectionMade,
  location: {
    city: notAvailable,
    latitude: 0,
    longitude: 0,
    name: notAvailable,
    street: notAvailable,
    zip: notAvailable,
  },
  maintenance: {
    featureEnabled: false,
    maintenanceAtLiters: 0,
    maintenanceDate: notAvailable,
    maintenanceDays: 1000,
    maintenanceUsed: 0,
    cycleBasedMaintenance: false,
    cyclesAverageHistorically: 0,
    cyclesCurrent: 0,
    cyclesInterval: 0,
  },
  waterFilter: {
    capacity: 0,
    capacityUsed: 0,
    featureEnabled: false,
    filterName: notAvailable,
    filterChangeDate: notAvailable,
    filterChangeDays: 1000,
  },
};

type AddCompanyTagRequest = {
  tagName: string;
  color: string;
};

export type UpdateTagRequest = {
  tagId: number;
  color: string;
  name: string;
};


export interface ICompanyModel {
  getCompanyInfo: Thunk<ICompanyModel, void, void, IStoreModel>;
  setCompanyInfo: Action<ICompanyModel, IFlowplanClient>;
  setCompanyIntegrations: Action<ICompanyModel, IntegrationInfo[]>;

  companyIntegrations: IntegrationInfo[];
  companyAllowsExternalTasks: boolean;
  companyId: number;
  companyCountryCode: CountryCodes;
  companyName: string;
  matchingEnabled: boolean;
  subscription: SubscriptionOptions;
  isPremium: boolean;
  logoUrl?: string;
  billingType?: BillingTypes;
  currency: Currencies | undefined;
  termsAccepted?: number;
  companyHardnessUnit: HardnessUnits;

  getCompanyAccessStatus: Thunk<ICompanyModel, void, void, IStoreModel>;
  setCompanyAccessStatus: Action<ICompanyModel, string | null>;
  companyAccessStatus: string | null;

  // Company installations
  companyInstallations: IDropdownData[];
  getCompanyInstallations: Thunk<ICompanyModel, number | undefined, void, IStoreModel>;
  setCompanyInstallations: Action<ICompanyModel, IDropdownData[]>;

  // External Service integration
  sendTasksToExternalService: Thunk<ICompanyModel, GridRowSelectionModel>;
  sendingTasksToExternalService: boolean;
  setSendingTasksToExternalService: Action<ICompanyModel, boolean>;

  // Terms and conditions
  acceptTermsAndConditions: Thunk<ICompanyModel, void, void, IStoreModel>;

  modalFlowStep: ModalFlowStep;
  setModalFlowStep: Action<ICompanyModel, ModalFlowStep>;
  handleModalFlow: Thunk<ICompanyModel, ModalFlow, void, IStoreModel>;

  // Low usage installations
  lowUsageInstallations: LowUsageInstallation[];
  loadingLowUsageInstallations: boolean;
  setLoadingLowUsageInstallations: Action<ICompanyModel, boolean>;
  getLowUsageInstallations: Thunk<ICompanyModel, void, void, IStoreModel>;
  setLowUsageInstallations: Action<ICompanyModel, LowUsageInstallation[]>;
  getLowUsageInstallationDetails: Thunk<ICompanyModel, number, void, IStoreModel>; // installationId

  performingLowUsageAction: boolean;
  setPerformingLowUsageAction: Action<ICompanyModel, boolean>;
  lowUsageActionIgnore: Thunk<ICompanyModel, LowUsageInstallationIgnoreRequest>;
  lowUsageActionEstimate: Thunk<ICompanyModel, LowUsageInstallationEstimateRequest>;

  loadingGooglePlacesInformation: boolean;
  setLoadingGooglePlacesInformation: Action<ICompanyModel, boolean>;
  getGooglePlacesInformation: Thunk<ICompanyModel, GooglePlacesRequest, void, IStoreModel>;

  // Not in system Beams
  beamSerialNotInSystem: IFrontendBeamSerialNotInSystem[];
  numNotInSystemWithPosition: Computed<ICompanyModel, number>;
  loadingBeamsSerialNotInSystem: boolean;
  setLoadingBeamsSerialNotInSystem: Action<ICompanyModel, boolean>;
  getBeamSerialNotInSystem: Thunk<ICompanyModel, void, void, IStoreModel>;
  setbeamSerialNotInSystem: Action<ICompanyModel, IFrontendBeamSerialNotInSystem[]>;

  // Tags
  getTagsForCompany: Thunk<ICompanyModel, void, void, IStoreModel>;
  companyTags: CompanyTag[];
  setCompanyTags: Action<ICompanyModel, CompanyTag[]>;
  loadingCompanyTags: boolean;
  setLoadingCompanyTags: Action<ICompanyModel, boolean>;
  createTagForCompany: Thunk<ICompanyModel, AddCompanyTagRequest, void, IStoreModel>;
  removeTagForCompany: Thunk<ICompanyModel, number, void, IStoreModel>;
  updateTagForCompany: Thunk<ICompanyModel, UpdateTagRequest, void, IStoreModel>;

  getInstallationCountByTag: Thunk<ICompanyModel, number, void, IStoreModel>;


  // Stock types are in flowplanClient.stock.type.model.ts  

  // Side effects
  onActionsThatUpdateNotInSystemBeams: ThunkOn<ICompanyModel, void, IStoreModel>;
  onActionsThatUpdateCompanyInfo: ThunkOn<ICompanyModel, void, IStoreModel>;
  onActionsThatUpdateCompanyTags: ThunkOn<ICompanyModel, void, IStoreModel>;
}

const companyModel: ICompanyModel = {

  companyIntegrations: [],
  companyAllowsExternalTasks: false,
  companyId: noSelectionMade,
  companyHardnessUnit: HardnessUnits.German,
  companyCountryCode: CountryCodes.DK,
  subscription: SubscriptionOptions.ESSENTIALS,
  isPremium: false,
  companyName: '',
  logoUrl: '',
  billingType: BillingTypes.REGULAR,
  currency: undefined,
  termsAccepted: 0,
  matchingEnabled: false,
  sendingTasksToExternalService: false,
  getCompanyInfo: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse: IResponse = await getStoreActions().serverRequestsModel.get({
      route: Routes.companyName,
      noTokenCheck: true,
    });
    if (!requestResponse.success) {
      return;
    }

    const flowplanClientData: IFlowplanClient = requestResponse.data as IFlowplanClient;
    const integrations: IntegrationInfo[] = requestResponse.extraData as IntegrationInfo[];
    actions.setCompanyIntegrations(integrations);

    actions.setCompanyInfo(flowplanClientData);

    const adminDataModel = getStoreActions().adminDataModel;
    const flowplanClientId = flowplanClientData.id ? flowplanClientData.id : noSelectionMade;
    adminDataModel.setFlowplanClientSelection(flowplanClientId);
    const flowplanClientGroupId = flowplanClientData.flowplanClientsGroupId ?? noSelectionMade;
    adminDataModel.setFlowplanClientGroupId(flowplanClientGroupId);
    const flowplanProductTypeId = flowplanClientData.flowplanProductTypeId ?? FlowplanProductTypes.WaterFilter;
    adminDataModel.setFlowplanProductTypeId(flowplanProductTypeId);

    await actions.handleModalFlow({ action: ModalFlowActions.GoTo, step: ModalFlowStep.TermsAndConditions });
  }),
  setCompanyInfo: action((state, payload) => {
    state.companyId = payload.id ? payload.id : noSelectionMade;
    state.companyCountryCode = payload.countryCode || CountryCodes.DK;
    state.companyName = payload.name || '';
    state.matchingEnabled = payload.enableMatching === 1;
    state.subscription = payload.subscription || SubscriptionOptions.ESSENTIALS;
    state.isPremium = payload.subscription !== SubscriptionOptions.ESSENTIALS;
    state.companyHardnessUnit = payload.hardnessUnit || HardnessUnits.German;
    state.logoUrl = payload.logoUrl;
    state.billingType = payload.billingType;
    state.termsAccepted = payload.termsAccepted;
    state.currency = payload.currency;
  }),

  setCompanyIntegrations: action((state, payload) => {
    state.companyIntegrations = payload;
    state.companyAllowsExternalTasks = payload.find((item) => item.externalGenerateTasks === 1) !== undefined;
  }),

  sendTasksToExternalService: thunk(async (actions, payload) => {
    const route = `/send-tasks-to-external-service/`;
    actions.setSendingTasksToExternalService(true);
    const requestResponse = await handlePostRequest({ installationIds: payload }, route, true);
    actions.setSendingTasksToExternalService(false);
    return requestResponse;
  }),

  setSendingTasksToExternalService: action((state, payload) => {
    state.sendingTasksToExternalService = payload;
  }),

  companyInstallations: [],
  getCompanyAccessStatus: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: '/companyAccessStatus/',
      noTokenCheck: true,
    });

    actions.setCompanyAccessStatus(requestResponse.data);
  }),
  setCompanyAccessStatus: action((state, payload) => {
    state.companyAccessStatus = payload;
  }),

  companyAccessStatus: null,
  getCompanyInstallations: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    const flowplanClientIdSelected = payload ? payload : getStoreState().adminDataModel.flowplanClientSelected;
    if (flowplanClientIdSelected === noSelectionMade) {
      actions.setCompanyInstallations([]);
      return;
    }
    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: Routes.simpleDeviceList + flowplanClientIdSelected,
    });
    if (!requestResponse.success) {
      return;
    }

    const deviceList: IDeviceSimple[] = requestResponse.data as IDeviceSimple[];
    sortByKey(deviceList, 'name', true);
    const startItem: IDeviceSimple = {
      id: 0,
      name: 'Select installation',
      flowplanClientId: -1,
      flowplanDeviceId: '0000000000000000',
    };
    deviceList.unshift(startItem);

    const companyDevices: IDropdownData[] = deviceList.map((device, index) => {
      const { flowplanDeviceId, id, name } = device;
      const text = `( ${getSerialNumberToShow(flowplanDeviceId)} ) ${name}`;
      return { key: index, text, value: id };
    });

    actions.setCompanyInstallations(companyDevices);
  }),
  setCompanyInstallations: action((state, payload) => {
    state.companyInstallations = payload;
  }),

  modalFlowStep: ModalFlowStep.Completed,
  setModalFlowStep: action((state, payload) => {
    state.modalFlowStep = payload;
  }),

  beamSerialNotInSystem: [],
  loadingBeamsSerialNotInSystem: false,
  setLoadingBeamsSerialNotInSystem: action((state, payload) => {
    state.loadingBeamsSerialNotInSystem = payload;
  }),

  numNotInSystemWithPosition: computed(
    (state) => state.beamSerialNotInSystem.filter((item) => item.notInSystemLocationEstimate).length,
  ),

  getBeamSerialNotInSystem: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingBeamsSerialNotInSystem(true);
    const flowplanClientId = getStoreState().adminDataModel.flowplanClientSelected;
    const route = Routes.BeamSerial + 'not-in-system/' + flowplanClientId;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route });
    if (requestResponse.success) {
      const beamSerialNotInSystem: IFrontendBeamSerialNotInSystem[] = requestResponse.data.map(
        (element: IBeamSerialNotInSystem) => {
          const consumptionInLiters = (element.lastPayloadPulsesCount - element.firstPayloadPulsesCount) / 1000;
          return {
            ...element,
            id: element.flowplanDeviceId,
            usage: consumptionInLiters,
          };
        },
      );
      actions.setbeamSerialNotInSystem(beamSerialNotInSystem);
    }
    actions.setLoadingBeamsSerialNotInSystem(false);
  }),

  setbeamSerialNotInSystem: action((state, payload) => {
    state.beamSerialNotInSystem = payload;
  }),

  lowUsageInstallations: [],
  loadingLowUsageInstallations: false,
  setLoadingLowUsageInstallations: action((state, payload) => {
    state.loadingLowUsageInstallations = payload;
  }),

  getLowUsageInstallations: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingLowUsageInstallations(true);
    const flowplanClientId = getStoreState().adminDataModel.flowplanClientSelected;
    const route = '/low-usage-installations/' + flowplanClientId;
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route });
    if (requestResponse.success) {
      actions.setLowUsageInstallations(requestResponse.data);
    }
    actions.setLoadingLowUsageInstallations(false);
  }),

  getLowUsageInstallationDetails: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingLowUsageInstallations(true);
    const route = '/low-usage-installations-details/';
    const params: LowUsageInstallationParams = {
      installationId: payload,
    };
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route, params });
    actions.setLoadingLowUsageInstallations(false);
    return requestResponse;
  }),

  lowUsageActionEstimate: thunk(async (actions, payload) => {
    const route = `/low-usage-installations/estimate`;
    actions.setPerformingLowUsageAction(true);
    const requestResponse = await handlePostRequest(payload, route, true);
    actions.setPerformingLowUsageAction(false);
    return requestResponse;
  }),

  lowUsageActionIgnore: thunk(async (actions, payload) => {
    const route = `/low-usage-installations/ignore`;
    actions.setPerformingLowUsageAction(true);
    const requestResponse = await handlePostRequest(payload, route, true);
    actions.setPerformingLowUsageAction(false);
    return requestResponse;
  }),

  performingLowUsageAction: false,
  setPerformingLowUsageAction: action((state, payload) => {
    state.performingLowUsageAction = payload;
  }),

  loadingGooglePlacesInformation: false,
  setLoadingGooglePlacesInformation: action((state, payload) => {
    state.loadingGooglePlacesInformation = payload;
  }),

  getGooglePlacesInformation: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingGooglePlacesInformation(true);
    const route = '/google/places/';

    const requestResponse = await getStoreActions().serverRequestsModel.get({ route, params: payload });
    actions.setLoadingGooglePlacesInformation(false);
    return requestResponse.data;
  }),

  setLowUsageInstallations: action((state, payload) => {
    state.lowUsageInstallations = payload;
  }),

  handleModalFlow: thunk(async (actions, payload, { getState, getStoreState }) => {
    const role = getStoreState().authModel.currentUser.role;
    const isAdministrator = isUserAdmin(role);
    if (!isAdministrator) {
      actions.setModalFlowStep(ModalFlowStep.Completed);
      return;
    }

    let stepToEnter = ModalFlowStep.TermsAndConditions;
    if (payload.action === ModalFlowActions.GoTo) {
      // step indicated is the one to go for
      stepToEnter = payload.step;
    } else if (payload.action === ModalFlowActions.Next) {
      // step indicated is previous, move to next
      switch (payload.step) {
        case ModalFlowStep.TermsAndConditions:
          stepToEnter = ModalFlowStep.Completed;
          break;
        case ModalFlowStep.Completed:
          stepToEnter = ModalFlowStep.Completed;
          break;
      }
    }

    // terms are required to be accepted for administrators
    const termsAccepted = getState().termsAccepted;
    if (!termsAccepted) {
      actions.setModalFlowStep(ModalFlowStep.TermsAndConditions);
      return;
    } else {
      if (stepToEnter === ModalFlowStep.TermsAndConditions) {
        stepToEnter = ModalFlowStep.Completed;
      }
    }

    actions.setModalFlowStep(ModalFlowStep.Completed);
  }),

  acceptTermsAndConditions: thunk(async (actions, payload) => {
    const route = `${Routes.flowplanClients}accept-terms`;
    const requestResponse = await handlePostRequest(null, route, true);
    return requestResponse;
  }),

  companyTags: [],
  getTagsForCompany: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingCompanyTags(true);
    const requestResponse: IResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.tags });
    actions.setLoadingCompanyTags(false);
    if (requestResponse.success) {
      actions.setCompanyTags(requestResponse.data as CompanyTag[]);
    }

    return requestResponse;
  }),

  loadingCompanyTags: false,
  setLoadingCompanyTags: action((state, payload) => {
    state.loadingCompanyTags = payload;
  }),
  setCompanyTags: action((state, payload) => {
    state.companyTags = payload;
  }),

  createTagForCompany: thunk(async (actions, payload) => {
    const requestResponse = await handlePostRequest({ newTags: [payload] }, Routes.tags, true);
    return requestResponse;
  }),
  removeTagForCompany: thunk(async (actions, payload) => {
    return await handleDeleteRequest({ id: payload }, Routes.tags);
  }),
  updateTagForCompany: thunk(async (actions, payload) => {
    const requestResponse = await handlePutRequest(
      payload,
      Routes.tags,
      true, // Set to true if credentials are required
    );
    return requestResponse;
  }),

  getInstallationCountByTag: thunk(async (actions, tagId, { getStoreActions }) => {
    try {
      const requestResponse: IResponse = await getStoreActions().serverRequestsModel.get({
        route: `/installation-count-by-tag?tagId=${tagId}`,
      });
      return requestResponse;
    } catch (error) {
      console.error(`Error fetching installation count for tag ${tagId}:`, error);
      return {
        success: false,
        data: { tagId, count: 0 },
        message: 'Failed to fetch installation count for tag',
        error,
      };
    }
  }),


  onActionsThatUpdateNotInSystemBeams: thunkOn(
    (actions, storeActions) => [storeActions.deviceActionsModel.addSensor],
    async (actions, target, { getState, getStoreState }) => {
      const access = getStoreState().authModel.access;
      if (!access.inventory.dataNotInSystem) {
        return;
      }

      if (getState().beamSerialNotInSystem.length === 0) {
        return;
      }

      actions.getBeamSerialNotInSystem();
    },
  ),

  onActionsThatUpdateCompanyInfo: thunkOn(
    (actions, storeActions) => [actions.acceptTermsAndConditions],
    async (actions, target) => {
      actions.getCompanyInfo();
    },
  ),

  //to update tags
  onActionsThatUpdateCompanyTags: thunkOn(
    (actions, storeActions) => [actions.createTagForCompany, actions.removeTagForCompany, actions.updateTagForCompany],
    async (actions, target) => {
      actions.getTagsForCompany();
    },
  ),
};

export default companyModel;
