import productsModel from '../modules/Products/services/products-model';
import { IProductsModel } from '../modules/Products/types/products-types';
import authModel from '../modules/authentication/services/auth-model';
import { IAuthModel } from '../modules/authentication/types/auth-types';
import clientsModel from '../modules/clients/services/clients-model';
import { IClientsModel } from '../modules/clients/types/clients-types';
import companyModel, { ICompanyModel } from '../modules/company/services/company-model';
import markerFilterModel, { IMarkerFilterModel } from '../modules/dashboard/dashboard-marker-filter-model';
import dashboardModel, { IDashboardModel } from '../modules/dashboard/dashboard-model';
import suspiciousDataModel from '../modules/data-suspicious/service/data-suspicious-model';
import { ISuspiciousDataModel } from '../modules/data-suspicious/types/data-suspicious-types';
import faultyDevicesModel, { IFaultyDevicesModel } from '../modules/faulty-devices/services/faulty-devices-model';
import firmwareReleasesModel, { FirmwareReleasesModel } from '../modules/firmware-releases/firmware-releases-model';
import flowplanClientGroupsModel from '../modules/flowplan-client-groups/flowplan-client-groups-model';
import { IFlowplanClientGroupsModel } from '../modules/flowplan-client-groups/flowplan-client-groups-types';
import flowplanClientModel, { FlowplanClientModel } from '../modules/flowplan-clients/services/flowplan-clients-model';
import installationDataModel, {
  InstallationDataModel,
} from '../modules/installation-history/installation-history-model';
import deviceActionsModel from '../modules/installations/services/installations-actions';
import installationsModel from '../modules/installations/services/installations-store';
import newInstallationModel from '../modules/installations/services/newInstallation-store';
import IDeviceActionsModel from '../modules/installations/types/installations-action-model';
import IInstallationsModel from '../modules/installations/types/installations-model';
import INewInstallationModel from '../modules/installations/types/newInstallation-model';
import inventoryBottlesModel, {
  IInventoryBottlesModel,
} from '../modules/inventory-bottles/services/inventoryDataBottles';
import inventoryModel from '../modules/inventory/services/inventory-model';
import { IInventoryModel } from '../modules/inventory/types/inventory-types';
import locationHistoryModel, { ILocationHistoryModel } from '../modules/location-history/location-history-model';
import locationsModel, { ILocationsModel } from '../modules/locations/locations-model';
import logsModel, { ILogsModel } from '../modules/logs/services/logs-model';
import newsAndUpdatesModel, { NewsAndUpdatesModel } from '../modules/news-and-updates/services/news-and-updates-model';
import ordersModel from '../modules/orders/services/orders-model';
import { IOrdersModel } from '../modules/orders/types/orders-type';
import productDiscountsModel from '../modules/prodcut-discounts/services/product-discounts-model';
import { IProductDiscountsModel } from '../modules/prodcut-discounts/types/product-discounts-types';
import reportsModel, { ReportsModel } from '../modules/reports/services/reports-model';
import serialsModel from '../modules/serials/serials-model';
import { SerialsModel } from '../modules/serials/serials-types';
import serverRequestsModel, { ServerRequestsModel } from '../modules/server-requests/server-requests-model';
import stockDailyDataModel, { IStockDailyDataModel } from '../modules/stock-daily-data/stock-daily-data-model';
import stockSettingsModel, { IStockSettingsModel } from '../modules/stock-settings/stock-settings-model';
import stockModel, { IStockModel } from '../modules/stock/stock-model';
import flowplanClientStockTypeModel, {
  IFlowplanClientStockTypeModel,
} from '../modules/taps/services/flowplanClient.stock.type.model';
import sublocationModel, { ISublocationModel } from '../modules/taps/services/sublocation.model';
import tapsModel, { ITapsModel } from '../modules/taps/services/tap.model';
import tapStockCounterModel, { ITapStockCounterModel } from '../modules/taps/services/tap.stock.counter.model.ts';
import tapStockModel, { ITapStockModel } from '../modules/taps/services/tap.stock.model';
import usersModel from '../modules/users/services/users-model';
import { IUsersModel } from '../modules/users/types/users-types';
import workOrderModel, { WorkOrderModel } from '../modules/work-orders/services/work-order-model';
import adminDataModel, { IAdminDataModel } from './admin/admin.model';
import blackListDataModel, { IBlacklistDataModel } from './blacklistData';
import deviceConfigModel, { IDeviceConfigModel } from './deviceConfig';
import deviceDataModel, { IDeviceDataModel } from './deviceData';
import externalServices, { IExternalServices } from './externalServices';
import filterDataModel, { IFilterDataModel } from './filterData/filterdata.model';
import graphSettingsModel, { IGraphSettingsAndDataModel } from './graphSettings';
import modalModel, { IModalModel } from './modals';
import staticDataModel, { IStaticDataModel } from './staticData';
import waterSamplesModel, { IWaterSamplesModel } from './waterSamplesData';

export interface IStoreModel {
  adminDataModel: IAdminDataModel;
  authModel: IAuthModel;
  blackListDataModel: IBlacklistDataModel;
  clientsModel: IClientsModel;
  companyModel: ICompanyModel;
  dashboardModel: IDashboardModel;
  deviceActionsModel: IDeviceActionsModel;
  deviceConfigModel: IDeviceConfigModel;
  deviceDataModel: IDeviceDataModel;
  externalServices: IExternalServices;
  faultyDevicesModel: IFaultyDevicesModel;
  filterDataModel: IFilterDataModel;
  firmwareReleasesModel: FirmwareReleasesModel;
  flowplanClientGroupsModel: IFlowplanClientGroupsModel;
  flowplanClientModel: FlowplanClientModel;
  graphSettingsModel: IGraphSettingsAndDataModel;
  installationDataModel: InstallationDataModel;
  installationsModel: IInstallationsModel;
  inventoryBottlesModel: IInventoryBottlesModel;
  inventoryModel: IInventoryModel;
  locationHistoryModel: ILocationHistoryModel;
  tapStockModel: ITapStockModel;
  tapStockCounterModel: ITapStockCounterModel;
  locationsModel: ILocationsModel;
  sublocationModel: ISublocationModel;
  logsModel: ILogsModel;
  markerFilterModel: IMarkerFilterModel;
  modalModel: IModalModel;
  newInstallationModel: INewInstallationModel;
  newsAndUpdatesModel: NewsAndUpdatesModel;
  ordersModel: IOrdersModel;
  productDiscountsModel: IProductDiscountsModel;
  productsModel: IProductsModel;
  reportsModel: ReportsModel;
  serialsModel: SerialsModel;
  serverRequestsModel: ServerRequestsModel;
  staticDataModel: IStaticDataModel;
  stockDailyDataModel: IStockDailyDataModel;
  stockModel: IStockModel;
  stockSettingsModel: IStockSettingsModel;
  suspiciousDataModel: ISuspiciousDataModel;
  usersModel: IUsersModel;
  waterSamplesModel: IWaterSamplesModel;
  workOrderModel: WorkOrderModel;
  tapsModel: ITapsModel;
  flowplanClientStockTypeModel: IFlowplanClientStockTypeModel;
}

const storeModel: IStoreModel = {
  adminDataModel,
  authModel,
  blackListDataModel,
  clientsModel,
  companyModel,
  dashboardModel,
  deviceActionsModel,
  deviceConfigModel,
  deviceDataModel,
  externalServices,
  faultyDevicesModel,
  filterDataModel,
  firmwareReleasesModel,
  flowplanClientGroupsModel,
  flowplanClientModel,
  graphSettingsModel,
  installationDataModel,
  installationsModel,
  inventoryBottlesModel,
  inventoryModel,
  locationHistoryModel,
  locationsModel,
  logsModel,
  markerFilterModel,
  modalModel,
  newInstallationModel,
  newsAndUpdatesModel,
  ordersModel,
  productsModel,
  reportsModel,
  serialsModel,
  serverRequestsModel,
  staticDataModel,
  stockDailyDataModel,
  stockModel,
  stockSettingsModel,
  suspiciousDataModel,
  usersModel,
  waterSamplesModel,
  workOrderModel,
  sublocationModel,
  tapStockModel,
  tapStockCounterModel,
  productDiscountsModel,
  tapsModel,
  flowplanClientStockTypeModel,
};

export default storeModel;
