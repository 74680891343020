import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

interface DialogProps {
  fill?: 'none';
  secondaryFill?: string;
  width?: number;
  height?: number;
  sx?: SxProps;
  stroke?: 'black' | 'white';
  strokeMiterlimit?: number;
  strokeWidth?: number;
  fontSize?: 'small' | 'medium' | 'large'; // Add fontSize prop
}

const InstallationsIcon = (props: DialogProps): JSX.Element => {
  const {
    fill = 'none',
    stroke = 'black',
    strokeMiterlimit = 10,
    strokeWidth = 16,
    fontSize = 'medium',
    sx = {},
    ...rest
  } = props;

  // Calculate size based on fontSize like MUI does
  const size = fontSize === 'small' ? 20 : fontSize === 'large' ? 35 : 24;

  return (
    <svg
      className="icon"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 416.27 416.27"
      style={{
        display: 'inline-block',
        fontSize: 'inherit',
        userSelect: 'none',
        flexShrink: 0,
        ...(sx as CSSProperties),
      }}
      {...rest}
    >
      <path
        style={{
          fill: fill,
          strokeMiterlimit: strokeMiterlimit,
          stroke: stroke,
          strokeWidth: strokeWidth,
        }}
        d="M138.57,252.81a33.17,33.17,0,0,0-14.31,13.75c-5.77,10.18,2.2,22.56,2.2,22.56L162,334.79s12.8,12.71,20.78,13.81,17.19,1.6,28.75-2.8,163-70.26,163-70.26,9.88-5.55,12.88-12.5c2.58-5.94,3.57-13.19-.28-19s-8-10-10.2-11.07v-9.91S377,221,375,216.33c-2.28-5.15-69.11-59.81-69.11-59.81a11,11,0,0,0-9.63-4.13,60,60,0,0,0-12.1,2.47L146.55,204.39s-5,1.65-7.16,6.05-1.92,7.15-1.92,7.15Z"
        transform="translate(-47.37 -52.71)"
      />
      <path
        style={{
          fill: fill,
          strokeMiterlimit: strokeMiterlimit,
          stroke: stroke,
          strokeWidth: strokeWidth,
        }}
        d="M376.83,233s.09,32.52.09,34.81-4.74,4.36-4.74,4.36l-54.25,23.2-12.87,5.51c-39.33,16.82-81.68,34.94-83.55,35.76-3.53,1.53-8.76,1.53-13.73.45a15.31,15.31,0,0,1-8-5.06L138.65,257.3l-.08-4.49"
        transform="translate(-47.37 -52.71)"
      />
      <path
        style={{
          fill: fill,
          strokeMiterlimit: strokeMiterlimit,
          stroke: stroke,
          strokeWidth: strokeWidth,
        }}
        d="M139.39,210.44l60.69,68.49s5.8,7.44,19.11,3.78c12-3.31,127.21-49.83,149.76-59a7.76,7.76,0,0,0,4.95-7.39,3.61,3.61,0,0,0-.64-2.13"
        transform="translate(-47.37 -52.71)"
      />
      <path
        style={{
          fill: fill,
          strokeMiterlimit: strokeMiterlimit,
          stroke: stroke,
          strokeWidth: strokeWidth,
        }}
        d="M165.54,214.44l40.9,46.44s4.62,4.89,7.17,5.07,2.94.37,6.32-.8,121.92-48.76,121.92-48.76,3.32-.94,3.09-3.44-.14-2.94-3.09-5.44-47.19-41.61-47.19-41.61-2.5-2.35-5.29-2.35-7.21,1.76-7.21,1.76l-113.51,42.2s-3,1.2-3.9,2.73S165.54,214.44,165.54,214.44Z"
        transform="translate(-47.37 -52.71)"
      />
      <path
        style={{
          fill: fill,
          strokeMiterlimit: strokeMiterlimit,
          stroke: stroke,
          strokeWidth: strokeWidth,
        }}
        d="M299,295.9,291.75,288s1.14-6,4.24-8.05,5.61-2,6.35-1.62,8.32,7.73,8.32,7.73"
        transform="translate(-47.37 -52.71)"
      />
      <path
        style={{
          fill: fill,
          strokeMiterlimit: strokeMiterlimit,
          stroke: stroke,
          strokeWidth: strokeWidth,
        }}
        d="M299,299.84a2,2,0,0,1-.5-1.68l1.41-6.77a3.11,3.11,0,0,1,1-1.57l4.32-3.18a3.51,3.51,0,0,1,1.8-.6h5.87a2.68,2.68,0,0,1,1.69.73l4.89,5.12c.38.4.24.66-.31.6l-5.9-.74a3.71,3.71,0,0,0-1.88.34l-4.59,2.4a2.53,2.53,0,0,0-1.13,1.44l-1.87,7.53c-.14.53-.56.65-.95.26Z"
        transform="translate(-47.37 -52.71)"
      />
      <path
        style={{
          fill: fill,
          strokeMiterlimit: strokeMiterlimit,
          stroke: stroke,
          strokeWidth: strokeWidth,
        }}
        d="M304.78,301.41l44.81,48.13c3,2.9,2.89,3.2,4.87,4,3.56,1.48,11.9-3.42,10-8.62a10.21,10.21,0,0,0-2.27-3.09l-47.7-50.11"
        transform="translate(-47.37 -52.71)"
      />
    </svg>
  );
};

export default InstallationsIcon;
