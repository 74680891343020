import { useMediaQuery, useTheme } from '@mui/material';
import { Fragment, memo } from 'react';
import { useStoreState } from '../../../../Store';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpModalConfirmSimple from '../../../common/components/FpModalConfirmSimple/FpModalConfirmSimple';
import FpText from '../../../common/components/FpText/FpText';
import { isUserGuest } from '../../../users/types/users-configs';
import { NEWS_IMAGE_BORDER } from '../../news-and-updates-consts';
import changeFlowmeterDialog from './change-flowmeter-dialog.webp';
import fieldOfficeUpdates from './field-office-updates.webp';
import flowmeterSelectionSelected from './flowmeter-selection-selected.webp';
import flowmeterSelection from './flowmeter-selection.webp';
import usageRemembered from './usage-remembered.webp';
import userProfile from './user-settings-profile-editing.webp';

type Props = {
  closeModal(): void;
  showModal: boolean;
};

const ReleaseInfoChangeFlowmeter = memo(({ closeModal, showModal }: Props): JSX.Element => {
  const showMenu = useStoreState((state) => state.authModel.showMenu);
  const currentUser = useStoreState((state) => state.authModel.currentUser);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (!showMenu || isUserGuest(currentUser.role)) {
    return <Fragment />;
  }

  return (
    <FpModalConfirmSimple
      closeModal={closeModal}
      largerContentMargins={false}
      fullScreen={fullScreen}
      maxWidth="md"
      label="Close"
      modalTitle="2024 - September. Change flowmeter action! New profile editing."
      showModal={showModal}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center' }}>
        <FpText variant="body1" sx={{ textAlign: 'left' }}>
          We are very happy to release the change flowmeter functionality. There are several improvements we have made
          in order to bring this feature to you. Read on to find out:
        </FpText>
        <FpDivider height="0.5rem" marginY="0.5rem" />
        <FpDivider hidden height="1rem" />
        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Updated flowmeter selection view!
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We wanted to provide a better experience on all screen sizes, so the new selection view always shows the text
          and it highlights which flowmeter you have selected with a visual view.
        </FpText>
        <FpDivider hidden height="1rem" />
        <img
          srcSet={flowmeterSelection}
          src={flowmeterSelection}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Installations list with tags'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          The new flowmeter selection when adding an installation.
        </FpText>

        <FpDivider hidden height="1rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We have made an update to the flowmeter selection preview. For Web you can now see the image of the selected
          flowmeter.
        </FpText>

        <FpDivider hidden height="1rem" />
        <img
          srcSet={flowmeterSelectionSelected}
          src={flowmeterSelectionSelected}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Selected flowmeter preview'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: '12px' }}>
          We now show a visual representation of the flowmeter, when you have selected it.
        </FpText>

        <FpDivider hidden height="2rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Changing flowmeter on an existing installation.
        </FpText>

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          When you want to change a flowmeter for an existing installation, you can now see which flowmeter is currently
          installed. You can select a new one and at what date you installed the new flowmeter.
        </FpText>
        <FpDivider hidden height="0.25rem" />
        <img
          srcSet={changeFlowmeterDialog}
          src={changeFlowmeterDialog}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '80%',
            border: NEWS_IMAGE_BORDER,
          }}
          alt={'Change flowmeter selection dialog'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ fontStyle: 'italic', fontSize: '12px' }}>
          The new flowmeter selection dialog.
        </FpText>

        <FpDivider hidden height="1rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          We now remember what the old usage was.
        </FpText>

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          When we changed a flowmeter previously, we would lose how much consumption was at the location before. In this
          update, we have updated our logic, so that the history is preserved correctly.
        </FpText>
        <FpDivider hidden height="0.25rem" />
        <img
          srcSet={usageRemembered}
          src={usageRemembered}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '80%',
            border: NEWS_IMAGE_BORDER,
          }}
          alt={'Usage is now stored'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ fontStyle: 'italic', fontSize: '12px' }}>
          On the 10th of September, the flowmeter was changed to a different type for this installation.
        </FpText>
        <FpDivider hidden height="2rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Updated access to user settings and profile.
        </FpText>
        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We have added a new profile button in the top right corner, where you can access your user profile and change
          settings. In this new section we have added a new option to control if you want to use the client name when
          adding locations or the search result from Google. The default value is the search result.
        </FpText>
        <FpDivider hidden height="0.25rem" />
        <img
          srcSet={userProfile}
          src={userProfile}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'New user profile section'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ fontStyle: 'italic', fontSize: '12px' }}>
          You can now access and edit your user profile settings from anywhere with the new top right profile icon.
        </FpText>

        <FpDivider hidden height="2rem" />

        <FpText variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold', mx: 2 }}>
          Field Office has also been updated with the above changes.
        </FpText>

        <FpText variant="body1" sx={{ textAlign: 'left', mx: 2 }}>
          We have also updated our Field Office app, look for app version: 2024.09.1.
        </FpText>
        <FpDivider hidden height="0.25rem" />
        <img
          srcSet={fieldOfficeUpdates}
          src={fieldOfficeUpdates}
          style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '80%', border: NEWS_IMAGE_BORDER }}
          alt={'Field Office updates'}
          loading="lazy"
        />
        <FpText variant="body1" sx={{ fontStyle: 'italic', fontSize: '12px' }}>
          Field Office has had the settings page updated and the flowmeter selection also has a QR code scanner.
        </FpText>
      </div>
    </FpModalConfirmSimple>
  );
});

export default ReleaseInfoChangeFlowmeter;
