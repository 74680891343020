import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { IStoreModel } from '../../../Models';
import { handlePutRequest } from '../../server-requests/server-requests';
import { TapStockStatus } from './tap.stock.model';
import { FlowplanProductTypes } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { DeviceStatus } from '@flowplan/flowplan-shared';

export interface ITapStockCounterStatus {
  id: number;
  sublocation: { name: string; id: number, locationId: number };
  sensor: {
    id: number;
    name: string;
    status: DeviceStatus;
    hoursNotSeen: number;
    flowplanDeviceId: string;
    lastSeen: string
  };
  tapStock: {
    id: number;
    name: string;
    capacity: number;
    currentInventory: number;
    status: TapStockStatus;
  };
  remainder: number;
  stockTypeMultiplier: number;
  updatedAt: string,
  refilledAt: string
}

type RefillTapStockCounter = {
  tapStockCounterId: number;
  locationId: number;
}

export interface ITapStockCounterModel {
  tapStockCountersStatus: ITapStockCounterStatus[];

  setTapStockCountersStatus: Action<ITapStockCounterModel, ITapStockCounterStatus[]>;
  getTapStockCountersByTaplocationId: Thunk<ITapStockCounterModel, number, void, IStoreModel>;

  refillTapStockCounter: Thunk<ITapStockCounterModel, RefillTapStockCounter, void, IStoreModel>;

  onTapStockCounterRefilled: ThunkOn<ITapStockCounterModel, void, IStoreModel>;
}

const tapStockCounterModel: ITapStockCounterModel = {
  tapStockCountersStatus: [],

  setTapStockCountersStatus: action((state, payload) => {
    state.tapStockCountersStatus = payload;
  }),

  getTapStockCountersByTaplocationId: thunk(async (actions, locationId, { getStoreActions }) => {
    const response = await getStoreActions().serverRequestsModel.get({
      route: `/taplocation/${locationId}/stock-counters`,
    });

    if (response.success) {
      actions.setTapStockCountersStatus(response.data);
    }
  }),

  refillTapStockCounter: thunk(async (actions, payload) => {
    const response = await handlePutRequest({}, `/taps/stock-counter/${payload.tapStockCounterId}`, true);
    return response;
  }),

  // Side effects
  onTapStockCounterRefilled: thunkOn(
    (actions) => actions.refillTapStockCounter,
    async (actions, payload, { getStoreActions }) => {
      const getTapStockCoutner = actions.getTapStockCountersByTaplocationId(payload.payload.locationId);
      const getLocations = getStoreActions().locationsModel.getLocations({ flowplanProductTypeId: FlowplanProductTypes.Taps })
      await Promise.all([getTapStockCoutner, getLocations])
    },
  ),
};

export default tapStockCounterModel;
