import { useStoreState } from '../../../../../../Store';
import FpSelectSearch from '../../../../../common/components/FpSelectSearch/FpSelectSearch';

type Props = {
  value: number;
  onSelection(value: number): void;
  translateOptions?: boolean
};

const FlowplanFilterProducerSelection = ({ onSelection, value, translateOptions = true }: Props): JSX.Element => {
  const filterProducers = useStoreState((state) => state.staticDataModel.filterProducers);

  return (
    <FpSelectSearch
      hideSearchBar
      label="Filter producer*"
      options={filterProducers.map((filterProducer) => ({
        name: filterProducer.name,
        value: filterProducer.id,
      }))}
      selectedValue={value}
      setSelectionValue={onSelection}
      translateOptions
    />
  );
};

export default FlowplanFilterProducerSelection;
