import React, { Component, ErrorInfo, ReactNode } from 'react';
import { deleteStoredSettings } from '../../../../Utility/LocalStorageFunctions';
import LowUsageSpacer from '../../../company/containers/LowUsageInstallations/Common/LowUsageSpacer';
import FpButtonOutlined from '../FpButtonOutlined/OutlinedButton';
import FpContainer from '../FpContainer/FpContainer';
import FpText from '../FpText/FpText';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <FpContainer centerText>
          <FpText variant="h4">An error has occured</FpText>
          <FpText variant="body1">This might be due to browser files not being updated correctly.</FpText>
          <LowUsageSpacer height={10} />
          <FpText variant="body1">Use the button below to try and fix it.</FpText>

          <FpButtonOutlined
            onClick={() => {
              deleteStoredSettings();
              if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                  for (const name of names) caches.delete(name);
                });
              }
              // delete browser cache and hard reload
              window.location.reload();
            }}
            size="large"
          >
            Reset and reload Backoffice
          </FpButtonOutlined>
          <LowUsageSpacer />
          <FpText variant="body1">If the issue continues to happen, please contact Flowplan at </FpText>
          <FpText variant="body1">
            <a href="mailto:support@flowplan.dk" target="_blank" rel="noreferrer">
              support@flowplan.dk
            </a>
          </FpText>
        </FpContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
