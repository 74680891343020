import { MapFilterOptions } from '@flowplan/flowplan-shared/lib/installations/dashboard';
import { Action, action } from 'easy-peasy';
import { deleteStoredSettings, storeDashboardLastUsedMapFilter } from '../../Utility/LocalStorageFunctions';
import { MAX_SLIDER_VALUE, MIN_SLIDER_VALUE } from './dashboard-consts';

export enum MapFilterLabels {
  Monitoring = 'Monitoring',
  Online = 'Online',
  NotSeen = 'Not seen >48h',
  Inactive = 'Inactive',
  Pending = 'Pending',
  Good = '>30 days',
  OneMonth = '1 month',
  OneWeek = '1 week',
  Overdue = 'Overdue',
}

export const defaultMapFilters = [
  MapFilterOptions.Monitoring,
  MapFilterOptions.Inactive,
  MapFilterOptions.NotSeen,
  MapFilterOptions.Online,
  MapFilterOptions.Pending,
  MapFilterOptions.Good,
  MapFilterOptions.OneMonth,
  MapFilterOptions.OneWeek,
  MapFilterOptions.Overdue,
];

export enum TagFilterMode {
  Exclude = 'excludeSelected',
  Include = 'onlyViewSelected'
}

export type FilterSelection = {
  statusSelection: string[];
  daysLeftSelection: number[];
  selectedTags: number[];
  tagFilteringMode: TagFilterMode;
}

const defaultDaysLeftSelection = [MIN_SLIDER_VALUE, MAX_SLIDER_VALUE];

export const defaultFilterSelections: FilterSelection = {
  daysLeftSelection: defaultDaysLeftSelection,
  statusSelection: defaultMapFilters,
  selectedTags: [],
  tagFilteringMode: TagFilterMode.Exclude
}

export interface IMarkerFilterModel {
  filterSelections: FilterSelection;
  setStatusSelection: Action<IMarkerFilterModel, string>;
  setDaysLeftSelection: Action<IMarkerFilterModel, number[]>;
  setSelectedTags: Action<IMarkerFilterModel, number[]>;
  setTagFilteringMode: Action<IMarkerFilterModel, TagFilterMode>;
  setAllFilterSelections: Action<IMarkerFilterModel, FilterSelection>;

  resetData: Action<IMarkerFilterModel>;
}

const markerFilterModel: IMarkerFilterModel = {
  filterSelections: defaultFilterSelections,
  setStatusSelection: action((state, payload) => {
    const statusSelection = state.filterSelections.statusSelection;

    if (!statusSelection.includes(payload)) {
      statusSelection.push(payload);
    } else {
      statusSelection.splice(statusSelection.indexOf(payload), 1);
    }

    state.filterSelections.statusSelection = statusSelection;
    storeDashboardLastUsedMapFilter(state.filterSelections);
  }),
  setDaysLeftSelection: action((state, payload) => {
    state.filterSelections.daysLeftSelection = payload;
    storeDashboardLastUsedMapFilter(state.filterSelections);
  }),
  setSelectedTags: action((state, payload) => {
    state.filterSelections.selectedTags = payload;
    storeDashboardLastUsedMapFilter(state.filterSelections);
  }),
  setTagFilteringMode: action((state, payload) => {
    state.filterSelections.tagFilteringMode = payload;
    storeDashboardLastUsedMapFilter(state.filterSelections);
  }),
  setAllFilterSelections: action((state, payload) => {
    state.filterSelections = payload;
  }),
  resetData: action((state) => {
    state.filterSelections = defaultFilterSelections;
    deleteStoredSettings();
  }),
};

export default markerFilterModel;
