import { ReactNode, Suspense } from 'react';
import ModalContainer from './Components/Modals/ModalContainer';
import ErrorBoundary from './modules/common/components/ErrorBoundary/ErrorBoundary';
import FpFallback from './modules/common/components/FpFallback/FpFallback';
import Navigation from './modules/navigation/containers/Navigation/Navigation';

interface IApp {
  children: ReactNode;
}

function App({ children }: IApp): JSX.Element {
  return (
    <div style={{ height: '100vh', lineHeight: '1.4em' }}>
      <Navigation>
        <ErrorBoundary>
          <Suspense fallback={<FpFallback />}>
            <main>{children}</main>
          </Suspense>
        </ErrorBoundary>
      </Navigation>
      <ModalContainer />
    </div>
  );
};

export default App;
