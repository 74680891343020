import './TextAreaInput.scss';

interface ITextAreaInput extends React.HTMLProps<HTMLTextAreaElement> {
  onlyNumbers: boolean;
  isRequired: boolean;
  maxLength: number;
  inputKey?: string;
  height: '1.875rem' | '2rem' | '3rem' | '40rem' | string;
  inputText?: string;
  disableWordCount?: boolean;
}

const TextAreaInput = ({
  isRequired,
  value,
  inputText,
  height,
  inputKey,
  maxLength,
  disableWordCount = false,
  onlyNumbers,
  ...rest
}: ITextAreaInput): JSX.Element => {
  return (
    <div className="input-group input-textarea" style={{ height: height }}>
      <textarea value={value} style={{ height: height }} maxLength={maxLength} required={isRequired} {...rest} />
      <span className="input-highlight" />
      <span className="input-bar" />
      {disableWordCount ? (
        <></>
      ) : (
        <label className="input-label input-label-textarea">
          {inputText} ({((value as string) ?? '').length} / {maxLength}){' '}
        </label>
      )}
    </div>
  );
};

export default TextAreaInput;
