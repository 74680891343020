import { notAvailable } from '@flowplan/flowplan-shared';
import { noSelectionMade } from '../../../Models/admin/admin.model';

export interface IPlacesAutocomplete {
  alternateLayout: boolean;
  inputKey: string;
  inputFieldName: string;
  initialSearchTerm: string;
  initialSearch: boolean;
  shouldRender: boolean;
  locationInputChange?: () => void;
  locationSelectedCallback(locationData: IPlacesResult): void;
  hideHeader?: boolean;
  error?: boolean;
  helpText?: string
  inputFieldSize?: 'small' | 'medium'
}

export interface IPlaceAddress {
  name: string;
  street: string;
  city: string;
  zipCode: number | string;
}

export interface IPlacesResult extends IPlaceAddress {
  lat: number;
  lng: number;
}

export const defaultCoordinates = { lat: noSelectionMade, lng: noSelectionMade };
export const defaultPlaceInfo: IPlaceAddress = { city: notAvailable, street: '', zipCode: noSelectionMade, name: '' };
export const defaultPlaceResult: IPlacesResult = { ...defaultPlaceInfo, ...defaultCoordinates };

export const parsePlaceDetails = (details: google.maps.places.PlaceResult): IPlaceAddress => {
  const detailed: google.maps.places.PlaceResult = details as google.maps.places.PlaceResult;
  let city = '';
  let street = '';
  let streetNo = '';
  let zipCode: string | number = 0;
  detailed.address_components?.forEach((info) => {
    if (info.types.includes('street_number')) {
      streetNo = info.long_name;
    } else if (info.types.includes('route')) {
      street = info.long_name;
    } else if (info.types.includes('locality')) {
      city = info.long_name;
    } else if (info.types.includes('postal_code')) {
      zipCode = info.long_name;
    }
  });

  return {
    city,
    name: detailed.name || '',
    street: street + ' ' + streetNo,
    zipCode,
  };
};

// long_name: "86"
// short_name: "86"
// types: Array [ "street_number" ]

// long_name: "Falkoner Alle"
// short_name: "Falkoner Alle"
// types: Array [ "route" ]

// long_name: "Frederiksberg"
// short_name: "Frederiksberg"
// types: Array [ "locality", "political" ]

// long_name: "2000"
// short_name: "2000"
// types: Array [ "postal_code" ]
// <prototype>: Object { … }
