import { LatLngBounds, LatLngExpression, LatLngTuple } from 'leaflet';
import React from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { LocalStore, getUserSettingNumber } from '../../../../Utility/LocalStorageFunctions';
import { mapCoordsDefault, maxZoomPossible, zoomLevelDefault } from '../../../../common/constsFrontend';
import './MapLeaflet.scss';
import MapLeafletEvents from './MapLeafletEvents';

type IMapLeaflet = {
  children: React.ReactNode;
  search: React.ReactNode;
  keyLat?: LocalStore;
  keyLng?: LocalStore;
  keyZoom?: LocalStore;
  maxZoom?: number;
  overrideStartPosition?: LatLngTuple;
  areaSelection?(bounds: LatLngBounds): void;
  styling: string;
};

const MapLeaflet = ({
  areaSelection,
  children,
  search,
  styling,
  keyLat,
  keyLng,
  keyZoom,
  maxZoom = maxZoomPossible,
  overrideStartPosition,
}: IMapLeaflet): JSX.Element => {
  const defaultZoom = zoomLevelDefault;
  const defaultLat = mapCoordsDefault[0];
  const defaultLng = mapCoordsDefault[1];

  const storedZoom = keyZoom ? getUserSettingNumber({ fallback: defaultZoom, identifier: keyZoom }) : defaultZoom;
  const storedLat = keyLat
    ? getUserSettingNumber({
        fallback: defaultLat,
        identifier: keyLat,
        returnFloat: true,
      })
    : defaultLat;
  const storedLng = keyLng
    ? getUserSettingNumber({
        fallback: defaultLng,
        identifier: keyLng,
        returnFloat: true,
      })
    : defaultLng;

  const center: LatLngExpression = overrideStartPosition
    ? [overrideStartPosition[0], overrideStartPosition[1]]
    : [storedLat, storedLng];

  return (
    <MapContainer
      className={styling}
      scrollWheelZoom={false}
      maxZoom={maxZoom}
      zoomControl={false}
      center={center}
      zoom={storedZoom}
    >
      <MapLeafletEvents
        areaSelection={areaSelection}
        keyLat={keyLat}
        keyLng={keyLng}
        keyZoom={keyZoom}
        overrideStartPosition={overrideStartPosition}
      />
      <TileLayer
        attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomControl position="bottomleft" />
      {children}
      {search}
    </MapContainer>
  );
};

export default MapLeaflet;
