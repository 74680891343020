import { Box, BoxProps } from '@mui/material';
import React from 'react';

interface IHeaderBar extends BoxProps {
  type?: 'title' | 'subtitle';
  children: React.ReactNode;
  isTop?: boolean;
  minHeight?: number;
}

const HeaderBar = ({ type = 'title', children, sx, className }: IHeaderBar): JSX.Element => {
  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        padding: '1.2rem 0.75rem',
        borderRadius: '3px 3px 0 0',
        backgroundColor: (theme) => (type === 'title' ? theme.palette.primary.main : 'grey'),
        textAlign: 'left',
        fontSize: type === 'title' ? '1.3rem' : '1.2rem',
        color: 'white',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default HeaderBar;
