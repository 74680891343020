import { Color } from '@flowplan/flowplan-shared';
import { numberWithCommas } from '@hsjakobsen/utilities';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { UsageInformation } from '../../../services/company-model-types';
import LowUsageSpacer from '../Common/LowUsageSpacer';
import LowUsageWizardContent from '../Common/LowUsageWizardContent';

type Props = {
  daysSinceLastFlowEvent: number;
  noConsumptionState: boolean;
  usageInformation: UsageInformation;
};

const LowUsageConsumption = ({ daysSinceLastFlowEvent, noConsumptionState, usageInformation }: Props): JSX.Element => {
  const { averageDailyConsumptionHistorically, days, lastFlowEventDate } = usageInformation;

  const historicalAverage = numberWithCommas(averageDailyConsumptionHistorically.toFixed(1));
  const lastFlowEventInfo = noConsumptionState
    ? 'Not found'
    : (
      <>
        <span className='do-not-translate'>{lastFlowEventDate}</span>
        <span className='do-not-translate'>{` (${daysSinceLastFlowEvent} `}</span>
        <span>days ago)</span>
      </>
    );
  const averageInfo = noConsumptionState 
    ? 'No data found'
    : (
      <>
        <span className='do-not-translate'>{historicalAverage}</span>
        <span className='do-not-translate'>{`L (${daysSinceLastFlowEvent} `}</span>
        <span>days average)</span>
      </>
    );
  const estimatedUsageSinceLastFlowEvent = noConsumptionState
    ? 'No consumption found'
    : (
      <>
        <span className='do-not-translate'>{`${Math.round(averageDailyConsumptionHistorically * daysSinceLastFlowEvent)}L`}</span>
      </>
    );

  return (
    <LowUsageWizardContent
      title="Water consumption"
      icon={<WaterDropIcon sx={{ height: 60, width: 60, color: Color.FlowplanBlue }} />}
    >
      <span>
        <span>Last flow event: </span>
        <strong>{lastFlowEventInfo}</strong>
      </span>
      <span>
        <span>Average daily usage: </span>
        <strong>{averageInfo}</strong>
      </span>
      <LowUsageSpacer height={10} />
      <span>
        <span>Estimated usage since last flow event: </span>
        <strong>{estimatedUsageSinceLastFlowEvent}</strong>
      </span>
    </LowUsageWizardContent>
  );
};

export default LowUsageConsumption;
