import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useStoreState } from '../../../../Store';
import Page from './Page';
import { FlowplanProductTypes } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { Paths } from '../../containers/Navigation/navigation-types';

type Props = {
  title: string;
  children: JSX.Element;
};

export type RedirectTo = {
  refer: boolean;
  toLocation: string;
};

export const PrivateRoute = ({ children, title }: Props): JSX.Element => {
  const isAuthenticated = useStoreState((state) => state.authModel.isAuthenticated);
  const { pathname, search } = useLocation();
  const [params] = useSearchParams();

  if (!isAuthenticated) {
    return <Navigate to={Paths.login} state={{ refer: params.get('refer'), toLocation: pathname + search }} replace />;
  }

  return <Page title={title}>{children}</Page>;
};

export const WaterFilterPrivateRoute = ({ children, title }: Props): JSX.Element => {
  const isAuthenticated = useStoreState((state) => state.authModel.isAuthenticated);
  const flowplanProductType = useStoreState((state) => state.adminDataModel.flowplanProductTypeId)
  const noAccessToFilterProduct = !(flowplanProductType === FlowplanProductTypes.WaterFilter || flowplanProductType === FlowplanProductTypes.Everything)
  const { pathname, search } = useLocation();
  const [params] = useSearchParams();

  if (!isAuthenticated) {
    return <Navigate to={Paths.login} state={{ refer: params.get('refer'), toLocation: pathname + search }} replace />;
  }

  if (noAccessToFilterProduct) {
    return <Navigate to={Paths.root} replace />;
  }

  return <Page title={title}>{children}</Page>;
};

export const TapsPrivateRoute = ({ children, title }: Props): JSX.Element => {
  const isAuthenticated = useStoreState((state) => state.authModel.isAuthenticated);
  const flowplanProductType = useStoreState((state) => state.adminDataModel.flowplanProductTypeId)
  const noAccessToTapsProduct = !(flowplanProductType === FlowplanProductTypes.Taps || flowplanProductType === FlowplanProductTypes.Everything)
  const { pathname, search } = useLocation();
  const [params] = useSearchParams();

  if (!isAuthenticated) {
    return <Navigate to={Paths.login} state={{ refer: params.get('refer'), toLocation: pathname + search }} replace />;
  }

  if (noAccessToTapsProduct) {
    return <Navigate to={Paths.root} replace />;
  }

  return <Page title={title}>{children}</Page>;
};

export const DashboardPrivateRoute = ({ children, title }: Props): JSX.Element => {
  const isAuthenticated = useStoreState((state) => state.authModel.isAuthenticated);
  const flowplanProductType = useStoreState((state) => state.adminDataModel.flowplanProductTypeId)
  const { pathname, search } = useLocation();
  const [params] = useSearchParams();

  if (!isAuthenticated) {
    return <Navigate to={Paths.login} state={{ refer: params.get('refer'), toLocation: pathname + search }} replace />;
  }

  if (flowplanProductType === FlowplanProductTypes.Taps) {
    return <Navigate to={Paths.tapDashboard} replace />;
  }

  return <Page title={title}>{children}</Page>;
};

export const FlowplanAdminUserRoute = ({ children, title }: Props): JSX.Element => {
  const isAuthenticated = useStoreState((state) => state.authModel.isAuthenticated);

  const { pathname, search } = useLocation();
  const [params] = useSearchParams();
  const access = useStoreState((state) => state.authModel.access);

  if (!isAuthenticated) {
    return <Navigate to={Paths.login} state={{ refer: params.get('refer'), toLocation: pathname + search }} replace />;
  }

  if (!access.flowplanAdmin) {
    return <Navigate to={Paths.dashboard} replace />;
  }

  return <Page title={title}>{children}</Page>;
};

export const ClientRoute = ({ children, title }: Props): JSX.Element => {
  const isAuthenticated = useStoreState((state) => state.authModel.isAuthenticated);
  const access = useStoreState((state) => state.authModel.access);

  if (!isAuthenticated) {
    return <Navigate to={Paths.login} replace />;
  }

  if (!access.clients.view) {
    return <Navigate to={Paths.dashboard} replace />;
  }

  return <Page title={title}>{children}</Page>;
};

export const AdminUserRoute = ({ children, title }: Props): JSX.Element => {
  const isAuthenticated = useStoreState((state) => state.authModel.isAuthenticated);
  const access = useStoreState((state) => state.authModel.access);

  if (!isAuthenticated) {
    return <Navigate to={Paths.login} replace />;
  }

  if (!access.users.view) {
    return <Navigate to={Paths.dashboard} replace />;
  }

  return <Page title={title}>{children}</Page>;
};

export const WaterFilterAdminUserRoute = ({ children, title }: Props): JSX.Element => {
  const isAuthenticated = useStoreState((state) => state.authModel.isAuthenticated);
  const flowplanProductType = useStoreState((state) => state.adminDataModel.flowplanProductTypeId)
  const noAccessToFilterProduct = !(flowplanProductType === FlowplanProductTypes.WaterFilter || flowplanProductType === FlowplanProductTypes.Everything)
  const access = useStoreState((state) => state.authModel.access);

  if (!isAuthenticated) {
    return <Navigate to={Paths.login} replace />;
  }

  if (noAccessToFilterProduct) {
    return <Navigate to={Paths.root} replace />;
  }

  if (!access.users.view) {
    return <Navigate to={Paths.dashboard} replace />;
  }

  return <Page title={title}>{children}</Page>;
};

export const TapsAdminUserRoute = ({ children, title }: Props): JSX.Element => {
  const isAuthenticated = useStoreState((state) => state.authModel.isAuthenticated);
  const flowplanProductType = useStoreState((state) => state.adminDataModel.flowplanProductTypeId)
  const noAccessToTapsProduct = !(flowplanProductType === FlowplanProductTypes.Taps || flowplanProductType === FlowplanProductTypes.Everything)
  const access = useStoreState((state) => state.authModel.access);

  if (!isAuthenticated) {
    return <Navigate to={Paths.login} replace />;
  }

  if (noAccessToTapsProduct) {
    return <Navigate to={Paths.root} replace />;
  }

  if (!access.tapSettings.manage) {
    return <Navigate to={Paths.dashboard} replace />;
  }

  return <Page title={title}>{children}</Page>;
};
