import { RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { ChangeEvent, Fragment } from 'react';
import Checkbox from '../../../../Components/Input/Checkbox';
import { isUserGuest } from '../../types/users-configs';
import styles from './NotificationSettings.module.scss';

interface INotificationSettings {
  inputAlertEmails: boolean;
  inputAlertLeaks: boolean;
  inputConfirmationEmails: boolean;
  inputKeyAccountAlertEmails: string;
  inputKeyAccountAlertNotifications: string;
  inputKeyAccountConfirmationEmails: string;
  inputKeyAccountConfirmationNotifications: string;
  inputKeyLeakAlerts: string;
  userTypeValue: RoleType;
  handleInput(e: ChangeEvent, inputKey: string): void;
}

const NotificationSettings = ({
  inputAlertEmails,
  inputAlertLeaks,
  inputConfirmationEmails,
  handleInput,
  inputKeyAccountAlertEmails,
  inputKeyLeakAlerts,
  inputKeyAccountAlertNotifications,
  inputKeyAccountConfirmationEmails,
  inputKeyAccountConfirmationNotifications,
  userTypeValue,
}: INotificationSettings): JSX.Element => {
  return (
    <Fragment>
      <div className={styles.notificationTopArea}>
        <span className={styles.notificationTitle}>Notification settings</span>
      </div>
      {isUserGuest(userTypeValue) ? null : (
        <>
          <Checkbox
            inputValue={inputAlertEmails}
            wideFormat={true}
            inputKey={inputKeyAccountAlertEmails}
            inputText="Alert emails"
            handleInput={handleInput}
          />

          <Checkbox
            inputValue={inputConfirmationEmails}
            wideFormat={true}
            inputKey={inputKeyAccountConfirmationEmails}
            inputText="Confirmation emails"
            handleInput={handleInput}
          />
        </>
      )}

      <Checkbox
        inputValue={inputAlertLeaks}
        wideFormat={true}
        inputKey={inputKeyLeakAlerts}
        inputText="Receive leak alerts"
        handleInput={handleInput}
      />
    </Fragment>
  );
};

export default NotificationSettings;
