import LogRocket from 'logrocket';
import { isDevelopment } from './config';

if (!isDevelopment) {
    LogRocket.init('vjmakh/test-project', { // alternate project
        network: {
            requestSanitizer: (request) => {
                // if the url contains 'ignore'
                if (request.url.toLowerCase().indexOf('auth/signin/') !== -1) {
                    // scrub out the body
                    request.body = undefined;
                }

                if (request.headers['authorization']) {
                    request.headers['authorization'] = '';
                }

                if (request.headers['Authorization']) {
                    request.headers['Authorization'] = '';
                }

                return request;
            },
            responseSanitizer: (response) => {
                response.body = undefined;
                return response;
            },
        },
        dom: {
            textSanitizer: true,
            inputSanitizer: true,
        },
    });
}