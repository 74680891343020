import { useStoreState } from '../../../../Store';
import { IFilterNotification } from '../../installation-history-interfaces';
import { formatInstallationHistoryData } from './History.Item.Data';
import './History.Item.scss';
import { getInstallationHistoryItemStyle } from './History.Item.Styling';

interface IFilterHistoryItem {
  data: IFilterNotification;
}

const FilterHistoryItem = ({ data }: IFilterHistoryItem): JSX.Element => {
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const companyId = useStoreState((actions) => actions.companyModel.companyId);

  const processedDataSet = formatInstallationHistoryData(companyId, data, baseFilter);

  const itemStyling = getInstallationHistoryItemStyle({
    notificationTypeId: data.notificationTypeId,
    processedDataSet,
  });

  return (
    <div className={'filter-history-item' + itemStyling}>
      <li className="filter-history-li">
        <span className="flex-2 filter-history-name">{processedDataSet.timeStamp}</span>
        <div className="flex-1 filter-history-status">
          <span className="filter-history-info">
            <strong>{processedDataSet.notificationText}</strong>
          </span>
          <span className="filter-history-info">{processedDataSet.extra}</span>
        </div>
        <span className="flex-1 filter-history-info">{processedDataSet.actor}</span>
        <span className="flex-1 filter-history-info">{processedDataSet.note}</span>
      </li>
    </div>
  );
};

export default FilterHistoryItem;
