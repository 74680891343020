import { IResponse } from '@hsjakobsen/utilities';
import { action, thunk, thunkOn } from 'easy-peasy';
import { IClientInput } from '../../common/interfacesInput';
import { validateInputClient } from '../clients/services/clients-validation';
import { OptionProp } from '../common/components/FormikSelect/FormikSelect';
import { handleDeleteRequest, handlePostRequest, handlePutRequest } from '../server-requests/server-requests';
import { IFlowplanClientGroupsModel } from './flowplan-client-groups-types';

const flowplanClientGroupsModel: IFlowplanClientGroupsModel = {
  loadingClientsGroup: false,
  setLoadingClientsGroup: action((state, payload) => {
    state.loadingClientsGroup = payload;
  }),
  getClientsGroup: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingClientsGroup(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/flowplan-clients-group/' });
    if (requestResponse.success) {
      actions.setClientsGroup(requestResponse.data);
    }
    actions.setLoadingClientsGroup(false);
  }),
  duplicateClientsGroup: thunk(async (actions, payload) => {
    actions.setLoadingClientsGroup(true);
    const requestResponse = await handlePostRequest({ id: payload }, '/flowplan-clients-group/duplicate', true);
    //if (requestResponse.success) {
    //  actions.setClientsGroup(requestResponse.data);
    //}
    actions.setLoadingClientsGroup(false);
    return requestResponse
  }),

  clientsGroup: [],
  clientGroupsDropdown: [],
  setClientsGroup: action((state, payload) => {
    state.clientsGroup = payload;
    const options: OptionProp[] = payload.map((item) => {
      return {
        value: item.id,
        name: item.name,
      };
    });
    state.clientGroupsDropdown = options;
  }),
  clientsGroupRemove: thunk(async (actions, payload) => {
    const requestResponse = await handleDeleteRequest({ id: payload }, '/flowplan-clients-group/');
    if (requestResponse.success) {
      actions.getClientsGroup();
    }
    return requestResponse;
  }),

  newClientsGroupErrorMessage: '',
  inputClientsGroupName: '',
  setInputClientsGroupName: action((state, payload) => {
    state.inputClientsGroupName = payload;
  }),
  setNewClientsGroupErrorMessage: action((state, payload) => {
    state.newClientsGroupErrorMessage = payload;
  }),
  newClientsGroupInteractionDisabled: false,

  toggleNewClientsGroupInteraction: action((state, payload) => {
    state.newClientsGroupInteractionDisabled = payload;
  }),
  clientsGroupId: undefined,
  setClientsGroupId: action((state, payload) => {
    state.clientsGroupId = payload;
  }),
  isUpdateClientsGroup: false,
  showNewClientsGroupModal: false,
  handleNewClientsGroupFeedback: action((state, payload) => {
    state.newClientsGroupInteractionDisabled = false;
    if (!payload.success) {
      state.newClientsGroupErrorMessage = payload.message;

      if (payload.message !== '' && state.newClientsGroupErrorMessage === payload.message) {
        // document.forceUpdate();
        // console.log("force event area triggered");
      }

      if (!state.showNewClientsGroupModal) {
        alert('Error occured: ' + payload.message);
      }

      return;
    }
    state.newClientsGroupErrorMessage = '';
    state.clientsGroupId = undefined;
    state.inputClientsGroupName = '';
    state.isUpdateClientsGroup = false;
    state.showNewClientsGroupModal = false;
  }),
  handleClientsGroupData: thunk(async (actions, payload, { getState }) => {
    actions.toggleNewClientsGroupInteraction(true);
    const state = getState();

    const clientsGroupInput: IClientInput = {
      id: state.clientsGroupId,
      inputName: state.inputClientsGroupName,
      isUpdate: state.isUpdateClientsGroup,
    };

    const clientsGroupInfo = validateInputClient(clientsGroupInput);
    if (!clientsGroupInfo.success && clientsGroupInfo.message !== undefined) {
      actions.toggleNewClientsGroupInteraction(false);
      actions.setNewClientsGroupErrorMessage(clientsGroupInfo.message);
      return clientsGroupInfo;
    }

    let response: IResponse;
    if (clientsGroupInput.isUpdate) {
      const body = { name: clientsGroupInfo.name, id: clientsGroupInfo.id };
      response = await handlePutRequest(body, '/flowplan-clients-group/', true);
      if (response.success) {
        actions.getClientsGroup();
      }
      actions.handleNewClientsGroupFeedback(response);
      return response;
    }
    delete clientsGroupInfo.id;
    response = await handlePostRequest({ name: clientsGroupInfo.name }, '/flowplan-clients-group/', true);
    if (response.success) {
      actions.getClientsGroup();
    }
    actions.handleNewClientsGroupFeedback(response);
    return response;
  }),
  toggleNewClientsGroupModal: action((state, payload) => {
    state.newClientsGroupErrorMessage = '';
    state.clientsGroupId = payload.clientsGroupData?.id;
    state.inputClientsGroupName = payload.clientsGroupData === undefined ? '' : payload.clientsGroupData.name;
    state.isUpdateClientsGroup = payload.clientsGroupData !== undefined;
    state.showNewClientsGroupModal = payload.showModal;
  }),

  onActionsThatUpdateClientsGroup: thunkOn(
    (actions) => [actions.duplicateClientsGroup, actions.clientsGroupRemove],
    async (actions) => {
      await actions.getClientsGroup();
    },
  ),
};

export default flowplanClientGroupsModel;
