import { DeviceStatus, getSerialNumberToShow, notAvailable } from '@flowplan/flowplan-shared';
import { HealthState, IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { getPowerStatusText } from '../../../../Utility/Texts';
import { formatDateYMD, getDateObject } from '../../../../Utility/time';
import {
  ISignalStrengthResult,
  interpretSignalStrength,
} from '../../components/SignalStrength/signal-strength-parsing';

export interface IBeamInformation {
  batteryCharge: number;
  batteryDaysRemaining: string;
  batteryInstallationDate: string;
  showBatteryInformation: boolean;
  deviceTemperature: number | null;
  firmware: string;
  healthState: HealthState;
  deviceStatus: DeviceStatus;
  flowplanDeviceId: string;
  hardware: string;
  network: number;
  powerStatus: string;
  signalInfo: ISignalStrengthResult;
  signalStrength: number;
}

const collectBeamInformation = (sensor: IDeviceView): IBeamInformation => {
  const {
    batteryCharge, batteryInstallationDate, batteryRemainingDays, batteryShowInformation,
    deviceStatus, firmware, hardware, network,
    signalStrengthAverage, signalStrengthLatest,
    temperatureInternalAverage, temperatureInternalLatest
  } = sensor.infoDeviceMetrics;

  const signalStrength: number = signalStrengthAverage || signalStrengthLatest;
  return {
    batteryCharge,
    batteryDaysRemaining: batteryRemainingDays < 1000 ? batteryRemainingDays.toFixed(0) : '>1000',
    batteryInstallationDate: formatDateYMD(getDateObject(batteryInstallationDate)),
    deviceTemperature: temperatureInternalLatest || temperatureInternalAverage,
    deviceStatus,
    firmware: firmware || notAvailable,
    flowplanDeviceId: getSerialNumberToShow(sensor.infoBasic.flowplanDeviceId),
    hardware: hardware || notAvailable,
    network,
    powerStatus: getPowerStatusText({ network, percentageStatus: batteryCharge }),
    showBatteryInformation: batteryShowInformation,
    signalInfo: interpretSignalStrength(network, signalStrength),
    signalStrength,
    healthState: sensor.infoHealth.healthState,
  };
};

export { collectBeamInformation };
