import NoteIcon from '@mui/icons-material/Note';
import { useSnackbar, VariantType } from 'notistack';
import { noSelectionMade } from '../../../../Models/admin/admin.model';
import { useStoreActions, useStoreState } from '../../../../Store';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import FpText from '../../../common/components/FpText/FpText';

interface Props {
  alwaysLeftAlign?: boolean;
}
const InstallationAddNote = (props: Props): JSX.Element => {
  const { alwaysLeftAlign } = props;

  const access = useStoreState((state) => state.authModel.access);
  const selectedInstallation = useStoreState((state) => state.graphSettingsModel.selectedInstallation);

  const addNote = useStoreActions((actions) => actions.deviceActionsModel.addNote);
  const confirmActionToggle = useStoreActions((actions) => actions.modalModel.confirmActionToggle);

  const { enqueueSnackbar } = useSnackbar();

  const requestAddNote = () => {
    const deviceIdToShow = selectedInstallation.infoBasic.flowplanDeviceId.substring(6);
    const confirmValue = selectedInstallation.infoBasic.name + ' (' + deviceIdToShow + ')';
    const title =
      (
        <>
          <FpText componentType='span' variant='h6'>Add note to: </FpText>
          <FpText componentType='span' variant='h6' className='do-not-translate'>{selectedInstallation.infoBasic.name}</FpText>
        </>

      )
    confirmActionToggle({
      confirmValue,
      showModal: true,
      freeInput: true,
      confirmValueIsNumber: false,
      maxLength: 100,
      text: 'Add a note to the installation history:',
      title,
      confirmCallback: confirmAddNote,
    });
  };

  const confirmAddNote = async (noteToAdd: string) => {
    const prunedNote = noteToAdd.replace(/'/g, '').replace(/"/g, '');
    const requestResponse = await addNote({
      installationId: selectedInstallation.infoBasic.id,
      note: prunedNote,
      notificationDataId: noSelectionMade,
    });

    const feedback = requestResponse.success
      ? 'Installation note added: ' + prunedNote
      : 'Error: ' + requestResponse.message;
    const variant: VariantType = requestResponse.success ? 'success' : 'error';
    enqueueSnackbar(feedback, { variant });
  };

  return (
    <FpButtonOutlinedAligned
      alwaysLeftAlign={alwaysLeftAlign}
      disabled={!access.installations.manage}
      className="yellowButton"
      icon={<NoteIcon />}
      onClick={requestAddNote}
      label="Add Note"
    />
  );
};

export default InstallationAddNote;
