import { Container, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import styles from './FpContainer.module.scss';
type Props = {
  basic?: boolean;
  centerText?: boolean;
  children?: ReactNode;
  backgroundColor?: '#f9fafb' | 'rgba(92, 184, 92, 0.3)';
  fixedHeight?: number;
  marginBottom?: boolean;
  marginTop?: boolean;
  flex?: boolean;
  borderTopColor?: string;
  sx?: SxProps<Theme>;
  onClick?(): void;
};

const FpContainer = ({
  basic,
  borderTopColor,
  centerText,
  children,
  backgroundColor,
  onClick,
  fixedHeight,
  marginBottom,
  marginTop,
  sx,
}: Props): JSX.Element => {
  let style = basic ? styles.fpBasic : styles.fpContainer;
  if (centerText) {
    style += ' ' + styles.centerText;
  }
  if (marginTop) {
    style += ' ' + styles.marginTop;
  }
  if (marginBottom) {
    style += ' ' + styles.marginBottom;
  }

  let maxHeight = undefined;

  if (fixedHeight) {
    maxHeight = fixedHeight + 'px';
    style += ' ' + styles.limitHeight;
  }

  const bgcolor = backgroundColor || 'common.white';

  return (
    <Container
      className={style}
      maxWidth={false}
      onClick={(event) => {
        if (onClick) {
          onClick();
        }
      }}
      sx={{ bgcolor, color: 'text.primary', p: 2, maxHeight, height: maxHeight, borderTopColor, ...sx }}
      disableGutters
    >
      {children}
    </Container>
  );
};

export default FpContainer;
