import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSnackbar, VariantType } from 'notistack';
import { noSelectionMade } from '../../../../Models/admin/admin.model';
import { defaultBaseFilter } from '../../../../Models/graphSettings';
import { useStoreActions, useStoreState } from '../../../../Store';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import FpText from '../../../common/components/FpText/FpText';

interface Props {
  alwaysLeftAlign?: boolean;
  closeOnSuccess?: boolean;
  isTapInstallation?: boolean
}

const InstallationDelete = (props: Props): JSX.Element => {
  const { alwaysLeftAlign, closeOnSuccess, isTapInstallation } = props;
  const access = useStoreState((state) => state.authModel.access);
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const flowplanClientSelected = useStoreState((state) => state.adminDataModel.flowplanClientSelected);
  const companyId = useStoreState((state) => state.companyModel.companyId);

  const confirmationToggle = useStoreActions((actions) => actions.modalModel.confirmationToggle);
  const setBaseFilter = useStoreActions((actions) => actions.graphSettingsModel.setSelectedInstallation);
  const getCompanyInstallations = useStoreActions((actions) => actions.companyModel.getCompanyInstallations);
  const removeSensor = useStoreActions((actions) => actions.deviceActionsModel.removeSensor);
  const removeTapSensor = useStoreActions((actions) => actions.deviceActionsModel.removeTapSensor)
  const confirmStrictToggle = useStoreActions((actions) => actions.modalModel.confirmStrictToggle);

  const { enqueueSnackbar } = useSnackbar();

  const requestDelete = () => {
    const deviceIdToShow = baseFilter.infoBasic.flowplanDeviceId.substring(6);
    const confirmValue = deviceIdToShow;
    const title =
      (
        <>
          <FpText componentType='span' variant='h6'>Delete installation: </FpText>
          <FpText componentType='span' variant='h6' className='do-not-translate'>{baseFilter.infoBasic.name}</FpText>
        </>
      )
    confirmStrictToggle({
      confirmValue,
      showModal: true,
      text: 'Confirm delete by typing:',
      title,
      confirmCallback: confirmDelete,
    });
  };

  const confirmDelete = async () => {
    let requestResponse;
    if (isTapInstallation) {
      requestResponse = await removeTapSensor()
    } else {
      requestResponse = await removeSensor();
    }

    if (requestResponse.success) {
      if (companyId !== flowplanClientSelected || flowplanClientSelected !== noSelectionMade) {
        // this functionality is for the admin section
        await getCompanyInstallations(undefined);
      }

      setBaseFilter(defaultBaseFilter);

      if (closeOnSuccess) {
        confirmationToggle({ content: undefined, showModal: false, title: '', confirmationSize: false });
      }
    }

    const feedback = requestResponse.success ? 'Installation deleted' : 'Error: ' + requestResponse.message;
    const variant: VariantType = requestResponse.success ? 'success' : 'error';
    enqueueSnackbar(feedback, { variant });
  };

  return (
    <FpButtonOutlinedAligned
      color="error"
      alwaysLeftAlign={alwaysLeftAlign}
      disabled={!access.installations.manage}
      className="redButton"
      icon={<DeleteForeverIcon />}
      onClick={requestDelete}
      label="Delete"
    />
  );
};

export default InstallationDelete;
