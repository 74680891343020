import { modalTitleAddEdit, notAvailable } from '@flowplan/flowplan-shared';
import { ILocationRequestPackage } from '@flowplan/flowplan-shared/lib/locations/locations';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useEffect } from 'react';
import { DropdownProps, Header, Icon, Image } from 'semantic-ui-react';
import Feedback from '../../../../Components/Feedback/Feedback';
import DisabledInput from '../../../../Components/Input/DisabledInput';
import DropdownListSearchable from '../../../../Components/Input/DropdownListSearchable';
import TextInput from '../../../../Components/Input/TextInput';
import AddItemModal from '../../../../Components/Modals/AddItemModal';
import { GooglePlacesSearch } from '../../../../Components/Peripherals/GoogleAPI/GooglePlacesSearch';
import { IPlacesResult } from '../../../../Components/Peripherals/GoogleAPI/GooglePlacesSearchInterfaces';
import iconPlacementStyles from '../../../../Components/Popup/IconPlacement.module.scss';
import PopupWithIcon from '../../../../Components/Popup/PopupWithIcon';
import { noSelectionMade } from '../../../../Models/admin/admin.model';
import { useStoreActions, useStoreState } from '../../../../Store';
import { checkNumbersOnlyRestriction } from '../../../../Utility/Texts';
import { HardnessEnum } from '../../../../common/constsFrontend';
import { ILocationInput } from '../../../../common/interfacesInput';
import FpButton from '../../../common/components/FpButton/FpButton';
import StockFeatureSelection, {
  IStockTypeSelection,
} from '../../../stock/components/StockFeatureSelection/StockFeatureSelection';
import { FilterSofteningType, getWaterHardnessRange, getWaterHardnessType } from '../../locations-texts';
import { validateInputLocation } from '../../locations-validation';

const inputKeyName = 'name';
const inputKeySearchInput = 'searchInput';
const inputKeyStreet = 'street';
const inputKeyCarbonateHardness = 'carbonateHardness';
const inputKeyTotalHardness = 'totalHardness';
const inputKeyMicroSimens = 'microSiemens';
const inputKeyPhone = 'phone';

const NewLocationModal = (): JSX.Element => {
  const getLocationDataAndStock = useStoreActions((actions) => actions.locationsModel.getLocationDataAndStock);
  const toggleNewLocationModal = useStoreActions((actions) => actions.locationsModel.toggleNewLocationModal);
  const createLocation = useStoreActions((actions) => actions.locationsModel.createLocation);
  const updateLocation = useStoreActions((actions) => actions.locationsModel.updateLocation);
  const manageStockCreation = useStoreActions((actions) => actions.stockModel.manageStockCreation);
  const manageStockUpdates = useStoreActions((actions) => actions.stockModel.manageStockUpdates);

  const showNewLocationModal = useStoreState((state) => state.locationsModel.showNewLocationModal);
  const googleAPISearching = useStoreState((state) => state.externalServices.googleAPISearching);
  const currentUserInfo = useStoreState((state) => state.usersModel.userInformation);
  const clientsDropdown = useStoreState((state) => state.clientsModel.clientsDropdown);
  const locationData = useStoreState((state) => state.locationsModel.locationData);
  const stockData = useStoreState((state) => state.locationsModel.stockData);
  const companyHardnessUnit = useStoreState((state) => state.companyModel.companyHardnessUnit);

  const [showHardnessInfo, setShowHardnessInfo] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [locationId, setLocationId] = React.useState<number | undefined>(undefined);
  const [inputClientId, setInputClientId] = React.useState(noSelectionMade);
  const [inputLocationName, setInputLocationName] = React.useState('');
  const [inputCarbonateHardness, setInputCarbonateHardness] = React.useState<number | string>('');
  const [inputTotalHardness, setInputTotalHardness] = React.useState<number | string>('');
  const [inputMicroSiemens, setInputMicroSiemens] = React.useState<number | string>('');
  const [inputStreet, setInputStreet] = React.useState('');
  const [inputLocationPhone, setInputLocationPhone] = React.useState('');
  const [inputCity, setInputCity] = React.useState('');
  const [inputZipCode, setInputZipCode] = React.useState<number | string>('');
  const [searchInput, setSearchInput] = React.useState('');
  const [newLocationErrorMessage, setNewLocationErrorMessage] = React.useState('');
  const [latitude, setLatitude] = React.useState<number | undefined>(undefined);
  const [longitude, setLongitude] = React.useState<number | undefined>(undefined);
  const [newLocationInteractionDisabled, setNewLocationInteractionDisabled] = React.useState(false);
  const [selectedStockTypes, setSelectedStockTypes] = React.useState<IStockTypeSelection[]>([]);

  const handleStockSelections = (stockItem: IStockTypeSelection, selected: boolean) => {
    if (selected) {
      const itemInList = selectedStockTypes.find(
        (selectedStockType) => selectedStockType.stockType === stockItem.stockType,
      );
      if (itemInList) {
        itemInList.totalAmount = stockItem.totalAmount;
      } else {
        selectedStockTypes.push({ stockType: stockItem.stockType, totalAmount: stockItem.totalAmount });
      }
      setSelectedStockTypes([...selectedStockTypes]);
    } else {
      setSelectedStockTypes(
        selectedStockTypes.filter((selectedStockType) => selectedStockType.stockType !== stockItem.stockType),
      );
    }
  };

  React.useEffect(() => {
    const stockOnLocation: IStockTypeSelection[] = [];
    stockData.forEach((stockItem) => {
      stockOnLocation.push({ id: stockItem.id, stockType: stockItem.stockType, totalAmount: stockItem.totalAmount });
    });
    setSelectedStockTypes(stockOnLocation);
    return () => {
      setSelectedStockTypes([]);
    };
  }, [stockData]);

  React.useEffect(() => {
    if (locationData.id !== 0 && showNewLocationModal) {
      setIsUpdate(true);
      setLocationId(locationData.id);
      setInputClientId(locationData.clientId);
      setInputLocationName(locationData.name);
      setInputCarbonateHardness(locationData.carbonateHardness);
      setInputTotalHardness(locationData.totalHardness);
      setInputMicroSiemens(locationData.microSiemensPerCm);
      setInputStreet(locationData.street);
      setInputLocationPhone(locationData.phoneNumber ? locationData.phoneNumber : '');
      setInputCity(locationData.city);
      setInputZipCode(locationData.zipCode);
      setSearchInput(locationData.street);
      setLatitude(locationData.latitude);
      setLongitude(locationData.longitude);
    } else {
      setIsUpdate(false);
      setLocationId(undefined);
      setInputClientId(noSelectionMade);
      setInputLocationName('');
      setSearchInput('');
      setInputCarbonateHardness('');
      setInputTotalHardness('');
      setInputMicroSiemens('');
      setInputStreet(notAvailable);
      setInputLocationPhone('');
      setInputCity(notAvailable);
      setInputZipCode(notAvailable);
      setLatitude(undefined);
      setLongitude(undefined);
    }
    setNewLocationErrorMessage('');
  }, [locationData, showNewLocationModal]);

  const handleInput = (ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
    setNewLocationErrorMessage('');
    const value = ev.target.value;
    switch (inputKey) {
      case inputKeyName:
        setInputLocationName(value);
        break;
      case inputKeyCarbonateHardness: {
        const newCarbonateHardnessInput = checkNumbersOnlyRestriction(ev) ? value : inputCarbonateHardness;

        setInputCarbonateHardness(newCarbonateHardnessInput);
        break;
      }
      case inputKeyTotalHardness: {
        const newTotalHardnessInput = checkNumbersOnlyRestriction(ev) ? value : inputTotalHardness;
        setInputTotalHardness(newTotalHardnessInput);
        break;
      }
      case inputKeyMicroSimens: {
        const newMicroSiemensInput = checkNumbersOnlyRestriction(ev) ? value : inputMicroSiemens;
        setInputMicroSiemens(newMicroSiemensInput);
        break;
      }
      case inputKeyPhone: {
        setInputLocationPhone(value);
        break;
      }
    }
  };

  const handleLocationSelected = (locationData: IPlacesResult) => {
    const { city, lat, lng, name, street, zipCode } = locationData;
    const cityToShow = city.trim() === '' ? notAvailable : city;
    setInputCity(cityToShow);
    const streetToShow = street.trim() === '' ? notAvailable : street;
    setInputStreet(streetToShow);
    const zipCodeToShow = Number(zipCode) <= 0 ? notAvailable : zipCode;
    setInputZipCode(zipCodeToShow);

    if (lat !== noSelectionMade && lng !== noSelectionMade) {
      setLatitude(lat);
      setLongitude(lng);
    }

    if (name && !isUpdate) {
      setInputLocationName(
        searchInput && currentUserInfo?.notificationSettings.locationSearch === 'ClientName' ? searchInput : name,
      );
    }
    setNewLocationErrorMessage('');
  };

  const handleLocationInputChange = () => {
    setLatitude(undefined);
    setLongitude(undefined);
  };

  const carbonateHardnessInfo =
    'Carbonate hardness (' +
    getWaterHardnessRange({
      filterSofteningType: FilterSofteningType.CarbonateOnly,
      hardnessUnit: companyHardnessUnit,
    }) +
    getWaterHardnessType({
      hardnessUnit: companyHardnessUnit,
      includeLaymansTerm: false,
      spaceInFront: true,
      filterSofteningType: FilterSofteningType.CarbonateOnly,
    }) +
    ')';
  const totalHardnessInfo =
    'Total hardness (' +
    getWaterHardnessRange({
      filterSofteningType: FilterSofteningType.TotalSoftening,
      hardnessUnit: companyHardnessUnit,
    }) +
    getWaterHardnessType({
      hardnessUnit: companyHardnessUnit,
      includeLaymansTerm: false,
      spaceInFront: true,
      filterSofteningType: FilterSofteningType.TotalSoftening,
    }) +
    ')';
  const conductivityInfo =
    'Conductivity (' +
    getWaterHardnessRange({
      filterSofteningType: FilterSofteningType.BothAndMicroSiemens,
      hardnessUnit: companyHardnessUnit,
    }) +
    getWaterHardnessType({
      hardnessUnit: companyHardnessUnit,
      includeLaymansTerm: false,
      spaceInFront: true,
      filterSofteningType: FilterSofteningType.MicroSiemens,
    }) +
    ')';

  const handleClicks = async (): Promise<void> => {
    setNewLocationInteractionDisabled(true);
    if (!latitude || !longitude) {
      setNewLocationErrorMessage('Address has to be chosen from the search result');
      setNewLocationInteractionDisabled(false);
      return;
    }

    const idToUse: number = locationId ? locationId : noSelectionMade;

    const locationInput: ILocationInput = {
      id: idToUse,
      inputCarbonateHardness: Number(inputCarbonateHardness),
      inputCity,
      inputClientId,
      inputLatitude: latitude,
      inputLongitude: longitude,
      inputName: inputLocationName,
      inputStreet,
      inputMicroSiemens: Number(inputMicroSiemens),
      inputTotalHardness: Number(inputTotalHardness),
      inputZipCode: inputZipCode,
      isUpdate,
      inputPhone: inputLocationPhone,
    };

    const locationInfo: ILocationRequestPackage = validateInputLocation({
      hardnessUnit: companyHardnessUnit,
      inputData: locationInput,
    });
    if (!locationInfo.success && locationInfo.message !== undefined) {
      setNewLocationInteractionDisabled(false);
      setNewLocationErrorMessage(locationInfo.message);
      return;
    }

    if (isUpdate) {
      let operationSuccess = false;
      let operationFeedback = '';
      const updateResult = await updateLocation(locationInfo);
      operationSuccess = updateResult.success;
      operationFeedback = updateResult.message;

      if (operationSuccess) {
        const locationId = locationInfo.id ? locationInfo.id : null;
        const { success, message } = await manageStockUpdates({ locationId, selectedStockTypes, stockData });
        operationSuccess = success;
        operationFeedback = message;
      }
      requestFollowUp({ operationFeedback, operationSuccess });
      await getLocationDataAndStock(locationInfo.id);
      return;
    }

    await locationCreate(locationInfo);
  };

  const locationCreate = async (locationInfo: ILocationRequestPackage): Promise<void> => {
    let operationSuccess = false;
    let operationFeedback = '';
    const createResult = await createLocation(locationInfo);
    operationSuccess = createResult.success;
    operationFeedback = createResult.message;
    if (operationSuccess && selectedStockTypes.length > 0) {
      const { success, message } = await manageStockCreation({ locationId: createResult.data.id, selectedStockTypes });
      operationFeedback = message;
      operationSuccess = success;
    }

    requestFollowUp({ operationFeedback, operationSuccess });
  };

  interface IRequestFollowup {
    operationFeedback: string;
    operationSuccess: boolean;
  }

  const requestFollowUp = ({ operationFeedback, operationSuccess }: IRequestFollowup) => {
    setNewLocationInteractionDisabled(false);
    if (operationSuccess) {
      handleCloseModal();
    } else {
      setNewLocationErrorMessage(operationFeedback);
    }
  };

  const handleCloseModal = () => {
    toggleNewLocationModal({ locationId: undefined, showModal: false });
  };

  const closeHardnessInfo = () => {
    setShowHardnessInfo(false);
  };

  const interactionDisabled = newLocationInteractionDisabled || googleAPISearching;

  const modalTitle = modalTitleAddEdit(isUpdate, 'location');

  useEffect(() => {
    const fetchedTotalHardnessValue = Number(
      locationData.hardnessData.find((hardnessData) => hardnessData.hardnessType.name === HardnessEnum.TOTAL_HARDNESS)
        ?.value || 0,
    );
    const fetchedCarbonateHardnessValue = Number(
      locationData.hardnessData.find(
        (hardnessData) => hardnessData.hardnessType.name === HardnessEnum.CARBONATE_HARDNESS,
      )?.value || 0,
    );
    const fetchedMicroSiemensValue = Number(
      locationData.hardnessData.find(
        (hardnessData) => hardnessData.hardnessType.name === HardnessEnum.MICRO_SIEMENS_PER_CM,
      )?.value || 0,
    );
    setInputTotalHardness(fetchedTotalHardnessValue);
    setInputCarbonateHardness(fetchedCarbonateHardnessValue);
    setInputMicroSiemens(fetchedMicroSiemensValue);
  }, [locationData.hardnessData]);

  return (
    <AddItemModal
      isModalOpen={showNewLocationModal}
      confirmInteractionDisabled={false}
      interactionDisabled={interactionDisabled}
      closeModal={handleCloseModal}
      confirmAction={handleClicks}
      modalCancelLabel="Cancel"
      modalConfirmLabel="Confirm"
      modalTitle={modalTitle}
    >
      <DropdownListSearchable
        items={clientsDropdown}
        className='do-not-translate'
        placeholder="Location client"
        selectedValue={inputClientId}
        onChangeFunction={(e: SyntheticEvent, { value }: DropdownProps) => {
          const clientIdToUse = Number(value);
          const clientLookup = clientsDropdown.find((x) => x.value === clientIdToUse);
          const clientName = clientLookup !== undefined ? clientLookup.text : '';
          setInputClientId(clientIdToUse);
          const inputToUse = clientIdToUse > noSelectionMade ? clientName : '';
          if (isUpdate) {
            return;
          }
          // setInputLocationName(inputToUse);
          setSearchInput(inputToUse);
          setInputStreet(notAvailable);
          setInputCity(notAvailable);
          setInputZipCode(notAvailable);
          setLatitude(undefined);
          setLongitude(undefined);
        }}
      />

      <GooglePlacesSearch
        alternateLayout={true}
        shouldRender={!isUpdate}
        inputFieldName="Flowplan location search*"
        initialSearchTerm={searchInput}
        initialSearch={true}
        inputKey={inputKeySearchInput}
        locationInputChange={handleLocationInputChange}
        locationSelectedCallback={handleLocationSelected}
      />

      <Header as="h4">Location details</Header>

      <TextInput
        inputValue={inputLocationName}
        inputKey={inputKeyName}
        maxLength={100}
        inputText={'Name* (' + inputLocationName.length + ' / 100)'}
        handleInput={handleInput}
        isRequired={true}
      >
        <PopupWithIcon position="left center">
          <p>
            This is the location of the machine/filter. A good location name example could be "Company name -
            Department"
          </p>
        </PopupWithIcon>
      </TextInput>

      <GooglePlacesSearch
        alternateLayout={false}
        inputFieldName="Street*"
        shouldRender={true}
        initialSearchTerm={inputStreet}
        initialSearch={false}
        inputKey={inputKeyStreet}
        locationInputChange={handleLocationInputChange}
        locationSelectedCallback={handleLocationSelected}
      />

      <DisabledInput inputText="Zipcode" inputValue={inputZipCode} />
      <DisabledInput inputText="City" inputValue={inputCity} />

      <TextInput
        inputValue={inputCarbonateHardness}
        inputKey={inputKeyCarbonateHardness}
        inputText={carbonateHardnessInfo}
        handleInput={handleInput}
        onlyNumbers={true}
        isRequired={true}
      >
        <Icon
          name="info circle"
          className={iconPlacementStyles.infoIconPlacement}
          onClick={() => {
            setShowHardnessInfo(true);
          }}
          color="blue"
        />
      </TextInput>
      <TextInput
        inputValue={inputTotalHardness}
        inputKey={inputKeyTotalHardness}
        inputText={totalHardnessInfo}
        handleInput={handleInput}
        onlyNumbers={true}
        isRequired={true}
      />
      <TextInput
        inputValue={inputMicroSiemens}
        inputKey={inputKeyMicroSimens}
        inputText={conductivityInfo}
        handleInput={handleInput}
        onlyNumbers={true}
        isRequired={true}
      >
        <PopupWithIcon position="left center">
          <p>Input will be rounded to nearest integer.</p>
        </PopupWithIcon>
      </TextInput>
      <TextInput
        inputValue={inputLocationPhone}
        inputKey={inputKeyPhone}
        inputText="Phone Number (optional)"
        handleInput={handleInput}
      />

      <StockFeatureSelection handleStockSelections={handleStockSelections} selectedStockTypes={selectedStockTypes} />

      <Dialog open={showHardnessInfo} onClose={closeHardnessInfo} maxWidth="md">
        <DialogTitle>Hardness information</DialogTitle>

        <DialogContent>
          <p>
            We've put together this visual guide to help you with choosing what water hardness scale you want to use.
          </p>
          <Image className="hardness-image" src="https://flowplan.dk/FP_Understanding_hard_water.jpg" />
        </DialogContent>
        <DialogActions>
          <FpButton color="secondary" onClick={closeHardnessInfo}>
            Close
          </FpButton>
        </DialogActions>
      </Dialog>

      <Feedback feedbackMessage={newLocationErrorMessage} />
    </AddItemModal>
  );
};

export default NewLocationModal;
