import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { FlowplanProductTypes, HardnessUnits } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { ILocationInfo, ILocationRequestPackage } from '@flowplan/flowplan-shared/lib/locations/locations';
import { IStock } from '@flowplan/flowplan-shared/lib/stock/stock';
import { IResponse, sortByKey } from '@hsjakobsen/utilities';
import { Action, action, Thunk, thunk, ThunkOn, thunkOn } from 'easy-peasy';
import { noSelectionMade } from '../../Models/admin/admin.model';
import { IStoreModel } from '../../Models/index';
import { HardnessEnum } from '../../common/constsFrontend';
import { OptionProp } from '../common/components/FormikSelect/FormikSelect';
import { DashboardInstallationTableRow } from '../dashboard/dashboard-model';
import { convertIDeviceViewToDashboardInstallation } from '../dashboard/dashboard-utils';
import { handleDeleteRequest, handlePostRequest, handlePutRequest } from '../server-requests/server-requests';
import { defaultLocationData, defaultLocationInfo, ILocationExtended, IToggleModalLocationSettings } from './location.types';
import { FilterSofteningType, getWaterHardnessInfo, getWaterHardnessType } from './locations-texts';

export enum defaultNameFieldMode {
  CLIENT = 'client',
  SEARCH = 'search',
}

type GetLocations = {
  flowplanClientId?: number;
  flowplanProductTypeId: number;
};

export interface ExtendedLocationInfo extends ILocationInfo {
  id: string;
  installations: DashboardInstallationTableRow[];
}

type SetLocationDropdown = {
  locations: ILocationExtended[];
  hardnessUnit: HardnessUnits;
  flowplanProductTypeId: FlowplanProductTypes;
};

export interface ILocationsModel {
  createLocation: Thunk<ILocationsModel, ILocationRequestPackage>;
  updateLocation: Thunk<ILocationsModel, ILocationRequestPackage>;
  removeLocation: Thunk<ILocationsModel, number>;

  getLocations: Thunk<ILocationsModel, GetLocations, void, IStoreModel>;
  getLocation: Thunk<ILocationsModel, number, void, IStoreModel>;

  getLocationDataAndStock: Thunk<ILocationsModel, number | undefined, void, IStoreModel>;
  getLocationData: Thunk<ILocationsModel, number | undefined, void, IStoreModel>;

  loadingLocations: boolean;
  setLoadingLocations: Action<ILocationsModel, boolean>;

  waterFilterLocations: ILocationExtended[];
  setLocations: Action<ILocationsModel, SetLocationDropdown>;

  waterFilterLocationsDropdown: OptionProp[];
  setLocationsDropdown: Action<ILocationsModel, SetLocationDropdown>;

  resetModelData: Action<ILocationsModel>;

  // modal section
  toggleNewLocationModal: Thunk<ILocationsModel, IToggleModalLocationSettings, void, IStoreModel>;

  defaultNameField: defaultNameFieldMode;
  setDefaultNameField: Action<ILocationsModel, defaultNameFieldMode>;

  showNewLocationModal: boolean;
  setShowNewLocationModal: Action<ILocationsModel, boolean>;

  locationData: ILocationExtended;
  setLocationData: Action<ILocationsModel, ILocationExtended>;
  stockData: IStock[];
  setStockData: Action<ILocationsModel, IStock[]>;

  getLocationInfo: Thunk<ILocationsModel, number, void, IStoreModel>;
  setLocationInfo: Action<ILocationsModel, ExtendedLocationInfo>;
  locationInfo: ExtendedLocationInfo;
  setLoadingLocationInfo: Action<ILocationsModel, boolean>;
  loadingLocationInfo: boolean;

  onActionsThatUpdateLocations: ThunkOn<ILocationsModel, void, IStoreModel>;

  // TAPS section

  tapLocations: ILocationExtended[];
  tapLocationsDropdown: OptionProp[];

  RemoveTapLocation: Thunk<ILocationsModel, number>;
}

const locationModel: ILocationsModel = {
  tapLocations: [],
  tapLocationsDropdown: [],
  waterFilterLocations: [],
  waterFilterLocationsDropdown: [],

  // Thunk for removing a location, expecting the ID as payload
  RemoveTapLocation: thunk(async (actions, payload: number): Promise<IResponse> => {
    const requestResponse = await handleDeleteRequest({}, `/taplocation/${payload}`);
    return requestResponse;
  }),

  createLocation: thunk(async (actions, payload) => {
    const response: IResponse = await handlePostRequest(payload, Routes.locations, true);
    return response;
  }),

  updateLocation: thunk(async (actions, payload) => {
    const response: IResponse = await handlePutRequest(payload, Routes.locations, true);
    return response;
  }),

  removeLocation: thunk(async (actions, payload) => {
    const requestResponse = await handleDeleteRequest({ id: payload }, Routes.locations);
    return requestResponse;
  }),

  getLocations: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingLocations(true);

    const { flowplanClientId, flowplanProductTypeId } = payload;

    // Ensure no double slashes by conditionally adding flowplanClientId if it's valid
    const route = `${Routes.locations}/${flowplanClientId !== undefined ? flowplanClientId : ''
      }/${flowplanProductTypeId}`;

    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: route.replace(/\/+/g, '/'), // This will replace any double slashes with a single slash
    });

    const companyHardnessUnit = getStoreState().companyModel.companyHardnessUnit;

    if (requestResponse.success) {
      const data: SetLocationDropdown = {
        flowplanProductTypeId,
        hardnessUnit: companyHardnessUnit,
        locations: requestResponse.data,
      };
      actions.setLocations(data);
      actions.setLocationsDropdown(data);
    }
    actions.setLoadingLocations(false);
  }),

  //This doesnt work data doesnt get extracted. Refer to getLocationData for the functionality of getLocation.
  getLocation: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/locationById/' + payload });
    return requestResponse;
  }),

  getLocationData: thunk(async (actions, payload) => {
    if (!payload) {
      actions.setLocationData(defaultLocationData);
      return;
    }

    const locationResponse = await actions.getLocation(payload);
    if (!locationResponse.success) {
      // Handle error case
      actions.setLocationData(defaultLocationData);
      return;
    }

    actions.setLocationData(locationResponse.data);
  }),

  getLocationDataAndStock: thunk(async (actions, payload, { getStoreState, getStoreActions }) => {
    if (!payload) {
      actions.setLocationData(defaultLocationData);
      actions.setStockData([]);
      return;
    }

    const locationResponse = await actions.getLocation(payload);
    if (!locationResponse.success) {
      return;
    }

    actions.setLocationData(locationResponse.data);

    if (!getStoreState().companyModel.isPremium) {
      return;
    }

    const stock: IStock[] = await getStoreActions().stockModel.getStock(payload);

    actions.setStockData(stock);
  }),

  loadingLocations: false,

  setLoadingLocations: action((state, payload) => {
    state.loadingLocations = payload;
  }),
  setLocations: action((state, payload) => {
    const list: ILocationExtended[] = payload.locations;
    sortByKey(list, 'name', true);

    if (payload.flowplanProductTypeId === FlowplanProductTypes.WaterFilter) {
      state.waterFilterLocations = list;
    } else {
      state.tapLocations = list;
    }
  }),
  setLocationsDropdown: action((state, payload) => {
    const { hardnessUnit, locations } = payload;
    const locationsDropdown: OptionProp[] = [];
    locations.forEach((location) => {
      const { name, id, client, hardnessData } = location;
      if (id && hardnessData) {
        const carbonateHardness =
          hardnessData.find((item) => item.hardnessType.name === HardnessEnum.CARBONATE_HARDNESS)?.value || 0;
        const totalHardness =
          hardnessData.find((item) => item.hardnessType.name === HardnessEnum.TOTAL_HARDNESS)?.value || 0;
        const microSiemensPerCm =
          hardnessData.find((item) => item.hardnessType.name === HardnessEnum.MICRO_SIEMENS_PER_CM)?.value || 0;
        let hardnessInfo = '(' + getWaterHardnessInfo({ carbonateHardness, hardnessUnit, totalHardness });

        if (microSiemensPerCm) {
          hardnessInfo +=
            ' / ' +
            microSiemensPerCm +
            getWaterHardnessType({
              filterSofteningType: FilterSofteningType.MicroSiemens,
              hardnessUnit,
              includeLaymansTerm: false,
              spaceInFront: true,
            });
        }

        hardnessInfo += ')';

        let clientName = '';
        if (client) {
          clientName = name.toLowerCase() !== client.name.toLowerCase() ? ' - ' + client.name : '';
        }
        const alternateName = name + ' ' + hardnessInfo + clientName;
        locationsDropdown.push({ name, alternateName, value: id });
      }
    });

    sortByKey(locationsDropdown, 'name', true);
    const itemToAdd: OptionProp = { name: 'Select location', value: -1 };
    locationsDropdown.unshift(itemToAdd);

    if (payload.flowplanProductTypeId === FlowplanProductTypes.WaterFilter) {
      state.waterFilterLocationsDropdown = locationsDropdown;
    } else {
      state.tapLocationsDropdown = locationsDropdown;
    }
  }),

  defaultNameField: defaultNameFieldMode.CLIENT,
  setDefaultNameField: action((state, payload) => {
    state.defaultNameField = payload;
  }),

  // modal section
  showNewLocationModal: false,

  setShowNewLocationModal: action((state, payload) => {
    state.showNewLocationModal = payload;
  }),

  locationData: defaultLocationData,
  setLocationData: action((state, payload) => {
    state.locationData = payload;
  }),

  stockData: [],
  setStockData: action((state, payload) => {
    state.stockData = payload;
  }),

  toggleNewLocationModal: thunk(async (actions, payload) => {
    const { locationId, showModal } = payload;
    actions.setShowNewLocationModal(showModal);
    if (showModal) {
      actions.getLocationDataAndStock(locationId);
    }
  }),

  resetModelData: action((state) => {
    state.waterFilterLocations = [];
    state.waterFilterLocationsDropdown = [];
    state.locationInfo = defaultLocationInfo;
  }),

  getLocationInfo: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    actions.setLoadingLocationInfo(true);

    const requestResponse = await getStoreActions().serverRequestsModel.get({
      route: Routes.LocationInfo,
      params: { locationId: payload },
    });
    if (requestResponse.success) {
      const locationInfo = requestResponse.data as ILocationInfo;
      const firmwareReleases = getStoreState().firmwareReleasesModel.firmwareReleases;
      const extendedTableData = convertIDeviceViewToDashboardInstallation({
        firmwareReleases,
        installations: locationInfo.installations,
      });

      actions.setLocationInfo({
        ...locationInfo,
        id: locationInfo.uuid,
        installations: extendedTableData,
      });
    } else {
      actions.setLocationInfo(defaultLocationInfo);
    }
    actions.setLoadingLocationInfo(false);
  }),

  loadingLocationInfo: false,
  setLoadingLocationInfo: action((state, payload) => {
    state.loadingLocationInfo = payload;
  }),
  locationInfo: defaultLocationInfo,
  setLocationInfo: action((state, payload) => {
    state.locationInfo = payload;
  }),

  onActionsThatUpdateLocations: thunkOn(
    (actions, storeActions) => [
      storeActions.locationsModel.createLocation,
      storeActions.locationsModel.updateLocation,
      storeActions.locationsModel.removeLocation,
      storeActions.stockModel.manageStockUpdates,
      storeActions.stockModel.manageStockCreation,
    ],
    async (actions) => {
      actions.getLocations({
        flowplanClientId: noSelectionMade,
        flowplanProductTypeId: FlowplanProductTypes.WaterFilter,
      });
    },
  ),
};

export default locationModel;
