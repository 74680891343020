import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';

export interface IInstallationInformation {
  externalSensorId: number;
  externalSensorData: string | null;
  externalMeasuringUnit: string;
  externalMatchingEnabled: boolean;
  initialInstallDate: string;
  flowmeterName: string;
}

const collectInstallationInformation = (sensor: IDeviceView): IInstallationInformation => {
  const { infoBasic, infoFlowmeter, infoExternalSensorData } = sensor;
  return {
    externalSensorId: infoExternalSensorData.externalSensorId,
    externalSensorData: infoExternalSensorData.externalSensorData,
    externalMatchingEnabled: infoExternalSensorData.matchingEnabled,
    externalMeasuringUnit: infoExternalSensorData.measurementUnit,
    flowmeterName: infoFlowmeter.flowmeterName,
    initialInstallDate: infoBasic.initialInstallDate,
  };
};

export { collectInstallationInformation };
