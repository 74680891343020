import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import { forwardRef } from 'react';

const FpButtonIcon = forwardRef<HTMLButtonElement, MuiIconButtonProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <MuiIconButton {...rest} ref={ref}>
      {children}
    </MuiIconButton>
  );
});

FpButtonIcon.displayName = 'IconButton';

export default FpButtonIcon;
