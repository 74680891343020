import { FirmwareRelease, FirmwareStatus } from '@flowplan/flowplan-shared/lib/firmware.releases/firmware.releases';
import { HealthState } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { BatteryType } from '@flowplan/flowplan-shared/lib/interfaces/networkEUIToFlowplanSerialDb';
import { defaultHealthStatus } from '../Models/graphSettings';
import { BeamHealthTexts } from '../modules/installations/components/Tasks/tasks-utility-health';

export enum APNs {
    Telia = 'lpwa.telia.iot',
    DeutscheTelekom = 'internet.gma.iot',
    TDC = 'nbiot.tdc.dk',
    ONCE = 'iot.1nce.net',
    None = 'None'
}

export enum HardwareGenerations {
    GenOne = '1.0',
    GenTwo = '2.0',
    GenThree = '3.0',
    GenFour = '4.0'
}

export type NbConfig = {
    gsmNbApn: string;
    simCardIccid: string;
};

export type BeamHealthStatus = {
    shouldBeamBeReplaced: boolean;
    hardwareGeneration: string;
    firmwareHealthy: boolean;
    hardwareHealthy: boolean;
    batteryHealthy: boolean;
    connectivityHealthy: boolean;
    otaHealthy: boolean;
}

type BeamHealthVariables = {
    apn: string;
    batteryTypeId: BatteryType;
    firmware: string;
    firmwareReleases: FirmwareRelease[];
    hardware: string;
}

export const getBeamHealthStatus = ({
    apn,
    batteryTypeId,
    firmware,
    firmwareReleases,
    hardware
}: BeamHealthVariables): BeamHealthStatus => {
    const beamHealthstatusToReturn: BeamHealthStatus = { ...defaultHealthStatus };

    beamHealthstatusToReturn.hardwareGeneration = hardware;

    if (hardware === HardwareGenerations.GenOne || hardware === HardwareGenerations.GenTwo) {
        beamHealthstatusToReturn.shouldBeamBeReplaced = true;
        beamHealthstatusToReturn.hardwareHealthy = false;
    }

    const firmwareRelease = firmwareReleases.find((release) => release.firmware === firmware);
    if (!firmwareRelease || !firmwareRelease.id) {
        beamHealthstatusToReturn.shouldBeamBeReplaced = true;
        beamHealthstatusToReturn.firmwareHealthy = false;
        return beamHealthstatusToReturn;
    }

    // When we have firmware settings OTA, we should use the below line that is commented out. Henrikj 2024.12.02
    // if (apn === APNs.None || (apn !== APNs.DeutscheTelekom && apn !== APNs.ONCE && apn !== APNs.TDC && apn !== APNs.Telia)) {
    if (apn !== APNs.None && apn !== APNs.DeutscheTelekom && apn !== APNs.ONCE && apn !== APNs.TDC && apn !== APNs.Telia) {
        beamHealthstatusToReturn.shouldBeamBeReplaced = true;
        beamHealthstatusToReturn.connectivityHealthy = false;
    }

    if (hardware === HardwareGenerations.GenThree) {
        if (batteryTypeId === BatteryType.SAFT) {
            beamHealthstatusToReturn.shouldBeamBeReplaced = true;
            beamHealthstatusToReturn.batteryHealthy = false;
        }

        if (firmwareRelease.statusGenThree !== FirmwareStatus.Supported) {
            beamHealthstatusToReturn.shouldBeamBeReplaced = true;
            beamHealthstatusToReturn.firmwareHealthy = false;
        } else {

            if (firmwareRelease.id < 62 && apn !== APNs.Telia) {
                beamHealthstatusToReturn.shouldBeamBeReplaced = true;
                beamHealthstatusToReturn.connectivityHealthy = false;
            }
        }

    } else if (hardware === HardwareGenerations.GenFour) {
        if (firmwareRelease.id < 62 && apn !== APNs.Telia) {
            beamHealthstatusToReturn.shouldBeamBeReplaced = true;
            beamHealthstatusToReturn.connectivityHealthy = false;
        }
    }

    if (firmwareRelease.id < 62) {
        beamHealthstatusToReturn.otaHealthy = false;
    }

    return beamHealthstatusToReturn;
}

type GetHealthStateText = {
    healthState: HealthState;
    hardwareGeneration: string;
    short: boolean;
}

export const getHealthStateText = ({ hardwareGeneration, healthState, short }: GetHealthStateText): string => {
    let feedback = '';
    switch (healthState) {
        case HealthState.OTAMissing:
            if (short) {
                return BeamHealthTexts.BeamHealthOtaMissing;
            }

            feedback = BeamHealthTexts.BeamHealthGood;
            if (hardwareGeneration === HardwareGenerations.GenThree) {
                feedback += `${BeamHealthTexts.OptionalGenThree}`;
            } else if (hardwareGeneration === HardwareGenerations.GenFour) {
                feedback += `${BeamHealthTexts.OptionalGenFour}`;
            }
            return feedback;

        case HealthState.HasIssue:
            if (short) {
                return BeamHealthTexts.BeamHealthNotSupported;
            }

            if (hardwareGeneration === HardwareGenerations.GenThree) {
                feedback = BeamHealthTexts.IssueGenThree;
            } else if (hardwareGeneration === HardwareGenerations.GenFour) {
                feedback = BeamHealthTexts.IssueGenFour;
            } else {
                feedback = BeamHealthTexts.IssueGenThree;
            }
            return feedback;

        case HealthState.NotSupported:
            if (short) {
                return BeamHealthTexts.BeamHealthNotSupported;
            }

            if (hardwareGeneration === HardwareGenerations.GenThree) {
                feedback = BeamHealthTexts.IssueGenThree;
            } else if (hardwareGeneration === HardwareGenerations.GenFour) {
                feedback = BeamHealthTexts.IssueGenFour;
            } else {
                feedback = BeamHealthTexts.IssueGenThree;
            }
            return feedback;

        default:
            if (short) {
                return BeamHealthTexts.BeamHealthGood;
            }
            return BeamHealthTexts.BeamHealthGoodLong;
    }
};

export type HealthStateColors = 'secondary' | 'success' | 'error' | 'info' | 'warning';

export const getHealthStateColor = (healthState: HealthState): HealthStateColors => {
    switch (healthState) {
        case HealthState.OTAMissing:
            return 'secondary'

        case HealthState.HasIssue:
        case HealthState.NotSupported:
            return 'info'

        default:
            return 'success';
    }
}

export const healthIsOnlyBatteryChange = (healthStatus: BeamHealthStatus): boolean => {
    const {
        batteryHealthy,
        connectivityHealthy,
        firmwareHealthy,
        hardwareHealthy,
        otaHealthy
    } = healthStatus;

    return !batteryHealthy && connectivityHealthy && firmwareHealthy && hardwareHealthy && otaHealthy;
}
export const healthIsOnlyOtaUpdateMissing = (healthStatus: BeamHealthStatus): boolean => {
    const {
        batteryHealthy,
        connectivityHealthy,
        firmwareHealthy,
        hardwareHealthy,
        otaHealthy
    } = healthStatus;

    return batteryHealthy && connectivityHealthy && firmwareHealthy && hardwareHealthy && !otaHealthy;
}

export const healthBeamHasIssue = (healthStatus: BeamHealthStatus): boolean => {
    const {
        batteryHealthy,
        connectivityHealthy,
        firmwareHealthy,
        hardwareHealthy,
    } = healthStatus;

    return !batteryHealthy || !connectivityHealthy || !firmwareHealthy || !hardwareHealthy;
}