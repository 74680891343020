import { IResponse } from '@hsjakobsen/utilities';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useSnackbar, VariantType } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../../../Store';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import FpText from '../../../common/components/FpText/FpText';
import HealthWarning, { HealthWarningInfo } from '../../../inventory/components/HealthWarning/HealthWarning';
import { Paths } from '../../../navigation/containers/Navigation/navigation-types';
import InstallationInformationPerformMaintenance from '../Options/InstallationInformationPerformMaintenance';

interface Props {
  featureEnabled: boolean;
  alwaysLeftAlign?: boolean;
}
const InstallationMaintenance = (props: Props): JSX.Element => {
  const { alwaysLeftAlign } = props;

  const access = useStoreState((state) => state.authModel.access);
  const selectedInstallation = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const selectedInstallationHealthStatus = useStoreState(
    (state) => state.graphSettingsModel.selectedInstallationHealthStatus,
  );

  const performMaintenance = useStoreActions((actions) => actions.deviceActionsModel.performMaintenance);
  const getFilterDetailsData = useStoreActions((actions) => actions.deviceActionsModel.getFilterDetailsData);

  const toggleFlexibleModal = useStoreActions((actions) => actions.modalModel.toggleFlexibleModal);

  const [showHealthWarning, setShowHealthWarning] = useState<HealthWarningInfo>({
    ignore: false,
    show: false,
  });

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const RequestPerformMaintenance = async () => {
    const title = (
      <>
        <FpText componentType="span" variant="h6">
          Perform maintenance for:{' '}
        </FpText>
        <FpText componentType="span" variant="h6" className="do-not-translate">
          {selectedInstallation.infoBasic.name}
        </FpText>
      </>
    );
    const content = (
      <InstallationInformationPerformMaintenance
        infoBasic={selectedInstallation.infoBasic}
        infoService={selectedInstallation.infoService}
      />
    );
    toggleFlexibleModal({
      content,
      title,
      showModal: true,
      requireInput: true,
      confirmCallback: confirmMaintenancePerformed,
    });
  };

  const confirmMaintenancePerformed = async () => {
    const maintenanceResponse: IResponse = await performMaintenance();
    if (maintenanceResponse.success) {
      const detailsResponse = await getFilterDetailsData();
      if (!detailsResponse) {
        navigate(Paths.installations);
        return;
      }
    }

    const feedback = maintenanceResponse.success
      ? 'Maintenance performed with note: ' + maintenanceResponse.extraData.note
      : 'Error: ' + maintenanceResponse.message;
    const variant: VariantType = maintenanceResponse.success ? 'success' : 'error';
    enqueueSnackbar(feedback, { variant });

    const { shouldBeamBeReplaced } = selectedInstallationHealthStatus;

    if (shouldBeamBeReplaced) {
      setShowHealthWarning({
        ignore: false,
        show: true,
      });
      return;
    }
  };

  return (
    <>
      <FpButtonOutlinedAligned
        alwaysLeftAlign={alwaysLeftAlign}
        disabled={!props.featureEnabled || !access.installations.manage}
        className="orangeButton"
        icon={<SyncAltIcon />}
        onClick={RequestPerformMaintenance}
        label="Perform Maintenance"
      />
      <HealthWarning
        goodActionLabel="Do not perform maintenance"
        onlyShowOneButton={true}
        choiceSelection={(info) => {
          setShowHealthWarning(info);
        }}
        choiceSelectionInfo={showHealthWarning}
        healthStatus={selectedInstallationHealthStatus}
        healthState={selectedInstallation.infoHealth.healthState}
      />
    </>
  );
};

export default InstallationMaintenance;
