import { getDateObject } from "../../../Utility/time";
import { ReportQuarters } from "./reports-model";

export const getQuarterPeriod = (quarterTitle: string): {
    quarterPeriod: ReportQuarters;
    quarterNumber: number;
} => {
    switch (quarterTitle) {
        case "Q1":
            return {
                quarterNumber: 1,
                quarterPeriod: ReportQuarters.Q1
            }
        case "Q2":
            return {
                quarterNumber: 2,
                quarterPeriod: ReportQuarters.Q2,
            };
        case "Q3":
            return {
                quarterNumber: 3,
                quarterPeriod: ReportQuarters.Q3
            };
        case "Q4":
            return {
                quarterNumber: 4,
                quarterPeriod: ReportQuarters.Q4
            };
    }

    return {
        quarterNumber: 1,
        quarterPeriod: ReportQuarters.Q1
    };
}

export const getCurrentQuarterFromDate = (): ReportQuarters => {
    const currentQuarter = getDateObject(null).quarter() - 1;
    if (currentQuarter === 0) {
        return ReportQuarters.Q4;
    }

    switch (currentQuarter) {
        case 1:
            return ReportQuarters.Q1;
        case 2:
            return ReportQuarters.Q2;
        case 3:
            return ReportQuarters.Q3;
        case 4:
            return ReportQuarters.Q4;
    }
    return ReportQuarters.Q1;
}


export const getCurrentYearFromDate = (): number => {
    const currentQuarter = getDateObject(null).quarter() - 1;
    const currentYear = getDateObject(null).year();
    if (currentQuarter === 0) {
        return currentYear - 1;
    }

    return currentYear;
}