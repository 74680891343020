import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IDeviceDataStatus } from '@flowplan/flowplan-shared/lib/interfaces/deviceDb';
import { ISimpleLineData } from '@flowplan/flowplan-shared/lib/interfaces/installationView';
import { dateFormatYMD, timeFormatHM } from '@hsjakobsen/utilities/lib/date';
import { getDateObject } from '../Utility/time';
import { getStartAndEndDateSelection } from '../Utility/utilitiesDate';
import { GraphDomainAndTick } from './deviceData';

export const getDataForDate = (dateForData: string, dataToFilter: IDeviceDataStatus[]): IDeviceDataStatus[] => {
  const dataToReturn: IDeviceDataStatus[] = [];
  if (dataToFilter !== undefined) {
    dataToFilter.forEach((dataEntry) => {
      if (getDateObject(dataEntry.date).format(dateFormatYMD) === dateForData) {
        dataToReturn.push(dataEntry);
      }
    });
  }

  return dataToReturn;
};

export const parseTypeToReadable = (typeName: string): string => {
  switch (typeName) {
    case 'eventsWater':
      return 'Water';
    case 'eventsCoffee':
      return 'Coffee';
    case 'eventsEspresso':
      return 'Espresso';
  }

  return '';
};

type RouteForGraphDataInput = {
  route: Routes;
  installationId: number;
  selectedOption: number;
}

export const getRouteForGraphData = ({ installationId, route, selectedOption }: RouteForGraphDataInput): string => {
  const dates = getStartAndEndDateSelection(selectedOption);
  const requestRoute = route + installationId + '/' + dates.startDate + '/' + dates.endDate;
  return requestRoute;
}

export const getDomainAndTick = (maxValueToUse: number): GraphDomainAndTick => {
  const maxFactor = maxValueToUse < 100 ? 10 : 100;
  let maxDomainValue = Math.ceil(maxValueToUse / maxFactor) * maxFactor;
  let fixedNumber = 0;
  if (maxDomainValue === 0) {
    maxDomainValue = 10;
  }

  if (maxDomainValue === 10) {
    fixedNumber = 1;
  }

  if (maxValueToUse < 1) {
    maxDomainValue = 1;
    fixedNumber = 2;
  }

  const tickIncrementValue = Number((maxDomainValue / 4).toFixed(fixedNumber));

  return {
    domain: [0, maxDomainValue],
    yAxisTicks: [
      tickIncrementValue,
      tickIncrementValue * 2,
      tickIncrementValue * 3,
      tickIncrementValue * 4,
    ]
  }
}
export const parseDataForGraph = (data: ISimpleLineData[]): ISimpleLineData[] => {
  const parsedData: ISimpleLineData[] = data.map((item) => {
    const date = getDateObject(item.date).format(dateFormatYMD + ' - ' + timeFormatHM);
    return { date, value: item.value, extraValue: item.extraValue };
  });

  return parsedData;
}