import { SxProps, Theme } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { ChangeEvent, FocusEvent } from 'react';

type Props = {
  /**
   * Specifies the autoComplete attribute of the input element.
   * @default "off"
   */
  autoComplete?: string;
  /**
   * Label for the text field.
   */
  label?: string;
  /**
   * Function to handle input changes.
   */
  handleBlur?: (e: FocusEvent) => void;
  /**
   * Restricts input to only numeric values.
   * @default false
   */
  onlyNumbers?: boolean;
  /**
   * Extra information when using the textfield.
   */
  helperText?: string;
  /**
   * Text to be displayed as the label of the input field.
   */
  inputText?: string;
  /**
   * Value of the input field.
   */
  isRequired?: boolean;
  /**
   * Unique key for the input field.
   */
  inputKey: string;
  /**
   * Maximum length of the input field.
   */
  maxLength?: number;
  /**
   * Placeholder text for the input field.
   */
  placeholder?: string;
  /**
   * Type of the input field.
   * @default "text"
   */
  type?: string;
  /**
   * Specifies whether the input field should auto-focus.
   * @default false
   */
  shouldAutoFocus?: boolean;
  /**
   * Specifies whether the input field is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * Style object for the input field.
   */
  sx?: SxProps<Theme>;
  /**
   * Variant of the input field.
   */
  variant: 'standard' | 'filled' | 'outlined';
  /**
   * Whether the text field should take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Defines margin styles for the text field.
   */
  margin?: TextFieldProps['margin'];
  /**
   * Function to handle input changes.
   */
  handleInput(e: ChangeEvent<HTMLInputElement>, inputKey: string): void;
  /**
   * Function to handle key press events.
   */
  handleKeyPress?(e: React.KeyboardEvent): void;
  /**
   * Restricts input to only numeric values.
   * @default false
   */

  /**
   * Value of the input field.
   */
  inputValue?: string | number | null | undefined;
  /**
   * Specifies whether the input field is required.
   * @default false
   */
  /**
   * If `true`, the label is displayed in an error state.
   * @default false
   */
  error?: boolean;
  /**
   * The size of the component.
   */
  size?: 'small' | 'medium';
  inputRef?: React.Ref<any>;
  handleFocus?(e: React.FocusEvent): void;
};

/**
 * A customizable text field component for input with various configurations.
 *
 * @param {Props} props - The properties object.
 * @returns {JSX.Element} The rendered component.
 */
const FpTextField = ({
  autoComplete = 'off',
  handleKeyPress,
  inputText,
  inputValue,
  handleInput,
  onlyNumbers = false,
  helperText = '',
  isRequired = false,
  inputKey,
  maxLength,
  placeholder,
  type = 'text',
  shouldAutoFocus = false,
  disabled = false,
  sx,
  variant,
  fullWidth = false,
  margin,
  error = false,
  handleBlur,
  label,
  size,
  inputRef,
  handleFocus
}: Props): JSX.Element => {
  const patternRestriction = onlyNumbers ? '[0-9]*' : undefined;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleInput(e, inputKey);
  };

  return (
    <TextField
      inputRef={inputRef}
      size={size}
      id={inputKey}
      name={inputKey}
      label={label ?? inputText}
      variant={variant}
      sx={sx}
      autoComplete={autoComplete}
      disabled={disabled}
      value={inputValue}
      helperText={helperText}
      placeholder={placeholder}
      inputProps={{ maxLength, pattern: patternRestriction }}
      type={type}
      autoFocus={shouldAutoFocus}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      required={isRequired}
      fullWidth={fullWidth}
      margin={margin}
      error={error}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
}

FpTextField.displayName = 'FpTextField';

export default FpTextField;
