import SearchIcon from '@mui/icons-material/Search';
import { FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { getHealthStateColor } from '../../../../hooks/BeamHealthStatus';
import HealthStateSimple from '../../../installations/components/HealthStatus/HealthStateSimple';
import { OptionProp } from '../FormikSelect/FormikSelect';

type ContainsText = {
  text: string;
  searchText: string;
  removeWhiteSpace: boolean;
};

const containsText = ({ removeWhiteSpace, searchText, text }: ContainsText) => {
  const textToSearch = removeWhiteSpace ? text.replaceAll(' ', '') : text;
  const searchStringToUse = removeWhiteSpace ? searchText.replaceAll(' ', '') : searchText;
  return textToSearch.toLowerCase().indexOf(searchStringToUse.toLowerCase()) > -1;
};

type Props = {
  label: string;
  options: OptionProp[];
  removeWhiteSpaceWhenSearching?: boolean;
  hideSearchBar?: boolean;
  selectedValue: string | number;
  setSelectionValue(value: string | number): void;
  isDisabled?: boolean;
  size?: 'small' | 'medium';
  translateOptions?: boolean
};

const FpSelectSearch = ({
  label,
  options,
  hideSearchBar = false,
  selectedValue,
  setSelectionValue,
  removeWhiteSpaceWhenSearching = false,
  isDisabled,
  size = 'medium',
  translateOptions = true
}: Props) => {
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);
  const displayedOptions = useMemo(
    () =>
      options.filter((option) =>
        containsText({
          removeWhiteSpace: removeWhiteSpaceWhenSearching,
          text: option.name,
          searchText,
        }),
      ),
    [removeWhiteSpaceWhenSearching, searchText, options],
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="search-select-label" size={size === 'small' ? 'small' : 'normal'}>
        {label}
      </InputLabel>
      <Select
        // Disables auto focus on MenuItems and allows TextField to be in focus
        open={open}
        size={size}
        onOpen={() => setOpen(true)}
        MenuProps={{ autoFocus: false }}
        labelId="search-select-label"
        id="search-select"
        className={!translateOptions ? 'do-not-translate' : ''}
        value={selectedValue}
        label={label}
        disabled={isDisabled || false}
        onChange={(e) => {
          setSelectionValue(e.target.value as string);
        }}
        onClose={() => {
          setSearchText('');
          setOpen(false);
        }}
        // This prevents rendering empty string in Select's value
        // if search text would exclude currently selected option.
        renderValue={() => {
          const itemSelected = options.find((item) => item.value === selectedValue);
          return itemSelected?.alternateName ? itemSelected.alternateName : itemSelected?.name || 'N/A';
        }}
      >
        {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
        {!hideSearchBar && (
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    if (displayedOptions.length > 0 && displayedOptions.length < 3) {
                      const selectedItem = displayedOptions[0];
                      setSelectionValue(selectedItem.value);
                      setOpen(false);
                    }
                  }
                }
              }}
            />
          </ListSubheader>
        )}
        {displayedOptions.map((option, i) => {
          const color = option.extra ? getHealthStateColor(option.extra) : 'success';
          // extra value is only used for serials and healthState information

          return (
            <MenuItem key={i} value={option.value} className={!translateOptions ? 'do-not-translate' : ''}>
              {option.extra ? <HealthStateSimple color={color} text="" /> : undefined}
              {option.extra ? ' ' : undefined}
              {option.alternateName ? option.alternateName : option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FpSelectSearch;
