import logo from '../../../../assetstore/logo/flowplan-large.png';
import FpButton from '../FpButton/FpButton';
import FpContainer from '../FpContainer/FpContainer';
import FpDivider from '../FpDivider/FpDivider';
import FpGrid from '../FpGrid/FpGrid';
import FpGridItem from '../FpGrid/FpGridItem';
import FpLoader from '../FpLoader/FpLoader';
import FpText from '../FpText/FpText';

interface Props {
  hasCheckedCache: boolean;
  serverVersion: string;
  serverVersionDescription: string;
  showReload: boolean;
}

const CacheBusterInfo = (props: Props): JSX.Element => {
  const { hasCheckedCache, serverVersion, serverVersionDescription, showReload } = props;
  const currentVersion = import.meta.env.VITE_APP_VERSION;
  const serverNotavailable = serverVersion === 'Server unavailable';

  const actionsGridPropsTopRow = { xs: 12, sm: 12, md: 12, lg: 12 };

  return (
    <FpContainer sx={{ textAlign: 'center' }}>
      <img src={logo} className="App-logo" alt="logo" />
      <FpText variant="h4">
        {showReload ? 'New update ready' : !hasCheckedCache ? 'Checking version' : 'Server connection status'}
      </FpText>
      <FpDivider hidden />
      <FpContainer basic>
        {hasCheckedCache && (
          <FpGrid spacing={{ sm: 0.5 }} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
            {!serverNotavailable && (
              <FpGridItem {...actionsGridPropsTopRow}>
                <FpText variant="h5">Your version</FpText>
                <FpText variant="h5" sx={{ color: 'orange', marginTop: '0.5rem', marginBottom: '1rem' }}>
                  {currentVersion}
                </FpText>
              </FpGridItem>
            )}

            <FpGridItem {...actionsGridPropsTopRow}>
              {!serverNotavailable && <FpText variant="h5">Server version</FpText>}
              <FpText
                variant="h5"
                sx={{ color: showReload ? 'green' : 'red', marginTop: '0.5rem', marginBottom: '1rem' }}
              >
                {serverVersion}
              </FpText>
            </FpGridItem>
            {!serverNotavailable && (
              <FpGridItem
                {...actionsGridPropsTopRow}
                sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}
              >
                <FpText
                  variant="h5"
                  sx={{ marginTop: '0.5rem', marginBottom: '1rem', maxWidth: '200px', textAlign: 'center' }}
                >
                  Update details
                </FpText>
                <FpText
                  variant="body1"
                  sx={{ marginTop: '0.5rem', marginBottom: '1rem', maxWidth: '200px', textAlign: 'center' }}
                >
                  Please read more about the new release in our news section. You can find it in the top right of our
                  solution, it is next to your account settings.
                </FpText>
              </FpGridItem>
            )}
            <FpGridItem {...actionsGridPropsTopRow}>
              {showReload && (
                <FpButton
                  onClick={() => {
                    if (caches) {
                      // Service worker cache should be cleared with caches.delete()
                      caches.keys().then((names) => {
                        for (const name of names) caches.delete(name);
                      });
                    }
                    // delete browser cache and hard reload
                    window.location.reload();
                  }}
                  size="large"
                  color="success"
                >
                  Update to latest release
                </FpButton>
              )}
              {!showReload && (
                <FpText variant="h5" sx={{ color: 'warning', marginTop: '2rem' }}>
                  Try again later
                </FpText>
              )}
            </FpGridItem>
          </FpGrid>
        )}
      </FpContainer>
      {!hasCheckedCache && <FpLoader loading={!hasCheckedCache} variant="h5" text="Verifying Backoffice release" />}
    </FpContainer>
  );
};

export default CacheBusterInfo;
