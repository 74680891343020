import { memo, ReactNode } from 'react';
import FpText from '../../../common/components/FpText/FpText';
import './DetailsSection.scss';

interface IDetailsSection {
  children?: ReactNode;
  sectionTitle: string;
}

const DetailsSection = memo(({ children, sectionTitle }: IDetailsSection) => {
  return (
    <div className="details-section">
      <div className="details-title">
        <FpText variant="h5">{sectionTitle}</FpText>
      </div>
      <div className="section-content">{children}</div>
    </div>
  );
});

export default DetailsSection;
