import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  title: string;
  children: ReactNode;
  minHeight?: string;
  elevation?: number;
  expanded?: boolean;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
};

const FpAccordion = ({ children, minHeight = '0', title, elevation = 1, onChange }: Props) => {
  return (
    <Accordion elevation={elevation} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ fontWeight: 'bold' }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails sx={{ minHeight }}>{children}</AccordionDetails>
    </Accordion>
  );
};

FpAccordion.displayName = 'Accordion';

export default FpAccordion;
