import { ChangeEvent } from 'react';
import ComponentRow from '../../../../../../Components/Containers/ComponentRow';
import InputComponentCanDisable from '../../../../../../Components/Input/InputComponentCanDisable';
import TextInput from '../../../../../../Components/Input/TextInput';
import { useStoreActions, useStoreState } from '../../../../../../Store';
import { checkNumbersOnlyRestriction } from '../../../../../../Utility/Texts';
import { DeviceTypes } from '../../../../../../common/constsCategoryAndTypes';
import { InputKeys } from '../../../../../../common/constsFrontend';

const FlowplanMaintenanceLiters = (): JSX.Element => {
  const inputSelectedType = useStoreState((state) => state.newInstallationModel.inputSelectedType);
  const inputServiceAtLiters = useStoreState((state) => state.newInstallationModel.inputServiceAtLiters);

  const setInputServiceAtLiters = useStoreActions((actions) => actions.newInstallationModel.setInputServiceAtLiters);

  return (
    <ComponentRow>
      <InputComponentCanDisable
        isDisabled={inputSelectedType === DeviceTypes.NoSelection}
        inputValue="No device type selected"
        inputText="Consumption maintenance (L)"
      >
        <TextInput
          inputValue={inputServiceAtLiters}
          inputKey={InputKeys.ServiceMaintenanceAtLiters}
          inputText="Consumption maintenance (L)*"
          handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
            const value = ev.target.value;
            const newMaintenanceAtLiters = checkNumbersOnlyRestriction(ev) ? value : Number(inputServiceAtLiters);
            setInputServiceAtLiters(newMaintenanceAtLiters);
          }}
          onlyNumbers={true}
          isRequired={true}
        />
      </InputComponentCanDisable>
    </ComponentRow>
  );
};

export default FlowplanMaintenanceLiters;
