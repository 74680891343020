import L, { Icon } from 'leaflet';
import { iconFlowplan } from '../../dashboard/components/Map.Markers/MarkerIcons';
import shadow from './marker-shadow.png';
import offlineLong from './offline-long.png';
import offlineMedium from './offline-medium.png';
import offlineShort from './offline-short.png';
import offlineVeryLong from './offline-very-long.png';
import onlineGood from './online-good.png';
import pending from './pending.png';

export enum FaultyDeviceOverviewMarkerStatus {
  short = 1,
  medium = 2,
  long = 3,
  veryLong = 4,
  online = 5,
  pending = 6,
  flowplan = 7
}

export const getFaultyDeviceMarkerIcon = (markerStatus: FaultyDeviceOverviewMarkerStatus): Icon => {
  if (markerStatus === FaultyDeviceOverviewMarkerStatus.flowplan) {
    return iconFlowplan;
  }

  if (markerStatus === FaultyDeviceOverviewMarkerStatus.pending) {
    return iconPending;
  }
  if (markerStatus === FaultyDeviceOverviewMarkerStatus.online) {
    return iconGood;
  }

  if (markerStatus === FaultyDeviceOverviewMarkerStatus.short) {
    return iconShort;
  }

  if (markerStatus === FaultyDeviceOverviewMarkerStatus.medium) {
    return iconMedium;
  }

  if (markerStatus === FaultyDeviceOverviewMarkerStatus.long) {
    return iconLong;
  }

  if (markerStatus === FaultyDeviceOverviewMarkerStatus.veryLong) {
    return iconVeryLong;
  }

  return iconShort;
};

export const iconPending = new L.Icon({
  iconUrl: pending,
  iconRetinaUrl: pending,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const iconGood = new L.Icon({
  iconUrl: onlineGood,
  iconRetinaUrl: onlineGood,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const iconShort = new L.Icon({
  iconUrl: offlineShort,
  iconRetinaUrl: offlineShort,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const iconMedium = new L.Icon({
  iconUrl: offlineMedium,
  iconRetinaUrl: offlineMedium,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const iconLong = new L.Icon({
  iconUrl: offlineLong,
  iconRetinaUrl: offlineLong,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export const iconVeryLong = new L.Icon({
  iconUrl: offlineVeryLong,
  iconRetinaUrl: offlineVeryLong,
  shadowUrl: shadow,

  className: '',
  iconAnchor: [12, 41],
  iconSize: [25, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
