import { IInfoBasic, IInfoService } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { numberWithCommas } from '@hsjakobsen/utilities';
import { useEffect } from 'react';
import { Divider, Input } from 'semantic-ui-react';
import { useStoreActions, useStoreState } from '../../../../Store';

interface IInstallationInformationPerformMaintenance {
  infoBasic: IInfoBasic;
  infoService: IInfoService;
}

const InstallationInformationPerformMaintenance = ({
  infoBasic,
  infoService,
}: IInstallationInformationPerformMaintenance): JSX.Element => {
  const deviceIdToShow = infoBasic.flowplanDeviceId.substring(6);

  const confirmValue = infoBasic.name + ' (' + deviceIdToShow + ')';

  const litersRemaining = numberWithCommas((infoService.serviceAtLiters - infoService.serviceUsage).toFixed(0));
  const litersBetweenMaintenance = numberWithCommas(infoService.serviceAtLiters.toFixed(0));

  const maintenanceDescription = useStoreState((state) => state.deviceActionsModel.maintenanceDescription);
  const setFlexibleConfirmDisabled = useStoreActions((state) => state.modalModel.setFlexibleConfirmDisabled);
  const setMaintenanceDescription = useStoreActions((state) => state.deviceActionsModel.setMaintenanceDescription);

  useEffect(() => {
    setFlexibleConfirmDisabled(maintenanceDescription.length <= 0);
  }, [maintenanceDescription, setFlexibleConfirmDisabled]);

  return (
    <div>
      <p className="center-text">You're about to perform maintenance on the following installation:</p>
      <p className="center-text do-not-translate">
        <strong>{confirmValue}</strong>
      </p>
      <Divider />
      <p className="center-text">Installation maintenance status:</p>
      <p className="center-text">
        Liters remaining: <strong>{litersRemaining}L</strong>
      </p>
      <p className="center-text">
        Liters between maintenance: <strong>{litersBetweenMaintenance}L</strong>
      </p>
      <Divider />
      <p className="center-text">
        <strong>Add description of maintenance (max 200 characters)</strong>
      </p>
      <Input
        value={maintenanceDescription}
        size="large"
        maxLength={200}
        className="center-text"
        onChange={(event, data) => {
          const prunedNote = data.value.replace(/'/g, '').replace(/"/g, '');
          setMaintenanceDescription(prunedNote);
        }}
      />
    </div>
  );
};

export default InstallationInformationPerformMaintenance;
