import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import { useSnackbar, VariantType } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { healthIsOnlyBatteryChange } from '../../../../hooks/BeamHealthStatus';
import { useStoreActions, useStoreState } from '../../../../Store';
import { getDateObject } from '../../../../Utility/time';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import FpText from '../../../common/components/FpText/FpText';
import HealthWarning, { HealthWarningInfo } from '../../../inventory/components/HealthWarning/HealthWarning';
import { Paths } from '../../../navigation/containers/Navigation/navigation-types';
import InstallationInformationChangeBattery from '../Options/InstallationInformationChangeBattery';

interface Props {
  featureEnabled: boolean;
  alwaysLeftAlign?: boolean;
}

const InstallationChangeBattery = (props: Props): JSX.Element => {
  const { alwaysLeftAlign } = props;

  const access = useStoreState((state) => state.authModel.access);
  const selectedInstallation = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const selectedInstallationHealthStatus = useStoreState(
    (state) => state.graphSettingsModel.selectedInstallationHealthStatus,
  );

  const changeBattery = useStoreActions((actions) => actions.deviceActionsModel.changeBattery);
  const getFilterDetailsData = useStoreActions((actions) => actions.deviceActionsModel.getFilterDetailsData);
  const setBatteryChangeDate = useStoreActions((state) => state.deviceActionsModel.setBatteryChangeDate);
  const confirmSimpleToggle = useStoreActions((actions) => actions.modalModel.confirmSimpleToggle);

  const [showHealthWarning, setShowHealthWarning] = useState<HealthWarningInfo>({
    ignore: false,
    show: false,
  });

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const requestBatteryChange = async () => {
    const today = getDateObject(null);
    setBatteryChangeDate(today);
    const content = <InstallationInformationChangeBattery />;
    const title = (
      <>
        <FpText componentType="span" variant="h6">
          Change battery for:{' '}
        </FpText>
        <FpText componentType="span" variant="h6" className="do-not-translate">
          {selectedInstallation.infoBasic.name}
        </FpText>
      </>
    );
    confirmSimpleToggle({
      content,
      title,
      showModal: true,
      confirmCallback: () => {
        const { shouldBeamBeReplaced } = selectedInstallationHealthStatus;
        const onlyBatteryChange = healthIsOnlyBatteryChange(selectedInstallationHealthStatus);

        if (shouldBeamBeReplaced && !onlyBatteryChange) {
          setShowHealthWarning({
            ignore: false,
            show: true,
          });
          return;
        }
        choiceConfirmed();
      },
    });
  };

  const choiceConfirmed = async () => {
    const changeBatteryResponse = await changeBattery();
    if (changeBatteryResponse.success) {
      const detailsResponse = await getFilterDetailsData();
      if (!detailsResponse) {
        navigate(Paths.installations);
        return;
      }
    }

    const feedback = changeBatteryResponse.success ? 'Battery changed' : 'Error: ' + changeBatteryResponse.data;
    const variant: VariantType = changeBatteryResponse.success ? 'success' : 'error';
    enqueueSnackbar(feedback, { variant });
  };

  return (
    <>
      <FpButtonOutlinedAligned
        alwaysLeftAlign={alwaysLeftAlign}
        className="purpleButton"
        disabled={!props.featureEnabled || !access.installations.manage}
        icon={<Battery1BarIcon />}
        onClick={requestBatteryChange}
        label="Change battery"
      />
      <HealthWarning
        goodActionLabel="Do not change battery"
        onlyShowOneButton={false}
        choiceSelection={(info) => {
          setShowHealthWarning(info);
          if (info.ignore) {
            choiceConfirmed();
          }
        }}
        choiceSelectionInfo={showHealthWarning}
        healthStatus={selectedInstallationHealthStatus}
        healthState={selectedInstallation.infoHealth.healthState}
      />
    </>
  );
};

export default InstallationChangeBattery;
