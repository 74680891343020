import AddCircleIcon from '@mui/icons-material/AddCircle';
import SendIcon from '@mui/icons-material/Send';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  GridDensity,
  GridEventListener,
  GridState,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiEventHandler,
} from '@mui/x-data-grid-premium';
import { ReactNode } from 'react';
import TapStockNoInventoryAvailableTooltip from '../../../taps/components/TapStockNoInventoryAvailableTooltip';
import FpButton from '../FpButton/FpButton';
import styles from './DataGridTableCustomToolbar.module.scss';

export interface DataGridTableCustomToolbarBarProps {
  density: boolean;
  column: boolean;
  showQuickFilter: boolean;
  filters: boolean;
  addButtonFunction: () => void;
  addButtonLabel: string;
  addButtonLabelAlternate: string;
  addButtonShow: boolean;
  addButtonDisabled: boolean;
  extraButtonFunction: () => void;
  extraButtonLabel: string;
  extraButtonLabelAlternate: string;
  extraButtonShow: boolean;
  extraButtonDisabled: boolean;
  syncState?: (stateToSave: GridState) => void;
  storeDensitySetting?: (density: GridDensity) => void;
  showExportButton: boolean;
  showExtraContent?: boolean;
  extraContent?: ReactNode;
}

const DataGridTableCustomToolbar = (props: DataGridTableCustomToolbarBarProps): JSX.Element => {
  const {
    column,
    density,
    filters,
    showQuickFilter,
    addButtonFunction,
    addButtonLabel,
    addButtonLabelAlternate,
    addButtonShow,
    addButtonDisabled,
    extraButtonLabel,
    extraButtonLabelAlternate,
    extraButtonFunction,
    extraButtonShow,
    extraButtonDisabled,
    syncState,
    storeDensitySetting,
    showExportButton = true,
    showExtraContent = false,
    extraContent = '',
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const apiRef = useGridApiContext();

  const handleStateChange: GridEventListener<'stateChange'> = () => {
    if (syncState !== undefined) {
      syncState(apiRef.current.state);
    }

    if (storeDensitySetting !== undefined) {
      storeDensitySetting(apiRef.current.state.density.value);
    }
  };

  useGridApiEventHandler(apiRef, 'stateChange', handleStateChange);

  const addButtonLabelToShow = !isSmallScreen ? addButtonLabel : addButtonLabelAlternate;
  const externalTasksLabelToShow = !isSmallScreen ? extraButtonLabel : extraButtonLabelAlternate;

  return (
    <GridToolbarContainer>
      <div className={`${styles.ToolbarContainer} ${addButtonShow ? styles.ReverseFlexDirection : styles.fullWidth}`}>
        <div className={styles.ActionButtonContainer}>
          {extraButtonShow && (
            <FpButton
              className={styles.ExternalTasks}
              color="primary"
              disabled={extraButtonDisabled}
              onClick={extraButtonFunction}
              variant="contained"
              startIcon={<SendIcon />}
              disableElevation
            >
              <Box sx={{ cursor: 'pointer' }} component="label">
                {externalTasksLabelToShow}
              </Box>
            </FpButton>
          )}
          {addButtonShow && (
            <TapStockNoInventoryAvailableTooltip showTooltip={addButtonDisabled}>
              <FpButton
                className={styles.AddButton}
                color="secondary"
                onClick={addButtonFunction}
                disabled={addButtonDisabled}
                variant="contained"
                startIcon={<AddCircleIcon />}
                disableElevation
              >
                <Box sx={{ cursor: 'pointer' }} component="label">
                  {addButtonLabelToShow}
                </Box>
              </FpButton>
            </TapStockNoInventoryAvailableTooltip>
          )}
        </div>
        <div className={styles.FilterWrapper}>
          {column && <GridToolbarColumnsButton className={styles.ToolbarButton} variant="contained" disableElevation />}

          {density && (
            <GridToolbarDensitySelector className={styles.ToolbarButton} variant="contained" disableElevation />
          )}

          {filters && (
            <GridToolbarFilterButton
              componentsProps={{
                button: {
                  variant: 'contained',
                  disableElevation: true,
                  className: styles.ToolbarButton,
                },
              }}
            />
          )}

          {showExportButton && (
            <GridToolbarExport
              className={styles.ToolbarButton}
              variant="contained"
              disableElevation
              printOptions={{ disableToolbarButton: true }}
            />
          )}

          <div className={styles.QuickFilter}>
            {showQuickFilter && (
              <GridToolbarQuickFilter
                sx={{ paddingTop: '0.75rem' }}
                quickFilterParser={(searchInput) => {
                  let returnValue = searchInput.split(' ');
                  const searchInputWithoutSpaces = searchInput.replace(/ /g, '');
                  if (!isNaN(Number(searchInputWithoutSpaces))) {
                    returnValue = searchInput.split(',').map((value) => {
                      return value.replace(/ /g, '');
                    });
                  }
                  return returnValue;
                }}
              />
            )}
          </div>
        </div>
      </div>
      {showExtraContent && <Box sx={{ width: '100%', overflowX: 'auto' }}>{extraContent}</Box>}
    </GridToolbarContainer>
  );
};
export default DataGridTableCustomToolbar;
