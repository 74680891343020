import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';
import { forwardRef } from 'react';

const DialogTitle = forwardRef<HTMLDivElement, MuiDialogTitleProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <MuiDialogTitle
      sx={{
        fontWeight: 500,
        p: 2,
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </MuiDialogTitle>
  );
});

DialogTitle.displayName = 'DialogTitle';

export default DialogTitle;
