import { Color } from '@flowplan/flowplan-shared';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ReactElement } from 'react';

type Props = {
  title: string;
  children?: ReactElement;
  placement?: TooltipProps['placement'];
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#ecf0f5',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ecf0f5',
    fontSize: '12px',
  },
}));

const FpTooltip = ({ title, children, placement }: Props): JSX.Element => (
  <CustomTooltip placement={placement || 'right'} title={title}>
    {children ? (
      children
    ) : (
      <IconButton aria-label="info">
        <InfoIcon sx={{ color: Color.FlowplanBlue }} />
      </IconButton>
    )}
  </CustomTooltip>
);

export default FpTooltip;
