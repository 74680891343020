import React from 'react';
import InputComponentToggle from '../Input/InputComponentToggle';
import './Feedback.scss';

interface IFeedback {
  feedbackMessage: string;
}

const Feedback = ({ feedbackMessage }: IFeedback): JSX.Element => {
  return (
    <InputComponentToggle hideComponent={feedbackMessage === ''}>
      <span className="center-align feedback-message">{feedbackMessage}</span>
    </InputComponentToggle>
  );
};

export default Feedback;
