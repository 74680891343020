import React from 'react';
import './FlexRow.scss';

interface IFlexRow {
  children: React.ReactNode;
  isTopRow?: boolean;
}

const FlexRow = ({ children, isTopRow }: IFlexRow): JSX.Element => {
  let rowStyle = 'flex-row';
  rowStyle += isTopRow === undefined ? '' : ' top-row';
  return <div className={rowStyle}>{children}</div>;
};

export default FlexRow;
