export enum Paths {
  root = '/',
  admin = '/administration',
  clients = '/clients/',
  dashboard = '/waterfilterDashboard',
  support = '/support',
  installationDetails = '/installationDetails',
  inventoryBeams = '/inventoryBeams',
  inventoryBottles = '/bottles',
  installations = '/installations',
  waterfilterLocations = '/waterfilter/locations',
  login = '/login',
  passwordForgotten = '/forgotPassword',
  passwordReset = '/resetPassword',
  tasks = '/tasks',
  users = '/users',
  water = '/waterSamples',
  bulkInstallations = '/bulkInstallations',
  workOrders = '/workOrders',
  orders = '/orders',
  tapDashboard = '/tapsDashboard',
  tapLocations = '/tapLocations',
  tapSettings = '/tapSettings',
  tapStock = '/stockManagement',
  tapKeg = '/kegStatus',
  tapTechnician = '/technicianPortal',
  reports = '/reports',
}

export const PageTitles = {
  admin: 'Admin',
  clients: 'Clients',
  dashboard: 'Dashboard',
  support: 'Support',
  installationDetails: 'Installation details',
  inventoryBeams: 'Beam Inventory',
  inventoryBottles: 'Bottles',
  installations: 'Installations',
  waterfilterLocations: 'Locations',
  login: 'Login',
  logout: 'Log out',
  passwordForgotten: 'Forgot password',
  passwordReset: 'Reset password',
  tasks: 'Tasks',
  users: 'Users',
  water: 'Register sample',
  bulkInstallations: 'Bulk installations',
  workOrders: 'Work orders',
  tapDashboard: 'Dashboard',
  tapLocations: 'Locations',
  tapSettings: 'Settings',
  tapStock: 'Stock Management',
  tapKeg: 'Keg Status',
  tapTechnician: 'Technician Portal',
  orders: 'Orders',
  reports: 'Reports',
}
