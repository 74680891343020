import React, { ChangeEvent, Fragment, ReactNode } from 'react';
import './TextInput.scss';

interface ITextInput {
  isFirst?: boolean;
  onlyNumbers?: boolean;
  isRequired?: boolean;
  type?: string;
  maxLength?: number;
  children?: React.ReactNode;
  inputValue: string | number;
  inputKey: string;
  inputText: ReactNode;
  autoComplete?: string;
  shouldAutoFocus?: boolean;
  disabled?: boolean;
  handleInput(e: ChangeEvent, inputKey: string): void;
  handleKeyPress?(e: React.KeyboardEvent): void;
}

const TextInput = ({
  autoComplete,
  children,
  handleInput,
  handleKeyPress,
  onlyNumbers,
  inputText,
  inputValue,
  isRequired,
  isFirst,
  inputKey,
  maxLength,
  type,
  shouldAutoFocus,
  disabled,
}: ITextInput): JSX.Element => {
  const inputStyle = 'input-group input-text' + (isFirst !== undefined && isFirst ? '-first' : '');
  const patternRestriction = onlyNumbers !== undefined && onlyNumbers ? '[0-9]*' : undefined;
  const inputType = type === undefined ? 'text' : type;
  const hasCharLimit = maxLength === undefined ? undefined : maxLength;
  const hasInfoArea = children === undefined ? false : true;
  const interactionDisabled = disabled === undefined ? false : disabled;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleInput(e, inputKey);
  };

  let infoPopup: React.ReactNode = <Fragment />;
  if (hasInfoArea) {
    infoPopup = children;
  }

  return (
    <div className={inputStyle}>
      <input
        autoComplete={autoComplete}
        disabled={interactionDisabled}
        value={inputValue}
        maxLength={hasCharLimit}
        name={inputKey}
        id={inputKey}
        aria-label={inputKey}
        onChange={handleChange}
        pattern={patternRestriction}
        type={inputType}
        autoFocus={shouldAutoFocus}
        onKeyDown={handleKeyPress}
        required={isRequired}
      />
      <span className="input-highlight" />
      <span className="input-bar" />
      <label className="input-label input-text-label" htmlFor={inputKey}>
        {inputText}
      </label>
      {infoPopup}
    </div>
  );
};

export default TextInput;
