import {
  formatLiters,
  getInactivityPeriod,
  NotificationTypes,
  toLiters
} from '@flowplan/flowplan-shared';
import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { dateFormatYMD, timeFormatHM } from '@hsjakobsen/utilities/lib/date';
import { getDateObject } from '../../../../Utility/time';
import { IFilterNotification, IFormattedInstallationHistory } from '../../installation-history-interfaces';

export enum TagDeleteReasons {
  DeletedByUser = 1,
  DeletedBecauseCompanyTagWasDeleted = 2,
}

export enum TagUpdateInformation {
  ExpirationAdded = 1,
  ExpirationRemoved = 2,
}

export const formatInstallationHistoryData = (
  companyId: number,
  dataSet: IFilterNotification,
  filterLookup: IDeviceView,
  forCSV = false,
): IFormattedInstallationHistory => {
  const { createdOn, extraInfo, notificationTypeId, notificationSource, notificationNote } = dataSet;
  const timeStamp = getDateObject(createdOn).format(dateFormatYMD + ' ' + timeFormatHM);
  const notificationText = translateNotificationTypeToText(notificationTypeId);

  let actor = '';
  if (notificationSource !== null && notificationSource !== undefined) {
    const { user } = notificationSource;
    const { name, flowplanClientId } = user;

    actor = 'User inactive';
    if (name !== undefined) {
      actor = name;
    }

    if (companyId !== 1 && flowplanClientId === 1) {
      actor = 'Flowplan admin';
    }
  }

  let extra = '';
  if (extraInfo !== null && extraInfo !== 0) {
    extra = translateNotificationTypeToExtraInfo(notificationTypeId, extraInfo, filterLookup);
  }

  let note = '';
  if (notificationNote !== null) {
    note = notificationNote.note;
  }

  if (forCSV) {
    return {
      timeStamp,
      notificationText,
      extra,
      actor,
      note,
    };
  }

  return {
    actor,
    extra,
    note,
    notificationText,
    timeStamp,
  };
};

const translateNotificationTypeToText = (notificationTypeId: number) => {
  switch (notificationTypeId) {
    case NotificationTypes.InstallationAdded:
      return 'Installation added';
    case NotificationTypes.InstallationUpdated:
      return 'Installation updated';
    case NotificationTypes.InstallationDeleted:
      return 'Installation deleted';
    case NotificationTypes.WaterFilterChanged:
      return 'Water filter changed';
    case NotificationTypes.LocationAdded:
      return 'Location added';
    case NotificationTypes.LocationUpdated:
      return 'Location updated';
    case NotificationTypes.LocationDeleted:
      return 'Location deleted';
    case NotificationTypes.ClientAdded:
      return 'Client added';
    case NotificationTypes.ClientUpdated:
      return 'Client updated';
    case NotificationTypes.ClientDeleted:
      return 'Client deleted';
    case NotificationTypes.UserAdded:
      return 'User added';
    case NotificationTypes.UserUpdated:
      return 'User updated';
    case NotificationTypes.UserDeleted:
      return 'User deleted';

    case NotificationTypes.WaterFilterSoonToBeChanged:
      return 'Water filter change soon';
    case NotificationTypes.WaterFilterOverdue:
      return 'Change overdue';
    case NotificationTypes.InstallationUnusualDailyAverage:
      return 'Unusual average';
    case NotificationTypes.InstallationConnectionLost:
      return 'Connection lost';
    case NotificationTypes.InstallationLowBattery:
      return 'Battery low';
    case NotificationTypes.InstallationBackOnline:
      return 'Connection re-established';
    case NotificationTypes.InstallationUsageAdded:
      return 'Usage added to installation';
    case NotificationTypes.LeakageFlow:
      return 'Potential leak (high flow)';
    case NotificationTypes.LeakageTime:
      return 'Leak';
    case NotificationTypes.InstallationSerialChanged:
      return 'Installation serial changed';
    case NotificationTypes.InstallationAverageReset:
      return 'Average date reset';
    case NotificationTypes.Note:
      return 'Note';
    case NotificationTypes.LowUsage:
      return 'Sudden Consumption drop';
    case NotificationTypes.ServicePerformed:
      return 'Maintenance performed';
    case NotificationTypes.ServiceOverdue:
      return 'Maintenance overdue';
    case NotificationTypes.ServiceUpcoming:
      return 'Upcoming maintenance';
    case NotificationTypes.InstallationBatteryChanged:
      return 'Battery changed';
    case NotificationTypes.BeamCancelLocation:
      return 'Beam reconnect attempt';
    case NotificationTypes.LowUsageAction:
      return 'Low usage action performed';
    case NotificationTypes.InstallationTagAdded:
      return 'Tag added';
    case NotificationTypes.InstallationTagRemoved:
      return 'Tag removed';
    case NotificationTypes.InstallationTagUpdated:
      return 'Tag updated';
    default:
      return 'Event not found';
  }
};

const translateNotificationTypeToExtraInfo = (
  notificationTypeId: number,
  extraInfo: number,
  sensorLookup: IDeviceView,
) => {
  switch (notificationTypeId) {
    case NotificationTypes.InstallationAdded:
    case NotificationTypes.InstallationUpdated:
    case NotificationTypes.InstallationDeleted:
    case NotificationTypes.LocationAdded:
    case NotificationTypes.LocationUpdated:
    case NotificationTypes.LocationDeleted:
    case NotificationTypes.ClientAdded:
    case NotificationTypes.ClientUpdated:
    case NotificationTypes.ClientDeleted:
    case NotificationTypes.UserAdded:
    case NotificationTypes.UserUpdated:
    case NotificationTypes.UserDeleted:
    case NotificationTypes.InstallationBackOnline:
    case NotificationTypes.Note:
    case NotificationTypes.LowUsage:
    case NotificationTypes.LowUsageAction:
      // no extra info
      return '';
    case NotificationTypes.WaterFilterChanged:
      return 'Remaining: ' + formatLiters(extraInfo) + 'L';
    case NotificationTypes.InstallationBatteryChanged:
      return 'Lifetime: ' + extraInfo + ' days';
    case NotificationTypes.WaterFilterOverdue:
    case NotificationTypes.WaterFilterSoonToBeChanged:
    case NotificationTypes.ServiceOverdue:
    case NotificationTypes.ServiceUpcoming:
      return 'Days left: ' + extraInfo;
    case NotificationTypes.InstallationUnusualDailyAverage: {
      const averageMultiplier = extraInfo.toFixed(1);
      return averageMultiplier + ' times larger than expected';
    }
    case NotificationTypes.InstallationConnectionLost:
      return 'Not seen for: ' + getInactivityPeriod(extraInfo);
    case NotificationTypes.InstallationLowBattery:
      return 'Charge at ' + extraInfo + '%';
    case NotificationTypes.InstallationUsageAdded:
      return extraInfo + 'L added';
    case NotificationTypes.LeakageFlow:
      return formatLiters(toLiters(extraInfo / sensorLookup.infoFlowmeter.flowmeterTickConstant)) + 'L in 1 event';
    case NotificationTypes.LeakageTime:
      return formatLiters(extraInfo) + ' liters leaked';
    case NotificationTypes.InstallationSerialChanged:
      return 'Previous serial: 0' + extraInfo;
    case NotificationTypes.ServicePerformed:
      return 'Remaining: ' + formatLiters(extraInfo) + 'L';
    case NotificationTypes.BeamCancelLocation:
      return 'Attempts: ' + extraInfo;
    case NotificationTypes.InstallationTagRemoved:
      switch (extraInfo) {
        case TagDeleteReasons.DeletedByUser:
          return 'Reason: Deleted by user';
        case TagDeleteReasons.DeletedBecauseCompanyTagWasDeleted:
          return 'Reason: Tag was deleted from company';
      }
      return '';
    case NotificationTypes.InstallationTagUpdated:
      switch (extraInfo) {
        case TagUpdateInformation.ExpirationAdded:
          return 'Expiration date added';
        case TagUpdateInformation.ExpirationRemoved:
          return 'Expiration date removed';
      }
      return '';
    default:
      return 'Data not found';
  }
};
