import { ClassificationStatus } from '@flowplan/flowplan-shared/lib/installation.classification/installation.classification.status';
import _ from 'lodash';
import { Fragment } from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';
import PopupWithIcon from '../../../../Components/Popup/PopupWithIcon';
import { useStoreState } from '../../../../Store';
import { getSignificantFigures } from '../../../../common/formatNumbers';
import { IProcessedDeviceDetails } from '../../../../common/interfacesData';
import GraphSectionUsageElement from '../../../installations/components/Details/Graphs/GraphSectionUsageElement';
import { getInsightsUsage } from '../../../stock-settings/types/stock-settings-utils';
import { getStockMeasurementUnit } from '../../../stock/stock-utils';
import { EventTypeString } from '../../types/insights-consts';
import styles from './InstallationDetailsInsights.module.scss';
import { getTypeOfEvent, getTypeOfInsightsEvent } from './installation-details-insights-utils';
import FpText from '../../../common/components/FpText/FpText';

const InstallationDetailsInsights = (): JSX.Element => {
  const isPremium = useStoreState((state) => state.companyModel.isPremium);
  const eventDataFromPeriod = useStoreState((state) => state.deviceDataModel.eventDataFromPeriod);
  const timePeriodselection = useStoreState((state) => state.graphSettingsModel.graphSettingsTime.timePeriodselection);
  const timePeriodOptions = useStoreState((state) => state.graphSettingsModel.timePeriodOptions);
  const selectedInstallation = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const stock = useStoreState((state) => state.stockDailyDataModel.stock);
  const stockSettings = useStoreState((state) => state.stockDailyDataModel.stockSettings);
  const maintenanceCyclesDetails = useStoreState((state) => state.deviceDataModel.maintenanceCyclesDetails);
  const deviceDetails: IProcessedDeviceDetails = useStoreState(
    (state) => state.deviceActionsModel.sensorDetailsProcessedDeviceDetails,
  );

  // const getInstallationCleaningHistory = useStoreActions(
  //   (actions) => actions.installationDataModel.getInstallationCleaningHistory,
  // );

  // const [isCalenderOpen, setIsCalenderOpen] = useState(false);

  const { insightsAvailable, status } = selectedInstallation.infoInsights;
  const showInsightsAvailable = status ? status === ClassificationStatus.COMPLETED && insightsAvailable : false;
  const timePeriodInfo = '(' + timePeriodOptions[timePeriodselection] + ') ';
  const getInsightsTypeStyle = (insightsEvent: string): string => {
    const insightsEventType = getTypeOfEvent(insightsEvent);
    let insightsEventTypeClass = '';
    switch (insightsEventType) {
      case EventTypeString.brown:
        insightsEventTypeClass = styles.brown;
        break;
      case EventTypeString.grey:
        insightsEventTypeClass = styles.grey;
        break;
      case EventTypeString.red:
        insightsEventTypeClass = styles.red;
        break;
      default:
        insightsEventTypeClass = styles.blue;
        break;
    }

    return insightsEventTypeClass;
  };

  const stockOverview = stock.map((stockItem, index) => {
    const { averageDailyConsumption, daysLeft, stockType, totalAmount } = stockItem;
    const averageDailyConsumptionParsed = getSignificantFigures({
      input: averageDailyConsumption,
      significantFigures: 3,
    });
    const totalAmountParsed = getSignificantFigures({
      input: totalAmount,
      significantFigures: 3,
    });
    const measurementUnit = getStockMeasurementUnit(stockType);
    const paragraph = totalAmountParsed + measurementUnit + ' remaining';

    return (
      <Fragment key={index}>
        <Grid.Column className="usage-element-column">
          <p className={styles.paragraphHeader}>
            <strong>{_.capitalize(stockType) + ':'}</strong>
          </p>
          <p className={styles.paragraphSubHeader}>Average daily consumption:</p>
          <p className={styles.paragraphSubHeader}>Days left:</p>
        </Grid.Column>
        <Grid.Column className="usage-element-column">
          <p className={styles.paragraphHeaderData}>
            <strong>{paragraph}</strong>
          </p>
          <p className={styles.paragraphSubHeaderData}>
            <strong>
              {averageDailyConsumption ? averageDailyConsumptionParsed : '0'} {measurementUnit}
            </strong>
          </p>
          <p className={styles.paragraphSubHeaderData}>
            <strong>{daysLeft ? daysLeft : 1000} days</strong>
          </p>
        </Grid.Column>
      </Fragment>
    );
  });

  stockOverview.unshift(<GraphSectionUsageElement key={-1} boldTitle title={'Stock status: '} />);

  const eventOverview = eventDataFromPeriod.map((eventStatsOverview, index) => {
    const { amount, title } = eventStatsOverview;
    const { modifier, unit, unitSmall } = getInsightsUsage({ stockSettings, title });
    let paragraph = amount + getTypeOfInsightsEvent(title);

    if (modifier !== 0) {
      const modifierParsed = getSignificantFigures({ input: (amount * modifier) / 1000, significantFigures: 3 });
      paragraph += ' x ' + modifier + ' ' + unitSmall + '\nUsage: ' + modifierParsed + ' ' + unit;
    }
    const insightsEventTypeClass = getInsightsTypeStyle(title);
    return (
      <GraphSectionUsageElement
        key={index}
        title={title + ':'}
        titleStyle={styles.insights + ' ' + insightsEventTypeClass}
      >
        <p>{paragraph}</p>
      </GraphSectionUsageElement>
    );
  });

  eventOverview.unshift(<GraphSectionUsageElement key={-1} boldTitle title={'Consumption ' + timePeriodInfo} />);

  const eventDataDetails = useStoreState((state) => state.deviceDataModel.eventDataDetails);
  const eventsDetails = eventDataDetails.map((eventStats, index) => {
    const mlString = eventStats.averageMilliliters.toFixed(0) + 'mL';
    const eventString = 'Avg.' + mlString.padStart(8) + eventStats.averageFlowTime.toString().padStart(9) + 's';
    const insightsEventTypeClass = getInsightsTypeStyle(eventStats.titleName);

    return (
      <GraphSectionUsageElement
        key={index}
        title={eventStats.titleName}
        titleStyle={styles.insights + ' ' + insightsEventTypeClass}
      >
        <p>{eventString}</p>
      </GraphSectionUsageElement>
    );
  });

  eventsDetails.unshift(
    <GraphSectionUsageElement
      key={-1}
      boldTitle
      title={'Characteristics ' + timePeriodInfo}
      tooltipInfo={
        <PopupWithIcon position="left center" extraStyle={styles.popupIcon}>
          <p>
            <strong>Characteristics calculation:</strong>
          </p>
          <p>The below calculations are based on a maximum of the latest 500 events for this period. </p>
          <p>
            <strong>Espresso sizes:</strong>
          </p>
          <p>
            The shown espresso volume may be higher than the actual in-cup volume. This is due to espresso machines
            using around 50mL extra water to build pressure, which is released through the 3-way solenoid valve at the
            end of the extraction.
          </p>
        </PopupWithIcon>
      }
    />,
  );

  if (!isPremium) {
    return (
      <Segment className={styles.segment}>
        <Header as="h3" className={styles.header}>
          Flowplan<sup>®</sup> Insights
        </Header>
        <p>
          Upgrading to Premium gives further insights into the operations of the installation. Find out more by
          contacting Flowplan<sup>®</sup>.
        </p>
        {/* <FpButton
          color="info"
          onClick={() => {
            setIsCalenderOpen(true);
            getInstallationCleaningHistory();
          }}
        >
          View Cleaning Calender
        </FpButton>
        <Dialog open={isCalenderOpen} onClose={() => setIsCalenderOpen(false)} fullWidth maxWidth="sm">
          <DialogTitle>Cleaning Calender</DialogTitle>
          <DialogContent>
            <FpCalender />
          </DialogContent>
          <DialogActions>
            <FpButton onClick={() => setIsCalenderOpen(false)} color="primary">
              Close
            </FpButton>
          </DialogActions>
        </Dialog> */}
      </Segment>
    );
  }

  return (
    <Segment className={styles.segment}>
      <Header as="h3" className={styles.header} >
        <FpText variant='h6' componentType='span' className='do-not-translate' sx={{ fontWeight: 'bold' }}>
          Flowplan<sup>®</sup>Stock and Insights{' '}
        </FpText>
        {!showInsightsAvailable && (
          <span className={styles.dataProcessing}> (Note: Data is still being processed) </span>
        )}
      </Header>

      <Grid columns={2} divided="vertically">
        {stock.length === 0 || stockSettings.length === 0 ? (
          <Grid.Row>
            <p className={styles.paragraph}>Stock feature not enabled</p>
          </Grid.Row>
        ) : (
          <>
            <Grid.Row>{stockOverview}</Grid.Row>
          </>
        )}

        {deviceDetails.infoService.cycleBasedMaintenance && (
          <Grid.Row>
            <Grid.Column className="usage-element-column">
              <p className={styles.paragraphHeader}>
                <strong>Cup Information:</strong>
              </p>

              <p className={styles.paragraphSubHeader}>Total cup counter:</p>
              <p className={styles.paragraphSubHeader}>Average daily cup count:</p>
            </Grid.Column>
            <Grid.Column className="usage-element-column">
              <p className={styles.paragraphHeaderData}>
                <br />
              </p>
              <p className={styles.paragraphSubHeaderData}>
                <strong>{maintenanceCyclesDetails.totalCycles}</strong>
              </p>
              <p className={styles.paragraphSubHeaderData}>
                <strong>{maintenanceCyclesDetails.averageCycles}</strong>
              </p>
            </Grid.Column>
          </Grid.Row>
        )}
        {eventOverview.length === 1 ? (
          <Grid.Row>
            <p className={styles.paragraph}>No usage data available for this period</p>
          </Grid.Row>
        ) : (
          <Grid.Row>{eventOverview}</Grid.Row>
        )}
        {eventsDetails.length === 1 ? (
          <Grid.Row>
            <p className={styles.paragraph}>No characteristics data available for this period</p>
          </Grid.Row>
        ) : (
          <Grid.Row>{eventsDetails}</Grid.Row>
        )}
      </Grid>
    </Segment>
  );
};

export default InstallationDetailsInsights;
