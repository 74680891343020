import FpContainer from '../FpContainer/FpContainer';
import FpText from '../FpText/FpText';

const FpFallback = (): JSX.Element => {
  return (
    <FpContainer backgroundColor="#f9fafb">
      <FpText variant="h5" sx={{ textAlign: 'center' }}>
        Loading...
      </FpText>
    </FpContainer>
  );
};

export default FpFallback;
