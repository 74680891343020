import { useStoreActions, useStoreState } from '../../../../../../Store';
import FpSelectSearch from '../../../../../common/components/FpSelectSearch/FpSelectSearch';
import FpContainer from '../../../../../common/components/FpContainer/FpContainer';

const FlowplanExternalSensorSelection = ({ allowNone = false }: { allowNone?: boolean }): JSX.Element | null => {
  const matchingEnabled = useStoreState((state) => state.companyModel.matchingEnabled);
  const externalSensors = useStoreState((state) => state.staticDataModel.externalSensors);
  const inputExternalSensorId = useStoreState((state) => state.newInstallationModel.inputExternalSensorId);
  const setInputExternalSensorId = useStoreActions((actions) => actions.newInstallationModel.setInputExternalSensorId);

  const noExternalSensorOption = {
    id: 0,
    name: 'None',
    externalSensorCategoryId: 0,
    sortIndex: 0
  }
  allowNone && externalSensors.findIndex((externalSensor) => externalSensor.id === 0) === -1 && externalSensors.push(noExternalSensorOption)

  if (!matchingEnabled) {
    return null;
  }

  return (
    <FpContainer basic sx={{ marginTop: '1.25rem' }}>
      <FpSelectSearch
        translateOptions={false}
        label='External sensor'
        options={externalSensors.map((externalSensor) => {
          return {
            value: externalSensor.id,
            name: externalSensor.name
          }
        })}
        selectedValue={inputExternalSensorId}
        setSelectionValue={(value) => {
          setInputExternalSensorId(Number(value))
        }}
      />
    </FpContainer>
  );
};

export default FlowplanExternalSensorSelection;
