import { ChangeEvent } from 'react';
import TextInput from '../../../../Components/Input/TextInput';
import PopupWithIcon from '../../../../Components/Popup/PopupWithIcon';
import { useStoreActions, useStoreState } from '../../../../Store';
import { InputKeys } from '../../../../common/constsFrontend';

const FlowplanNameSelection = (): JSX.Element => {
  const setInputName = useStoreActions((actions) => actions.newInstallationModel.setInputName);
  const inputName = useStoreState((state) => state.newInstallationModel.inputName);

  return (
    <TextInput
      inputValue={inputName}
      inputKey={InputKeys.Name}
      maxLength={125}
      inputText={'Name* (' + inputName.length + ' / 125)'}
      handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
        const value = ev.target.value;
        setInputName(value);
      }}
      isRequired={true}
    >
      <PopupWithIcon position="left center">
        <p>
          If you have an internal numbering system for all your machines / filters, here would be a good place to insert
          it.
        </p>
        <p>A good name example could be "FP1521 - Coffee Machine"</p>
      </PopupWithIcon>
    </TextInput>
  );
};

export default FlowplanNameSelection;
