import { notAvailable } from "@flowplan/flowplan-shared";

export enum LowUsageAction {
    DoNothing = 1,
    Ignore = 2,
    ActivateEstimate = 3,
    ViewInstallation = 4,
}

type LowUsageDays = {
    actionDate: string;
    actionDays: number;
    daysSinceLastFlowEvent: number;
}

export const getLowUsageDays = ({
    daysSinceLastFlowEvent,
    actionDate,
    actionDays
}: LowUsageDays): number => {
    let days = daysSinceLastFlowEvent;
    if (actionDate !== notAvailable) {
        days = actionDays > daysSinceLastFlowEvent ? daysSinceLastFlowEvent : actionDays;
    }

    return days;
}
