import { HardnessUnits } from '@flowplan/flowplan-shared/lib/flowplan.clients/Flowplan.clients';
import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { getDeviceTypeText } from '../../../../Utility/Texts';
import { FilterSofteningType, getWaterHardnessInfo, getWaterHardnessType } from '../../../locations/locations-texts';

export interface IBaseInformation {
  clientName: string;
  deviceType: number;
  deviceTypeText: string;
  installationId: number;
  installationName: string;
  installationDescription: string;
  locationAddress: string;
  locationPhoneNumber: string;
  locationTitle: string;
  waterHardnessInfo: string;
  microSiemensPerCmInfo: string;
}

type CollectBasicInformation = {
  installation: IDeviceView;
  hardnessUnit: HardnessUnits;
}


const collectBasicInformation = ({ hardnessUnit, installation }: CollectBasicInformation): IBaseInformation => {
  const { carbonateHardness, clientName, locationAddress, locationId, locationName, locationPhoneNumber, totalHardness, microSiemensPerCm } = installation.infoLocationAndClient;
  const { description, id, name, sensorTypeId } = installation.infoBasic;

  return {
    clientName,
    deviceType: sensorTypeId,
    deviceTypeText: getDeviceTypeText(sensorTypeId),
    installationDescription: description,
    installationId: id,
    installationName: name,
    locationAddress: locationId ? locationAddress : '',
    locationPhoneNumber,
    locationTitle: locationName,
    waterHardnessInfo: getWaterHardnessInfo({ carbonateHardness, totalHardness, hardnessUnit }),
    microSiemensPerCmInfo: microSiemensPerCm ? microSiemensPerCm + getWaterHardnessType({
      filterSofteningType: FilterSofteningType.MicroSiemens,
      hardnessUnit,
      includeLaymansTerm: false,
      spaceInFront: true
    }) : ''
  };
};

export { collectBasicInformation };
