import { BillingTypes } from '@flowplan/flowplan-shared/lib/interfaces/networkEUIToFlowplanSerialDb';
import { ChangeEvent } from 'react';
import { isNetworkGSMGT } from '../Utility/networkTypeCheck';
import { DeviceTypes } from '../common/constsCategoryAndTypes';

export enum BatteryStatusInformation {
  ActivityInfoNA = 'Battery status unknown.\nThe firmware version does not provide detailed battery information.',
  FirmwareUnknown = 'Firmware not known yet, waiting for transmission',
  GridPowered = 'Powered by electricity grid',
  Pending = 'Battery status unknown.\nMore information is available when the Beam transmits data for the first time.',
}

interface IPowerTooltip {
  network: number;
  percentageStatus: number;
}

export const getPowerStatusText = ({ network, percentageStatus }: IPowerTooltip): string => {
  let tooltip = 'Battery status: ' + percentageStatus + '%';

  if (isNetworkGSMGT(network)) {
    tooltip = BatteryStatusInformation.GridPowered;
  }

  return tooltip;
};

export const getPasswordText = (isUpdate: boolean, isRepeat: boolean, showAsterix: boolean): string => {
  let textToReturn;
  if (isUpdate) {
    textToReturn = isRepeat ? 'Repeat new password' : 'New password';
  } else {
    textToReturn = isRepeat ? 'Repeat password' : 'Password';
  }

  if (showAsterix) {
    textToReturn += '*';
  }

  return textToReturn;
};

export const checkNumbersOnlyRestriction = (ev: ChangeEvent<HTMLInputElement>): boolean => {
  if (ev.target.pattern === '[0-9]*') {
    if (!ev.target.validity.valid && ev.target.value !== '') {
      // ev.target.value = this.state[name];
      // pattern not valid, and not empty, so previous state should be used
      return false;
    }
  }

  return true;
};

export const getDeviceTypeText = (deviceType: DeviceTypes): string => {
  switch (deviceType) {
    case DeviceTypes.SemiAutomatic:
      return 'Semi automatic coffee machine';
    case DeviceTypes.FullyAutomatic:
      return 'Fully automatic coffee machine';
    case DeviceTypes.FilterBrewer:
      return 'Filter brewer coffee machine';
    case DeviceTypes.Tabletop:
      return 'Standard dishwashing machine';
    case DeviceTypes.Column:
      return 'Column dishwashing machine';
    case DeviceTypes.Tunnel:
      return 'Tunnel washing machine';
    case DeviceTypes.Regular:
      return 'Standard ice machine';
    case DeviceTypes.Crushed:
      return 'Crushed ice machine';
    case DeviceTypes.SteamOven:
      return 'Steam oven';
    case DeviceTypes.WaterDispenser:
      return 'Water dispenser';
    case DeviceTypes.FullyAutomaticFreshMilk:
      return 'Fully automatic coffee machine with fresh milk';
    case DeviceTypes.SlushIceMachine:
      return 'Slush ice machine';
    case DeviceTypes.SoftIceMachine:
      return 'Soft ice machine';
    case DeviceTypes.CarWashAutomatic:
      return 'Car wash automatic';
    case DeviceTypes.CarWashManual:
      return 'Car wash manual';
  }

  return 'Other';
};

export const getBillingTypeText = (billingType: BillingTypes | undefined): string => {
  if (!billingType) {
    return 'Standard';
  }

  switch (billingType) {
    case BillingTypes.LEASING:
      return 'Subscription';
    case BillingTypes.PREPAID:
      return 'Prepaid';
    default:
      return 'Standard';
  }
};
