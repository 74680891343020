import { useEffect, useState } from 'react';
import { useStoreState } from '../Store';
import { NewsAndUpdates } from '../modules/news-and-updates/services/news-and-updates-model';

interface NewsAndUpdatesExtended extends NewsAndUpdates {
    isUnread: boolean;
}

export function useNewNotifications() {
    const newsAndUpdates = useStoreState((state) => state.newsAndUpdatesModel.newsAndUpdates);
    const newsAndUpdateStatus = useStoreState((state) => state.newsAndUpdatesModel.newsAndUpdateStatus);

    const [newsStatus, setNewsStatus] = useState<NewsAndUpdatesExtended[]>([]);

    useEffect(() => {
        const newsAndUpdatesUpdated: NewsAndUpdatesExtended[] = [];
        for (const newsAndUpdate of newsAndUpdates) {
            const hasBeenRead = newsAndUpdateStatus.find((item) => item.newsId === newsAndUpdate.id);
            const updatedItem: NewsAndUpdatesExtended = { ...newsAndUpdate, isUnread: hasBeenRead === undefined };
            newsAndUpdatesUpdated.push(updatedItem);
        }
        setNewsStatus(newsAndUpdatesUpdated);

    }, [newsAndUpdateStatus, newsAndUpdates])
    return newsStatus;
}
