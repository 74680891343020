import React, { memo } from 'react';
import FpText from '../../../common/components/FpText/FpText';
import styles from './InstallationDetailsItem.module.scss';

type Props = {
  title: string;
  icon?: React.ReactNode;
  margins: boolean;
  boldTitle?: boolean;
  children?: React.ReactNode;
  showBorder: boolean;
  clickHandler?: () => void;
};

const InstallationDetailsItem = memo(
  ({ boldTitle, children, clickHandler, icon, margins, showBorder, title }: Props) => {
    const marginStyle = margins ? ' ' + styles.margins : '';
    return (
      <div className={styles.installationDetailsItem + marginStyle}>
        {icon ? <div>{icon}</div> : null}
        <div className={styles.explanation}>
          <FpText variant="body1" sx={{ fontWeight: boldTitle ? 'bold' : '' }}>
            {title}
          </FpText>
          <div
            onClick={() => {
              if (clickHandler) {
                clickHandler();
              }
            }}
            className={styles.interaction + (showBorder ? ' ' + styles.showBorder : '')}
          >
            {children}
          </div>
        </div>
      </div>
    );
  },
);

export default InstallationDetailsItem;
