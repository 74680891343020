import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Fragment, SyntheticEvent } from 'react';
import DropdownListMultipleSearch from '../../../../Components/Input/DropdownListMultipleSearch';
import EmptyListItem from '../../../../Components/ListItems/EmptyListItem';
import ListWithHeaderAndTitles from '../../../../Components/Lists/ListWithHeaderAndTitles';
import { useStoreActions, useStoreState } from '../../../../Store';
import FpButtonOutlined from '../../../common/components/FpButtonOutlined/OutlinedButton';
import { installationHistoryFilterOptions } from '../../installation-history-consts';
import { exportFilterHistory } from './History.Export';
import FilterHistoryItem from './History.Item';

interface IDropdownSelection {
  value: number[];
}

const InstallationHistoryComponent = () => {
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);
  const companyId = useStoreState((actions) => actions.companyModel.companyId);

  const notificationsFiltered = useStoreState((state) => state.installationDataModel.notificationsFiltered);
  const notificationFiltersSelection = useStoreState(
    (state) => state.installationDataModel.notificationFiltersSelection,
  );
  const setNotificationFilterSelection = useStoreActions(
    (actions) => actions.installationDataModel.setNotificationFilterSelection,
  );

  let filterChangeHistoryList;
  if (notificationsFiltered.length === 0) {
    filterChangeHistoryList = <EmptyListItem />;
  } else {
    filterChangeHistoryList = [];
    filterChangeHistoryList = notificationsFiltered.map((filterChangeHistoryItem, index) => {
      return <FilterHistoryItem data={filterChangeHistoryItem} key={index} />;
    });
  }

  return (
    <Fragment>
      <FpButtonOutlined
        className={'blueButton'}
        startIcon={<FileDownloadIcon />}
        onClick={() => {
          exportFilterHistory({ baseFilter, companyId, notificationHistory: notificationsFiltered });
        }}
      >
        Export to CSV
      </FpButtonOutlined>
      <DropdownListMultipleSearch
        items={installationHistoryFilterOptions}
        placeholder="select notification types to view"
        selectedValue={notificationFiltersSelection}
        onChangeFunction={(e: SyntheticEvent, { value }: IDropdownSelection) => {
          setNotificationFilterSelection(value);
        }}
      />

      <ListWithHeaderAndTitles
        isTitleSticky={false}
        listTitle={'Installation events'}
        subTitleOne="Date"
        subTitleTwo="Event type"
        subTitleThree="User"
        subTitleFour="Note"
        titleExtraInfoOne=""
        titleExtraInfoTwo=""
        data={filterChangeHistoryList}
      />
    </Fragment>
  );
};

export default InstallationHistoryComponent;
