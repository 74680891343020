import { StockTypes } from '@flowplan/flowplan-shared/lib/stock/stock';
import _ from 'lodash';
import { ChangeEvent, Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import TextInput from '../../../../Components/Input/TextInput';
import { IStockSettingSelection } from '../../../installations/components/NewInstallation/StockSettings';
import { getStockSettingMeasurementUnit } from '../../types/stock-settings-utils';

interface StockSettingsProps {
  stockType: StockTypes;
  stockSetting: IStockSettingSelection;
  stockSettingSelectionHandler: (stockSettingItem: IStockSettingSelection) => void;
}

const StockSettingsItem = ({
  stockSetting,
  stockType,
  stockSettingSelectionHandler,
}: StockSettingsProps): JSX.Element => {
  const measurementUnit = getStockSettingMeasurementUnit(stockType);

  return (
    <Fragment>
      <Header as="h4">{_.capitalize(stockType)} settings</Header>
      {Object.entries(stockSetting.usageForEvents).map((entry, index) => {
        const name: string = entry[0];
        const prettyName = _.capitalize(name.replace(/^usagePer/, ''));
        const value: number = entry[1];
        const inputText = prettyName + ' setting (' + measurementUnit + ')';
        return (
          <TextInput
            key={index}
            inputValue={value}
            inputKey={name}
            inputText={inputText}
            handleInput={(ev: ChangeEvent<HTMLInputElement>, inputKey: string) => {
              const amount = Number(ev.target.value);
              const usageForEvents = { ...stockSetting.usageForEvents, [inputKey]: amount };
              stockSettingSelectionHandler({ stockId: stockSetting.stockId, stockType, usageForEvents });
            }}
          />
        );
      })}
    </Fragment>
  );
};

export default StockSettingsItem;
