import { HealthState } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { useMediaQuery, useTheme } from '@mui/material';
import { BeamHealthStatus, getHealthStateText, healthIsOnlyBatteryChange } from '../../../../hooks/BeamHealthStatus';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '../../../common/components';
import FpButton from '../../../common/components/FpButton/FpButton';
import FpText from '../../../common/components/FpText/FpText';
import { BeamHealthTexts } from '../../../installations/components/Tasks/tasks-utility-health';

export type HealthWarningInfo = {
  show: boolean;
  ignore: boolean;
};

interface Props {
  goodActionLabel: string;
  choiceSelectionInfo: HealthWarningInfo;
  healthStatus: BeamHealthStatus;
  healthState: HealthState;
  onlyShowOneButton: boolean;
  choiceSelection(info: HealthWarningInfo): void;
}

const HealthWarning = ({
  choiceSelection,
  choiceSelectionInfo,
  goodActionLabel,
  healthStatus,
  healthState,
  onlyShowOneButton,
}: Props): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const closeModal = () => {
    choiceSelection({ ignore: false, show: false });
  };

  const onlyBatteryChange = healthIsOnlyBatteryChange(healthStatus);

  const healthText = onlyBatteryChange
    ? BeamHealthTexts.ChangeBattery
    : getHealthStateText({
        hardwareGeneration: healthStatus.hardwareGeneration.replace('Beam ', ''),
        healthState,
        short: false,
      });

  return (
    <Dialog open={choiceSelectionInfo.show} onClose={closeModal} fullWidth maxWidth="sm" fullScreen={fullScreen}>
      <DialogTitle>{BeamHealthTexts.BeamHealthTitle}</DialogTitle>

      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <FpText variant="body1" sx={{ textAlign: 'center', width: '100%' }}>
            {healthText}
          </FpText>
        </div>
      </DialogContent>
      <DialogActions>
        <FpButton color="error" onClick={() => choiceSelection({ ignore: true, show: false })}>
          Ignore warning
        </FpButton>
        {!onlyShowOneButton && (
          <FpButton color="secondary" onClick={closeModal}>
            {goodActionLabel}
          </FpButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default HealthWarning;
