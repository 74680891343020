import { DeviceStatus } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import {
  ConsumptionStatus,
  IDashboardInstallations,
  IInstallationMapItem,
  ITasksInfo,
  IUpcomingLocations,
} from '@flowplan/flowplan-shared/lib/installations/dashboard';
import { IDeviceView } from '@flowplan/flowplan-shared/lib/interfaces/deviceViewObject';
import { Action, action, Computed, computed, Thunk, thunk, ThunkOn, thunkOn } from 'easy-peasy';
import _ from 'lodash';
import { IStoreModel } from '../../Models/index';
import { IPieChartData } from '../../common/interfacesFrontend';
import { IShowTaskIconsResult, ITaskInformationToShowResult } from '../installations/components/Tasks/tasks-utility';
import { defaultInstallationConnectionGraphData, defaultInstallationsGraphData } from './dashboard-consts';
import { FilterSelection } from './dashboard-marker-filter-model';
import { convertIDeviceViewToDashboardInstallation } from './dashboard-utils';

const defaultTasksInfo: ITasksInfo = {
  filterChangeCount: 0,
  maintenanceCount: 0,
  nextQuarterLocations: 0,
  nextQuarterTasks: 0,
  oneMonthLocations: 0,
  oneMonthTasks: 0,
  oneweekLocations: 0,
  oneWeekTasks: 0,
  twoweekLocations: 0,
  twoWeekTasks: 0,
};

const defaultInstallationStatus: IInstallationStatus = {
  monitoring: 0,
  good: 0,
  inactive: 0,
  notSeen: 0,
  oneMonth: 0,
  oneWeek: 0,
  online: 0,
  overdue: 0,
  pending: 0,
};

interface IInstallationStatus {
  monitoring: number;
  good: number;
  oneMonth: number;
  oneWeek: number;
  overdue: number;
  online: number;
  notSeen: number;
  inactive: number;
  pending: number;
}

export interface DashboardInstallationTableRow extends IDeviceView {
  taskDisplayOptions: IShowTaskIconsResult;
  taskInfo: ITaskInformationToShowResult;
}

export interface DashboardLocationTableRow extends IUpcomingLocations {
  id: number;
}

export interface IDashboardModel {
  resetModelData: Action<IDashboardModel>;

  getUpcomingInstallations: Thunk<IDashboardModel, void, void, IStoreModel>;
  upcomingInstallations: DashboardInstallationTableRow[];
  setUpcomingInstallations: Action<IDashboardModel, DashboardInstallationTableRow[]>;

  getUpcomingLocations: Thunk<IDashboardModel, void, void, IStoreModel>;
  upcomingLocations: DashboardLocationTableRow[];
  setUpcomingLocations: Action<IDashboardModel, DashboardLocationTableRow[]>;

  loadingTasks: boolean;
  setLoadingTasks: Action<IDashboardModel, boolean>;

  getTaskInfo: Thunk<IDashboardModel, void, void, IStoreModel>;

  taskInfo: ITasksInfo;
  setTaskInfo: Action<IDashboardModel, ITasksInfo>;

  getDashboardInstallations: Thunk<IDashboardModel, void, void, IStoreModel>;
  dashboardInstallations: IDashboardInstallations[];
  setDashboardInstallations: Action<IDashboardModel, IDashboardInstallations[]>;
  installationsCount: Computed<IDashboardModel, number>;

  installationsConnectionData: IPieChartData[];
  setInstallationsConnectionData: Action<IDashboardModel, IPieChartData[]>;
  installationsGraphData: IPieChartData[];
  setInstallationsGraphData: Action<IDashboardModel, IPieChartData[]>;
  installationStatus: IInstallationStatus;
  setInstallationStatus: Action<IDashboardModel, IInstallationStatus>;

  getInstallationMapItems: Thunk<IDashboardModel, void, void, IStoreModel>;

  installationMapItems: IInstallationMapItem[];
  setInstallationMapItems: Action<IDashboardModel, IInstallationMapItem[]>;

  getMapFilterInstallations: Thunk<IDashboardModel, { lower: number, upper: number }, void, IStoreModel>;
  mapFilterInstallations: IDeviceView[];
  setMapFilterInstallations: Action<IDashboardModel, IDeviceView[]>;

  onMapFilterSelectionChanges: ThunkOn<IDashboardModel, void, IStoreModel>;
  onTagFilterSelectionChanges: ThunkOn<IDashboardModel, void, IStoreModel>;
}

const dashboardModel: IDashboardModel = {
  resetModelData: action((state) => {
    state.dashboardInstallations = [];
    state.installationMapItems = [];
    state.upcomingInstallations = [];
    state.upcomingLocations = [];
    state.installationStatus = defaultInstallationStatus;
    state.taskInfo = defaultTasksInfo;
  }),

  upcomingLocations: [],
  setUpcomingLocations: action((state, payload) => {
    state.upcomingLocations = payload;
  }),
  getUpcomingLocations: thunk(async (actions, payload, { getStoreActions }) => {

    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.dashboardUpcomingLocations });
    if (requestResponse.success) {
      const data = requestResponse.data as IUpcomingLocations[];
      const updatedLocations: DashboardLocationTableRow[] = data.map((location) => {
        return {
          ...location,
          id: location.locationId
        }
      })
      actions.setUpcomingLocations(updatedLocations);
    } else {
      actions.setUpcomingLocations([]);
    }
  }),

  installationsGraphData: defaultInstallationsGraphData,
  setInstallationsGraphData: action((state, payload) => {
    state.installationsGraphData = payload;
  }),
  installationsConnectionData: defaultInstallationConnectionGraphData,
  setInstallationsConnectionData: action((state, payload) => {
    state.installationsConnectionData = payload;
  }),
  installationStatus: defaultInstallationStatus,
  setInstallationStatus: action((state, payload) => {
    state.installationStatus = payload;
  }),

  getUpcomingInstallations: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.dashboardUpcomingInstallations });
    if (!requestResponse.success) {
      actions.setUpcomingInstallations([]);
      return;
    }

    const installations = requestResponse.data as IDeviceView[];
    const firmwareReleases = getStoreState().firmwareReleasesModel.firmwareReleases;
    const extendedTableData = convertIDeviceViewToDashboardInstallation({
      firmwareReleases,
      installations
    });
    actions.setUpcomingInstallations(extendedTableData);
  }),
  upcomingInstallations: [],
  setUpcomingInstallations: action((state, payload) => {
    state.upcomingInstallations = payload;
  }),

  getInstallationMapItems: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const filterSelection = getStoreState().markerFilterModel.filterSelections;
    const params: FilterSelection = {
      ...filterSelection
    };
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.dashboardMap, params });
    if (requestResponse.success) {
      const installationMapItems = requestResponse.data as IInstallationMapItem[];
      actions.setInstallationMapItems(installationMapItems);
    } else {
      actions.setInstallationMapItems([]);
    }
  }),
  installationMapItems: [],
  setInstallationMapItems: action((state, payload) => {
    state.installationMapItems = payload;
  }),

  getMapFilterInstallations: thunk(async (actions, payload, { getStoreActions }) => {
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/map-filter-selection/', params: { ...payload } });
    if (requestResponse.success) {
      const mapFilterInstallations = requestResponse.data as IDeviceView[];
      actions.setMapFilterInstallations(mapFilterInstallations);
    } else {
      actions.setMapFilterInstallations([]);
    }


  }),
  mapFilterInstallations: [],
  setMapFilterInstallations: action((state, payload) => {
    state.mapFilterInstallations = payload;
  }),

  getDashboardInstallations: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    let installationsMonitoring = 0;
    let installationsGood = 0;
    let installationsOneMonth = 0;
    let installationsOneWeek = 0;
    let installationsOverdue = 0;

    let installationsOnline = 0;
    let installationsNotSeen = 0;
    let installationsInactive = 0;
    let installationsPending = 0;

    const filterSelection = getStoreState().markerFilterModel.filterSelections;
    const params: FilterSelection = {
      ...filterSelection
    };

    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.dashboardInstallations, params });
    if (requestResponse.success) {
      const dashboardInstallations = requestResponse.data as IDashboardInstallations[];
      actions.setDashboardInstallations(dashboardInstallations);

      for (const installationMapItem of dashboardInstallations) {
        switch (installationMapItem.connectionStatus) {
          case DeviceStatus.Inactive:
            installationsInactive++;
            break;
          case DeviceStatus.NotSeen:
            installationsNotSeen++;
            break;
          case DeviceStatus.Online:
            installationsOnline++;
            break;
          case DeviceStatus.Pending:
            installationsPending++;
            break;
        }

        switch (installationMapItem.consumptionStatus) {
          case ConsumptionStatus.Good:
            installationsGood++;
            break;
          case ConsumptionStatus.Monitoring:
            installationsMonitoring++;
            break;
          case ConsumptionStatus.OneMonth:
            installationsOneMonth++;
            break;
          case ConsumptionStatus.OneWeek:
            installationsOneMonth++;
            installationsOneWeek++;
            break;
          case ConsumptionStatus.Overdue:
            installationsOverdue++;
            break;
        }
      }
    }

    const installationTaskData: IPieChartData[] = _.cloneDeep(defaultInstallationsGraphData);
    installationTaskData[0].value = installationsGood;
    installationTaskData[1].value = installationsOneMonth;
    installationTaskData[2].value = installationsOneWeek;
    installationTaskData[3].value = installationsOverdue;
    actions.setInstallationsGraphData(installationTaskData);

    const installationConnectionData: IPieChartData[] = _.cloneDeep(defaultInstallationConnectionGraphData);
    installationConnectionData[0].value = installationsOnline;
    installationConnectionData[1].value = installationsNotSeen;
    installationConnectionData[2].value = installationsInactive;
    installationConnectionData[3].value = installationsPending;
    actions.setInstallationsConnectionData(installationConnectionData);

    const installationStatusUpdate: IInstallationStatus = {
      monitoring: installationsMonitoring,
      good: installationsGood,
      inactive: installationsInactive,
      notSeen: installationsNotSeen,
      oneMonth: installationsOneMonth,
      oneWeek: installationsOneWeek,
      online: installationsOnline,
      overdue: installationsOverdue,
      pending: installationsPending,
    };

    actions.setInstallationStatus(installationStatusUpdate);
  }),

  dashboardInstallations: [],
  setDashboardInstallations: action((state, payload) => {
    state.dashboardInstallations = payload;
  }),
  installationsCount: computed((state) => state.dashboardInstallations.length),

  getTaskInfo: thunk(async (actions, payload, { getStoreActions }) => {
    actions.setLoadingTasks(true);
    const requestResponse = await getStoreActions().serverRequestsModel.get({ route: Routes.taskInfo });
    if (requestResponse.success) {
      const taskInfo = requestResponse.data as ITasksInfo;
      actions.setTaskInfo(taskInfo);
    } else {
      actions.setTaskInfo(defaultTasksInfo);
    }
    actions.setLoadingTasks(false);
  }),
  loadingTasks: true,
  setLoadingTasks: action((state, payload) => {
    state.loadingTasks = payload;
  }),
  setTaskInfo: action((state, payload) => {
    state.taskInfo = payload;
  }),
  taskInfo: defaultTasksInfo,

  onMapFilterSelectionChanges: thunkOn(
    (actions, storeActions) => [
      storeActions.markerFilterModel.setStatusSelection,
      storeActions.markerFilterModel.setDaysLeftSelection,
    ],
    async (actions, payload) => {
      actions.setLoadingTasks(true);
      await actions.getInstallationMapItems();
      actions.setLoadingTasks(false);
    },
  ),

  onTagFilterSelectionChanges: thunkOn(
    (actions, storeActions) => [
      storeActions.markerFilterModel.setSelectedTags,
      storeActions.markerFilterModel.setTagFilteringMode
    ],
    async (actions, payload) => {
      actions.setLoadingTasks(true);
      await actions.getInstallationMapItems();
      await actions.getDashboardInstallations();
      actions.setLoadingTasks(false);
    },
  ),
};

export default dashboardModel;
