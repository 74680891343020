import { Box } from '@mui/material';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../Store';
import { ItemsPerPage } from '../../../common/constsFrontend';
import FpButton from '../../../modules/common/components/FpButton/FpButton';
import FpGrid from '../../../modules/common/components/FpGrid/FpGrid';
import FpGridItem from '../../../modules/common/components/FpGrid/FpGridItem';
import FpList from '../../../modules/common/components/FpList/FpList';
import FpLoader from '../../../modules/common/components/FpLoader/FpLoader';
import FpModalConfirmSimple from '../../../modules/common/components/FpModalConfirmSimple/FpModalConfirmSimple';
import FpPagination from '../../../modules/common/components/FpPagination/FpPagination';
import FpText from '../../../modules/common/components/FpText/FpText';
import FpTextField from '../../../modules/common/components/FpTextField/FpTextField';
import LogDetailsItem from './LogDetailsItem';
import { findAndExtractData, LogSuperDetails, parseAndInsertData } from './logs.utils';

interface ILogDetailsList {
  flowplanDeviceId: string;
}

const LogDetailsList = ({ flowplanDeviceId }: ILogDetailsList): JSX.Element => {
  const loadingLogDetails = useStoreState((state) => state.logsModel.loadingLogDetails);
  const logDetails = useStoreState((state) => state.logsModel.logDetails);
  const retrieveLogDetails = useStoreActions((actions) => actions.logsModel.retrieveLogDetails);

  useEffect(() => {
    if (!flowplanDeviceId) {
      return;
    }
    retrieveLogDetails(flowplanDeviceId);
  }, [flowplanDeviceId, retrieveLogDetails]);

  const [currentPage, setCurrentPage] = useState(1);
  const [inputValue, setInputValue] = useState(String(currentPage));
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [superDetails, setSuperDetails] = useState<LogSuperDetails[]>([]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    setInputValue(String(page));
  };

  const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    setInputValue(value);
    if (value === '') {
      return; // Do nothing if the input is empty
    }
    const valueNum = Number(value);
    if (!isNaN(valueNum) && valueNum >= 1 && valueNum <= totalpages) {
      setCurrentPage(valueNum);
    }
  };

  const viewLogInMoreDetails = (logData: string) => {
    setShowMoreDetails(true);

    const details: LogSuperDetails[] = [];

    const timeStampSplit = logData.split(' - ');
    const timeStamp = timeStampSplit[0];

    details.push({
      data: [],
      title: timeStamp,
    });

    const flowEvents = findAndExtractData({
      data: logData,
      keyWord: '"flowEvent":',
      pattern: '],"',
    });

    const heartbeat = findAndExtractData({
      data: logData,
      keyWord: '"heartbeat":',
    });

    const externalSensorEvent = findAndExtractData({
      data: logData,
      keyWord: '"externalSensorEvent":',
    });

    const lastActivity = findAndExtractData({
      data: logData,
      keyWord: '"lastActivity":',
    });

    const gsmNbMetadata = findAndExtractData({
      data: logData,
      keyWord: '"gsmNbMetadata":',
    });

    const announcement = findAndExtractData({
      data: logData,
      keyWord: '"announcement":',
    });

    const configuration = findAndExtractData({
      data: logData,
      keyWord: '"configuration":',
    });

    const batteryInfo = findAndExtractData({
      data: logData,
      keyWord: '"batteryInfo":',
    });

    parseAndInsertData({
      data: flowEvents,
      details,
      pattern: '},',
      titlePattern: ':[',
    });
    parseAndInsertData({
      data: heartbeat,
      details,
    });
    parseAndInsertData({
      data: externalSensorEvent,
      details,
    });
    parseAndInsertData({
      data: lastActivity,
      details,
    });
    parseAndInsertData({
      data: gsmNbMetadata,
      details,
      pattern: '],',
    });
    parseAndInsertData({
      data: announcement,
      details,
    });
    parseAndInsertData({
      data: configuration,
      details,
    });
    parseAndInsertData({
      data: batteryInfo,
      details,
    });

    setSuperDetails(details);
  };

  const totalpages = Math.ceil(logDetails.length / ItemsPerPage.Fifty);
  const indexMax = currentPage * ItemsPerPage.Fifty;
  const indexMin = indexMax - ItemsPerPage.Fifty;

  const actionsGridPropsPagination = { xs: 12, sm: 6, md: 4, lg: 4 };
  const actionsGridPropsSmall = { xs: 12, sm: 6, md: 4, lg: 4 };

  return (
    <Fragment>
      <FpGrid spacing={{ sm: 1 }} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 0 }} sx={{ textAlign: 'center' }}>
        <FpGridItem {...actionsGridPropsPagination}>
          <FpPagination count={totalpages} page={currentPage} onChange={handlePageChange} color="primary" />
        </FpGridItem>

        <FpGridItem {...actionsGridPropsSmall}>
          <FpTextField
            inputValue={inputValue}
            inputKey="currentPage"
            inputText="Set current page"
            onlyNumbers={true}
            handleInput={(ev: ChangeEvent<HTMLInputElement>) => handleInputChange(ev)}
            isRequired={true}
            variant="standard"
          />
        </FpGridItem>
        <FpGridItem {...actionsGridPropsSmall} sx={{ textAlign: 'right' }}>
          <FpButton
            color="secondary"
            onClick={() => {
              retrieveLogDetails(flowplanDeviceId);
            }}
          >
            Update logs
          </FpButton>
        </FpGridItem>
      </FpGrid>

      <FpList>
        {logDetails.map((logsItem, index) => {
          if (index >= indexMin && index <= indexMax) {
            return (
              <LogDetailsItem
                key={index}
                data={logsItem}
                onClick={() => {
                  viewLogInMoreDetails(logsItem);
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </FpList>
      <FpModalConfirmSimple
        closeModal={() => {
          setShowMoreDetails(false);
        }}
        fullScreen
        label="Close"
        maxWidth="xxl"
        modalTitle="Payload details"
        showModal={showMoreDetails}
      >
        {superDetails.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
            <FpText variant="body1" sx={{ fontSize: '1.2rem' }}>
              {item.title}
            </FpText>
            {item.data.map((info, index) => {
              return (
                <FpText key={index} variant="body1" sx={{ marginLeft: '2rem' }}>
                  {info}
                </FpText>
              );
            })}
          </Box>
        ))}
      </FpModalConfirmSimple>
      <FpLoader loading={loadingLogDetails} variant="h5" text="" />
    </Fragment>
  );
};

export default LogDetailsList;
