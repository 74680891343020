import { Divider, SxProps, Theme } from '@mui/material';

type Props = {
  hidden?: boolean;
  marginY?: '0rem' | '0.25rem' | '0.5rem' | '1rem';
  height?: '0rem' | '0.25rem' | '0.5rem' | '1rem' | '1.5rem' | '2rem';
  sx?: SxProps<Theme>;
  orientation?: 'horizontal' | 'vertical';
};

const FpDivider = ({ height = '2rem', hidden = false, marginY = '0.25rem', orientation = 'horizontal', sx }: Props) => {
  return (
    <Divider
      orientation={orientation}
      sx={{ ...sx, height, opacity: hidden ? 0 : 1, marginTop: marginY, marginBottom: marginY }}
    />
  );
};

FpDivider.displayName = 'Divider';

export default FpDivider;
