import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { IVersionHistoryBackoffice } from '@flowplan/flowplan-shared/lib/versionHistory/versionHistory';
import { IResponse } from '@hsjakobsen/utilities';
import { useEffect, useState } from 'react';
import { useStoreActions } from '../../../../Store';
import Footer from '../../../navigation/components/Footer/Footer';
import CacheBusterInfo from './CacheBusterInfo';

interface IProps {
  children: JSX.Element;
}

const CacheBuster = ({ children }: IProps): JSX.Element => {
  const getRequest = useStoreActions((actions) => actions.serverRequestsModel.get);

  const [hasCheckedCache, setHasCheckedCache] = useState(false);
  const [serverVersion, setServerVersion] = useState('');
  const [serverVersionDescription, setServerVersionDescription] = useState('');
  const [showReload, setShowReload] = useState(false);

  useEffect(() => {
    getRequest({ route: Routes.versionHistoryBackoffice, noTokenCheck: true }).then((response: IResponse) => {
      const serverVersion = response.data as IVersionHistoryBackoffice;
      setHasCheckedCache(true);
      if (serverVersion) {
        setServerVersion(serverVersion.name);
        setServerVersionDescription(serverVersion.description);
        setShowReload(true);
      } else {
        setServerVersion('Server unavailable');
      }
    });
  }, [getRequest]);

  const currentVersion = import.meta.env.REACT_APP_VERSION;

  if (currentVersion && currentVersion !== serverVersion) {
    return (
      <>
        <CacheBusterInfo
          hasCheckedCache={hasCheckedCache}
          serverVersion={serverVersion}
          serverVersionDescription={serverVersionDescription}
          showReload={showReload}
        />
        <Footer />
      </>
    );
  }

  return children;
};

export default CacheBuster;
