import MuiDialogContent, { DialogContentProps as MuiDialogContentProps } from '@mui/material/DialogContent';
import { forwardRef } from 'react';

const DialogContent = forwardRef<HTMLDivElement, MuiDialogContentProps>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiDialogContent
      dividers
      sx={{
        padding: '1rem',
      }}
      {...rest}
      ref={ref}
    >
      {children}
    </MuiDialogContent>
  );
});

DialogContent.displayName = 'DialogContent';

export default DialogContent;
