import { formatNumberNoDecimals } from '@flowplan/flowplan-shared';
import InfoIcon from '@mui/icons-material/Info';
import { Popover } from '@mui/material';
import { Fragment, useState } from 'react';
import FpButtonIcon from '../../../common/components/FpButtonIcon';
import FpDivider from '../../../common/components/FpDivider/FpDivider';
import FpText from '../../../common/components/FpText/FpText';

interface IEstimatePopup {
  showEstimate: boolean;
  usage: number;
  estimatedUsage: number;
  unit: string;
}

const EstimatePopup = ({ estimatedUsage, showEstimate, usage, unit }: IEstimatePopup): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!showEstimate) {
    return <></>;
  }

  const usageInfo = formatNumberNoDecimals(usage);
  const estimateInfo = formatNumberNoDecimals(estimatedUsage);

  return (
    <Fragment>
      <FpButtonIcon
        onClick={(event) => {
          event.stopPropagation();
          handleClick(event);
        }}
        aria-label="estimated values button"
        size="small"
      >
        <InfoIcon color="primary" />
      </FpButtonIcon>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FpText variant="body1" sx={{ paddingTop: 1, paddingLeft: 1 }}>
          Usage is being estimated.
        </FpText>
        <FpText variant="body1" sx={{ paddingTop: 1, paddingLeft: 1 }}>
          The estimate is based on installation data.
        </FpText>
        <FpDivider height="0rem" marginY="0.5rem" />
        <FpText variant="body1" sx={{ paddingLeft: 1 }}>
          Usage:{' '}
          <strong>
            {usageInfo} {unit}
          </strong>
        </FpText>
        <FpText variant="body1" sx={{ paddingLeft: 1, paddingBottom: 1 }}>
          Estimated:{' '}
          <strong>
            {estimateInfo} {unit}
          </strong>
        </FpText>
      </Popover>
    </Fragment>
  );
};

export default EstimatePopup;
