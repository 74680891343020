import React, { Fragment, useEffect, useState } from 'react';
import { List, Popup } from 'semantic-ui-react';
import usePlacesAutocomplete, {
  GetDetailsArgs,
  Suggestion,
  getDetails,
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { useStoreActions, useStoreState } from '../../../Store';
import DisabledInput from '../../Input/DisabledInput';
import TextInput from '../../Input/TextInput';
import { IPlaceAddress, IPlacesAutocomplete, parsePlaceDetails } from './GooglePlacesSearchInterfaces';
import FpContainer from '../../../modules/common/components/FpContainer/FpContainer';
import FpTextField from '../../../modules/common/components/FpTextField/FpTextField';

export const GooglePlacesSearch = ({
  alternateLayout,
  initialSearch,
  initialSearchTerm,
  inputFieldName,
  inputKey,
  shouldRender,
  locationInputChange,
  locationSelectedCallback,
  hideHeader = false,
  error = false,
  helpText: helpMessage = '',
  inputFieldSize = 'medium',
}: IPlacesAutocomplete): JSX.Element => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      bounds: { north: 71.963674, south: 31.908425, west: -19.184006, east: 40.921635 },
    },
    debounce: 300,
  });

  const googleAPILoaded = useStoreState((state) => state.externalServices.googleAPILoaded);

  const setGoogleAPISearching = useStoreActions((state) => state.externalServices.setGoogleAPISearching);
  const [showSearchResults, setShowSearchResults] = useState(false);

  useEffect(() => {
    setValue(initialSearchTerm, initialSearch);
  }, [initialSearch, initialSearchTerm, setValue]);

  useEffect(() => {
    setShowSearchResults(status === 'OK' && data.length !== 0);
  }, [status, data]);

  const handleSelect = (suggestion: Suggestion) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(suggestion.structured_formatting.main_text, false);
    clearSuggestions();
    setShowSearchResults(false);
    setGoogleAPISearching(true);

    getGeocode({ address: suggestion.description }).then((results) => {
      const coordinates = getLatLng(results[0]);

      const parameter: GetDetailsArgs = {
        // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
        placeId: suggestion.place_id,
        // Specify the return data that you want (optional)
        fields: ['name', 'formatted_address', 'address_component'],
      };

      getDetails(parameter)
        .then((details) => {
          if (typeof details === 'string') {
            setGoogleAPISearching(false);
          } else {
            const placeInfo: IPlaceAddress = parsePlaceDetails(details);
            locationSelectedCallback({ ...placeInfo, ...coordinates });
            setGoogleAPISearching(false);
          }
        })
        .catch((error) => {
          // setPlaceInfo(defaultPlaceInfo);
          setGoogleAPISearching(false);
        });
    });
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    if (locationInputChange) {
      locationInputChange();
    }
    if (e.currentTarget.value === '') {
      setShowSearchResults(false);
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <List.Item key={place_id} onClick={handleSelect(suggestion)}>
          <List.Icon name="map marker alternate" />
          <List.Content>
            <List.Header as="a">{main_text}</List.Header>
            <List.Description>{secondary_text}</List.Description>
          </List.Content>
        </List.Item>
      );
    });

  const inputField = alternateLayout ? (
    <FpContainer basic >
      <FpTextField
        fullWidth
        size={inputFieldSize}
        label={inputFieldName ?? "Search for an address"}
        inputKey={inputKey}
        handleInput={(e) => {
          handleInput(e);
        }}
        inputValue={value}
        margin="dense"
        variant="outlined"
        error={error}
        helperText={helpMessage}
      />
    </FpContainer>
  ) : (
    <TextInput
      inputValue={value}
      inputKey={inputKey}
      inputText={inputFieldName}
      handleInput={handleInput}
      isRequired={true}
    />
  );

  if (!shouldRender) {
    return <Fragment />;
  }

  if (!googleAPILoaded || !ready) {
    return <DisabledInput inputText="Name" inputValue="Loading address lookup" />;
  }

  return (
    <Popup
      onClose={() => {
        clearSuggestions();
        setShowSearchResults(false);
      }}
      on="click"
      open={showSearchResults}
      position="bottom left"
      wide
      trigger={inputField}
    >
      {status === 'OK' && (
        <List divided selection>
          {renderSuggestions()}
        </List>
      )}
    </Popup>
  );
};
