import { NotificationTypes } from '@flowplan/flowplan-shared';
import { IDropdownData } from '../../common/interfacesFrontend';

export const installationHistoryFilterOptions: IDropdownData[] = [
  { key: 0, text: 'Filter changed', value: NotificationTypes.WaterFilterChanged },
  { key: 1, text: 'Filter change overdue', value: NotificationTypes.WaterFilterOverdue },
  { key: 2, text: 'Unusual average', value: NotificationTypes.InstallationUnusualDailyAverage },
  { key: 3, text: 'Connection lost', value: NotificationTypes.InstallationConnectionLost },
  { key: 4, text: 'Low battery', value: NotificationTypes.InstallationLowBattery },
  { key: 5, text: 'Back online', value: NotificationTypes.InstallationBackOnline },
  { key: 6, text: 'Usage added', value: NotificationTypes.InstallationUsageAdded },
  { key: 7, text: 'Water Leak', value: NotificationTypes.LeakageTime },
  { key: 8, text: 'Leakage (flow)', value: NotificationTypes.LeakageFlow },
  { key: 9, text: 'Installation added', value: NotificationTypes.InstallationAdded },
  { key: 10, text: 'Filter change soon', value: NotificationTypes.WaterFilterSoonToBeChanged },
  { key: 11, text: 'Serial changed', value: NotificationTypes.InstallationSerialChanged },
  { key: 12, text: 'Average reset', value: NotificationTypes.InstallationAverageReset },
  { key: 13, text: 'Note', value: NotificationTypes.Note },
  { key: 14, text: 'Maintenance performed', value: NotificationTypes.ServicePerformed },
  { key: 15, text: 'Maintenance overdue', value: NotificationTypes.ServiceOverdue },
  { key: 16, text: 'Upcoming maintenance', value: NotificationTypes.ServiceUpcoming },
  { key: 17, text: 'Battery changed', value: NotificationTypes.InstallationBatteryChanged },
  { key: 18, text: 'Stock running out', value: NotificationTypes.StockRunningOut },
  { key: 19, text: 'Reset reconnect attempt', value: NotificationTypes.BeamCancelLocation },
  { key: 20, text: 'Low usage action', value: NotificationTypes.LowUsageAction },
  { key: 21, text: 'Tag added', value: NotificationTypes.InstallationTagAdded },
  { key: 22, text: 'Tag updated', value: NotificationTypes.InstallationTagUpdated },
  { key: 23, text: 'Tag removed', value: NotificationTypes.InstallationTagRemoved },

];