import OpacityIcon from '@mui/icons-material/Opacity';
import { useSnackbar, VariantType } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../../../Store';
import FpButtonOutlinedAligned from '../../../common/components/FpButtonOutlined/OutlinedButtonAligned';
import FpText from '../../../common/components/FpText/FpText';
import { Paths } from '../../../navigation/containers/Navigation/navigation-types';

interface Props {
  featureEnabled: boolean;
  alwaysLeftAlign?: boolean;
}
const InstallationAddUsage = (props: Props): JSX.Element => {
  const { alwaysLeftAlign } = props;

  const access = useStoreState((state) => state.authModel.access);
  const baseFilter = useStoreState((state) => state.graphSettingsModel.selectedInstallation);

  const addUsage = useStoreActions((actions) => actions.deviceActionsModel.addUsage);
  const getFilterDetailsData = useStoreActions((actions) => actions.deviceActionsModel.getFilterDetailsData);
  const confirmActionToggle = useStoreActions((actions) => actions.modalModel.confirmActionToggle);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const requestAddUsage = () => {
    const deviceIdToShow = baseFilter.infoBasic.flowplanDeviceId.substring(6);
    const confirmValue = baseFilter.infoBasic.name + ' (' + deviceIdToShow + ')';
    const title = (
      <>
        <FpText componentType="span" variant="h6">
          Add usage to:{' '}
        </FpText>
        <FpText componentType="span" variant="h6" className="do-not-translate">
          {baseFilter.infoBasic.name}
        </FpText>
      </>
    );
    confirmActionToggle({
      confirmValue,
      showModal: true,
      confirmValueIsNumber: true,
      text: 'Add a number of litres:',
      title,
      confirmCallback: confirmAddUsage,
    });
  };

  const confirmAddUsage = async (litersToAdd: number) => {
    const requestResponse = await addUsage(litersToAdd);
    if (requestResponse.success) {
      const detailsResponse = await getFilterDetailsData();
      if (!detailsResponse) {
        navigate(Paths.installations);
        return;
      }
    }

    const feedback = requestResponse.success
      ? 'Installation usage added: ' + litersToAdd + ' litres'
      : 'Error: ' + requestResponse.message;
    const variant: VariantType = requestResponse.success ? 'success' : 'error';
    enqueueSnackbar(feedback, { variant });
  };

  return (
    <FpButtonOutlinedAligned
      alwaysLeftAlign={alwaysLeftAlign}
      disabled={!props.featureEnabled || !access.installations.manage}
      className="tealButton"
      icon={<OpacityIcon />}
      onClick={requestAddUsage}
      label="Add Usage"
    />
  );
};

export default InstallationAddUsage;
