import { ISimpleLineData } from '@flowplan/flowplan-shared/lib/interfaces/installationView';
import { memo } from 'react';
import { SelectedOption } from '../../../../common/constsFrontend';
import FpButton from '../../components/FpButton/FpButton';
import FpContainer from '../../components/FpContainer/FpContainer';
import FpGrid from '../../components/FpGrid/FpGrid';
import FpGridItem from '../../components/FpGrid/FpGridItem';
import FpLoader from '../../components/FpLoader/FpLoader';
import FpText from '../../components/FpText/FpText';
import DateLinechart from './DateLineChart';
import './LineChartWithControls.scss';

type ILineChartWithControls = {
  chartData: ISimpleLineData[];
  dataUnit: string;
  graphTitle: string;
  isLoading: boolean;
  placeholderText: string;
  selectedOption: number;
  domain?: [string | number, string | number];
  yAxisTicks?: number[];
  interactionFunction(OptionSelected: number): void;
};

const LineChartWithControls = memo(
  ({
    chartData,
    dataUnit,
    graphTitle,
    interactionFunction,
    isLoading,
    placeholderText,
    selectedOption,
    domain,
    yAxisTicks,
  }: ILineChartWithControls) => {
    const disabled = chartData === undefined;

    const handleClicks = (optionSelected: number) => {
      if (optionSelected === selectedOption) {
        return;
      }
      interactionFunction(optionSelected);
    };

    const actionsGridPropsTopRow = { xs: 12, sm: 12, md: 4, lg: 4 };

    return (
      <FpContainer basic sx={{ position: 'relative' }}>
        {graphTitle.length > 0 && <FpText variant="h5">{graphTitle}</FpText>}
        {chartData.length === 0 ? (
          <div className="linechart-placeholder">{placeholderText}</div>
        ) : (
          <DateLinechart data={chartData} dataUnit={dataUnit} domain={domain} yAxisTicks={yAxisTicks} />
        )}
        <FpGrid spacing={{ sm: 0.5 }} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
          <FpGridItem {...actionsGridPropsTopRow}>
            <FpButton
              disabled={disabled}
              sx={{ width: '100%' }}
              onClick={() => handleClicks(SelectedOption.Lifetime)}
              value={SelectedOption.Lifetime}
              color={SelectedOption.Lifetime === selectedOption ? 'success' : 'primary'}
            >
              Last 6 months
            </FpButton>
          </FpGridItem>
          <FpGridItem {...actionsGridPropsTopRow}>
            <FpButton
              disabled={disabled}
              sx={{ width: '100%' }}
              onClick={() => handleClicks(SelectedOption.LastMonth)}
              value={SelectedOption.LastMonth}
              color={SelectedOption.LastMonth === selectedOption ? 'success' : 'primary'}
            >
              Last month
            </FpButton>
          </FpGridItem>
          <FpGridItem {...actionsGridPropsTopRow}>
            <FpButton
              disabled={disabled}
              sx={{ width: '100%' }}
              onClick={() => handleClicks(SelectedOption.SevenDays)}
              value={SelectedOption.SevenDays}
              color={SelectedOption.SevenDays === selectedOption ? 'success' : 'primary'}
            >
              Seven days
            </FpButton>
          </FpGridItem>
        </FpGrid>
        <FpLoader loading={isLoading} variant="h5" text="" insideComponent />
      </FpContainer>
    );
  },
);

export default LineChartWithControls;
