import { INotificationSettingsRequestPackage, minimumStringLength } from '@flowplan/flowplan-shared';
import { Languages, RoleType } from '@flowplan/flowplan-shared/lib/users/users.database';
import { IUserRequestPackage } from '@flowplan/flowplan-shared/lib/users/users.requests';
import { IResponse, compareStrings, trimStringAndCheckLength, validateEmail } from '@hsjakobsen/utilities';
import { getPasswordText } from '../../../Utility/Texts';
import { IUserInput, IUserInputNotificationSettings } from '../../../common/interfacesInput';
import { isUserExternal, isUserGuest } from '../types/users-configs';

export const validateInputUsers = (inputData: IUserInput): IResponse => {
  const validationResponse: IResponse = {
    success: false,
    message: '',
  };

  if (!validateEmail(inputData.inputEmail)) {
    validationResponse.message = 'Email not valid';
    return validationResponse;
  }

  const usernameCheckResponse = trimStringAndCheckLength(
    inputData.inputUsername,
    'Username',
    minimumStringLength,
    false,
  );
  if (!usernameCheckResponse.success) {
    validationResponse.message = usernameCheckResponse.feedback;
    return validationResponse;
  }

  const nameCheckResponse = trimStringAndCheckLength(inputData.inputName, 'Name', minimumStringLength, false);
  if (!nameCheckResponse.success) {
    validationResponse.message = nameCheckResponse.feedback;
    return validationResponse;
  }

  let currentPassword = '';
  if (inputData.isUpdate) {
    if (inputData.inputCurrentPassword !== '') {
      const currentPasswordResponse = trimStringAndCheckLength(
        inputData.inputCurrentPassword,
        'Current password',
        minimumStringLength,
        false,
      );
      if (!currentPasswordResponse.success) {
        validationResponse.message = currentPasswordResponse.feedback;
        return validationResponse;
      }
      currentPassword = currentPasswordResponse.result;
    }
  }

  if (!inputData.isUpdate && isUserExternal(inputData.inputUserType)) {
    // when adding a new user, and it's an external user. 
    // It's a required to a clientId set
    if (!inputData.inputClientId) {
      validationResponse.message = 'Select company to restrict user to';
      return validationResponse;
    }
  }

  const newPasswordResponse = trimStringAndCheckLength(
    inputData.inputNewPassword,
    getPasswordText(inputData.isUpdate, false, false),
    minimumStringLength,
    false,
  );
  const repeatPasswordResponse = trimStringAndCheckLength(
    inputData.inputNewPasswordRepeat,
    getPasswordText(inputData.isUpdate, true, false),
    minimumStringLength,
    false,
  );

  let shouldAddPassword = true;

  if (currentPassword === '' && newPasswordResponse.result === '' && repeatPasswordResponse.result === '') {
    // it's an update, no current password nor updated passwords are filled in, skip password checks for updates
    shouldAddPassword = false;
  } else {
    if (!newPasswordResponse.success) {
      validationResponse.message = newPasswordResponse.feedback;
      return validationResponse;
    }

    if (!repeatPasswordResponse.success) {
      validationResponse.message = repeatPasswordResponse.feedback;
      return validationResponse;
    }

    if (compareStrings(newPasswordResponse.result, repeatPasswordResponse.result) !== 0) {
      validationResponse.message = "Passwords don't match";
      return validationResponse;
    }
  }

  validationResponse.success = true;

  const userRequestData: IUserRequestPackage & { language?: Languages } = {
    name: nameCheckResponse.result,
    username: usernameCheckResponse.result,
    email: inputData.inputEmail,
    role: inputData.inputUserType,
    language: inputData.inputUserLanguage
  };
  if (inputData.inputClientId) {
    userRequestData.clientId = inputData.inputClientId;
  }
  if (shouldAddPassword) {
    userRequestData.password = newPasswordResponse.result;
  }

  if (inputData.isUpdate) {
    userRequestData.id = inputData.id;
    if (shouldAddPassword) {
      userRequestData.currentPassword = currentPassword;
    }
  }
  validationResponse.data = userRequestData;
  console.log(inputData, userRequestData)

  return validationResponse;
};

interface IValidateInputNotificationSettings {
  inputData: IUserInputNotificationSettings;
  userType?: RoleType;
}

export const validateInputNotificationSettings = ({
  inputData,
  userType,
}: IValidateInputNotificationSettings): INotificationSettingsRequestPackage => {
  const notificationSettingsRequestPackage: INotificationSettingsRequestPackage = {
    alertEmails: 0,
    alertLeaks: 0,
    alertNotifications: 0,
    alertPushNotifications: 0,
    confirmationEmails: 0,
    confirmationNotifications: 0,
    confirmationPushNotifications: 0,
    locationSearch: inputData.locationSearch,
    success: false,
  };

  notificationSettingsRequestPackage.alertEmails = inputData.inputAlertEmails ? 1 : 0;
  notificationSettingsRequestPackage.alertLeaks = inputData.inputAlertLeaks ? 1 : 0;
  notificationSettingsRequestPackage.confirmationEmails = inputData.inputConfirmationEmails ? 1 : 0;
  notificationSettingsRequestPackage.success = true;
  // notificationSettingsRequestPackage.alertNotifications = inputData.alertNotifications ? 1 : 0;
  // notificationSettingsRequestPackage.alertPushNotifications = inputData.alertPushNotifications ? 1 : 0;
  // notificationSettingsRequestPackage.confirmationNotifications = inputData.confirmationNotifications ? 1 : 0;
  // notificationSettingsRequestPackage.confirmationPushNotifications = inputData.confirmationPushNotifications ? 1 : 0;

  if (!userType) {
    return notificationSettingsRequestPackage;
  }

  // Guest users should never receive notifications either from inside the app or via emails.
  if (isUserGuest(userType)) {
    notificationSettingsRequestPackage.alertEmails = 0;
    notificationSettingsRequestPackage.confirmationEmails = 0;
  }

  return notificationSettingsRequestPackage;
};
