import { DeviceStatus } from '@flowplan/flowplan-shared';
import { Routes } from '@flowplan/flowplan-shared/lib/api/routes';
import { BatteryType, BillingStatus, BillingTypes } from '@flowplan/flowplan-shared/lib/interfaces/networkEUIToFlowplanSerialDb';
import { action, thunk } from 'easy-peasy';
import { handlePostRequest, handlePutRequest } from '../server-requests/server-requests';
import { SerialsModel } from './serials-types';

export enum FlowplanCompanies {
    FLOWPLAN = 'Flowplan',
    FLOWPLAN_GRAVEYARD = 'Flowplan graveyard',
    FLOWPLAN_SWAP = 'Flowplan swap',
    FLOWPLAN_LOST_IN_FIELD = 'Flowplan Lost in field',
}

export type SerialInfo = {
    activationDate: string | null;
    assignmentDate: string | null;
    batteryTypeId: BatteryType;
    billingStatus: BillingStatus;
    billingType: BillingTypes;
    firmware: string | null;
    hardware: string;
    flowplanClient?: {
        id: number;
        name: string;
    },
    flowplanClientId: number | null;
    flowplanDeviceId: string;
    invoicedDate: string | null;
    networkDeviceId: string;
    payloadReceivedCount: number;
    firstPayloadPulsesCount: number | null;
    firstPayloadReceivedAt: string | null;
    lastPayloadPulsesCount: number | null;
    lastPayloadReceivedAt: string | null;
    sensor?: {
        id: number;
        hoursNotSeen: number;
        name: string;
        status: DeviceStatus;
        dataOverView: {
            averageSignalStrength: number;
            batteryPercentLeft: number;
        }
    }
}

const serialsModel: SerialsModel = {
    selectedSerialsFromInventory: [],
    setSelectedSerialsFromInventory: action((state, payload) => {
        state.selectedSerialsFromInventory = payload;
    }),

    addSerials: thunk(async (actions, payload) => {
        const route = Routes.BeamSerial + payload.flowplanClientId;
        const requestResponse = await handlePostRequest(payload.inventoryToAdd, route, true);
        return requestResponse;
    }),

    assignSerialToCompany: thunk(async (actions, payload) => {
        const route = Routes.BeamSerialAssign + payload.flowplanClientId;
        const requestResponse = await handlePutRequest({
            beamSerials: payload.inventoryToAdd,
            onlyUpdateStatus: payload.onlyUpdateStatus,
            wipeDataOnAssign: payload.wipeDataOnAssign
        }, route, true);
        return requestResponse;
    }),
    assignSerialToClient: thunk(async (actions, payload) => {
        const route = '/beam-serial/assign-client/' + payload.clientId;
        const requestResponse = await handlePutRequest({ beamSerials: payload.beamSerials }, route, true);
        return requestResponse;
    }),
    wipeDataNotInSystem: thunk(async (actions, payload) => {
        const requestResponse = await handlePutRequest({ beamSerials: payload }, '/beam-serial/wipe/', true);
        return requestResponse;
    }),
    changeBatteries: thunk(async (actions, payload) => {
        const requestResponse = await handlePutRequest(payload, '/beam-serial/change-battery/', true);
        return requestResponse;
    }),
    serialReplacementSwap: thunk(async (actions, payload) => {
        const requestResponse = await handlePutRequest(payload, '/beam-serial/swap/', true);
        return requestResponse;
    }),
    serialReplacementStatus: thunk(async (actions, payload) => {
        const requestResponse = await handlePutRequest(payload, '/beam-serial/status/', true);
        return requestResponse;
    }),
    getSerialInfo: thunk(async (actions, payload, { getStoreActions }) => {
        const requestResponse = await getStoreActions().serverRequestsModel.get({ route: '/beam-serial/serialInfo/' + payload });
        return requestResponse;
    }),
};

export default serialsModel;